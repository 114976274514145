import React from 'react';
import left from './../img/white/arrow_left_white_24dp.svg';
import right from './../img/white/arrow_right_white_24dp.svg';
import user_img from "./../img/user_img.png";
import logo from './../img/qsoft.ico';
import logout from './../img/black/logout_black_24dp.svg';
import * as ajax from './../ajax.js';
//import ArchiveButton from './../Components/ArchiveButton';
import DarkOrWhiteMode from './../Components/DarkOrWhiteMode';
import './../css/page_header.css';


class HeaderAdmin extends React.Component {

   constructor(props) {
      super(props);
      this.state = {
         companies: [],
         headerItems: [],
         archivedYears: []
      }
      this.loadCompanies();
   }

   componentDidMount() {
      this.loadCompanies();
   }

   getCompanyCombo = function () {
      var companiesData = this.state.companies;
      var items = [];

      let selectedValue = 0;

      //items.push(<option selected={false} key={"company_combo_0"} id={0} value={0} >VÁLASSZON KI EGY IRODÁT</option>);
      items.push(<option key={"company_combo_0"} id={0} value={0} >VÁLASSZON KI EGY IRODÁT</option>);

      for (var i = 0; i < companiesData.length; i++) {
         var company = companiesData[i];

         //var selected = (this.props.currentCompany == null && i === 0) ? true : this.props.currentCompany === company.id;

         //items.push(<option selected={selected} key={"company_combo_" + company.id} id={company.id} value={company.id} >{company.name}</option>);

         if ((this.props.currentCompany === null && i === 0) || this.props.currentCompany === company.id) {
            selectedValue = company.id;
         }

         items.push(<option key={"company_combo_" + company.id} id={company.id} value={company.id} >{company.name}</option>);
      }
      return <select
         defaultValue={selectedValue}
         onFocus={this.loadCompanies.bind(this)}
         style={{ marginRight: '20px', minWidth: '400px' }}
         onChange={this.setCompanyFilter.bind(this)}
         onClick={this.setCompanyFilter.bind(this)}
         className={"comboInput"}
      >
         {items}
      </select>
   }

   loadCompanies = function () {
      var self = this;
      try {
         const url = ajax.getUrlBase() + "modules/OfficeManager/officesComboLoader.php";
         return fetch(url, {
            method: "POST",
            //credentials: "include",
            //credentials: "*", // Tesztüzemben
            headers: new Headers({ "Content-Type": "application/json" }),
            body: JSON.stringify({})
         }).then(response => {
            if (response.ok) {
               return response.json().then(response => {
                  self.setState({ companies: response.data });
               }).catch(function (jsonError) {
                  if (null == jsonError) {
                     console.error("Fetch JSON error:");
                  } else {
                     console.error("Fetch JSON error:" + String(jsonError));
                  }
               });
            } else {
               console.error("HTTP status error:", response.statusText);
            }
         }).catch(function (error) {
            console.error("Fetch error:", error);
            if (null == error) {
               console.error("Fetch error:");
            } else {
               console.error("Fetch error:" + String(error));
            }
         });
      } catch (ex) {
         console.error("Fetch exception:", String(ex));
      }
   }

   getUserTypeName = function (userType) {
      switch (userType) {
         case "ADMIN":
            return "Adminisztrátor";
         case "OFFICE":
            return "Iroda";
         case "CHIEF_ACCOUNTANT":
            return "Főkönyvelő";
         case "ACCOUNTANT":
            return "Könyvelő";
         case "COMPANY":
            return "Cég";
         case "OPERATOR":
            return "Kezelő";
         default:
            return "";
      }
   }

   getHeaderContent = function () {
      var firstName = this.props.firstName;
      var lastName = (this.props.lastName != null) ? this.props.lastName : '';
      var userTypeName = this.getUserTypeName(this.props.userType);
      var yearChooser = this.getYearChooser();
      var companyCombo = this.getCompanyCombo();

      let circleType = 'userImg';

      if (this.props.userType === 'ADMIN') {
         circleType += ' circleAdmin';
      } else if (this.props.userType === 'CHIEF_ACCOUNTANT') {
         circleType += ' circleChiefAccountant';
      } else if (this.props.userType === 'ACCOUNTANT') {
         circleType += ' circleAccountant';
      }

      return <div style={{ marginRight: '20px', display: 'flex', alignItems: 'center' }}>
         <DarkOrWhiteMode blackTheme={this.props.blackTheme} setTheme={this.props.setTheme.bind(this)} />
         {yearChooser}
         {companyCombo}
         <div style={{ whiteSpace: 'nowrap' }} className="loggedInUserText">{'Bejelentkezve: ' + firstName + ' ' + lastName + ' - ' + userTypeName}</div>
         <div className={circleType} style={{ backgroundImage: `url(${user_img})` }}></div>
         <img
            style={{ cursor: 'pointer' }}
            className="logoutImage"
            alt="Kijelentkezés"
            title="Kijelentkezés"
            src={logout}
            onClick={this.logOut.bind(this)}
         />
      </div>
   }

   logOut = function () {
      try {
         const url = ajax.getUrlBase() + "secure/logOut.php";

         return fetch(url, {
            method: "POST",
            //credentials: "include",
            //credentials: "*", // Tesztüzemben
            headers: new Headers({ "Content-Type": "application/json" }),
            body: JSON.stringify({})
         }).then(response => {
            if (response.ok) {
               return response.json().then(response => {
                  this.props.logOut();
               }).catch(function (jsonError) {
                  if (null == jsonError) {
                     console.error("Fetch JSON error:");
                  } else {
                     console.error("Fetch JSON error:" + String(jsonError));
                  }
               });
            } else {
               console.error("HTTP status error:", response.statusText);
            }
         }).catch(function (error) {
            console.error("Fetch error:", error);
            if (null == error) {
               console.error("Fetch error:");
            } else {
               console.error("Fetch error:" + String(error));
            }
         });
      } catch (ex) {
         console.error("Fetch exception:", String(ex));
      }
   }

   setCompanyFilter = function (e) {
      var selectedCompanyId = e.target.value;
      var self = this;
      try {
         const url = ajax.getUrlBase() + "/modules/CompanyManager/setCurrentCompany.php";

         return fetch(url, {
            method: "POST",
            //credentials: "include",
            //credentials: "*", // Tesztüzemben
            headers: new Headers({ "Content-Type": "application/json" }),
            body: JSON.stringify({ id: selectedCompanyId })
         }).then(response => {
            if (response.ok) {
               return response.json().then(response => {
                  self.props.setCurrentCompany(selectedCompanyId);
               }).catch(function (jsonError) {
                  if (null == jsonError) {
                     console.error("Fetch JSON error:");
                  } else {
                     console.error("Fetch JSON error:" + String(jsonError));
                  }
               });
            } else {
               console.error("HTTP status error:", response.statusText);
            }
         }).catch(function (error) {
            console.error("Fetch error:", error);
            if (null == error) {
               console.error("Fetch error:");
            } else {
               console.error("Fetch error:" + String(error));
            }
         });
      } catch (ex) {
         console.error("Fetch exception:", String(ex));
      }
   }

   getYearChooser = function () {
      var options = [];
      if (this.state.archivedYears == null) {
         return '';
      }

      if (this.state.archivedYears.length === 0) {
         return '';
      }

      for (var i = 0; i < this.state.archivedYears.length; i++) {
         var archivedYear = this.state.archivedYears[i];
         options.push(<option

            id={'arcivedYear_' + archivedYear}
            key={'arcivedYear_' + archivedYear}
            value={archivedYear}
         >{archivedYear}</option>);
      }

      return <select
         value={this.state.currentYear}
         onChange={this.changeCurrentArchive.bind(this)}
      >{options}</select>
   }

   render() {
      var menuStyle = (this.props.collapsed) ? "menuHeaderCollapsed" : "menuHeader";
      var contenStyle = (this.props.collapsed) ? "contentHeaderMenuCollapsed" : "contentHeader";
      var arrow = (this.props.collapsed) ? right : left;
      var menuTitle = (this.props.collapsed) ? "" : "MENÜ";

      return (
         <div className={"header"} >
            <img className={"headerLogoStyle"} src={logo} alt="QualitySoft Logo" />
            <div className={"headerText"}>QualitySoft ügyfélkapu</div>
            <div className="menuAndContentHeader">
               <div onClick={this.props.collapseMenu.bind(this)} className={menuStyle}>

                  <div style={{ border: 'solid 2px #FFFFFF22', margin: '5px', cursor: 'pointer' }}>
                     <img style={{ paddingTop: '10px' }} src={arrow} alt="Menü összecsukó nyíl" /></div>
                  <div className={"menuHeaderText"}>{menuTitle}</div>

               </div>
               <div className={contenStyle}>{this.getHeaderContent()}</div>
            </div>
         </div>
      )
   }
}

export default HeaderAdmin;