import React from 'react';
import {ProjectDescriptor, FieldDescriptor, Layout}  from './../../utils.js';
import * as ajax from './../../ajax.js';
import Panel from './../../Components/Panel';
import BasicForm from './../../Components/BasicForm';


class BankiImportok extends React.Component {

    constructor(props) {
        super(props);
        var endpoint = ajax.getUrlBase() + 'modules/BankiImport/';
        var formTitle = 'Banki import fájl feltöltés';
        var projectDescriptor = new ProjectDescriptor('BankiImport', endpoint, formTitle);
        projectDescriptor.setFormDimensions(545, 280);

        var id = new FieldDescriptor(true, FieldDescriptor.TYPE_NUMBER, 'id', 'Iktatószám', '');



        var statusz = new FieldDescriptor(false, FieldDescriptor.TYPE_SELECT, 'statusz', 'Státusz', [{id: 0, label: 'Iktatva'}, {id: 1, label: 'Átadva'}]);
        var iktatasDatuma = new FieldDescriptor(false, FieldDescriptor.TYPE_DATE, 'iktatasDatuma', 'Iktatás dátuma', '');

        var atvetelDatuma = new FieldDescriptor(false, FieldDescriptor.TYPE_DATE, 'atvetelDatuma', 'Átvétel dátuma', '');

        var doc = new FieldDescriptor(false, FieldDescriptor.TYPE_FILE_UPLOADER, 'doc', 'Banki import fájl', {filename: '', file: ''});
        doc.setVisibleTableField(false);


        var fajlnev = new FieldDescriptor(false, FieldDescriptor.TYPE_TEXT, 'filename', 'Fájl neve', '');
        fajlnev.setVisibleFormField(false);
        
        var pdf = new FieldDescriptor(false, FieldDescriptor.TYPE_FILE_UPLOADER, 'pdf', 'Bankkivonat PDF', {filename: '', file: ''});
        pdf.setVisibleTableField(false);
        pdf.setJump(2);

        var pdfNev = new FieldDescriptor(false, FieldDescriptor.TYPE_TEXT, 'pdfName', 'PDF neve', '');
        pdfNev.setVisibleFormField(false);
        


        var fileExtension = new FieldDescriptor(false, FieldDescriptor.TYPE_TEXT, 'fileExtension', 'Fájl típus', '');
        fileExtension.setVisibleFormField(false);

        // var sharedDoc = new FieldDescriptor(false, FieldDescriptor.TYPE_BOOLEAN, 'sharedDoc', 'Megosztva', '');
        // sharedDoc.setJump(2);
        
         var commentText = new FieldDescriptor(false, FieldDescriptor.TYPE_COMMENT_FIELD, 'commentText', 'Megjegyzés', '');
         commentText.setCommentFieldWidth(397);
         commentText.setCommentFieldMaxTextLenght(35);
         commentText.setJump(2);

        projectDescriptor.addFieldDescriptor(id);
        projectDescriptor.addFieldDescriptor(statusz);
        projectDescriptor.addFieldDescriptor(iktatasDatuma);
        projectDescriptor.addFieldDescriptor(atvetelDatuma);
        projectDescriptor.addFieldDescriptor(doc);
        projectDescriptor.addFieldDescriptor(fajlnev);
        projectDescriptor.addFieldDescriptor(pdf);
        projectDescriptor.addFieldDescriptor(pdfNev);
        projectDescriptor.addFieldDescriptor(fileExtension);
        projectDescriptor.addFieldDescriptor(commentText);
        //projectDescriptor.addFieldDescriptor(sharedDoc);


        var layout = new Layout();
        layout.addColumns([0, 120, 270, 400])
        layout.addRows([0, 34, 68, 102, 136])
        this.projectDescriptor = projectDescriptor;
        this.layout = layout;
    }

    render() {
        return <Panel><BasicForm blackTheme={this.props.blackTheme} alertDialog={this.props.alertDialog.bind(this)} layout={this.layout} projectDescriptor={this.projectDescriptor} /></Panel>
    }
}

export default BankiImportok;