import React from 'react';
import {ProjectDescriptor, FieldDescriptor,Layout}  from './../../utils.js';
import * as ajax from './../../ajax.js';
import Panel from './../../Components/Panel';
import BasicForm from './../../Components/BasicForm';


class Kimeno extends React.Component {

    constructor(props) {
        super(props);
          var endpoint = ajax.getUrlBase() + 'modules/InvoiceOutgoing/';
            var formTitle = 'Kimenő számla NAV.XML feltöltés';
            var projectDescriptor = new ProjectDescriptor('InvoiceOutgoing',endpoint,formTitle);
            projectDescriptor.setFormDimensions(545,230);
            
            var id = new FieldDescriptor(true, FieldDescriptor.TYPE_NUMBER, 'id', 'Iktatószám', '');
            var statusz = new FieldDescriptor(false, FieldDescriptor.TYPE_SELECT, 'statusz', 'Státusz', [{id:0,label:'Iktatva'},{id:1,label:'Átadva'}]);
            var iktatasDatuma = new FieldDescriptor(false, FieldDescriptor.TYPE_DATE, 'iktatasDatuma', 'Iktatás dátuma', '');
            
            var atvetelDatuma = new FieldDescriptor(false, FieldDescriptor.TYPE_DATE, 'atvetelDatuma', 'Átvétel dátuma', '');
            
            var doc = new FieldDescriptor(false, FieldDescriptor.TYPE_FILE_UPLOADER, 'doc', 'Fájl', {filename:'',file:''});
            doc.setVisibleTableField(false);
            
            var fajlnev = new FieldDescriptor(false, FieldDescriptor.TYPE_TEXT, 'filename', 'Fájl neve', '');
            fajlnev.setVisibleFormField(false);
            
            var fileExtension = new FieldDescriptor(false, FieldDescriptor.TYPE_TEXT, 'fileExtension', 'Fájl típus', '');
            fileExtension.setVisibleFormField(false);

            //var sharedDoc = new FieldDescriptor(false, FieldDescriptor.TYPE_BOOLEAN, 'sharedDoc', 'Megosztva', '');
            //sharedDoc.setJump(2);

            projectDescriptor.addFieldDescriptor(id);
            projectDescriptor.addFieldDescriptor(statusz);
            projectDescriptor.addFieldDescriptor(iktatasDatuma);
            projectDescriptor.addFieldDescriptor(atvetelDatuma);
            projectDescriptor.addFieldDescriptor(doc);
            projectDescriptor.addFieldDescriptor(fajlnev);
            projectDescriptor.addFieldDescriptor(fileExtension);
            //projectDescriptor.addFieldDescriptor(sharedDoc);
            

            var layout = new Layout();
            layout.addColumns([0, 120, 270, 400])
            layout.addRows([0, 34, 68, 102])
            this.projectDescriptor=projectDescriptor;
            this.layout = layout;
    }

    render() {
        return <Panel><BasicForm blackTheme={this.props.blackTheme} alertDialog={this.props.alertDialog.bind(this)} layout={this.layout} projectDescriptor={this.projectDescriptor} /></Panel>
    }
}

export default Kimeno;