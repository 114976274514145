import React from 'react';

// Dakt theme
import dashboardWhite from './../img/white/dashboard_white_24dp.svg';

import formsWhite from './../img/white/view_list_white_24dp.svg';
import pagesWhite from './../img/white/description_white_24dp.svg';
import profileWhite from './../img/white/people_white_24dp.svg';
import widgetsWhite from './../img/white/widgets_white_24dp.svg';

import outgoingWhite from   './../img/white/outbox_white_24dp.svg';
import incomingWhite from   './../img/white/move_to_inbox_white_24dp.svg';
import bankWhite from   './../img/white/account_balance_white_24dp.svg';
import officeWhite from   './../img/white/store_white_24dp.svg';
import accountantWhite from   './../img/white/menu_book_white_24dp.svg';
import companiesWhite from   './../img/white/corporate_fare_white_24dp.svg';
import adminsWhite from   './../img/white/admin_panel_settings_white_24dp.svg';

import dropDownClosedWhite from "./../img/white/arrow_drop_down_white_24dp.svg";
import dropDownOpenedWhite from "./../img/white/arrow_right_white_24dp.svg";

// Light theme
import dashboardBlack from './../img/black/dashboard_black_24dp.svg';

import formsBlack from './../img/black/view_list_black_24dp.svg';
import pagesBlack from './../img/black/description_black_24dp.svg';
import profileBlack from './../img/black/people_black_24dp.svg';
import widgetsBlack from './../img/black/widgets_black_24dp.svg';

import outgoingBlack from   './../img/black/outbox_black_24dp.svg';
import incomingBlack from   './../img/black/move_to_inbox_black_24dp.svg';
import bankBlack from   './../img/black/account_balance_black_24dp.svg';
import officeBlack from   './../img/black/store_black_24dp.svg';
import accountantBlack from   './../img/black/menu_book_black_24dp.svg';
import companiesBlack from   './../img/black/corporate_fare_black_24dp.svg';
import adminsBlack from   './../img/black/admin_panel_settings_black_24dp.svg';

import dropDownClosedBlack from "./../img/black/arrow_drop_down_black_24dp.svg";
import dropDownOpenedBlack from "./../img/black/arrow_right_black_24dp.svg";

import uploadBlack from "./../img/black/upload_file_black_24dp.svg";
import uploadWhite from "./../img/white/upload_file_white_24dp.svg";

import operatorBlack from "./../img/black/badge_black_24dp.svg";
import operatorWhite from "./../img/white/badge_white_24dp.svg";

import lockCloseBlack from "./../img/black/lock_black_24dp.svg";
import lockOpenBlack from "./../img/black/lock_open_black_24dp.svg";

import lockCloseWhite from "./../img/white/lock_white_24dp.svg";
import lockOpenWhite from "./../img/white/lock_open_white_24dp.svg";

import docOtherWhite from "./../img/white/note_add_white_24dp.svg";
import docOtherBlack from "./../img/black/note_add_black_24dp.svg";

import dbAdminWhite from "./../img/white/storage_white_24dp.svg";
import dbAdminBlack from "./../img/black/storage_black_24dp.svg";

import dbTransferWhite from "./../img/white/move_up_white_24dp.svg";
import dbTransferBlack from "./../img/black/move_up_black_24dp.svg";

import limitWhite from "./../img/white/limit.svg";
import limitBlack from "./../img/black/limit.svg";



class MenuItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            onMouseOver: false,
            dropDownOpened: false
        }
    }

    getMenuIconByName = function (iconName) {


        var dashboard = (this.props.blackTheme) ? dashboardWhite : dashboardBlack;

        var forms = (this.props.blackTheme) ? formsWhite : formsBlack;
        var pages = (this.props.blackTheme) ? pagesWhite : pagesBlack;
        var profile = (this.props.blackTheme) ? profileWhite : profileBlack;
        var widgets = (this.props.blackTheme) ? widgetsWhite : widgetsBlack;

        var outgoing = (this.props.blackTheme) ? outgoingWhite : outgoingBlack;
        var incoming = (this.props.blackTheme) ? incomingWhite : incomingBlack;
        var bank = (this.props.blackTheme) ? bankWhite : bankBlack;
        var office = (this.props.blackTheme) ? officeWhite : officeBlack;
        var accountant = (this.props.blackTheme) ? accountantWhite : accountantBlack;
        var companies = (this.props.blackTheme) ? companiesWhite : companiesBlack;
        var admins = (this.props.blackTheme) ? adminsWhite : adminsBlack;

        var upload = (this.props.blackTheme) ? uploadWhite : uploadBlack;

        var operator = (this.props.blackTheme) ? operatorWhite : operatorBlack;

        var lockClose = (this.props.blackTheme) ? lockCloseWhite : lockCloseBlack;
        var lockOpen = (this.props.blackTheme) ? lockOpenWhite : lockOpenBlack;
        
        var docOther = (this.props.blackTheme) ? docOtherWhite : docOtherBlack;
        var dbAdmin = (this.props.blackTheme) ? dbAdminWhite : dbAdminBlack;
        var dbTransfer = (this.props.blackTheme) ? dbTransferWhite : dbTransferBlack;
        var limit = (this.props.blackTheme) ? limitWhite : limitBlack;
        

        switch (iconName) {

            case 'dashboard':
                return dashboard;
                
            case 'forms':
                return forms;
                
            case 'pages':
                return pages;
                
            case 'profile':
                return profile;
                
            case 'widgets':
                return widgets;
                
            case 'incoming'    :
                return incoming
                
            case 'outgoing'    :
                return outgoing
                
            case 'bank'    :
                return bank
                
            case 'office'    :
                return office
                
            case 'accountant'    :
                return accountant;
                
            case 'companies'    :
                return companies;
                
            case 'admins'    :
                return admins;
            case 'upload'    :
                return upload;
                
            case 'operator'    :
                return operator;
                
            case 'lockClose'    :
                return lockClose;
                
            case 'lockOpen'    :
                return lockOpen;
                
            case 'docOther'    :
                return docOther;
                
            case 'dbAdmin'    :
                return dbAdmin;
            case 'dbTransfer'    :
                return dbTransfer;
            case 'limits'    :
                return limit;

                
            default:
               break;
        }
    }

    onMouseOver = function (e) {
        this.setState({onMouseOver: true});
    }
    onMouseLeave = function (e) {
        this.setState({onMouseOver: false});
    }

    setActiveMenuItem = function () {
        this.props.setActiveMenu(this.props.item.id);
    }

    childMenuOpener = function () {
        this.setState({dropDownOpened: !this.state.dropDownOpened});
    }

    openNode = function () {
        this.setState({dropDownOpened: true});
    }

    render() {
        var dropDownClosed = (this.props.blackTheme) ? dropDownClosedWhite : dropDownClosedBlack;
        var dropDownOpened = (this.props.blackTheme) ? dropDownOpenedWhite : dropDownOpenedBlack;
        var menuIcon = this.getMenuIconByName(this.props.item.ico);
        var isSelected = this.props.activeMenuId === this.props.item.id;
        var selectionColor = (this.props.blackTheme) ? "#00000000" : "#ebedf7";
        var selectionBackground = (this.props.blackTheme) ? "#475360" : "#ebedf7";
        if (isSelected) {
            selectionColor = (this.props.blackTheme) ? "#9FD027" : "#9FD027";
            selectionBackground = (this.props.blackTheme) ? "#374350" : "#dfe2ef";
        }

        if (this.state.onMouseOver) {
            selectionBackground = (this.props.blackTheme) ? "#374350" : "#dfe2ef";
        }

        var collapsed = this.props.collapsed;

        var title = (collapsed) ? "" : this.props.item.title;

        var hasChildren = (typeof this.props.children != 'undefined' && this.props.children != null && this.props.children.length > 0) ? true : false;

        var childMenuOpener = "";
        if (hasChildren) {
            childMenuOpener = <img onClick={this.childMenuOpener.bind(this)} src={(this.state.dropDownOpened) ? dropDownClosed : dropDownOpened } alt='Menü kibontás ikon'/>;
        }

        var childMenu = (this.props.isChild) ? <div style={{width: "25px"}} /> : "";
        var menuItemTextClass = (this.props.blackTheme) ? "menuItemTextDark" : "menuItemTextLight";

        var menuSeparator = (this.props.blackTheme) ? 'solid 1px #ffffff11' : 'solid 1px #e4e3e2';

        return <div style={{borderBottom: menuSeparator}}>
            <div 
                style={{backgroundColor: selectionBackground}} 
                className={"menuItem"} 
                onClick={this.setActiveMenuItem.bind(this)}
                onMouseOver={this.onMouseOver.bind(this)}
                onMouseLeave={this.onMouseLeave.bind(this)}
                >
                <div style={{minWidth: '10px', backgroundColor: selectionColor, marginRight: '5px'}} >&nbsp;</div>
                {childMenu}
                <img src={menuIcon} alt='Menü ikon'/><div onClick={this.openNode.bind(this)} className={menuItemTextClass}>{title}</div>
                &nbsp;{childMenuOpener}
        
            </div>
            <div className={(this.state.dropDownOpened) ? 'fadeIn' : 'fadeOut'}>
                {(this.state.dropDownOpened) ? this.props.children : ""}
        
            </div>
        </div>
                ;
    }
}

export default MenuItem;