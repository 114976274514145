import React, { Component } from 'react';
import {ProjectDescriptor, FieldDescriptor, Layout}  from './../../utils.js';
import * as ajax from './../../ajax.js';
import Panel from './../../Components/Panel';
import BasicForm from './../../Components/BasicForm';
import PopUpWindow  from './../../Components/PopUpWindow';
import MultiFileChooserOtherDocs  from './../../Components/MultiFileChooserOtherDocs';
import multiUploadWhite from './../../img/white/upload_file_white_24dp.svg';
import multiUploadBlack from './../../img/black/upload_file_black_24dp.svg';

class DocOther extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            popUpVisible: false,
            currentDoc: null,
            multiUploadFileChooserOpen: false
        }

        var darkButtonStyle = {
            backgroundColor: '#475360',
            color: '#FFFFFFaa',
            border: 'solid 0px #FFFFFF',
            padding: '5px',
            margin: '5px',
            boxShadow: '1px 1px 2px #000000',
            cursor: 'pointer'
        };
        var lightButtonStyle = {

            backgroundColor: '#FFFFFF',
            color: '#000000aa',
            border: 'solid 0px #000000',
            padding: '5px',
            margin: '5px',
            boxShadow: '1px 1px 2px #000000aa',
            cursor: 'pointer'

        };

        var endpoint = ajax.getUrlBase() + 'modules/DocOther/';
        var formTitle = 'Egyéb dokumentumok';
        var projectDescriptor = new ProjectDescriptor('DocOther', endpoint, formTitle);
        projectDescriptor.setFormDimensions(545, 280);
        var multiUpload = (this.props.blackTheme) ? multiUploadWhite : multiUploadBlack;
        var buttonStyle = (this.props.blackTheme) ? darkButtonStyle : lightButtonStyle;
        var multiFileUploadButton = <button  onClick={this.multiUploadFileChooserOpen.bind(this)} style={buttonStyle}>
            <img 
                onClick={this.multiUploadFileChooserOpen.bind(this)}
                className={"buttonImage"}
                title={"Fájlok hozzáadása"} 
                src={multiUpload}/>Fájl választás</button>
        projectDescriptor.additionalButtons = [multiFileUploadButton];


        var id = new FieldDescriptor(true, FieldDescriptor.TYPE_NUMBER, 'id', 'Iktatószám', '');



        var statusz = new FieldDescriptor(false, FieldDescriptor.TYPE_SELECT, 'statusz', 'Státusz', [{id: 0, label: 'Iktatva'}, {id: 1, label: 'Átadva'}]);
        statusz.setJump(2);
        /**
         var docType = new FieldDescriptor(false, FieldDescriptor.TYPE_SELECT, 'docType', 'Típus', [
         {id: '', label: ''},
         {id: 'SZALLITOLEVEL', label: 'Szállítólevél'},
         {id: 'GARANCIALEVEL', label: 'Garancialevél'},
         {id: 'TELJESITES_IGAZOLAS', label: 'Teljesítés igazolás'},
         {id: 'BANKSZAMLA_KIVONAT', label: 'Bankszámla kivonat'},
         {id: 'SZERZODES', label: 'Szerződés'},
         
         {id: 'ALAPITASI_IRAT', label: 'Alapítási irat'},
         {id: 'MUNKAUGYI_IRAT', label: 'Munkaügyi irat'},
         {id: 'BEVALLASOK_MELLEKLETEK', label: 'Bevallások-mellékletek'},
         {id: 'HITELKERELMEK', label: 'Hitelkérelmek'},
         {id: 'PALYAZATOK_TAMOGATASOK', label: 'Pályázatok-Támogatások'},
         {id: 'VEGYES_KONYVELENDO', label: 'Vegyes könyvelendő'},
         {id: 'EGYEB_IRAT', label: 'Egyéb irat'},
         {id: 'PENZTARBIZONYLAT', label: 'Pénztárbizonylat'}
         
         
         ]);
         **/
        var docType = new FieldDescriptor(false, FieldDescriptor.TYPE_SELECT, 'docType', 'Típus', [

            {id: '1', label: 'Szállítólevél'},
            {id: '2', label: 'Garancialevél'},
            {id: '3', label: 'Teljesítés igazolás'},
            {id: '4', label: 'Bankszámla kivonat'},
            {id: '5', label: 'Szerződés'},
            {id: '6', label: 'Alapítási irat'},
            {id: '7', label: 'Munkaügyi irat'},
            {id: '8', label: 'Bevallások-mellékletek'},
            {id: '9', label: 'Hitelkérelmek'},
            {id: '10', label: 'Pályázatok-Támogatások'},
            {id: '11', label: 'Vegyes könyvelendő'},
            {id: '12', label: 'Egyéb irat'},
            {id: '13', label: 'Pénztárbizonylat'}
        ]);

        var iktatasDatuma = new FieldDescriptor(false, FieldDescriptor.TYPE_DATE, 'iktatasDatuma', 'Iktatás dátuma', '');

        var atvetelDatuma = new FieldDescriptor(false, FieldDescriptor.TYPE_DATE, 'atvetelDatuma', 'Átvétel dátuma', '');

        var doc = new FieldDescriptor(false, FieldDescriptor.TYPE_FILE_UPLOADER, 'doc', 'Fájl', {filename: '', file: ''});
        doc.setVisibleTableField(false);



        var fajlnev = new FieldDescriptor(false, FieldDescriptor.TYPE_TEXT, 'filename', 'Fájl neve', '');
        fajlnev.setVisibleFormField(false);

        var fileExtension = new FieldDescriptor(false, FieldDescriptor.TYPE_TEXT, 'fileExtension', 'Fájl típus', '');
        fileExtension.setVisibleFormField(false);

        //  var sharedDoc = new FieldDescriptor(false, FieldDescriptor.TYPE_BOOLEAN, 'sharedDoc', 'Megosztva', '');
        //  sharedDoc.setJump(2);

        var docComment = new FieldDescriptor(false, FieldDescriptor.TYPE_COMMENT_FIELD, 'docComment', 'Megjegyzés', '');
        docComment.setCommentFieldWidth(397);
        docComment.setJump(2);


        var docViewer = new FieldDescriptor(false, FieldDescriptor.TYPE_ACTION_BUTTON, 'actionId', 'Megnyitás', '');
        docViewer.setVisibleFormField(false);
        docViewer.setAction(this.showDocument.bind(this));

        projectDescriptor.addFieldDescriptor(id);
        projectDescriptor.addFieldDescriptor(statusz);
        projectDescriptor.addFieldDescriptor(docType);
        projectDescriptor.addFieldDescriptor(iktatasDatuma);
        projectDescriptor.addFieldDescriptor(atvetelDatuma);
        projectDescriptor.addFieldDescriptor(doc);
        projectDescriptor.addFieldDescriptor(fajlnev);
        projectDescriptor.addFieldDescriptor(fileExtension);
        projectDescriptor.addFieldDescriptor(docComment);
        // projectDescriptor.addFieldDescriptor(sharedDoc);
        projectDescriptor.addFieldDescriptor(docViewer);


        var layout = new Layout();
        layout.addColumns([0, 120, 270, 400])
        layout.addRows([0, 34, 68, 102, 136])
        this.projectDescriptor = projectDescriptor;
        this.layout = layout;
    }

    multiUploadFileChooserOpen = function () {
        this.setState({multiUploadFileChooserOpen: true});
    }
    multiUploadFileChooserClose = function () {
        this.setState({multiUploadFileChooserOpen: false});
    }

    showDocument = function (id) {
        try {
            const url = ajax.getUrlBase() + "modules/DocOther/getDocument.php";
            return fetch(url, {
                method: "POST",
                // credentials: "include",
                // credentials: "same-origin",
                // credentials: "*", // Tesztüzemben
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({id: id})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            this.setState({popUpVisible: true, currentDoc: response.data});
                        } else {
                            this.setState({loginSuccess: false});
                        }

                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");

                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));

                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }
    }

    hideDocument = function () {
        this.setState({popUpVisible: false});
    }

    b64toBlob = function (dataURI) {
        if (dataURI == null) {
            return null;
        }
        var parts = dataURI.split(',');
        var extension = parts[0];
        var mimeType = extension.substring("data:application/".length, extension.indexOf(";base64"));

        if (!mimeType.includes("pdf") && !mimeType.includes("zip")) {
            return dataURI;
        }




        var byteString = atob(parts[1]);
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);

        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        var originalMimeType = 'application/pdf';

        if (mimeType.includes("zip")) {
            originalMimeType = 'application/zip';
        }

        var blob = new Blob([ab], {type: originalMimeType});

        if (mimeType.includes("zip")) {
            this.saveBlobToFile(blob, "egyeb_irat.zip");
            return null;
        }

        return URL.createObjectURL(blob);
    }

    saveBlobToFile = function (blob, fileName) {
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.download = fileName;

        // Append anchor to body.
        document.body.appendChild(a);
        a.style.display = 'none';

        // Trigger the click event
        a.click();

        // Remove the anchor from body
        document.body.removeChild(a);
    }

    render() {
        var multiFileChooserOtherDocs = "";
        if (this.state.multiUploadFileChooserOpen) {
            multiFileChooserOtherDocs = <MultiFileChooserOtherDocs     
            multiUploadFileChooserClose={this.multiUploadFileChooserClose.bind(this)}
            blackTheme={this.props.blackTheme}
            invoiceDirection ={this.props.invoiceDirection}
            alertDialog={this.props.alertDialog.bind(this)}
            />
        }


        var popUp = null;
        if (this.state.popUpVisible == true) {

            var blobUrl = null;
            try {
                blobUrl = this.b64toBlob(this.state.currentDoc);
            } catch (e) {

            }

            if (blobUrl != null) {

                popUp = <PopUpWindow blackTheme={this.props.blackTheme}>
                    <div style={{width: '100%', height: '90%'}}>
                        <iframe style={{width: '100%', height: '100%'}} src={ blobUrl}/>
                    </div>
                    <div style={{width: '100%', marginTop: '40px', layout: 'flex', textAlign: 'center'}}>
                        <button 
                            style={{textAlign: 'center'}} 
                            onClick={this.hideDocument.bind(this)}
                            className={"bookingFormButton"}>Bezárás</button>
                    </div>
                </PopUpWindow>;
            }
        }
        return <Panel>
            {popUp}
            {multiFileChooserOtherDocs}
            <BasicForm blackTheme={this.props.blackTheme} alertDialog={this.props.alertDialog.bind(this)} layout={this.layout} projectDescriptor={this.projectDescriptor} /></Panel>
    }
}

export default DocOther;