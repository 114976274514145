import React, { Component } from 'react';
import * as ajax from './../../ajax.js';
import {Layout} from './../../utils.js';
import PopUpWindow from './../../Components/PopUpWindow';
import InfoBox from './InfoBox';
class CompanyBackgroundInfoPanel extends React.Component {

    constructor(props) {
        super(props);
        var taxNumber = (typeof this.props.taxNumber != 'undefined') ? this.props.taxNumber : '';
        var name = (typeof this.props.partnerName != 'undefined') ? this.props.partnerName : '';
        this.state = {
            companyInfoData: null,
            taxNumber: taxNumber,
            name: name,
            regNumber: '',
            insertButtonHidden: true
        };
        console.log(this.props);
        if (taxNumber != null) {
            this.loadCompanyInfo(taxNumber, '', '');
        } else {
            this.loadCompanyInfo('', '', '');
        }
    }

    setTaxNumber = function (e) {
        this.setState({taxNumber: e.target.value, name: '', regNumber: ''});
        if (e.target.value.length > 7) {
            this.loadCompanyInfo(e.target.value, '', '');
        }
    }

    setName = function (e) {
        this.setState({name: e.target.value, taxNumber: '', regNumber: ''});
        this.loadCompanyInfo('', e.target.value, '');
    }

    setRegNumber = function (e) {
        this.setState({regNumber: e.target.value, name: '', taxNumber: ''});
        this.loadCompanyInfo('', '', e.target.value);
    }

    loadCompanyInfo = function (taxNumber, name, regNumber) {
        try {
            const url = ajax.getUrlBase() + "modules/CompanyBackgroundInfo/selectNew.php";
            return fetch(url, {
                method: "POST",
                // credentials: "include",
                // credentials: "same-origin",
                // credentials: "*", // Tesztüzemben
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({taxNumber: taxNumber, name: name, regNumber: regNumber})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            this.setState({companyInfoData: response.data, insertButtonHidden: false});
                        }
                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }
    }

    insertPartner = function (companyData) {
        if (typeof this.props.documentId != 'undefined' && this.props.documentId != null && this.props.documentId != -1) {
            companyData["documentId"] = this.props.documentId;
        }
        try {
            const url = ajax.getUrlBase() + "modules/CompanyBackgroundInfo/insertPartner.php";
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify(companyData)
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            this.props.instantUpdateForm(response.data.partner_unique_id);
                            //this.setState({companyInfoData: response.data, taxNumber: response.data.new_partner_tax_number, name: response.data.new_partner_name, regNumber: '', insertButtonHidden: true});
                            this.props.close(this.props.documentId);
                        } else {
                            this.props.alertDialog(response.fail);
                        }
                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }
    }

    insertCompany = function (companyData) {
        try {
            const url = ajax.getUrlBase() + "modules/CompanyBackgroundInfo/insertCompany.php";
            return fetch(url, {
                method: "POST",
                // credentials: "include",
                // credentials: "same-origin",
                // credentials: "*", // Tesztüzemben
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify(companyData)
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {

                            this.props.instantUpdateForm(response.data.partner_unique_id);
                            //this.setState({companyInfoData: response.data, taxNumber: response.data.new_partner_tax_number, name: response.data.new_partner_name, regNumber: '', insertButtonHidden: true});

                            this.props.close(this.props.documentId);
                        } else {
                            this.props.alertDialog(response.fail);
                        }
                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }
    }

    isEmpty = function (myValue) {
        return (typeof myValue == 'undefined' || myValue == null) ? true : false;
    }

    getIrsz = function (address) {
        if (this.isEmpty(address)) {
            return null;
        }

        var irszVarosUtcaHsz = address;
        var irszVaros_utcaHsz = irszVarosUtcaHsz.split(",");

        if (this.isEmpty(irszVaros_utcaHsz[0])) {
            return null;
        }
        var irszVaros = irszVaros_utcaHsz[0];
        var irsz_varos = irszVaros.split(' ');

        if (this.isEmpty(irsz_varos[0])) {
            return null;
        }

        var irsz = irsz_varos[0];
        return irsz;

    }

    getVaros = function (address) {

        if (this.isEmpty(address)) {
            return null;
        }

        var irszVarosUtcaHsz = address;
        var irszVaros_utcaHsz = irszVarosUtcaHsz.split(",");

        if (this.isEmpty(irszVaros_utcaHsz[0])) {
            return null;
        }
        var irszVaros = irszVaros_utcaHsz[0];
        var irsz_varos = irszVaros.split(' ');

        if (this.isEmpty(irsz_varos[1])) {
            return null;
        }

        var varos = irsz_varos[1];
        return varos;
    }

    getUtcaHsz = function (address) {

        if (this.isEmpty(address)) {
            return null;
        }

        var irszVarosUtcaHsz = address;
        var irszVaros_utcaHsz = irszVarosUtcaHsz.split(",");

        if (this.isEmpty(irszVaros_utcaHsz[1])) {
            return null;
        }

        var utcaHsz = irszVaros_utcaHsz[1];

        return utcaHsz;
    }
    getBankszamlaszam = function (bankszamlaszam) {

        if (typeof bankszamlaszam=='undefined') {
            return null;
        }
        if (bankszamlaszam==null) {
            return null;
        }

        return bankszamlaszam;
    }

    addPartner = function () {
        if (this.state.companyInfoData == null) {
            return null;
        }
        var partnerData = this.state.companyInfoData;

        var result = {
            adoszam: "",
            nev: "",
            irsz: "",
            varos: "",
            utcaHsz: "",
            bankszamlaszam:""
        }


        // Adószám
        result.adoszam = (partnerData.tax_number) ? partnerData.tax_number : '-';
        result.nev = partnerData.short_name;
        result.irsz = this.getIrsz(partnerData.address_name);
        result.varos = this.getVaros(partnerData.address_name);
        result.utcaHsz = this.getUtcaHsz(partnerData.address_name);
        result.bankszamlaszam = this.getBankszamlaszam(partnerData.bank_account);
        
        console.log(partnerData);

        if (result.nev == 'undefined' || result.nev == null || result.nev == '' || result.nev.trim() == '') {
            result.nev = (partnerData.long_name != 'undefined' && partnerData.long_name != null && partnerData.long_name != '') ? partnerData.long_name : '';
        }

        if (this.props.companyBackgroundInfoPanelisPartner) {
            this.insertPartner(result);
        } else {
            this.insertCompany(result);
        }

    }

    partnerSearchPanel = function () {

        return <div style={{display: 'flex', textAlign: 'center', justifyContent: "center", marginBottom: 10, backgroundColor: 'white', color: "#000000aa"}}>
            <div style={{textAlign: 'center', justifyContent: "center", marginTop: 5, marginRight: 10, marginLeft: 10}} className={"titleSmall"}>Adószám:</div>
            <div><input autoFocus    className={"formInputLight"} type="text" onChange={this.setTaxNumber.bind(this)} value={this.state.taxNumber} /></div>
            <div style={{textAlign: 'center', justifyContent: "center", marginTop: 5, marginRight: 10, marginLeft: 10}} className={"titleSmall"}>Regisztrációs szám:</div>
            <div><input className={"formInputLight"}  type="text" onChange={this.setRegNumber.bind(this)} value={this.state.regNumber} /></div>
        </div>;
    }

    render() {

        var partnerSearchPanel = this.partnerSearchPanel();
        return <PopUpWindow blackTheme={this.props.blackTheme} style={{position: 'relative', width: 1010, height: 620, overflow: 'hidden'}}>
            <div style={{
                            width: '100%',
                            textAlign: 'center',
                            backgroundColor: '#586778',
                            color: '#FFFFFF',
                            position: 'fixed',
                            maxWidth: 1000,
                            marginBottom: 20,

                             }}>
                <h3>Céginformációk {(this.state.name != null && this.state.name != '') ? (' - ' + this.state.name) : ''} {(this.state.taxNumber != null && this.state.taxNumber != '') ? (' - (' + this.state.taxNumber + ')') : ''}</h3>
                {partnerSearchPanel}
        
            </div>
            <div style={{marginTop: 110, maxHeight: 440, minHeight: 440, overflow: 'auto'}}>
                <InfoBox 
                    init={true}
                    title={""}  
                    data={this.state.companyInfoData}
                    isCompany={this.props.companyBackgroundInfoPanelisCompany}
                    isPartner ={this.props.companyBackgroundInfoPanelisPartner}
                    documentId={this.props.documentId}
                    />
            </div>  
            <div style={{display: "flex", justifyContent: 'center', textAlign: 'center', paddingTop: 30}}>
                {(this.state.insertButtonHidden == false) ?
                            <div 
                                className={"bookingFormButton"} 
                                style={{paddingLeft: 10, paddingRight: 10, marginLeft: 10, marginRight: 10, backgroundColor: '#9fd027', color: '#FFFFFF'}} 
                                onClick={() => {

                                                this.addPartner(this.props.documentId)
                                            }}
                                >Partner hozzáadása</div> : ""}
                <div 
                    className={"bookingFormButton"} 
                    style={{paddingLeft: 10, paddingRight: 10}} 
                    onClick={() => {

                                    this.props.close(this.props.documentId)
                                }}
                    >Bezárás</div>
            </div>
        </PopUpWindow>;
    }
}

export default CompanyBackgroundInfoPanel;