import React from 'react';
import './../css/popup.css';


class PopUpWindow extends React.Component {

    constructor(props) {
        super(props);
          this.state = {
            black:false
        }
    }

    componentWillMount() {
        /**
        if (this.state.black) {
            require('./../css/popup.css');
        } else {
            require('./../css/popupWhite.css');
        }
         */
    }

    render() {
        var style={};
        if(typeof this.props.style != 'undefined'){
            style=this.props.style;
        }
        var frameClass = (this.props.blackTheme)?"popupFrameDark":"popupFrameLight";
        var formClass = (this.props.blackTheme)?"formDark":"formLight";
        return  <div  className={frameClass}>
                    <div style={style} className={formClass}>{this.props.children}</div>
                </div>;
    }
}

export default PopUpWindow;