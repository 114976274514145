import React, { Component } from 'react';
import PopUpWindow from './../../Components/PopUpWindow';
import * as ajax from './../../ajax';

class LoginDataForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            NavLogin: '',
            NavPassword: '',
            NavSignKey: '',
            NavExchangeKey: ''
        }
        this.load();
    }

    onValueChange = function (e) {
        this.state[e.target.id] = e.target.value;
        this.setState({});
    }

    update = function () {
        try {
            const url = ajax.getUrlBase() + "NAV/updateNavLogin.php";
            return fetch(url, {
                method: "POST",
                //credentials: "include",
                //credentials: "*", // Tesztüzemben
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({data: this.state})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success == false) {

                            this.props.alertDialog(response.fail);

                        }else{
                             this.props.hideLoginDataForm();
                        }



                    }).catch(function (jsonError) {

                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }

    }

    load = function () {
        try {
            const url = ajax.getUrlBase() + "NAV/loadNavLogin.php";
            return fetch(url, {
                method: "POST",
                //credentials: "include",
                //credentials: "*", // Tesztüzemben
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({data: ''})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success == false) {
                            this.props.alertDialog(response.fail);
                        }
                        var data = response.data;
                        this.setState({
                            NavLogin: data.NavLogin,
                            NavPassword: data.NavPassword,
                            NavSignKey: data.NavSignKey,
                            NavExchangeKey: data.NavExchangeKey
                        });
                        


                    }).catch(function (jsonError) {

                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }

    }

    render() {
        var formInputClass = (this.props.blackTheme) ? 'formInputDark' : 'formInputLight';
        return <PopUpWindow style={{width: '500px', height: "325px", textAlign: 'center'}} blackTheme={this.props.blackTheme}>
    <div style={{position: 'relative'}}>
        <h3 style={{marginBottom: '0px'}}>NAV Online számla adatszolgáltatás</h3>
        <h5 style={{margin: '0px'}}>Technikai felhaszhasználói adatok</h5>

        <div style={{positon: 'absolute', maxHeight: '200px', minHeight: '200px', width: '100%'}} >

            <div style={{position: 'absolute', top: 104, left: 5, width: 70, height: 18, textAlign: 'left'}} className={"titleSmall"} >Technikai felhasználó</div>
            <div style={{position: 'absolute', top: 100, left: 140, width: 320, height: 27}}>
                <input 
                    className={formInputClass}
                    type="text"
                    style={{minWidth: '320px'}}
                    id="NavLogin"
                    value={this.state.NavLogin}
                    onChange={this.onValueChange.bind(this)}
                    />
            </div>    
        </div>
        <div style={{position: 'absolute', top: 134, left: 5, width: 70, height: 18, textAlign: 'left'}} className={"titleSmall"} >Technikai profil jelszó</div>
        <div style={{position: 'absolute', top: 130, left: 140, width: 320, height: 27}}>
            <input 
                className={formInputClass}
                type="text"
                style={{minWidth: '320px'}}
                id="NavPassword"
                value={this.state.NavPassword}
                onChange={this.onValueChange.bind(this)}
                />
        </div>    
        <div style={{position: 'absolute', top: 164, left: 5, width: 70, height: 18, textAlign: 'left'}} className={"titleSmall"} >Aláíró kulcs</div>
        <div style={{position: 'absolute', top: 160, left: 140, width: 320, height: 27}}>
            <input 
                className={formInputClass}
                type="text"
                style={{minWidth: '320px'}}
                id="NavSignKey"
                value={this.state.NavSignKey}
                onChange={this.onValueChange.bind(this)}
                />
        </div>

        <div style={{position: 'absolute', top: 194, left: 5, width: 70, height: 18, textAlign: 'left'}} className={"titleSmall"} >Csere kulcs</div>
        <div style={{position: 'absolute', top: 190, left: 140, width: 320, height: 27}}>
            <input 
                className={formInputClass}
                type="text"

                style={{minWidth: '320px'}}
                id="NavExchangeKey"
                value={this.state.NavExchangeKey}
                onChange={this.onValueChange.bind(this)}
                />
        </div>    

        <button 
            className={"bookingFormButton"} 
            style={{backgroundColor: '#9fd027'}}
            onClick={this.update.bind(this)}
            >Mentés</button>&nbsp;
        <button 

            className={"bookingFormButton"} 
            onClick={() => this.props.hideLoginDataForm()}
            >Bezárás</button>
    </div>
</PopUpWindow>;
    }
}

export default LoginDataForm;

