import React from 'react';
import './../css/alertDialog.css';
import AlertImage from './../img/dialog/alert.svg';

class AlertDialog extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return <div className="dialogFrame">
            <div className="dialogWindow">
                <img className="alertImage" src={AlertImage} alt='Riasztás ikon'/>
                <div className="dialogHeader">
                    <h1>Hiba</h1>
                </div>
                <div className="dialogContent">
                    {this.props.children}
                </div>
                <input autoFocus onClick={this.props.alertDialogClose.bind(this)} className="dialogCloseButton" type="button" value="Bezárás" />
            </div>
        
        </div>;
    }
}

export default AlertDialog;