import React from 'react';
import * as ajax from './../../ajax';
import PopUpWindow from './../../Components/PopUpWindow';
import './../../css/DBTransferDialog.css';
import storageBlack from './../../img/black/storage_black_24dp.svg';

class DBTransferDialog extends React.Component {

    constructor(props) {
        super(props);
        this.url = ajax.getUrlBase() + 'img/progress.gif';
        this.maximumWidth = 400;
        this.maximumHeight = 400;

        // Loading state animation gif
        //   <img style={{ textAlign: 'center', maxWidth: maximumWidth, maxHeight: maximumHeight }} src={url} alt='Folyamatjelző GIF' />
        this.logs = [];
        this.idArray = (typeof this.props.idArray != 'undefined' && this.props.idArray != null) ? this.props.idArray : [];

        this.LOG_ERROR = "red";
        this.LOG_SIMPLE = "black";
        this.LOG_SUCCESS = "green";

        this.transferStarted = false;
        this.transferReady = false;

        this.currentYear = null;
        this.targetYears = null;
        this.selectedYear = -1;


        this.dbTransferTargetYears();

    }

    componentDidMount() {
        this.transferReady = false;
        this.idArray = (typeof this.props.idArray != 'undefined' && this.props.idArray != null) ? this.props.idArray : [];
        this.addLog("Jelölje ki az átmozgatásra szánt tételeket. ", this.LOG_SIMPLE);
        this.addLog("Válassza ki a láblécben a 'CÉL:' adatbázist. ", this.LOG_SIMPLE);
        this.addLog("A tranzakció indításához kattintson az 'Igen' gombra.", this.LOG_SIMPLE);
    }
    clearLogs = function () {
        this.logs = [];
    }
    addLog = function (logText, logType) {
        this.logs.push(<div class="dbTransferDialogRow" style={{color: logType, textAlign: "left", borderBottom: 'solid 1px #00000044'}}>{logText}</div>);
        if (logType == this.LOG_ERROR) {
            this.transferStarted = false;
        }
        this.forceUpdate();
    }

    dbTransferTargetYears = function () {
        try {
            const url = ajax.getUrlBase() + "modules/Booking/dbTransferTargetYears.php";
            return fetch(url, {
                method: "POST",
                //credentials: "include",
                //credentials: "*", // Tesztüzemben
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            this.currentYear = response.data['currentYear'];
                            this.targetYears = response.data['targetYears'];
                            this.forceUpdate();
                        }

                    }).catch(jsonError => {

                        if (null == jsonError) {
                            this.addLog("Hibás tranzakció: ", this.LOG_ERROR);
                        } else {
                            this.addLog("Hibás tranzakció: " + String(jsonError), this.LOG_ERROR);
                        }
                    });
                } else {
                    this.transferStarted = false
                    this.addLog("Hibás tranzakció: " + response.statusText, this.LOG_ERROR);
                }

            }).catch(error => {
                this.transferStarted = false
                this.addLog("", this.LOG_ERROR);
                if (null == error) {
                    this.addLog("Szerver oldali hiba:", this.LOG_ERROR);

                } else {
                    this.addLog("Szerver oldali hiba:" + error, this.LOG_ERROR);
                }
            });
        } catch (ex) {
            this.transferStarted = false
            this.addLog("Hibás tranzakció: " + String(ex), this.LOG_ERROR);
        }
    }

    dbTransferStart = function () {

        if (this.selectedYear == -1) {
            return false;
        }

        this.transferStarted = true;
        this.transferReady = true;
        this.clearLogs();
        this.addLog("Az adatok másolása megkezdődött. Kérem várjon a művelet befejezéséig.", this.LOG_SIMPLE);
        try {
            const url = ajax.getUrlBase() + "modules/Booking/dbTransfer.php";
            return fetch(url, {
                method: "POST",
                //credentials: "include",
                //credentials: "*", // Tesztüzemben
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({idArray: this.idArray, selectedYear: this.selectedYear})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            this.transferStarted = false;
                            this.addLog("A TRANZAKCIÓ EREDMÉNYE! ", this.LOG_SIMPLE);
                            for (var i = 0; i < response.data.length; i++) {
                                var success = response.data[i]['success'];
                                var logType = (success == true) ? this.LOG_SUCCESS : this.LOG_ERROR;
                                this.addLog(response.data[i]['text'], logType);
                            }
                        } else {
                            this.addLog(response.fail, this.LOG_ERROR);
                        }
                        this.transferStarted = false
                        this.forceUpdate();
                    }).catch(jsonError => {
                        this.transferStarted = false
                        if (null == jsonError) {
                            this.addLog("Hibás tranzakció: ", this.LOG_ERROR);
                        } else {
                            this.addLog("Hibás tranzakció: " + String(jsonError), this.LOG_ERROR);
                        }
                    });
                } else {
                    this.transferStarted = false
                    this.addLog("Hibás tranzakció: " + response.statusText, this.LOG_ERROR);
                }

            }).catch(error => {
                this.transferStarted = false
                this.addLog("", this.LOG_ERROR);
                if (null == error) {
                    this.addLog("Szerver oldali hiba:", this.LOG_ERROR);

                } else {
                    this.addLog("Szerver oldali hiba:" + error, this.LOG_ERROR);
                }
            });
        } catch (ex) {
            this.transferStarted = false
            this.addLog("Hibás tranzakció: " + String(ex), this.LOG_ERROR);
        }
    }
    setTargetDBYear = function (e) {
        this.selectedYear = e.target.value;
        this.forceUpdate();
    }

    getTargetDBYearChooser = function () {
        if (typeof this.targetYears == 'undefined') {
            return "";
        }
        if (this.targetYears == null) {
            return "";
        }
        if (Array.isArray(this.targetYears) == false) {
            return "";
        }
        if (this.targetYears.length == 0) {
            return "";
        }



        var options = [<option id={"targetDBYearChooserOption_NULL"} key={"targetDBYearChooserOption_NULL"} value={-1} >----</option>];

        for (var i = 0; i < this.targetYears.length; i++) {
            options.push(<option id={"targetDBYearChooserOption_" + i} key={"targetDBYearChooserOption_" + i} value={this.targetYears[i]} >{this.targetYears[i]}</option>);
        }
        return <select value={this.targetDBYear} onChange={this.setTargetDBYear.bind(this)}>{options}</select>
    }

    render() {

        var yearChooser = this.getTargetDBYearChooser();

        var buttons = [];

        var buttonYes = (this.transferStarted == false) ? <button
            onClick={this.dbTransferStart.bind(this)}
            style={{
                            backgroundColor: "#BB0000",
                            color: "white",
                            fontWeight: 'bold',
                            width: '40%',
                            marginLeft: '5%',
                            cursor: 'pointer',
                            border: 'solid 1px #aaaaaaaa'
                        }}>Igen</button> : "";

        var buttonCancel = (this.transferStarted == false) ? <button
            onClick={() => {
                            this.props.closeDBTransferDialog();
                        }}
            style={{
                            backgroundColor: "#000044",
                            color: "white",
                            fontWeight: 'bold',
                            width: '40%',
                            marginRight: '5%',
                            cursor: 'pointer',
                            border: 'solid 1px #aaaaaaaa'
                        }}>Mégsem</button> : "";

        var buttonClose = (this.transferStarted == false) ? <button
            onClick={() => {
                            this.props.closeDBTransferDialog();
                        }}
            style={{
                            backgroundColor: "#004400",
                            color: "white",
                            fontWeight: 'bold',
                            width: '40%',
                            marginRight: '5%',
                            cursor: 'pointer',
                            border: 'solid 1px #000000',
                            margin: 'auto'
                        }}>Bezárás</button> : "";

        if (this.transferReady == false) {
            buttons = [buttonYes, <div style={{minWidth: '5%'}}></div>, buttonCancel];
        } else {
            buttons = [<dev style={{width:'45%'}}></dev>,buttonClose];
        }

        if (this.selectedYear == -1) {
            buttons = [<dev style={{width:'45%'}}></dev>,buttonClose];
        }

        return (
                <PopUpWindow style={{textAlign: 'center', maxWidth: this.maximumWidth, maxHeight: this.maximumHeight}} >
                    <div class="dbTransferDialogContainer" style={{maximumWidth: 400, maximumHeight: 300}}>
                        <div class="dbTransferDialogHeader">Kijelölt elemek átmozgatása a kiválasztott adatbázisba.</div>    
                        <div class="dbTransferDialogContent">
                            {this.logs}
                        </div>    
                        <div class="dbTransferDialogFooter">
                            <div style={{display: 'flex', marginTop: 10, textAlign: 'center'}}>
                                <img  src={storageBlack} />&nbsp;CÉL:&nbsp;{yearChooser}  {buttons}
                            </div>    
                        </div>    
                
                    </div>
                </PopUpWindow>
                );
    }
}
;

export default DBTransferDialog;