import React, { Component } from 'react';
import Header from "./Header";
import HeaderAdmin from "./HeaderAdmin";
import Menu from "./Menu";
import Login from './../modules/Login/Login';
import Panel from './../Components/Panel';
import BookingTable from './../modules/Booking/BookingTable';
import DBTransferTable from './../modules/Booking/DBTransferTable';
import AccountantManager from './../modules/AccountantManager/AccountantManager';
import ChiefAccountantManager from './../modules/ChiefAccountantManager/ChiefAccountantManager';
import AdminManager from './../modules/AdminManager/AdminManager';
import CompanyManager from './../modules/CompanyManager/CompanyManager';
import PopUpWindow from './../Components/PopUpWindow';
import BookingForm from './../modules/Booking/BookingForm';
import InvoiceViewerForm from './../modules/Booking/InvoiceViewer';
import MasterData from './../modules/MasterData/MasterData';
import BasicForm from './../Components/BasicForm';
import BankiImportok from './../modules/BankiImportok/BankiImportok';
import Kimeno from './../modules/Kimeno/Kimeno';
import DocOther from './../modules/DocOther/DocOther';
import InfoPanel from './../modules/InfoPanel/InfoPanel';
import InfoPanelCompanies from './../modules/InfoPanelCompanies/InfoPanelCompanies';
import InfoPanelCompaniesAdmin from './../modules/InfoPanelCompaniesAdmin/InfoPanelCompaniesAdmin';
import OfficeManager from './../modules/OfficeManager/OfficeManager';
import OperatorManager from './../modules/OperatorManager/OperatorManager';
import DocStore from './../modules/DocStore/DocStore';
import DBAdmin from './../modules/DBAdmin/DBAdmin';
import DocStoreShared from './../modules/DocStoreShared/DocStoreShared';
import QueryInvoiceDigestTable from './../modules/Nav/QueryInvoiceDigestTable';
import CompanyBackgroundInfoPanel from './../modules/CompanyBackgroundInfo/CompanyBackgroundInfoPanel';
import Limits from './../modules/Limits/Limits';


import QueryInvoiceDigestLinesTable from './../modules/QueryInvoiceDigestLines/QueryInvoiceDigestLinesTable';


import * as ajax from './../ajax.js';
import {Layout} from './../utils.js';



import {ProjectDescriptor, FieldDescriptor}  from './../utils.js';
import AlertDialog from "./../Components/AlertDialog";
import MailSenderDialog from './../Components/MailSenderDialog';

class Frame extends React.Component {

    constructor(props) {

        super(props);
        var blackTheme = this.loadTheme();
        this.state = {

            instantUpdateForm: null,

            companyBackgroundInfoPanelDocumentId: -1,
            companyBackgroundInfoPanelVisible: false,
            companyBackgroundInfoPanelTaxNumber: null,
            companyBackgroundInfoPanelTaxPartnerName: '',
            companyBackgroundInfoPanelisCompany: false,
            companyBackgroundInfoPanelisPartner: false,

            bookingFormReference: null,

            timerID: null,
            invoiceDirection: 'OUTBOUND',
            blackTheme: blackTheme,

            selectedInvoice: null,
            error: {active: false, msg: ''},
            mailSenderDialog: {active: false, msg: ''},

            userType: null,
            firstName: null,
            lastName: null,
            currentCompany: null,
            iktathat: null,
            feltolthet: null,

            lastInsertId: -1,
            accountantCompanies: [],
            containerRef: React.createRef(),
            bookingFormVisible: false,
            bookingViewFormVisible: false,
            queryInvoiceDigestVisible: false,
            queryInvoiceDigestVisibleWithButtons: false,
            queryInvoiceDigestDOCUID: '',
            bookingFormTableFilters: null,
            menuCollapsed: false,
            loggedIn: false,
            currentContentId: null,
            selectedRowId: -1

        }

        if (this.state.timerID === null) {
            this.state.timerID = setInterval(() => this.stayAlive(), 60000);
        }

        this.updateNeed = null;

    }

    stayAlive() {
        try {
            const url = ajax.getUrlBase() + "secure/stayAlive.php";

            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {

                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }

    }

    setTheme = function (blackTheme) {
        this.setState({blackTheme: blackTheme});
        this.storeTheme(blackTheme);
    }

    storeTheme = function (blackTheme) {
        localStorage.setItem("blackTheme", blackTheme);
    }

    loadTheme = function () {
        var blackTheme = localStorage.getItem("blackTheme");
        return (typeof blackTheme != "undefined" && blackTheme != null && blackTheme != '' && blackTheme == true) ? true : false;
    }

    setLoginState = function (response) {
        var success = response.success;
        var userType = response.data.userType;
        var firstName = response.data.firstName;
        var lastName = response.data.lastName;
        var iktathat = response.data.iktathat;
        var feltolthet = response.data.feltolthet;

        if (typeof response.data.docStoreMenu != 'undefined' && response.data.docStoreMenu == 1) {
            iktathat = 0;
            feltolthet = 0;
            userType = 'OPERATOR';
            this.state.currentContentId = 'pages';
        } else {

            this.state.currentContentId = (userType != 'ADMIN') ? 'Booking' : null;
        }

        this.setState({loggedIn: success, userType: userType, firstName: firstName, lastName: lastName, iktathat: iktathat, feltolthet: feltolthet});
    }

    collapseMenu = function () {
        this.setState({menuCollapsed: !this.state.menuCollapsed});
    }
    showNav = function (visible) {
        this.setState({queryInvoiceDigestVisible: visible, queryInvoiceDigestVisibleWithButtons: false});
    }
    showNavWithButtons = function (visible) {
        this.setState({queryInvoiceDigestVisible: visible, queryInvoiceDigestVisibleWithButtons: true});
    }

    setContent = function (contentId) {
        this.setState({currentContentId: contentId});
    }

    logOut = function () {
        this.setState({loggedIn: false, currentCompany: null});
    }

    reload = function () {
        this.setState({});
    }

    setCurrentCompany = function (companyId) {
        this.setState({currentCompany: companyId});
        this.updateNeed = (new Date()).getTime();
        this.forceUpdate();
    }

    closeBookingForm = function () {
        if (this.state.selectedRowId == this.state.lastInsertId) {
            if (this.state.selectedRow == -1) {
                return false;
            }
            var self = this;
            try {
                const url = ajax.getUrlBase() + "modules/Booking/delete.php";

                return fetch(url, {
                    method: "POST",
                    //credentials: "include",
                    //credentials: "*", // Tesztüzemben
                    headers: new Headers({"Content-Type": "application/json"}),
                    body: JSON.stringify({id: self.state.selectedRowId})
                }).then(response => {
                    if (response.ok) {
                        return response.json().then(response => {
                            self.setState({bookingFormVisible: false});
                        }).catch(function (jsonError) {
                            if (null == jsonError) {
                                console.error("Fetch JSON error:");
                            } else {
                                console.error("Fetch JSON error:" + String(jsonError));
                            }
                        });
                    } else {
                        console.error("HTTP status error:", response.statusText);
                    }
                }).catch(function (error) {
                    console.error("Fetch error:", error);
                    if (null == error) {
                        console.error("Fetch error:");
                    } else {
                        console.error("Fetch error:" + String(error));
                    }
                });
            } catch (ex) {
                console.error("Fetch exception:", String(ex));
            }
        }
        this.setState({bookingFormVisible: false});
    }

    openInsertBookingForm = function (bookingFormTableFilters) {
        this.setState({bookingFormVisible: true, bookingFormTableFilters: bookingFormTableFilters});
    }

    openUpdateBookingForm = function (bookingFormTableFilters) {
        this.setState({bookingFormVisible:true, bookingFormTableFilters: bookingFormTableFilters, lastInsertId: -1});
    }
    openViewBookingForm = function (bookingFormTableFilters) {
        this.setState({bookingViewFormVisible: true, bookingFormTableFilters: bookingFormTableFilters, lastInsertId: -1});
    }

    setSelectedRowId = function (selectedRowId) {
        this.state.selectedRowId = selectedRowId;
    }

    alertDialogClose = function () {
        this.state.error.active = false;
        this.state.error.msg = "";
        this.setState({});
    }
    mailSenderDialogClose = function () {
        this.state.mailSenderDialog.active = false;
        this.state.mailSenderDialog.msg = "";
        this.setState({});
    }

    alertDialog = function (msg) {
        this.state.error.active = true;
        this.state.error.msg = msg;
        this.setState({});
    }

    mailSenderDialog = function (msg) {
        this.state.mailSenderDialog.active = true;
        this.state.mailSenderDialog.msg = msg;
        this.setState({});
    }
    sendToForm = function (data, invoiceDirection) {
        this.setState({selectedInvoice: data, invoiceDirection: invoiceDirection, queryInvoiceDigestVisible: false});
    }

    companyBackgroundInfoPanelClose = function (recordId) {
        console.log(this.state.bookingFormReference);
        if (this.state.bookingFormReference != null) {
            this.state.bookingFormReference.loadRecord(recordId);
        }
        this.setState({companyBackgroundInfoPanelVisible: false});
    }
    companyBackgroundInfoPanelOpen = function (documentId, taxId, partnerName, isCompany, isPartner) {

        this.setState({
            companyBackgroundInfoPanelDocumentId: documentId,
            companyBackgroundInfoPanelVisible: true,
            companyBackgroundInfoPanelTaxNumber: taxId,
            companyBackgroundInfoPanelTaxPartnerName: partnerName,
            companyBackgroundInfoPanelisCompany: isCompany,
            companyBackgroundInfoPanelisPartner: isPartner
        });
    }

    render() {

        var menuStyle = (this.state.menuCollapsed) ? "menuCollapsed" : "menu";
        var contenStyle = (this.state.menuCollapsed) ? (this.state.blackTheme) ? "contentMenuCollapsedDark" : "contentMenuCollapsedLight" : (this.state.blackTheme) ? "contentDark" : "contentLight";
        if (!this.state.loggedIn) {
            return <Login alertDialog={this.alertDialog.bind(this)}  blackTheme={this.state.blackTheme} setLoginState={this.setLoginState.bind(this)}/>
        }
        var currentContent = "";

        if (this.state.currentContentId == 'bookingIncoming') {
            var bookingForm = "";
            this.state.invoiceDirection = "INBOUND";
            if (this.state.bookingFormVisible) {
                bookingForm = <PopUpWindow blackTheme={false} collapsed={this.state.menuCollapsed}>
                    <div style={{display: 'flex'}}>
                        <BookingForm 
                
                            blackTheme={false}
                            bookingFormTableFilters={this.state.bookingFormTableFilters}
                            selectedRowId = {this.state.selectedRowId}
                            closeBookingForm={this.closeBookingForm.bind(this)} 
                            parentState={this.state}
                            alertDialog={this.alertDialog.bind(this)} 
                            mailSenderDialog={this.mailSenderDialog.bind(this)} 
                            showNav={this.showNavWithButtons.bind(this)}
                            selectedInvoice={this.state.selectedInvoice}
                            invoiceDirection={this.state.invoiceDirection}
                            companyBackgroundInfoPanelOpen={this.companyBackgroundInfoPanelOpen.bind(this)}
                            frameState={this.state}
                            />
                    </div>
                </PopUpWindow>
            }
            if (this.state.bookingViewFormVisible) {
                bookingForm = <PopUpWindow blackTheme={false} collapsed={this.state.menuCollapsed}>
                    <div style={{display: 'flex'}}>
                        <InvoiceViewerForm 
                
                            blackTheme={false}
                            bookingFormTableFilters={this.state.bookingFormTableFilters}
                            selectedRowId = {this.state.selectedRowId}
                            closeBookingForm={() => this.setState({bookingViewFormVisible: false})} 
                            parentState={this.state}
                            alertDialog={this.alertDialog.bind(this)} 
                            mailSenderDialog={this.mailSenderDialog.bind(this)} 
                            showNav={this.showNavWithButtons.bind(this)}
                            selectedInvoice={this.state.selectedInvoice}
                            invoiceDirection={this.state.invoiceDirection}
                            companyBackgroundInfoPanelOpen={this.companyBackgroundInfoPanelOpen.bind(this)}
                            frameState={this.state}
                            />
                    </div>
                </PopUpWindow>
            }



            var containerHeight = (this.state.containerRef.current != null) ? this.state.containerRef.current.clientHeight : 550;

            currentContent = <Panel blackTheme={this.state.blackTheme}>
                {bookingForm}
            
                <BookingTable 
                    containerHeight={containerHeight}
                    setSelectedRowId={this.setSelectedRowId.bind(this)}
                    openUpdateBookingForm={this.openUpdateBookingForm.bind(this)} 
                    openInsertBookingForm={this.openInsertBookingForm.bind(this)}
                    openViewBookingForm={this.openViewBookingForm.bind(this)}
                    parentState={this.state}
                    alertDialog={this.alertDialog.bind(this)} 
                    showNav={this.showNav.bind(this)}
                    blackTheme={this.state.blackTheme}
                    invoiceDirection={this.state.invoiceDirection}
            
                    />
            
            </Panel>;
        }

        if (this.state.currentContentId == 'bookingOutgoing') {
            var bookingForm = "";
            this.state.invoiceDirection = "OUTBOUND";
            if (this.state.bookingFormVisible) {
                bookingForm = <PopUpWindow blackTheme={false} collapsed={this.state.menuCollapsed}>
                    <div style={{display: 'flex'}}>
                        <BookingForm 
                
                            blackTheme={false}
                            bookingFormTableFilters={this.state.bookingFormTableFilters}
                            selectedRowId = {this.state.selectedRowId}
                            closeBookingForm={this.closeBookingForm.bind(this)} 
                            parentState={this.state}
                            alertDialog={this.alertDialog.bind(this)} 
                            mailSenderDialog={this.mailSenderDialog.bind(this)} 
                            showNav={this.showNavWithButtons.bind(this)}
                            selectedInvoice={this.state.selectedInvoice}
                            invoiceDirection={"OUTBOUND"}
                            companyBackgroundInfoPanelOpen={this.companyBackgroundInfoPanelOpen.bind(this)}
                            frameState={this.state}
                            />
                    </div>
                </PopUpWindow>
            }

            if (this.state.bookingViewFormVisible) {
                bookingForm = <PopUpWindow blackTheme={false} collapsed={this.state.menuCollapsed}>
                    <div style={{display: 'flex'}}>
                        <InvoiceViewerForm 
                
                            blackTheme={false}
                            bookingFormTableFilters={this.state.bookingFormTableFilters}
                            selectedRowId = {this.state.selectedRowId}
                            closeBookingForm={() => this.setState({bookingViewFormVisible: false})} 
                            parentState={this.state}
                            alertDialog={this.alertDialog.bind(this)} 
                            mailSenderDialog={this.mailSenderDialog.bind(this)} 
                            showNav={this.showNavWithButtons.bind(this)}
                            selectedInvoice={this.state.selectedInvoice}
                            invoiceDirection={this.state.invoiceDirection}
                            companyBackgroundInfoPanelOpen={this.companyBackgroundInfoPanelOpen.bind(this)}
                            frameState={this.state}
                            />
                    </div>
                </PopUpWindow>
            }

            var containerHeight = (this.state.containerRef.current != null) ? this.state.containerRef.current.clientHeight : 550;

            currentContent = <Panel blackTheme={this.state.blackTheme}>
                {bookingForm}
            
                <BookingTable 
                    containerHeight={containerHeight}
                    setSelectedRowId={this.setSelectedRowId.bind(this)}
                    openUpdateBookingForm={this.openUpdateBookingForm.bind(this)} 
                    openViewBookingForm={this.openViewBookingForm.bind(this)}
                    openInsertBookingForm={this.openInsertBookingForm.bind(this)}
                    parentState={this.state}
                    alertDialog={this.alertDialog.bind(this)} 
                    showNav={this.showNav.bind(this)}
                    blackTheme={this.state.blackTheme}
                    invoiceDirection={this.state.invoiceDirection}
                    />
            
            </Panel>;



        }


        // A DB Transfer tábla 
        if (this.state.currentContentId == 'bookingIncomingTransfer') {
            var bookingForm = "";
            this.state.invoiceDirection = "INBOUND";
            if (this.state.bookingFormVisible) {
                bookingForm = <PopUpWindow blackTheme={false} collapsed={this.state.menuCollapsed}>
                    <div style={{display: 'flex'}}>
                        <DBTransferTable 
                
                            blackTheme={false}
                            bookingFormTableFilters={this.state.bookingFormTableFilters}
                            selectedRowId = {this.state.selectedRowId}
                            closeBookingForm={this.closeBookingForm.bind(this)} 
                            parentState={this.state}
                            alertDialog={this.alertDialog.bind(this)} 
                            mailSenderDialog={this.mailSenderDialog.bind(this)} 
                            showNav={this.showNavWithButtons.bind(this)}
                            selectedInvoice={this.state.selectedInvoice}
                            invoiceDirection={this.state.invoiceDirection}
                            companyBackgroundInfoPanelOpen={this.companyBackgroundInfoPanelOpen.bind(this)}
                            frameState={this.state}
                            />
                    </div>
                </PopUpWindow>
            }
            if (this.state.bookingViewFormVisible) {
                bookingForm = <PopUpWindow blackTheme={false} collapsed={this.state.menuCollapsed}>
                    <div style={{display: 'flex'}}>
                        <InvoiceViewerForm 
                
                            blackTheme={false}
                            bookingFormTableFilters={this.state.bookingFormTableFilters}
                            selectedRowId = {this.state.selectedRowId}
                            closeBookingForm={() => this.setState({bookingViewFormVisible: false})} 
                            parentState={this.state}
                            alertDialog={this.alertDialog.bind(this)} 
                            mailSenderDialog={this.mailSenderDialog.bind(this)} 
                            showNav={this.showNavWithButtons.bind(this)}
                            selectedInvoice={this.state.selectedInvoice}
                            invoiceDirection={this.state.invoiceDirection}
                            companyBackgroundInfoPanelOpen={this.companyBackgroundInfoPanelOpen.bind(this)}
                            frameState={this.state}
                            />
                    </div>
                </PopUpWindow>
            }



            var containerHeight = (this.state.containerRef.current != null) ? this.state.containerRef.current.clientHeight : 550;

            currentContent = <Panel blackTheme={this.state.blackTheme}>
                {bookingForm}
            
                <DBTransferTable
                    containerHeight={containerHeight}
                    setSelectedRowId={this.setSelectedRowId.bind(this)}
                    openUpdateBookingForm={this.openUpdateBookingForm.bind(this)} 
                    openInsertBookingForm={this.openInsertBookingForm.bind(this)}
                    openViewBookingForm={this.openViewBookingForm.bind(this)}
                    parentState={this.state}
                    alertDialog={this.alertDialog.bind(this)} 
                    showNav={this.showNav.bind(this)}
                    blackTheme={this.state.blackTheme}
                    invoiceDirection={this.state.invoiceDirection}
            
                    />
            
            </Panel>;
        }

        if (this.state.currentContentId == 'bookingOutgoingTransfer') {
            var bookingForm = "";
            this.state.invoiceDirection = "OUTBOUND";
            if (this.state.bookingFormVisible) {
                bookingForm = <PopUpWindow blackTheme={false} collapsed={this.state.menuCollapsed}>
                    <div style={{display: 'flex'}}>
                        <DBTransferTable
                
                            blackTheme={false}
                            bookingFormTableFilters={this.state.bookingFormTableFilters}
                            selectedRowId = {this.state.selectedRowId}
                            closeBookingForm={this.closeBookingForm.bind(this)} 
                            parentState={this.state}
                            alertDialog={this.alertDialog.bind(this)} 
                            mailSenderDialog={this.mailSenderDialog.bind(this)} 
                            showNav={this.showNavWithButtons.bind(this)}
                            selectedInvoice={this.state.selectedInvoice}
                            invoiceDirection={"OUTBOUND"}
                            companyBackgroundInfoPanelOpen={this.companyBackgroundInfoPanelOpen.bind(this)}
                            frameState={this.state}
                            />
                    </div>
                </PopUpWindow>
            }

            if (this.state.bookingViewFormVisible) {
                bookingForm = <PopUpWindow blackTheme={false} collapsed={this.state.menuCollapsed}>
                    <div style={{display: 'flex'}}>
                        <InvoiceViewerForm 
                
                            blackTheme={false}
                            bookingFormTableFilters={this.state.bookingFormTableFilters}
                            selectedRowId = {this.state.selectedRowId}
                            closeBookingForm={() => this.setState({bookingViewFormVisible: false})} 
                            parentState={this.state}
                            alertDialog={this.alertDialog.bind(this)} 
                            mailSenderDialog={this.mailSenderDialog.bind(this)} 
                            showNav={this.showNavWithButtons.bind(this)}
                            selectedInvoice={this.state.selectedInvoice}
                            invoiceDirection={this.state.invoiceDirection}
                            companyBackgroundInfoPanelOpen={this.companyBackgroundInfoPanelOpen.bind(this)}
                            frameState={this.state}
                            />
                    </div>
                </PopUpWindow>
            }

            var containerHeight = (this.state.containerRef.current != null) ? this.state.containerRef.current.clientHeight : 550;

            currentContent = <Panel blackTheme={this.state.blackTheme}>
                {bookingForm}
            
                <DBTransferTable
                    containerHeight={containerHeight}
                    setSelectedRowId={this.setSelectedRowId.bind(this)}
                    openUpdateBookingForm={this.openUpdateBookingForm.bind(this)} 
                    openViewBookingForm={this.openViewBookingForm.bind(this)}
                    openInsertBookingForm={this.openInsertBookingForm.bind(this)}
                    parentState={this.state}
                    alertDialog={this.alertDialog.bind(this)} 
                    showNav={this.showNav.bind(this)}
                    blackTheme={this.state.blackTheme}
                    invoiceDirection={this.state.invoiceDirection}
                    />
            
            </Panel>;
        }
        // A DB Transfer tábla vége 



        if (this.state.currentContentId == 'AccountantManager') {
            currentContent = <Panel blackTheme={this.state.blackTheme}><AccountantManager  blackTheme={this.state.blackTheme} alertDialog={this.alertDialog.bind(this)}  /></Panel>;
        }
        if (this.state.currentContentId == 'ChiefAccountantManager') {
            currentContent = <Panel blackTheme={this.state.blackTheme}><ChiefAccountantManager blackTheme={this.state.blackTheme} alertDialog={this.alertDialog.bind(this)}  /></Panel>;
        }
        if (this.state.currentContentId == 'limits') {
            currentContent = <Panel blackTheme={this.state.blackTheme}><Limits blackTheme={this.state.blackTheme} alertDialog={this.alertDialog.bind(this)}  /></Panel>;
        }
        if (this.state.currentContentId == 'AdminManager') {
            currentContent = <Panel blackTheme={this.state.blackTheme}><AdminManager blackTheme={this.state.blackTheme}  alertDialog={this.alertDialog.bind(this)}  /></Panel>;
        }
        if (this.state.currentContentId == 'CompanyManager') {
            currentContent = <Panel blackTheme={this.state.blackTheme}>
            <CompanyManager 
                blackTheme={this.state.blackTheme} 
                alertDialog={this.alertDialog.bind(this)} 
                companyBackgroundInfoPanelOpen={this.companyBackgroundInfoPanelOpen.bind(this)} 
                frameState={this.state}
                /></Panel>;
        }
        if (this.state.currentContentId == 'widgets') {
            currentContent = <Panel  blackTheme={this.state.blackTheme} >
            <MasterData 
                companyBackgroundInfoPanelOpen={this.companyBackgroundInfoPanelOpen.bind(this)} 
                blackTheme={this.state.blackTheme} 
                alertDialog={this.alertDialog.bind(this)} 
                frameState={this.state}
                />
        </Panel>;
        }

        if (this.state.currentContentId == 'outgoing') {
            currentContent = <Kimeno blackTheme={this.state.blackTheme} alertDialog={this.alertDialog.bind(this)} />;
        }

        if (this.state.currentContentId == 'docOther') {
            currentContent = <DocOther blackTheme={this.state.blackTheme} alertDialog={this.alertDialog.bind(this)} />;
        }

        if (this.state.currentContentId == 'bank') {
            currentContent = <BankiImportok blackTheme={this.state.blackTheme} alertDialog={this.alertDialog.bind(this)}  />;
        }

        if (this.state.currentContentId == 'Booking' || this.state.currentContentId == 'Upload') {

            currentContent = <InfoPanel updateNeed={this.updateNeed}  blackTheme={this.state.blackTheme} currentCompany={this.state.currentCompany}  setContent={this.setContent.bind(this)} />;
        }

        if (this.state.currentContentId == 'office') {
            currentContent = <Panel blackTheme={this.state.blackTheme}><OfficeManager blackTheme={this.state.blackTheme} alertDialog={this.alertDialog.bind(this)} /></Panel>;
        }
        if (this.state.currentContentId == 'OperatorManager') {
            currentContent = <Panel blackTheme={this.state.blackTheme}><OperatorManager blackTheme={this.state.blackTheme} alertDialog={this.alertDialog.bind(this)} /></Panel>;
        }
        if (this.state.currentContentId == 'pages') {
            currentContent = <DocStoreShared  blackTheme={this.state.blackTheme}  alertDialog={this.alertDialog.bind(this)}  />;
        }
        if (this.state.currentContentId == 'dbAdmin') {
            currentContent = <DBAdmin  blackTheme={this.state.blackTheme}  alertDialog={this.alertDialog.bind(this)}  />;
        }

        if (this.state.currentContentId == 'lockClose') {
            currentContent = <DocStore  blackTheme={this.state.blackTheme}  alertDialog={this.alertDialog.bind(this)}  />;
        }
        if (this.state.currentContentId == 'lockOpen') {
            currentContent = <DocStoreShared  blackTheme={this.state.blackTheme}  alertDialog={this.alertDialog.bind(this)}  />;
        }
        if (this.state.currentContentId == 'navConnection') {
            currentContent = <Panel blackTheme={this.state.blackTheme} style={{height:'85vh'}}>
                <QueryInvoiceDigestLinesTable  blackTheme={this.state.blackTheme}  alertDialog={this.alertDialog.bind(this)}  />
            </Panel>
        }
        if (this.state.currentContentId == 'Users') {
            if (this.state.userType == 'ADMIN') {

                currentContent = <InfoPanelCompaniesAdmin 
                    currentCompany={this.state.currentCompany} 
                    setCurrentCompany={this.setCurrentCompany.bind(this)} 
                    setContent={this.setContent.bind(this)}  
                    blackTheme={this.state.blackTheme}  
                    alertDialog={this.alertDialog.bind(this)}
                    updateNeed={this.updateNeed}
                
                    />;
            } else {


                currentContent = <InfoPanelCompanies 
                    currentCompany={this.state.currentCompany}
                    setCurrentCompany={this.setCurrentCompany.bind(this)} 
                    setContent={this.setContent.bind(this)}  
                    blackTheme={this.state.blackTheme}  
                    alertDialog={this.alertDialog.bind(this)}
                    updateNeed={this.updateNeed}
                
                    />;
            }
        }


        var alertDialog = "";

        if (this.state.error.active) {
            alertDialog = <AlertDialog 
                blackTheme={this.state.blackTheme}
                alertDialogClose={this.alertDialogClose.bind(this)}>{this.state.error.msg}
            </AlertDialog>
        }

        var mailSenderDialog = "";

        if (this.state.mailSenderDialog.active) {
            alertDialog = <MailSenderDialog 
                blackTheme={this.state.blackTheme}
                mailSenderDialogClose={this.mailSenderDialogClose.bind(this)}>{this.state.mailSenderDialog.msg}
            </MailSenderDialog>
        }

        var queryInvoiceDigest = null;

        if (this.state.queryInvoiceDigestVisible) {
            queryInvoiceDigest = <PopUpWindow blackTheme={this.state.blackTheme}  collapsed={this.state.menuCollapsed}>
                <QueryInvoiceDigestTable 
                    sendToForm={this.sendToForm.bind(this)} 
                    alertDialog={this.alertDialog.bind(this)} 
                    showNav={this.showNav.bind(this)} 
                    showButton={this.state.queryInvoiceDigestVisibleWithButtons}
                    blackTheme={this.state.blackTheme}
                    invoiceDirection={this.state.invoiceDirection}
                    DOCUID ={this.state.queryInvoiceDigestDOCUID}  
                    frameState={this.state}
                    />
            </PopUpWindow>
        }

        var frameClass = (this.state.blackTheme) ? "frameDark" : "frameLight";
        var companyBackgroundInfoPanel = (this.state.companyBackgroundInfoPanelVisible) ?
                <CompanyBackgroundInfoPanel 
                    documentId ={this.state.companyBackgroundInfoPanelDocumentId}
                    partnerName={this.state.companyBackgroundInfoPanelTaxPartnerName}
                    close={this.companyBackgroundInfoPanelClose.bind(this)} 
                    taxNumber={this.state.companyBackgroundInfoPanelTaxNumber}
                    companyBackgroundInfoPanelisCompany={this.state.companyBackgroundInfoPanelisCompany}
                    companyBackgroundInfoPanelisPartner={this.state.companyBackgroundInfoPanelisPartner}
                    instantUpdateForm={this.state.instantUpdateForm.bind(this)}
                    alertDialog={this.alertDialog.bind(this)} 
                
                    /> : "";


        var header = "";
        if (this.state.userType == 'ADMIN') {

            header = <HeaderAdmin  
                collapsed={this.state.menuCollapsed}  
                collapseMenu={this.collapseMenu.bind(this)}  
                userType ={this.state.userType}
                firstName ={this.state.firstName}
                lastName ={this.state.lastName}
                logOut={this.logOut.bind(this)}
                setCurrentCompany={this.setCurrentCompany.bind(this)}
                setTheme={this.setTheme.bind(this)}
                blackTheme={this.state.blackTheme}
                currentCompany ={0}
            
            
                />;



        } else {
            header = <Header  
                collapsed={this.state.menuCollapsed}  
                collapseMenu={this.collapseMenu.bind(this)}  
                userType ={this.state.userType}
                firstName ={this.state.firstName}
                lastName ={this.state.lastName}
                logOut={this.logOut.bind(this)}
                setCurrentCompany={this.setCurrentCompany.bind(this)}
                setTheme={this.setTheme.bind(this)}
                blackTheme={this.state.blackTheme}
                currentCompany ={this.state.currentCompany}
            
            
            
                />
        }

        return <div style={{height: "100%"}}>
            {header}
            <div className={frameClass}>
        
                <div  className={menuStyle}>
                    <Menu 
                        setContent={this.setContent.bind(this)} 
                        currentContentId={this.state.currentContentId}
                        collapsed={this.state.menuCollapsed}  
                        userType ={this.state.userType}
                        iktathat={this.state.iktathat}
                        feltolthet={this.state.feltolthet}
                        blackTheme={this.state.blackTheme}
        
                        /></div>
                <div className={contenStyle}>{currentContent}{queryInvoiceDigest}</div>
            </div>
            {companyBackgroundInfoPanel}
            {alertDialog}{mailSenderDialog}
        </div>
    }
}

export default Frame;