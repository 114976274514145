// Iktatva 
import React, { useState, useCallback }
from 'react'
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import '@inovua/reactdatagrid-community/base.css';

import '@inovua/reactdatagrid-community/theme/default-dark.css';
import '@inovua/reactdatagrid-community/theme/default-light.css';


import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import BoolFilter from '@inovua/reactdatagrid-community/BoolFilter'
//import DateEditor from '@inovua/reactdatagrid-community/DateEditor';
import DateEditor from './../../Components/DateEditor';
import NumericEditor from '@inovua/reactdatagrid-community/NumericEditor';
//import SelectEditor from '@inovua/reactdatagrid-community/SelectEditor';
import ComboEditorNAV from './../../Components/ComboEditorNAV';
import BooleanEditor from './../../Components/BooleanEditor';

import CustomDate from './../../Components/CustomDate';
import * as ajax from './../../ajax';
import PagingToolbar from './../../Components/PagingToolbar';
// https://reactdatepicker.com/
import DatePicker from "react-datepicker";
import {registerLocale, setDefaultLocale}
from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import hu from 'date-fns/locale/hu';
import calendar_ico from "./../../img/white/edit_calendar_white_24dp.svg";
import settingsBlackIcon from "./../../img/black/settings_black_24dp.svg";
import settingsWhiteIcon from "./../../img/white/settings_white_24dp.svg";
import LoginDataForm from './LoginDataForm';

import AlertDialog from "./../../Components/AlertDialog";
import QueryInvoiceDigestLinesTable from './../../modules/QueryInvoiceDigestLines/QueryInvoiceDigestLinesTable';
import PopUpWindow from './../../Components/PopUpWindow';

import DetailsBlack from './../../img/black/description_black_24dp.svg';
import DetailsWhite from './../../img/white/description_white_24dp.svg';

import saveBlack from './../../img/black/save_black_24dp.svg';
import saveWhite from './../../img/white/save_white_24dp.svg';

import moment from 'moment';
import 'moment/locale/hu';
moment().locale('hu');

registerLocale('hu', hu);



class QueryInvoiceDigestTable extends React.Component {

    constructor(props) {
        super(props);

        this.startDateRef = React.createRef();
        this.endDateRef = React.createRef();

        var end = new Date();
        var start = new Date();
        start.setDate(end.getDate() - 34);

        end = end.toISOString().substring(0, 10);
        start = start.toISOString().substring(0, 10);

        this.state = {
            columnOrder: ["egyediAz", "id", "invoiceNumberButton", "invoiceIssueDate", "state", "invoiceNumber", "insDate", "batchIndex", "invoiceOperation", "invoiceCategory", "supplierTaxNumber", "supplierName", "customerTaxNumber", "customerGroupMemberTaxNumber", "customerName", "paymentMethod", "paymentDate", "invoiceAppearance", "source", "invoiceDeliveryDate", "currency", "invoiceNetAmount", "invoiceNetAmountHUF", "invoiceVatAmount", "invoiceVatAmountHUF", "invoiceGrossAmountHUF", "transactionId", "originalInvoiceNumber", "completenessIndicator"],
            alertDialog: '',
            alertDialogDisplayed: false,
            loginDataFormVisible: false,
            loadingMaskVisible: false,
            startDate: start,
            endDate: end,
            invoiceDirection: this.props.invoiceDirection,
            invoiceIssueDate0_invoiceDeliveryDate1: 0,
            gridRef: null,
            defaultUpdateBlocked: false,
            isFilterVisible: true,
            dateEditor: {
                active: false,
                id: null,
                key: null,
                value: null,
                editorProps: null
            },
            requestProps: null,
            skip: 0,
            loading: false,
            page: 1,
            selectedRow: -1,
            paging: {
                totalPageCount: 0,
                currentPage: 0,
                pageRowCount: 100,
                showingRowFrom: 0,
                showingRowTo: 5,
                totalRowCount: 0,
                skip: 0
            }
        }

        this.setColumnOrder();

        this.queryInvoiceDigestLinesTableVisible = false;
        this.instantInvoiceLinesFromNavRef = React.createRef();


    }

    navDownload = function () {
        this.setState({loadingMaskVisible: true});
        var self = this;
        try {
            const url = ajax.getUrlBase() + "NAV/QueryInvoiceDigest.php";
            return fetch(url, {
                method: "POST",
                //credentials: "include",
                //credentials: "*", // Tesztüzemben
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({skip: 1, limit: 1000, invoiceDirection: this.state.invoiceDirection, startDate: this.state.startDate, endDate: this.state.endDate, invoiceIssueDate0_invoiceDeliveryDate1: this.state.invoiceIssueDate0_invoiceDeliveryDate1})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success == false) {
                            self.tooLongDateRange("invoiceIssueDate");
                            self.props.alertDialog(response.fail);
                        }
                        self.setState({loadingMaskVisible: false});


                    }).catch(function (jsonError) {
                        self.state.defaultUpdateBlocked = false;
                        self.setState({loadingMaskVisible: false});

                        if (null == jsonError) {

                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    self.state.defaultUpdateBlocked = false;
                    self.setState({loadingMaskVisible: false});

                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                self.setState({loadingMaskVisible: false});
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            self.state.defaultUpdateBlocked = false;
            self.setState({loadingMaskVisible: false});
            console.error("Fetch exception:", String(ex));
        }

        self.setState({loadingMaskVisible: false});


    }

    updateRecord = function (id, key, value) {
        if (id === -1) {
            
            var skip = this.state.paging.skip;
            this.reload(skip);
            return false;
        }

        console.log('id:', id, 'key:', key, 'value:', value);
        try {
            const url = ajax.getUrlBase() + "NAV/update.php";
            var reload = this.reload.bind(this);
            var skip = this.state.paging.skip;
            return fetch(url, {
                method: "POST",
                //credentials: "include",
                //credentials: "*", // Tesztüzemben
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({id: id, key: key, value: value})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success == false) {
                            this.props.alertDialog(response.fail);
                        }
                        reload(skip);
                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");

                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));

                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);

                }
            }).catch(function (error) {
                console.error("Fetch error:", error);

                if (null == error) {
                    console.error("Fetch error:");

                } else {
                    console.error("Fetch error:" + String(error));


                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));

        }
    }

    reload = function (skip) {
        if (this.state.dateEditor.editorProps) {
            this.state.dateEditor.editorProps.onComplete();
        }
        this.state.paging.skip = skip;
        this.setState({dateEditor: {
                active: this.state.dateEditor.active,
                id: null,
                key: null,
                value: null,
                editorProps: null,
                defaultUpdateBlocked: false
            }});
    }

    onSelectionChange = function (selection) {
        this.state.defaultUpdateBlocked = true;
        this.state.selectedRow = selection.data.egyediAz;
        //this.props.setSelectedRowId(selection.data.id);
        return true;
    }

    setFilterDate = function () {

        var start = this.state.startDate;
        var end = this.state.endDate;


        if (typeof start == 'undefined' || start == null) {
            this.props.alertDialog("A kezdés dátuma kötelező!");
            return false;
        }

        if (typeof end == 'undefined' || end == null) {
            this.props.alertDialog("A befejezés dátum kötelező!");
            return false;
        }

        start = start.replace(/\./g, '-');
        end = end.replace(/\./g, '-');

        var startDate = new Date(start);
        var endDate = new Date(end);

        if (startDate.getTime() > endDate.getTime()) {
            this.props.alertDialog("A kezdés dátuma nem lehet nagyobb mint a befejezés!");
            return false;
        }

        var diff = (endDate - startDate) / (1000 * 60 * 60 * 24);

        if (diff > 34) {
            this.props.alertDialog("Maximum 35 napos intervallum kérdezhető le. A jelenlegi intervallum " + (diff + 1) + " nap.");
            return false;
        }
        this.navDownload();

    }

    customPaging = function (pagingProps) {
        return <PagingToolbar blackTheme={this.props.blackTheme} tableReload={this.reload.bind(this)} paging={this.state.paging} />
    }

    setFilterVisible = function () {
        this.setState({isFilterVisible: !this.state.isFilterVisible});
    }

    setInvoiceDirection = function (ev) {
        var val = ev.target.value;
        this.setState({invoiceDirection: val});
    }

    findLabelById = function (array, id) {
        for (var i = 0; i < array.length; i++) {
            var obj = array[i];
            if (obj.id == id) {
                return obj.label;
            }
        }
        return "";
    }

    tooLongDateRange = function (paramName) {

        for (var i = 0; i < this.state.requestProps.filterValue.length; i++) {
            if (this.state.requestProps.filterValue[i].name == paramName) {
                this.state.requestProps.filterValue[i]["value"]["end"] = '';
            }
        }

    }

    setGridRef = function (gridRef) {
        this.state.gridRef = gridRef;
        if (this.state.gridRef != null) {
            this.state.gridRef.current.focus();
        }
    }

    onCellSelectionChanged = function (cell) {
        if (typeof cell == 'undefined') {
            return false;
        }
        if (cell == null) {
            return false;
        }
        var keys = Object.keys(cell);
        for (var i = 0; i < keys.length; i++) {
            var key = keys[i];
            if (typeof key != 'undefined' && key != null) {
                var idColName = key.split(',');
                if (idColName.length == 2) {
                    var id = idColName[0];
                    this.state.selectedRow = id;
                    //this.props.setSelectedRowId(id);
                }
            }

        }
    }

    isSelectedRecordExists = function (data, id) {
        if (typeof data == 'undefined') {
            return false;
        }

        if (typeof data.length == 'undefined') {
            return false;
        }
        if (data.length == 0) {
            return false;
        }

        if (typeof id == 'undefined' || id == null || id == -1) {
            return false;
        }

        for (var i = 0; i < data.length; i++) {
            var record = data[i];
            if (record.id == id) {
                return true;
            }
        }
        return false;
    }

    onColumnResize = function (resized) {

        var projectName = 'QueryInvoiceDigest';
        localStorage.setItem(projectName + '#' + resized.column.name, resized.width);
    }

    getColumnWidth = function (columnName, defaultSize) {
        var projectName = 'QueryInvoiceDigest';
        var storedSize = localStorage.getItem(projectName + "#" + columnName);
        storedSize = storedSize;
        return (storedSize != null) ? storedSize * 1.0 : defaultSize;
    }

    setDate = function (key, value) {
        this.state[key] = value;
    }

    sendToForm = function (data) {

        this.props.sendToForm(data, this.state.invoiceDirection);
    }

    showLoginDataForm = function () {
        this.setState({loginDataFormVisible: true});
    }
    hideLoginDataForm = function () {
        this.setState({loginDataFormVisible: false});
    }

    setDateFiledValue = function (customDateRef, date) {
        if (typeof date == 'undefined') {
            return false;
        }
        if (date == null) {
            return false;
        }
        if (date.trim() == '') {
            return false;
        }

        var dateParts = date.split('-');
        var year = dateParts[0];
        var month = dateParts[1];
        var day = dateParts[2];

        customDateRef.current.yearInput.current.value = year;
        customDateRef.current.monthInput.current.value = month;
        customDateRef.current.dayInput.current.value = day;
    }

    alertDialogClose = function () {
        this.setState({alertDialog: ''});
    }

    onColumnOrderChange = function (ordered) {
        localStorage.setItem('QueryInvoiceDigest#customColumnOrder', ordered);
    }

    setColumnOrder = function () {

        var storedOrder = localStorage.getItem('QueryInvoiceDigest#customColumnOrder');
        if (storedOrder == null) {
            return false;
        }

        if (typeof storedOrder == 'undefined') {
            storedOrder = '';
        }

        if (storedOrder == null) {
            storedOrder = '';
        }
        if (storedOrder == '') {
            storedOrder = '';
        }

        if (this.props.showButton) {
            if (!storedOrder.includes("invoiceNumberButton")) {
                storedOrder = 'invoiceNumberButton,' + storedOrder;
            }
        }

        var storedOrderArray = storedOrder.split(",");

        if (storedOrderArray.length > 0) {
            this.state.columnOrder = storedOrderArray;
        }

    }

    myColumnsReorder = function (columns) {
        var orderedColumns = [];
        var order = this.state.columnOrder;

        for (var o = 0; o < order.length; o++) {
            for (var c = 0; c < columns.length; c++) {
                var column = columns[c];
                if (order[o] == column.name) {
                    orderedColumns.push(column);
                }
            }
        }

        return orderedColumns;
    }
    showNavTetelek = function () {
        this.queryInvoiceDigestLinesTableVisible = true;
        this.setState({});
    }

    hideNavTetelek = function () {
        this.queryInvoiceDigestLinesTableVisible = false;
        this.setState({});
    }

    setBack = function (queryInvocieDigest_id) {

    }

    findLabelById = function (array, id) {

        for (var i = 0; i < array.length; i++) {
            var obj = array[i];
            if (obj.id == id) {
                return obj.label;
            }
        }
        return "";
    }

    isCellEditable = function () {
        return true;
    }
    
    exportToXls = function () {

        var state = this.state;
        var skip = this.state.requestProps.skip;
        var limit = this.state.requestProps.limit;
        var sortInfo = this.state.requestProps.sortInfo;
        var filterValue = this.state.requestProps.filterValue;

        try {
            const url = ajax.getUrlBase() + "NAV/export/xls.php";
            state.update = false;
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({skip: skip, limit: limit, sortInfo: sortInfo, filterValue: filterValue, invoiceDirection: this.props.invoiceDirection})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        var data = response.data;

                        window.open(data.file, '_blank');
                    }).catch(function (jsonError) {
                        this.state.defaultUpdateBlocked = false;
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    this.state.defaultUpdateBlocked = false;
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            this.state.defaultUpdateBlocked = false;
            console.error("Fetch exception:", String(ex));
        }
    }

    render() {

        var state = this.state;

        this.setColumnOrder();
        const documentStates = [
            {id: 1, label: 'Letöltve'},
            {id: 2, label: 'Párosítva'},
            {id: 3, label: 'Kép feltöltve'},
            {id: 4, label: 'Nem könyvelhetö'}
        ];

        // Ha a státusz letöltve akkor a 



        var invoiceOperation = {
            "0": "Eredeti",
            "1": "Módosított",
            "2": "Stronó"
        };
        var invoiceCategory = {
            "0": "Normál",
            "1": "Egyszerűsített",
            "2": "Gyűjtőszámla"
        };
        var paymentMethod = {
            "0": "Átutalás",
            "1": "Készpénz",
            "2": "Bankkártya",
            "3": "Utalvány",
            "4": "Egyéb"
        };
        var invoiceAppearance = {
            "0": "Papír",
            "1": "Elektronikus",
            "2": "EDI",
            "3": "Ismeretlen"

        };

        var source = {
            "0": "WEB",
            "1": "XML",
            "2": "MGM",
            "3": "OPG"
        }


        var queryInvoiceDigestLinesTable = "";

        if (this.queryInvoiceDigestLinesTableVisible) {

            queryInvoiceDigestLinesTable = <PopUpWindow blackTheme={this.state.blackTheme}  collapsed={this.state.menuCollapsed}>
                <QueryInvoiceDigestLinesTable 
                    setBack={this.setBack.bind(this)} 
                    additionalParams={(typeof this.props.additionalParams == 'undefined') ? [] : this.props.additionalParams}
                    formType={(typeof this.props.formType == 'undefined') ? null : this.props.formType}
                    theme={this.state.theme}
                    invoiceDirection={this.props.invoiceDirection}
                    close={this.hideNavTetelek.bind(this)}
                    invoiceChooser={() => {
                                    }}
                    queryInvoiceDigestId={this.state.selectedRow}
            
                    />
            </PopUpWindow>;
        }




        var invoiceNumberButton = {name: 'invoiceNumberButton', header: 'Betöltés', filterDelay: 3000,
            editable: () => {
                return false;
            },
            render: ({data}) => {
                return <div key={'action_button_' + data.invoiceNumber}>
                    <button 
                        onClick ={() => {
                                                this.sendToForm(data);
                                            }}
                        style={{
                                                backgroundColor: "#00000044",
                                                color: "white",
                                                width: '100%',
                                                height: '100%',
                                                cursor: 'pointer',
                                                border: 'solid 1px #aaaaaaaa'
                                            }}>Betöltés</button></div>;
            },
            defaultWidth: this.getColumnWidth('invoiceNumberButton', 200)};


        var columns = [
            {editable: false, name: 'egyediAz', header: 'egyediAz', defaultWidth: this.getColumnWidth('egyediAz', 200), defaultVisible: false},
            {editable: false, name: 'id', header: 'id', defaultWidth: this.getColumnWidth('id', 200), defaultVisible: false},

            {editable: false, name: 'invoiceIssueDate', header: 'Kiállítás', filterDelay: 1000, defaultWidth: this.getColumnWidth('invoiceIssueDate', 200),
                dateFormat: 'YYYY-MM-DD',
                filterEditor: DateFilter,
                filterDelay: 1000,
                filterEditorProps: (props, { index }) => {
                    return {
                        dateFormat: 'MM-DD-YYYY',
                        cancelButton: false,
                        highlightWeekends: false,
                        placeholder: index == 1 ? 'Előtte' : 'Utána'
                }
                },
                render: ({ value }) => {
                    return <div>{value}</div>
                }
            },

            {editable: true, name: 'state', header: 'Státusz', filterDelay: 1000, defaultWidth: this.getColumnWidth('state', 150),
               

                renderEditor: (editorProps) => {
                    var id = editorProps.cellProps.data.id;
                    var key = editorProps.cellProps.id;
                    return <div style={{width: '100%'}} key={"combo" + key + "_" + id}>
                        <ComboEditorNAV 
                            blackTheme={this.props.blackTheme}
                            callback={ this.updateRecord.bind(this) } 
                            values={documentStates} 
                            editorProps={editorProps}
                            state={state} 
                    
                            />
                    </div>
                },
                render: ({value}) => {
                    return this.findLabelById(documentStates, value);
                },
                filterEditor: SelectFilter,
                filterEditorProps: {
                    multiple: true,
                    wrapMultiple: false,
                    dataSource: documentStates.map(c => {
                        return {id: c.id, label: c.label}
                    }),
                }
            },

            {editable: false, name: 'invoiceNumber', header: 'Számlaszám', filterDelay: 3000, defaultWidth: this.getColumnWidth('invoiceNumber', 200)},

            {editable: false, name: 'insDate', header: 'Mentés',
                dateFormat: 'YYYY-MM-DD',
                filterEditor: DateFilter,
                filterDelay: 1000,
                filterEditorProps: (props, { index }) => {
                    return {
                        dateFormat: 'MM-DD-YYYY',
                        cancelButton: false,
                        highlightWeekends: false,
                        placeholder: index == 1 ? 'Előtte' : 'Utána'
                }
                },
                render: ({ value }) => {
                    return <div>{value}</div>
                }
                , defaultWidth: this.getColumnWidth('insDate', 200), defaultVisible: false},

            {editable: false, name: 'batchIndex', header: 'batchIndex', defaultWidth: this.getColumnWidth('batchIndex', 200), defaultVisible: false},
            {editable: false, name: 'invoiceOperation', filterDelay: 1000, header: 'Számlaművelet típus',
                filterEditor: SelectFilter,
                filterEditorProps: {
                    multiple: true,
                    wrapMultiple: false,
                    dataSource: ["0", "1", "2"].map(c => {
                        return {id: c, label: invoiceOperation[c]}
                    })
                },
                render: ({ value }) => {
                    return <div>{invoiceOperation[value]}</div>
                }
                , defaultWidth: this.getColumnWidth('invoiceOperation', 200)},
            {editable: false, name: 'invoiceCategory', filterDelay: 1000, header: 'Számla típusa',
                filterEditor: SelectFilter,
                filterEditorProps: {
                    multiple: true,
                    wrapMultiple: false,
                    dataSource: ["0", "1", "2"].map(c => {
                        return {id: c, label: invoiceCategory[c]}
                    })
                },
                render: ({ value }) => {
                    return <div>{invoiceCategory[value]}</div>
                },
                defaultWidth: this.getColumnWidth('invoiceCategory', 200)},

            {editable: false, name: 'supplierTaxNumber', filterDelay: 1000, header: 'Kibocsátó adószáma', defaultWidth: this.getColumnWidth('supplierTaxNumber', 200)},
            //{name: 'supplierGroupMemberTaxNumber', header: 'supplierGroupMemberTaxNumber', defaultWidth: this.getColumnWidth('supplierGroupMemberTaxNumber', 200)},
            {editable: false, name: 'supplierName', filterDelay: 1000, header: 'Szállító neve', defaultWidth: this.getColumnWidth('supplierName', 200)},
            {editable: false, name: 'customerTaxNumber', filterDelay: 1000, header: 'Vevő adószáma', defaultWidth: this.getColumnWidth('customerTaxNumber', 200)},
            {editable: false, name: 'customerGroupMemberTaxNumber', filterDelay: 1000, header: 'A vevő csoporttag száma', defaultWidth: this.getColumnWidth('customerGroupMemberTaxNumber', 200)},
            {editable: false, name: 'customerName', filterDelay: 1000, header: 'A vevő neve', defaultWidth: this.getColumnWidth('customerName', 200)},
            {editable: false, name: 'paymentMethod', filterDelay: 1000, header: 'Fizetés módja',
                filterEditor: SelectFilter,
                filterEditorProps: {
                    multiple: true,
                    wrapMultiple: false,
                    dataSource: ["0", "1", "2", "3", "4"].map(c => {
                        return {id: c, label: paymentMethod[c]}
                    })
                },
                render: ({ value }) => {
                    return <div>{paymentMethod[value]}</div>
                },
                defaultWidth: this.getColumnWidth('paymentMethod', 200)},
            {editable: false, name: 'paymentDate', filterDelay: 1000, header: 'Fizetési határidő',

                dateFormat: 'YYYY-MM-DD',
                filterEditor: DateFilter,
                filterEditorProps: (props, { index }) => {
                    return {
                        dateFormat: 'MM-DD-YYYY',
                        cancelButton: false,
                        highlightWeekends: false,
                        placeholder: index == 1 ? 'Előtte' : 'Utána'
                }
                },
                render: ({ value }) => {
                    return <div>{value}</div>
                },
                defaultWidth: this.getColumnWidth('paymentDate', 200)},
            {editable: false, name: 'invoiceAppearance', filterDelay: 1000, header: 'A számla megjelenési formája',
                filterEditor: SelectFilter,
                filterEditorProps: {
                    multiple: true,
                    wrapMultiple: false,
                    dataSource: ['0', '1', '2', '3'].map(c => {
                        return {id: c, label: invoiceAppearance[c]}
                    })
                },
                render: ({ value }) => {
                    return <div>{invoiceAppearance[value]}</div>
                },
                defaultWidth: this.getColumnWidth('invoiceAppearance', 200)},
            {editable: false, name: 'source', filterDelay: 1000, header: 'Az adatszolgáltatás forrása',
                filterEditor: SelectFilter,
                filterEditorProps: {
                    multiple: true,
                    wrapMultiple: false,
                    dataSource: ['0', '1', '2', '3'].map(c => {
                        return {id: c, label: source[c]}
                    })
                },
                render: ({ value }) => {
                    return <div>{source[value]}</div>
                },
                defaultWidth: this.getColumnWidth('source', 200)},

            {editable: false, name: 'invoiceDeliveryDate', filterDelay: 1000, header: 'Teljesítés dátuma',
                dateFormat: 'YYYY-MM-DD',
                filterEditor: DateFilter,
                filterEditorProps: (props, { index }) => {
                    return {
                        dateFormat: 'MM-DD-YYYY',
                        cancelButton: false,
                        highlightWeekends: false,
                        placeholder: index == 1 ? 'Előtte' : 'Utána'
                }
                },
                render: ({ value }) => {
                    return <div>{value}</div>
                }
                , defaultWidth: this.getColumnWidth('invoiceDeliveryDate', 200)},

            {editable: false, name: 'currency', filterDelay: 1000, header: 'Pénznem', defaultWidth: this.getColumnWidth('currency', 200)},
            {editable: false, name: 'invoiceNetAmount', filterDelay: 1000, header: 'Nettó', filterEditor: NumberFilter, defaultWidth: this.getColumnWidth('invoiceNetAmount', 200)},
            {editable: false, name: 'invoiceNetAmountHUF', filterDelay: 1000, header: 'Nettó HUF', filterEditor: NumberFilter, defaultWidth: this.getColumnWidth('invoiceNetAmountHUF', 200)},
            {editable: false, name: 'invoiceVatAmount', filterDelay: 1000, header: 'Áfa', filterEditor: NumberFilter, defaultWidth: this.getColumnWidth('invoiceVatAmount', 200)},
            {editable: false, name: 'invoiceVatAmountHUF', filterDelay: 1000, header: 'Áfa HUF', filterEditor: NumberFilter, defaultWidth: this.getColumnWidth('invoiceVatAmountHUF', 200)},
            {editable: false, name: 'invoiceGrossAmountHUF', filterDelay: 1000, header: 'Bruttó HUF', filterEditor: NumberFilter, defaultWidth: this.getColumnWidth('invoiceGrossAmountHUF', 200)},
            {editable: false, name: 'transactionId', filterDelay: 1000, header: 'Tranzakció az.', defaultWidth: this.getColumnWidth('transactionId', 200)},
            // {name: 'index', header: 'index', defaultWidth: this.getColumnWidth('index', 200)},
            {editable: false, name: 'originalInvoiceNumber', filterDelay: 1000, header: 'Eredeti számla', defaultWidth: this.getColumnWidth('originalInvoiceNumber', 200)},
            //{name: 'modificationIndex', header: 'modificationIndex', defaultWidth: this.getColumnWidth('modificationIndex', 200)},

            {editable: false, name: 'completenessIndicator', filterDelay: 1000, header: 'A számlán nem szerepel több adat', defaultWidth: this.getColumnWidth('completenessIndicator', 200)}

        ];




        if (this.props.showButton) {
            var newColumns = [];
            for (var i = 0; i < columns.length; i++) {
                if (i == 1) {
                    newColumns.push(invoiceNumberButton)
                }
                newColumns.push(columns[i]);
            }
            columns = newColumns;
        }




        var newHeight = window.innerHeight - 210;
        const gridStyle = {minHeight: newHeight, fontFamily: 'Arial'};
        const data = [];
        const callback = function (result, data) {
            if (this.state.defaultUpdateBlocked == true) {
                return [];
            }
            return data;
        }

        var booleanEditorStyleDark = {
            cursor: 'pointer',
            position: 'absolute',
            background: '#464d56',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            paddingLeft: 8,
            paddingTop: 9
        };

        var booleanEditorStyleLight = {
            cursor: 'pointer',
            position: 'absolute',
            background: '#FFFFFF',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            paddingLeft: 8,
            paddingTop: 9
        };


        var booleanEditorStyle = (this.props.blackTheme) ? booleanEditorStyleDark : booleanEditorStyleLight;

        const filterValue = [
            {name: 'id', operator: 'contains', type: 'number', value: ''},
            {name: 'state', operator: 'inlist', type: 'select'},
            {name: 'invoiceNumber', operator: 'contains', type: 'string', value: this.props.DOCUID},
            {name: 'batchIndex', operator: 'contains', type: 'string', value: ''},
            {name: 'invoiceOperation', operator: 'eq', type: 'select', value: ''},
            {name: 'invoiceCategory', operator: 'eq', type: 'select', value: ''},
            {name: 'invoiceIssueDate', operator: 'eq', type: 'date', value: ''},
            {name: 'supplierTaxNumber', operator: 'contains', type: 'string', value: ''},
            {name: 'supplierGroupMemberTaxNumber', operator: 'contains', type: 'string', value: ''},
            {name: 'supplierName', operator: 'contains', type: 'string', value: ''},
            {name: 'customerTaxNumber', operator: 'contains', type: 'string', value: ''},
            {name: 'customerGroupMemberTaxNumber', operator: '', type: 'string', value: ''},
            {name: 'customerName', operator: 'contains', type: 'string', value: ''},
            {name: 'paymentMethod', operator: 'eq', type: 'select', value: ''},
            {name: 'paymentDate', operator: 'eq', type: 'date', value: ''},
            {name: 'invoiceAppearance', operator: 'eq', type: 'select', value: ''},
            {name: 'source', operator: 'eq', type: 'select', value: ''},
            {name: 'invoiceDeliveryDate', operator: 'eq', type: 'date', value: ''},
            {name: 'currency', operator: 'contains', type: 'string', value: ''},
            {name: 'invoiceNetAmount', operator: 'eq', type: 'number', value: ''},
            {name: 'invoiceNetAmountHUF', operator: 'eq', type: 'number', value: ''},
            {name: 'invoiceVatAmount', operator: 'eq', type: 'number', value: ''},
            {name: 'invoiceVatAmountHUF', operator: 'eq', type: 'number', value: ''},
            {name: 'invoiceGrossAmountHUF', operator: 'eq', type: 'number', value: ''},
            {name: 'transactionId', operator: 'contains', type: 'string', value: ''},
            // {name: 'index', operator: 'contains', type: 'string', value: ''},
            {name: 'originalInvoiceNumber', operator: 'contains', type: 'string', value: ''},
            // {name: 'modificationIndex', operator: 'contains', type: 'string', value: ''},
            {name: 'insDate', operator: 'eq', type: 'date', value: ''},
            {name: 'completenessIndicator', operator: 'contains', type: 'string', value: ''}


        ];


        const dataLoader = ({ skip, limit, sortInfo, groupBy, filterValue }) => {
            var self = this;
            var state = this.state;

            this.state.requestProps = {skip: skip, limit: limit, sortInfo: sortInfo, filterValue: filterValue};
            try {
                // const url = ajax.getUrlBase() + "NAV/QueryInvoiceDigest.php";
                const url = ajax.getUrlBase() + "NAV/select.php";
                state.update = false;
                return fetch(url, {
                    method: "POST",
                    headers: new Headers({"Content-Type": "application/json"}),
                    body: JSON.stringify({skip: skip, limit: limit, sortInfo: sortInfo, filterValue: filterValue, invoiceDirection: this.state.invoiceDirection})
                }).then(response => {
                    if (response.ok) {
                        return response.json().then(response => {

                            if (response.success == false && this.state.alertDialogDisplayed == false) {
                                this.state.alertDialog = <AlertDialog alertDialogClose={this.alertDialogClose.bind(this)} >{response.fail}</AlertDialog>;
                                this.setState({alertDialogDisplayed: true});
                                this.tooLongDateRange("invoiceIssueDate");
                            }
                            // this.state.defaultUpdateBlocked = false;
                            if (typeof response.extra != 'undefined' && response.extra !== null) {
                                var extra = response.extra;
                                if (extra['instantInvoiceLinesFromNav'] == 1) {

                                    this.instantInvoiceLinesFromNavRef.current.style.display = 'block';
                                } else {
                                    this.instantInvoiceLinesFromNavRef.current.style.display = 'none';
                                }


                                if (typeof extra.startDate != 'undefined' && extra.startDate != null && extra.startDate != '') {
                                    self.state.startDate = extra.startDate;
                                    self.setDateFiledValue(self.startDateRef, extra.startDate);
                                }
                                if (typeof extra.endDate != 'undefined' && extra.endDate != null && extra.endDate != '') {
                                    self.state.endDate = extra.endDate;
                                    self.setDateFiledValue(self.endDateRef, extra.endDate);
                                }
                                if (typeof extra.invoiceDirection != 'undefined' && extra.invoiceDirection != null && extra.invoiceDirection != '') {
                                    this.state.invoiceDirection = extra.invoiceDirection;
                                }

                            }

                            this.props.frameState['queryInvoiceDigestDOCUID'] = '';

                            var totalCount = response.count;
                            var data = response.data;
                            state.paging.totalRowCount = totalCount;
                            var totalPageCount = ((totalCount) / state.paging.pageRowCount);
                            state.paging.totalPageCount = Math.ceil(totalPageCount) - 1;
                            state.paging.showingRowFrom = skip;
                            state.paging.showingRowTo = skip + state.pageRowCount;
                            var isSelectedRecordExists = this.isSelectedRecordExists(data, this.state.selectedRow);
                            if (isSelectedRecordExists == false) {
                                if (data.length > 0) {
                                    this.state.selectedRow = data[0].egyediAz;
                                    //this.props.setSelectedRowId(this.state.selectedRow);
                                }
                            }

                            return Promise.resolve({data, count: parseInt(totalCount), skip: skip});
                        }).catch(function (jsonError) {
                            this.state.defaultUpdateBlocked = false;
                            if (null == jsonError) {

                                console.error("Fetch JSON error:");
                            } else {
                                console.error("Fetch JSON error:" + String(jsonError));
                            }
                        });
                    } else {
                        this.state.defaultUpdateBlocked = false;
                        console.error("HTTP status error:", response.statusText);
                    }
                }).catch(function (error) {
                    this.state.defaultUpdateBlocked = false;
                    console.error("Fetch error:", error);
                    if (null == error) {
                        console.error("Fetch error:");
                    } else {
                        console.error("Fetch error:" + String(error));
                    }
                });
            } catch (ex) {
                this.state.defaultUpdateBlocked = false;
                console.error("Fetch exception:", String(ex));
        }
        }


// Ellenőrizni kell, hogy ez kell-e?
        const setSkip = function () {
            this.setState({skip: this.state.skip + 10});
        }


        const renderPaginationToolbar = this.customPaging.bind(this);
        var isVisibleDateEditor = (this.state.dateEditor.active) ? 'grid' : 'none';
        var filters = [];
        if (this.state.isFilterVisible) {
            filters = filterValue;
        }

        const DEFAULT_ACTIVE_CELL = [0, 0];
        const onKeyDown = (event) => {
            const grid = this.state.gridRef.current;
            let [rowIndex, colIndex] = grid.computedActiveCell;
            if (typeof grid.data[rowIndex] != 'undefined') {
                var selectedRowId = grid.data[rowIndex].egyediAz;
                this.state.selectedRow = selectedRowId;
                //this.props.setSelectedRowId(selectedRowId);
            }


            if (event.key === 'Enter') {
                this.state.gridRef.current.autoFocusOnEditComplete = false;
                const column = grid.getColumnBy(colIndex);
                grid.startEdit({columnId: column.name, rowIndex})
                event.preventDefault();
                if (column.id == 'invoiceNumberButton') {
                    this.sendToForm(grid.data[rowIndex]);
                }
                return;
            }
            if (event.key !== 'Tab') {
                return
            }
            event.preventDefault()
            event.stopPropagation()

            const direction = event.shiftKey ? -1 : 1

            const columns = grid.visibleColumns
            const rowCount = grid.count

            colIndex += direction
            if (colIndex === -1) {
                colIndex = columns.length - 1
                rowIndex -= 1
            }
            if (colIndex === columns.length) {
                rowIndex += 1
                colIndex = 0
            }
            if (rowIndex < 0 || rowIndex === rowCount) {
                return
            }

            grid.setActiveCell([rowIndex, colIndex])
        }

        const onEditComplete = ({ value, columnId, rowIndex }) => {
            data[rowIndex] = Object.assign({}, data[rowIndex], {[columnId]: value})
        }

        var gridTheme = "";
        if (this.props.blackTheme) {
            gridTheme = "default-dark";
        } else {
            gridTheme = "default-light";
        }

        var panelBackground = (this.props.blackTheme) ? "#313943" : "#FFFFFF";
        var currentTextColor = (this.props.blackTheme) ? "#FFFFFF" : "#000000";
        var currentToolbarColor = (this.props.blackTheme) ? "#313943" : "#FFFFFF";

        var rowStyle = ({ data }) => {
            return {
                color: (data.used) ? (this.props.blackTheme) ? "#069336cf" : "#069336cf" : (this.props.blackTheme) ? "#FFFFFF88" : "#000000aa"

        }
        };


        var loginDataForm = "";
        if (this.state.loginDataFormVisible) {
            loginDataForm = <LoginDataForm 
            alertDialog={this.props.alertDialog}
            hideLoginDataForm={this.hideLoginDataForm.bind(this)} 
            blackTheme={this.props.blackTheme} />
        }



        var invoiceDirectionTitle = '';
        if (this.props.invoiceDirection == "OUTBOUND") {
            invoiceDirectionTitle = 'Kimenő számlák';
        }
        if (this.props.invoiceDirection == "INBOUND") {
            invoiceDirectionTitle = 'Bejövő számlák';
        }


        var details = (this.props.blackTheme) ? DetailsWhite : DetailsBlack;

        var darkButtonStyle = {
            backgroundColor: '#475360',
            color: '#FFFFFFaa',
            border: 'solid 0px #FFFFFF',
            padding: '3px',
            marginLeft: '16px',
            boxShadow: '1px 1px 1px #000000',
            cursor: 'pointer'
        };
        var lightButtonStyle = {

            backgroundColor: '#FFFFFF',
            color: '#000000aa',
            border: 'solid 0px #000000',
            padding: '3px',
            marginLeft: '16px',
            boxShadow: '1px 1px 1px #000000aa',
            cursor: 'pointer'

        };

        

        var buttonStyle = (this.props.blackTheme) ? darkButtonStyle : lightButtonStyle;



        return (<div style={{color: 'white', backgroundColor: panelBackground}}>
            <div style={{display: "flex", justifyContent: 'center', color: currentTextColor}}>
                NAV Online számla adatszolgáltatás - (<i>{invoiceDirectionTitle}</i>)
            </div>
            <div style={{display: 'flex', textAlign: 'center', backgroundColor: currentToolbarColor, color: currentTextColor, margin: "10px"}}>
        
                <div style={{float: 'left', marginTop: 'auto', marginBottom: 'auto', marginLeft: "20px"}}>
                    Időszak megadása (max 35 nap)
                </div>
                <div style={{float: 'left', marginTop: 'auto', marginBottom: 'auto', marginLeft: "20px"}}>
                    Tól:
                </div>
                <div style={{float: 'left', marginTop: 'auto', marginBottom: 'auto', marginLeft: "20px"}}>
                    <div style={{backgroundColor: "white", color: "black"}}>
                        <CustomDate ref={this.startDateRef} reload={this.setDate.bind(this)} writeBack={this.state} dataKey={"startDate"} currentValue={this.state.startDate}/>
                    </div>
                </div>
                <div style={{float: 'left', marginTop: 'auto', marginBottom: 'auto', marginLeft: "20px"}}>
                    Ig:
                </div>
                <div style={{float: 'left', marginTop: 'auto', marginBottom: 'auto', marginLeft: "20px"}}>
                    <div style={{backgroundColor: "white", color: "black"}}>
                        <CustomDate ref={this.endDateRef} reload={this.setDate.bind(this)} writeBack={this.state} dataKey={"endDate"} currentValue={this.state.endDate}/>
                    </div>
                </div>
                <div style={{float: 'left', marginTop: 'auto', marginBottom: 'auto', marginLeft: "20px"}}>
                    <div style={{backgroundColor: "white", color: "black"}}>
                        <select onChange={(e) => {
                                        this.setState({invoiceIssueDate0_invoiceDeliveryDate1: e.target.value})
                                            }}>
                            <option key={'dateType_0'} id={0} value={0}>Számla kelte</option>
                            <option key={'dateType_1'} id={1} value={1}>Teljesítés ideje</option>
                        </select>
                    </div>
                </div>
                <div style={{float: 'left', marginTop: 'auto', marginBottom: 'auto', marginLeft: "20px"}}>
                    <button onClick={this.setFilterDate.bind(this)} className={"bookingFormButton"}>Lekérdezés</button>
                </div>
                <div style={{float: 'left', marginTop: 'auto', marginBottom: 'auto', marginLeft: "20px"}}>
                    <button 
                        ref ={this.instantInvoiceLinesFromNavRef}
                        style={{
                                        backgroundColor: '#FFFFFF',
                                        color: '#000000CC',
                                        border: 'solid 1px #00000044',
                                        borderRadius: 4,
                                        cursor: 'pointer',
                                        boxShadow: '1px 1px 2px #00000044',
                                        display: 'normal'

                                    }} 
                        onClick={() => {
                                        this.queryInvoiceDigestLinesTableVisible = true;
                                        this.setState({});
                                    }} 
                        className={"bookingFormButton"}
                        >Tételek megtekintése</button>
                         
        
                </div>
                <div>
                <button style={buttonStyle} onClick={() => {
                                this.exportToXls();
                                    }} >
                            <img className={"buttonImage"} src={(this.props.blackTheme) ? saveWhite : saveBlack}/>
                            Exportálás
                        </button> 
                </div>
                <div style={{width: '80%'}}></div>
                <img 
                    title={"Beállítások"} 
                    style={{marginTop: '5px'}} 
                    className={"buttonImage"} 
                    src={(this.props.blackTheme) ? settingsWhiteIcon : settingsBlackIcon}
                    onClick={this.showLoginDataForm.bind(this)}
        
                    />
            </div>
            <div  style={{margin: 'auto'}}>
                <ReactDataGrid 
                    onReady={this.state.gridRef}
                    handle ={this.setGridRef.bind(this)}
                    idProperty="egyediAz" 
                    columns={this.myColumnsReorder(columns)} 
                    style={gridStyle}
                    theme={gridTheme}
        
                    editable={true}
        
                    defaultActiveCell={DEFAULT_ACTIVE_CELL}
                    onKeyDown={onKeyDown}
        
                    i18n={ajax.getI18n()}
                    dataSource={dataLoader}
                    defaultLimit={100}
                    defaultFilterValue={filters}
                    rowStyle={rowStyle}
                    onCellSelectionChange={this.onCellSelectionChanged.bind(this)}
        
        
                    loading={this.state.loadingMaskVisible}
                    pagination={true}
                    renderPaginationToolbar={renderPaginationToolbar}
                    skip={this.state.paging.skip}
                    limit={this.state.paging.pageRowCount}
                    defaultCellSelection={[]}
                    onColumnResize={this.onColumnResize.bind(this)}   
        
                    onColumnOrderChange={this.onColumnOrderChange.bind(this)}
                    multiSelect={false}
                    onFilterValueChange={() => {
                                    this.state.paging.skip = 0;
                                    this.state.paging.currentPage = 0;
                                    this.setState({});
                                }}
        
        
        
                    />
            </div>
            <div style={{width: '100%', display: 'flex', textAlign: 'center', justifyContent: 'center', marginTop: '10px'}}>
                <button className={"bookingFormButton"}  onClick={() => {
                                this.props.showNav(false)
                                    }} >Bezárás</button>
            </div>
            {loginDataForm}
            {queryInvoiceDigestLinesTable}
        
            {this.state.alertDialog}
        </div>
                );
    }

}
export default QueryInvoiceDigestTable;