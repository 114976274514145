import React from 'react';
import * as ajax from './../ajax.js';


class TaxNumberField extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: '',
            fieldsToModify: []
           
        }

    }


    onKeyUp = function (e) {
        if (e.target.value.length > 7) {
            var taxNumber = e.target.value;
            this.loadCeginfo(taxNumber);
        }
    }

    loadCeginfo = function (taxNumber) {
        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/CompanyBackgroundInfo/formLoaderCompany.php";
            return fetch(url, {
                method: "POST",
                // credentials: "include",
                // credentials: "same-origin",
                // credentials: "*", // Tesztüzemben
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({taxNumber: taxNumber, name: '', regNumber: ''})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            var data = response.data;
                            var refs = self.props.refs;
                            for (const key in refs) {
                                if (typeof data[key] != 'undefined' && data[key] != null) {
                                    self.props.refs[key].current.value = data[key];
                                    self.props.state[key] = data[key];
                                }
                            }
                        }
                    }).catch(jsonError => {
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(error => {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }


    }

    render() {
        return <input ref={this.props.taxNumberFieldRef} onKeyUp={this.onKeyUp.bind(this)} className={this.props.className}  value={this.props.value} type="text"  onChange={this.props.onChange} id={this.props.id}   />;
    }
}

export default TaxNumberField;