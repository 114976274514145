import React, { Component } from 'react';
import {ProjectDescriptor, FieldDescriptor, Layout}  from './../../utils.js';
import * as ajax from './../../ajax.js';
import Panel from './../../Components/Panel';
import BasicForm from './../../Components/BasicForm';
import PopUpWindow  from './../../Components/PopUpWindow';

class DBAdmin extends React.Component {

    constructor(props) {
        super(props);

        this.state = {popUpVisible: false, currentDoc: null}

        var endpoint = ajax.getUrlBase() + 'modules/DBAdmin/';
        var formTitle = 'Archívumok';
        var projectDescriptor = new ProjectDescriptor('DBAdmin', endpoint, formTitle);

        projectDescriptor.deleteButtonVisible = false;
        projectDescriptor.newButtonVisible = false;
        projectDescriptor.filterButtonVisible = false;
        projectDescriptor.modifyButtonVisible = false;
        projectDescriptor.viewButtonVisible = false;
        projectDescriptor.viewXlsExportVisible = false;

        projectDescriptor.setFormDimensions(545, 280);

        // ARCHIVE STATES
        // 0:HASZNÁLATBAN
        // 1:ARCHIVÁLÁSRA VÁR
        // 2:ARCHIVÁLVA
        // 3:TÖRÖLVE

        var id = new FieldDescriptor(true, FieldDescriptor.TYPE_LABEL, 'id', '#', '');
        var officeName = new FieldDescriptor(false, FieldDescriptor.TYPE_LABEL, 'officeUser_firstName', 'Iroda', '');
        var dbYear = new FieldDescriptor(true, FieldDescriptor.TYPE_LABEL, 'dbYear', 'Év', '');
        var dbSize = new FieldDescriptor(true, FieldDescriptor.TYPE_LABEL, 'dbSize', 'Méret', '');
        var dbName = new FieldDescriptor(false, FieldDescriptor.TYPE_LABEL, 'dbName', 'Adatbázis neve', '');
        var statusz = new FieldDescriptor(false, FieldDescriptor.TYPE_SELECT, 'archiveState', 'Státusz', [
            {id: 0, label: 'Használatban'},
            {id: 1, label: 'Törlésre vár'},
            {id: 2, label: 'Törlés alatt'},
            {id: 3, label: 'Törölve'}
        ]);
        statusz.setEditableTableField(false);
        var deletedBy = new FieldDescriptor(false, FieldDescriptor.TYPE_LABEL, 'deletedByUser_firstName', 'Törölte', '');
        var deletedAt = new FieldDescriptor(false, FieldDescriptor.TYPE_LABEL, 'deletedAt', 'Törölve', '');



        var deleteForm = new FieldDescriptor(false, FieldDescriptor.TYPE_ACTION_BUTTON, 'actionId', 'Törlés', '');
        deleteForm.setVisibleFormField(false);
        deleteForm.setAction(this.showDeleteDialog.bind(this));

        projectDescriptor.addFieldDescriptor(id);
        projectDescriptor.addFieldDescriptor(dbYear);
        projectDescriptor.addFieldDescriptor(dbSize);
        projectDescriptor.addFieldDescriptor(officeName);
        projectDescriptor.addFieldDescriptor(dbName);
        projectDescriptor.addFieldDescriptor(statusz);
        projectDescriptor.addFieldDescriptor(deletedBy);
        projectDescriptor.addFieldDescriptor(deletedAt);
        projectDescriptor.addFieldDescriptor(deleteForm);

        var layout = new Layout();
        layout.addColumns([0, 120, 270, 400])
        layout.addRows([0, 34, 68, 102])
        this.projectDescriptor = projectDescriptor;
        this.layout = layout;

        this.selectedDatabaseId = -1;
    }

    showDeleteDialog = function (id) {
        this.selectedDatabaseId = id;
        this.setState({popUpVisible: true});
    }



    exportToXls = function () {

        var state = this.state;
        var skip = this.state.requestProps.skip;
        var limit = this.state.requestProps.limit;
        var sortInfo = this.state.requestProps.sortInfo;
        var filterValue = this.state.requestProps.filterValue;

        try {
            const url = ajax.getUrlBase() + "modules/Booking/xls.php";
            state.update = false;
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({skip: skip, limit: limit, sortInfo: sortInfo, filterValue: filterValue, invoiceDirection: this.props.invoiceDirection})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        var data = response.data;
                        window.open(data.file, '_blank');
                    }).catch(function (jsonError) {
                        this.state.defaultUpdateBlocked = false;
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    this.state.defaultUpdateBlocked = false;
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            this.state.defaultUpdateBlocked = false;
            console.error("Fetch exception:", String(ex));
        }
    }




    deleteDatabase = function () {
        
        try {
            const url = ajax.getUrlBase() + "modules/DBAdmin/deleteDatabase.php";
            return fetch(url, {
                method: "POST",
                // credentials: "include",
                // credentials: "same-origin",
                // credentials: "*", // Tesztüzemben
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({id: this.selectedDatabaseId})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            this.setState({popUpVisible: false});
                        } else {
                            this.setState({loginSuccess: false});
                        }

                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");

                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));

                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }
    }

    hideDocument = function () {
        this.setState({popUpVisible: false});
    }

    render() {
        var popUp = null;

        var panelBackground = (this.props.blackTheme) ? "#313943" : "#FFFFFF";

        if (this.state.popUpVisible == true) {


            popUp = <PopUpWindow style={{textAlign: 'center', maxWidth: 400, maxHeight: 300}} >
                <p>ADATBÁZIS TÖRLÉSE</p>
            
                <div  style={{width: '100%', color: 'red', textAlign: 'center'}}>
                    <p>Biztos benne, hogy törli a <b>{this.selectedDatabaseId}</b> azonosítóval<br/> ellátott adatbázist?<br/></p>
                </div>
                <div  style={{width: '100%', color: 'black', textAlign: 'center', whiteSpace: 'normal'}}>
                    <p>
                        Törlés esetén az adatbázisokról 24 órán belül biztonsági másolatot készítünk, utána eltávolítjuk a rendszerből.
            
                    </p>
                </div>
                <br/>
                <br/>
                <div style={{display: 'flex'}}>
                    <div style={{minWidth: '48%', textAlign: 'right'}}>
                        <input style={{backgroundColor: '#9fd027', paddingLeft: 15, paddingRight: 15}} autoFocus onClick={() => this.deleteDatabase()} className="bookingFormButton" type="button" value="Igen" />
                    </div>
                    <div style={{minWidth: '4%', textAlign: 'center'}}>
                    </div>
                    <div style={{minWidth: '48%', textAlign: 'left'}}>
                        <input autoFocus onClick={() => this.setState({popUpVisible:false,showDeleteDBForm: false})} className="bookingFormButton" type="button" value="Mégsem" />
                    </div>
                </div>
            
            </PopUpWindow>
        }
        return <Panel>
            {popUp}
            <BasicForm blackTheme={this.props.blackTheme} alertDialog={this.props.alertDialog.bind(this)} layout={this.layout} projectDescriptor={this.projectDescriptor} /></Panel>
    }
}

export default DBAdmin;