import React from 'react';
import * as ajax from './../ajax';
import PopUpWindow from './PopUpWindow';

const ProgressWindow = ({ maximumWidth = 200, maximumHeight = 170 }) => {
   const url = ajax.getUrlBase() + 'img/progress.gif';

   return (
      <PopUpWindow style={{ textAlign: 'center', maxWidth: maximumWidth, maxHeight: maximumHeight }} >
         <img style={{ textAlign: 'center', maxWidth: maximumWidth, maxHeight: maximumHeight }} src={url} alt='Folyamatjelző GIF' />
      </PopUpWindow>
   );
};

export default ProgressWindow;