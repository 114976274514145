import React, { Component } from 'react';
import calendar_ico from "./../img/calendarBlue.svg";
import './../css/dateTime.css';
import * as ajax from './../ajax.js';
import PopUpWindow from './PopUpWindow';
import ConfirmationDialog from './ConfirmationDialog';

class ArchiveButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            officeId: null,
            currentCompanyId: null,
            arhcivedYears: [],
            currentYear: 0,
            deleteNeedShow: false,
            deleteNeedYears: [],
            showArciveForm: false,
            showDeleteDBForm: false,
            showMask: false,
            showAlert: false,
            fail: ''

        }

        this.fullArchiveRecordsCount = 0;
        this.fromRecord = 0;
        this.archivedRecordsInOneStep = 1000;

        this.progressTitle = "ÚJ ADATBÁZIS LÉTREHOZÁSA FOLYAMATBAN";



        this.load();

    }

    componentDidUpdate(prevProps) {
        if (prevProps.currentValue !== this.props.currentValue) {
        }
    }

    load = function () {
        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/OfficeManager/currentOfficeInfo.php";
            return fetch(url, {
                method: "POST",
                //credentials: "include",
                //credentials: "*", // Tesztüzemben
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (typeof response.data != 'undefined' && response.data != null) {
                            self.setState({
                                officeId: response.data.officeId,
                                archivedYears: response.data.archivedYears,
                                currentYear: response.data.currentYear,
                                currentCompanyId: response.data.currentCompanyId,
                                deleteNeedYears: response.data.deleteNeedYears,
                                deleteNeedYearsShow: false

                            });
                            self.forceUpdate();


                            var value = {value: response.data.currentCompanyId};
                            var e = {target: value};

                            self.props.setCompanyFilter(e);
                        }
                    }).catch(function (jsonError) {

                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);
                }
            }
            ).catch(function (error) {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }
    }

    cloneOffice = function () {
        this.setState({showMask: true, showArciveForm: false});
        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/OfficeManager/clone.php";
            return fetch(url, {
                method: "POST",
                //credentials: "include",
                //credentials: "*", // Tesztüzemben
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({
                    officeId: this.state.officeId
                })
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        self.load();
                        if (response.success == false) {
                            self.setState({showAlert: true, fail: response.fail, showMask: false, showArciveForm: false});

                        } else {
                            self.fullArchiveRecordsCount = response.data.fullArchiveRecordsCount;

                            self.cloneOfficeOtherData();

                        }

                    }).catch(function (jsonError) {
                        self.setState({showMask: false, showArciveForm: false});
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    self.setState({showMask: false, showArciveForm: false});
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                self.setState({showMask: false, showArciveForm: false});
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            self.setState({showMask: false, showArciveForm: false});
            console.error("Fetch exception:", String(ex));
        }
    }

    cloneOfficeOtherData = function () {

        this.setState({showMask: true, showArciveForm: false});
        var self = this;
        self.progressTitle = "KIEGÉSZÍTŐ ADATOK MÁSOLÁSA";
        try {
            const url = ajax.getUrlBase() + "modules/OfficeManager/cloneOtherData.php";
            return fetch(url, {
                method: "POST",
                //credentials: "include",
                //credentials: "*", // Tesztüzemben
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        self.load();
                        if (response.success == false) {
                            self.setState({showAlert: true, fail: response.fail, showMask: false, showArciveForm: false});
                        } else {

                            this.setState({showMask: false, showArciveForm: false});
                        }

                    }).catch(function (jsonError) {
                        self.setState({showMask: false, showArciveForm: false});
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    self.setState({showMask: false, showArciveForm: false});
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                self.setState({showMask: false, showArciveForm: false});
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            self.setState({showMask: false, showArciveForm: false});
            console.error("Fetch exception:", String(ex));
        }
    }

    deleteOfficeDatabases = function () {

        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/OfficeManager/deleteOfficeDatabases.php";

            return fetch(url, {
                method: "POST",
                //credentials: "include",
                //credentials: "*", // Tesztüzemben
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({deleteNeedYears: this.state.deleteNeedYears})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        this.setState({showDeleteDBForm: false});
                        self.load();
                        if (response.success == false) {

                            self.setState({showAlert: true, fail: response.fail});
                        }
                    }).catch(function (jsonError) {
                        self.setState({showDeleteDBForm: false});
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    self.setState({showDeleteDBForm: false});
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                self.setState({showDeleteDBForm: false});
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            self.setState({showDeleteDBForm: false});
            console.error("Fetch exception:", String(ex));
        }
    }

    changeCurrentArchive = function (e) {

        var comboDbYear = e.target.value;
        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/OfficeManager/changeCurrentArchive.php";
            return fetch(url, {
                method: "POST",
                //credentials: "include",
                //credentials: "*", // Tesztüzemben
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({dbYear: comboDbYear})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        self.setState({currentYear: comboDbYear});

                        var value = {value: response.data.currentCompanyId};
                        var dbYear = {dbYear: comboDbYear};
                        var currentCompany = {target: value, dbYear: dbYear};

                        self.props.setCompanyFilter(currentCompany);
                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {

                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }
    }

    getArciveForm = function () {
        return <PopUpWindow style={{textAlign: 'center', maxWidth: 400, maxHeight: 300}} >
            <p>ADATBÁZIS ARCHIVÁLÁSA</p>
        
            <div  style={{width: '100%', color: 'red', textAlign: 'center'}}>
                <p>Biztos benne, hogy archiválja az adatbázist?</p>
            </div>
            <div  style={{width: '100%', textAlign: 'center'}}>
                Amennyiben az archiválás mellett dönt<br/>
                kattintson az <b>Igen</b> gombra. <br/><br/>A művelet megszakításához<br/> nyomja meg a <b>Mégsem</b> gombot.
            </div>
            <br/>
            <div style={{display: 'flex'}}>
                <div style={{minWidth: '48%', textAlign: 'right'}}>
                    <input style={{backgroundColor: '#9fd027', paddingLeft: 15, paddingRight: 15}} autoFocus onClick={() => this.cloneOffice()} className="bookingFormButton" type="button" value="Igen" />
                </div>
                <div style={{minWidth: '4%', textAlign: 'center'}}>
                </div>
                <div style={{minWidth: '48%', textAlign: 'left'}}>
                    <input autoFocus onClick={() => this.setState({showArciveForm: false})} className="bookingFormButton" type="button" value="Mégsem" />
                </div>
            </div>
        
        </PopUpWindow>
    }

    getDeleteDBForm = function () {
        var dbYearsToDelete = "";
        for (var i = 0; i < this.state.deleteNeedYears.length; i++) {
            var currentYear = this.state.deleteNeedYears[i];
            if (i > 0) {
                dbYearsToDelete += ","
            }
            dbYearsToDelete += currentYear;
        }
        return <PopUpWindow style={{textAlign: 'center', maxWidth: 400, maxHeight: 300}} >
            <p>ADATBÁZIS TÖRLÉSE</p>
        
            <div  style={{width: '100%', color: 'red', textAlign: 'center'}}>
                <p>Biztos benne, hogy törli az alábbi évek anyagait?<br/>{dbYearsToDelete}</p>
            </div>
            <div  style={{width: '100%', color: 'black', textAlign: 'center', whiteSpace: 'normal'}}>
                <p>
                    Törlés esetén az adatbázisokról 24 órán belül biztonsági másolatot készítünk, utána eltávolítjuk a rendszerből.
        
                </p>
            </div>
            <br/>
            <br/>
            <div style={{display: 'flex'}}>
                <div style={{minWidth: '48%', textAlign: 'right'}}>
                    <input style={{backgroundColor: '#9fd027', paddingLeft: 15, paddingRight: 15}} autoFocus onClick={() => this.deleteOfficeDatabases()} className="bookingFormButton" type="button" value="Igen" />
                </div>
                <div style={{minWidth: '4%', textAlign: 'center'}}>
                </div>
                <div style={{minWidth: '48%', textAlign: 'left'}}>
                    <input autoFocus onClick={() => this.setState({showDeleteDBForm: false})} className="bookingFormButton" type="button" value="Mégsem" />
                </div>
            </div>
        
        </PopUpWindow>
    }

    getMask = function () {
        const url = ajax.getUrlBase() + "img/progress.gif";

        return <PopUpWindow style={{textAlign: 'center', maxWidth: 400, maxHeight: 300}} >
            <br/>
            <div>{this.progressTitle}</div>
            <img style={{textAlign: 'center', maxWidth: 400, maxHeight: 220}} src={url}/>
        </PopUpWindow>
    }

    getYearChooser = function () {
        var options = [];
        if (this.state.archivedYears == null) {
            return '';
        }

        if (this.state.archivedYears.length == 0) {
            return '';
        }

        for (var i = 0; i < this.state.archivedYears.length; i++) {
            var archivedYear = this.state.archivedYears[i];
            options.push(<option 
            
                id={'arcivedYear_' + archivedYear} 
                key={'arcivedYear_' + archivedYear}
                value={archivedYear}
                >{archivedYear}</option>);


        }

        return <select 
            value={this.state.currentYear} 
            onChange={this.changeCurrentArchive.bind(this)}
        
            >{options}</select>

    }

    render() {

        var databaseDelete = "";


        if (typeof this.state.deleteNeedYears != 'undefined' && this.state.deleteNeedYears.length > 0 && this.props.userType == 'OFFICE') {
            databaseDelete = <div 
                title="Törölhető adatbázisok" 
                style={{textAlign: 'left', marginLeft: 20, cursor: 'pointer'}}
                onClick={() => {
                                    this.setState({showDeleteDBForm: true});
                                }}
                >
                &#128465;
            </div>
        }

        var databaseArchive = "";
        if (this.props.userType == 'OFFICE') {
            databaseArchive = <div 
                title="Archiválás" 
                style={{textAlign: 'left', marginLeft: 20, cursor: 'pointer'}}
                onClick={() => {
                                    this.setState({showArciveForm: true});
                                }}
                >
                &#128190;
            </div>;
        }


        var yearChooser = "";
        if (this.props.userType == "ADMIN" || this.props.userType == "OFFICE" || this.props.userType == "CHIEF_ACCOUNTANT" || this.props.userType == "ACCOUNTANT" || this.props.userType == "COMPANY") {
            yearChooser = this.getYearChooser();
        }
        var form = "";
        if (this.state.showArciveForm == true) {
            form = this.getArciveForm();
        }
        if (this.state.showDeleteDBForm == true) {
            form = this.getDeleteDBForm();
        }
        if (this.state.showMask == true) {
            form = this.getMask();
        }
        if (this.state.showAlert == true) {
            form = <ConfirmationDialog alertDialogClose={() => {
                                this.setState({showAlert: false, fail: ''})
                                                }} >{this.state.fail}</ConfirmationDialog>;
        }


        return <div style={{display: 'flex'}}>
        {databaseDelete}
        {databaseArchive}
        <div style={{marginLeft: 20, marginRight: 20}}>
            {yearChooser}
        </div>
        {form}
    </div>
    }
}

export default ArchiveButton;