import React, { Component }
from 'react';
import calendar_ico from "./../img/calendarBlue.svg";
import './../css/dateTime.css';
import DatePicker from "react-datepicker";
import {registerLocale, setDefaultLocale}
from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import hu from 'date-fns/locale/hu';


registerLocale('hu', hu)

class CustomDate extends React.Component {

    constructor(props) {
        super(props);
        var year = '';
        var month = '';
        var day = '';



        if (typeof this.props.currentValue != 'undefined' && this.props.currentValue != null && this.props.currentValue.split('-').length == 3) {

            var dateParts = this.props.currentValue.split('-');
            year = dateParts[0];
            month = dateParts[1];
            day = dateParts[2];
        }

        this.state = {
            focused: false,
            showCalendar: false,
            value: this.props.currentValue,
            year: year,
            month: month,
            day: day
        }

        this.yearInput = React.createRef();
        this.monthInput = React.createRef();
        this.dayInput = React.createRef();
    }

    componentDidUpdate(prevProps) {

        if (prevProps.currentValue !== this.props.currentValue) {
            var year = '';
            var month = '';
            var day = '';

            if (typeof this.props.currentValue != 'undefined' && this.props.currentValue != null && this.props.currentValue.split('-').length == 3) {

                var dateParts = this.props.currentValue.split('-');
                year = dateParts[0];
                month = dateParts[1];
                day = dateParts[2];
                this.setState({value: this.props.currentValue, year: year, month: month, day: day});
            }

            if (this.props.currentValue == null || this.props.currentValue == '') {
                this.setState({value: null, year: '', month: '', day: ''});
            }



        }
    }

    select = function () {
        this.yearInput.current.select();
    }

    onFocus = function (e) {
        
        if (e.target.id == 'yearInput') {
            this.yearInput.current.select();
        }
        if (e.target.id == 'monthInput') {
            this.monthInput.current.select();
        }
        if (e.target.id == 'dayInput') {
            this.dayInput.current.select();
        }
        this.setState({focused: true});
    }

    onBlur = function (e) {
        this.setState({focused: false});
    }

    showCalendar = function () {
        this.setState({showCalendar: true});
    }

    numberValidator = function (num) {
        if (typeof num == 'undefined') {
            return true;
        }
        if (num == null) {
            return true;
        }
        if (num == '') {
            return true;
        }

        var numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
        for (var i = 0; i < num.length; i++) {
            if (!numbers.includes(num[i])) {
                return false;
            }
        }
        return true;
    }

    yearValidator(year) {
        if (year.length > 4) {
            return false;
        }
        return this.numberValidator(year);
    }
    monthValidator(month) {
        if (month.length > 2) {
            return false;
        }
        return this.numberValidator(month);
    }
    dayValidator(day) {
        if (day.length > 2) {
            return false;
        }
        return this.numberValidator(day);
    }

    writeBack = function () {

    }

    isValidDate = function (dateString) {
        var regEx = /^\d{4}-\d{2}-\d{2}$/;
        if (!dateString.match(regEx))
            return false;  // Invalid format
        var d = new Date(dateString);
        var dNum = d.getTime();
        if (!dNum && dNum !== 0)
            return false; // NaN value, Invalid date
        return d.toISOString().slice(0, 10) === dateString;
    }

    writeBackValidator = function (writeBack) {
        var validDate = this.isValidDate(writeBack);
        if (validDate == false) {
            return null;
        }
        return writeBack;
    }

    setYear = function (e) {
        var isValid = this.yearValidator(e.target.value);
        if (!isValid) {
            return false;
        }
        this.setState({year: e.target.value});
        if (e.target.value.length > 3) {
            this.monthInput.current.focus();
        }
        var writeBack = this.state.year + '-' + this.state.month + '-' + this.state.day;
        var writeBackValidated = this.writeBackValidator(writeBack);
        this.props.writeBack[this.props.dataKey] = writeBackValidated;

    }
    setMonth = function (e) {
        var isValid = this.monthValidator(e.target.value);
        if (!isValid) {
            return false;
        }
        this.setState({month: e.target.value});
        if (e.target.value.length > 1) {
            this.dayInput.current.focus();
        }
    }

    setMonthKeyUp = function (e) {
        if (e.target.value == '') {
            if (e.keyCode == 8) {
             //   this.yearInput.current.focus();
            }
        }

        var writeBack = this.state.year + '-' + this.state.month + '-' + this.state.day;
        var writeBackValidated = this.writeBackValidator(writeBack);
        this.props.writeBack[this.props.dataKey] = writeBackValidated;
    }

    setDay = function (e) {
        var isValid = this.dayValidator(e.target.value);
        if (!isValid) {
            return false;
        }
        this.setState({day: e.target.value});
    }

    setDayKeyUp = function (e) {
        if (e.target.value == '') {
            if (e.keyCode == 8) {
              //  this.monthInput.current.focus();
            }
        }
        var writeBack = this.state.year + '-' + this.state.month + '-' + this.state.day;
        var writeBackValidated = this.writeBackValidator(writeBack);
        this.props.writeBack[this.props.dataKey] = writeBackValidated;
    }

    paddingDate = function (val) {
        return (val.length == 1) ? "0" + val : val;
    }

    render() {
        var containerStyle = (this.state.focused) ? (this.props.blackTheme) ? "dateContainerFocusedDark" : "dateContainerFocusedLight" : (this.props.blackTheme) ? "dateContainerDark" : "dateContainerLight";
        var display = (this.state.showCalendar) ? "flex" : "none";

        var reactDatepickerClass = (this.props.blackTheme) ? "react-datepickerDark" : "react-datepickerLight";
        var fontColor = (this.props.blackTheme) ? "#FFFFFF" : "#000000";



        return <div  className={containerStyle} style={{dislplay: 'flex'}} >
        
            <div style={{position: 'absolute', display: display, zIndex: 100}}>
                <DatePicker 
                    onChange={(date) => {
                                    var date = new Date(date);
                                    var year = date.getFullYear() + "";
                                    var month = (date.getMonth() + 1) + "";
                                    var date = date.getDate() + "";
                                    month = this.paddingDate(month);
                                    date = this.paddingDate(date);
                                    this.state.year = year;
                                    this.state.month = month;
                                    this.state.day = date;
                                    var dateString = year + "." + month + "." + date;
                                    this.props.reload(this.props.dataKey, dateString);
                                    this.setState({value: dateString, showCalendar: false});
                                }} 
                    locale="hu"  
                    inline 
                    calendarClassName={reactDatepickerClass}
                    />
            </div>
            <div>
                <input 
                    id="yearInput"
                    ref={this.yearInput}
                    size={4}
                    onChange={this.setYear.bind(this)} 
                    className="yearInput" 
                    onFocus={this.onFocus.bind(this)}
                    onBlur={this.onBlur.bind(this)}
                    type={"text"} 
                    value={this.state.year}
                    style={{color: fontColor}}
                    />
                <b>.</b>
                <input 
                    id="monthInput"
                    ref={this.monthInput}
                    size={2}
                    onChange={this.setMonth.bind(this)} 
                    onKeyUp={this.setMonthKeyUp.bind(this)}
                    className="monthInput" 
                    onFocus={this.onFocus.bind(this)}
                    onBlur={this.onBlur.bind(this)}
                    type={"text"} 
                    value={this.state.month}
                    style={{color: fontColor}}
                    />
                <b>.</b>
                <input 
                    id="dayInput"
                    ref={this.dayInput}
                    size={2}
                    onChange={this.setDay.bind(this)} 
                    onKeyUp={this.setDayKeyUp.bind(this)}
                    className="dayInput" 
                    onFocus={this.onFocus.bind(this)}
                    onBlur={this.onBlur.bind(this)}
                    type={"text"} 
                    value={this.state.day}
                    style={{color: fontColor}}
                    />    
            </div>
            <div style={{display: 'flex'}} onClick={this.showCalendar.bind(this)} >    
                <img 
        
                    style={{cursor: 'pointer', maxWidth: '17px', paddingLeft: '5px', paddingRight: '5px'}}
                    src={calendar_ico} 
                    onClick={this.showCalendar.bind(this)}
                    />
            </div>
        </div>;
    }
}

export default CustomDate;