import React, { Component } from 'react';
import personAdd from './../../img/black/person_add_black_24dp.svg';
import * as ajax from './../../ajax.js';

class InfoBox extends React.Component {

    constructor(props) {
        super(props);
        this.lang = new Dictionary();

    }

    getObjectContent = function (data) {

        if (typeof data == "undefined" || data == null) {
            return "";
        }

        var infoBoxArray = [];
        for (const [key, value] of Object.entries(data)) {
            var documentId = (typeof this.props.documentId != 'undefined' && this.props.documentId != null && this.props.documentId != -1) ? this.props.documentId : -1;
            infoBoxArray.push(<InfoBox 
                fullData={data} 
                title={key} 
                data={value} 
                isCompany={this.props.isCompany} 
                isPartner={this.props.isPartner}
                documentId={documentId}
                />);
        }
        return <div>{infoBoxArray}</div>;
    }

    isInt = function (value) {
        return !isNaN(value) &&
                parseInt(Number(value)) == value &&
                !isNaN(parseInt(value, 10));
    }

    render() {

        
        var isObject = (typeof this.props.data == 'object');


        var isKeyValue = isObject == false;


        if (isObject) {
            var title = this.props.title;

            var colWidth = (this.props.init)?20:240;
            if (this.isInt(this.props.title)) {
                title = "[" + (this.props.title * 1 + 1) + "]";
                var colWidth = 30;
            }

            return <div  style={{display: 'flex'}}>
                <div style={{display: "flex"}}>
                    <div  style={{maxWidth: colWidth, minWidth: colWidth, marginRight: 0, whiteSpace: 'normal', boxShadow: '1px 1px 2x #000000'}}>
                        <b>{Dictionary.getTitle(title) }</b>
                    </div>
                </div>
                <div style={{padding: 0, marginBottom: 0, width: '100%'}}>
                    {this.getObjectContent(this.props.data)}
                </div>
            </div>;
        }

        return <div style={{display: 'flex', backgroundColor: 'white', padding: 0, margin: 0}}>
            <div style={{marginRight: 20,minWidth:220}} >
        
                <b>{Dictionary.getTitle(this.props.title)}:</b>
            </div>
            <div>{this.props.data}</div>
        </div>


        return "";
    }
}

export default InfoBox;


class Dictionary {

    static getTitle(nameConstant) {
        var lang = {
            tax_number_id:'Adószám',
            tax_number_raw:'Adószám',
            name:'',
            address_name:'',
            query_result: 'A lekérdezés eredménye',
            address_name:'Cím',
            new_partner_name: 'Név',
            new_partner_tax_number: 'Adószám',
            new_partner_zip_code: 'Irsz',
            new_partner_city: 'Város',
            new_partner_address: 'Utca hsz',

            transaction_success: 'Sikeres tranzakció',
            partner_unique_id: 'A partner egyedi azonosítója',
            error: 'Hiba',
            message: 'Üzenet',
            deletion: 'Törlés',
            comment: 'Megjegyzés',
            activity: "Cél szerinti besorolás",
            address: "Székhely (telephely ha van)",
            bank_accounts: "Bankszámlaszám (ha van)",
            company_registration_number: "Cégjegyzékszám",
            constituent_document: "Létesítő okirat",
            constituent_document_date: "Létesítő okirat kelte",
            description: "Cél szerinti leírás",
            emails_and_webpages: "E-mail cím és értesítési e-mail cím (ha van)",
            end_cause: "Megszűnésének oka (ha van)",
            end_date: "Vállalkozói tevékenység megszűnésének dátuma (ha van)",
            finished_proceedings: "Volt ellene eljárás",
            head_count: "Létszám",
            insertion: "Bejegyzés dátuma",
            ksh_num: "KSH szám",
            registration_num: "Nyilvántartási szám",
            leading_orgs: "Ügyvezető szervek (ha van)",
            level_of_charity: "Közhasznúsági fokozat",
            long_name: "Bejegyzett név",
            main_activities: "Főtevékenység(ek)",
            pending_proceedings: "Van ellene eljárás",
            proceedings: "Folyamatban lévő eljárások (ha van)",
            profit_after_tax: "Előző éves mérleg eredmény",
            registered_capital: "Jegyzett tőke",
            registration_number: "Nyilvántartási szám",
            representatives: "Képviseletre jogosultak",
            revenue: "Előző éves nettó árbevétel",
            short_name: "Rövid név (ha van)",
            start_date: "Vállalkozói tevékenység kezdete",
            status: "Státusz",
            status_code: "Státusz kód (0 nem működő, 1 működő, 2 szüneteltetett)",
            status: "Státusz",
            tax_number: " ",
            
            type: "Szervezet típusa",

            tax_number_status: 'Adószám státusza',
            address_zip: 'Irsz.',
            delivery_email: 'Kézbesítési E-mail',
            webpage: 'Weboldal',
            insertion: 'Közzététel',
            main_activities: 'Főtevékenységek',
            status_code: 'Státusz kód',
            court: 'Bíróság',
            is_main_activity: '- Főtevékenység',
            nace_name: 'Tevékenység neve',
            nace_number: 'Főtevékenység száma',
            count: 'Szám',
            capital: 'Tőke',
            title: 'Cím',
            amount: 'Mennyiség',
            currency: 'Pénznem',
            status: 'Státusz',
            change: 'Változtatás',
            value_hash: 'Ellenőrzőszám',
            bank_address: 'Bank címe',
            bank_name: 'Bank neve',
            bank_account_open_date: 'A számla nyitási dátuma',
            bank_account: 'Bankszámlaszám',
            general_details: "Általános adatok",
            long_name: "A cég elnevezése",
            short_name: "Rövidített elnevezés",
            foreign_names: "A cég idegen nyelvű elnevezése",
            address: "A cég székhelye (i)",
            sites: "A cég telephelye (i)",
            branches: "A cég fióktelepe (i)",
            constituent_document_date: "A társasági szerződés kelte",
            activities: "A cég tevékenységi köre(i)",
            end_of_operation: "A működés befejezésének időpontja",
            registered_capital: "A cég jegyzett tőkéje",
            representatives: "A képviseletre jogosult(ak) adatai",
            auditors: "A könyvizsgáló(k) adatai",
            supervising_board_members: "A felügyelőbizottsági tagok adatai",
            predecessors: "A jogelőd cég(ek) adatai",
            chamber_membership: "Annak a kamarának a megnevezése, melynek a cég tagja",
            statistical_code: "A cég statisztikai számjele",
            tax_number: "A cég adószáma",
            successor: "A jogutód cég(ek) adatai",
            suspension_of_operation: "A cég működésének felfüggesztése",
            termination: "A cég megszűntnek nyilvánítása",
            succesorless_termination: "A jogutód nélküli megszűnések elhatározásának dátuma",
            voluntary_liquidation: "Végelszámolás kezdete és befejezése",
            bankruptcy: "Csődeljárás",
            liquidation: "Felszámolás kezdete és befejezése",
            registration_order_lawsuit: "Jogerős bejegyző végzés hatályon kívül helyezése iránti per adatai",
            constituent_document_lawsuit: "A létesítő okirat érténytelenségének megállapítása iránti per adatai",
            corporate_decision_lawsuit: "A társasági határozat felülvizsgálata iránti per adatai",
            bank_accounts: "A cég pénzforgalmi jelzőszáma",
            execution: "A birósági végrehajtás adatai",
            insurance_measure: "A biztosítási intézkedés adatai",
            seizure_of_property_ownership: "A cég tagja (részvényese) vagyoni részesedésének lefoglalása",
            cancellation: "A megszüntetési (hivatalbóli törlés) adatai",
            criminal_measure: "A büntetőjogi intézkedés adatai",
            emails_and_webpages: "A cég elektonikus elérhetősége",
            member_of_company_group: "Az elismert vállalatcsoportban való tagság adatai",
            other_members_of_company_group: "Az elismert vállalatcsoporthoz tartozó többi cég adatai",
            level_of_charity: "A közhasznúsági fokozat és jogállás adatai",
            company_registration_number: "A cég cégjegyzékszámai",
            central_administration_address: "A cég központi ügyintézésének helye",
            annual_report_production: "Az összevont (konszolidált) éves beszámoló készítésére vonatkozó adatok",
            other_companies_of_annual_report: "Az összevont (konszolidált) éves beszámoló készítésébe bevont többi cég adatai",
            balance_sheet_date: "A cég üzleti évének mérlegfordulónapja",
            property_law_practitioner: "Magyar Állam tulajdonos (tag) esetén a tulajdonosi joggyakorló",
            member_with_unlimited_responsibility: "A képviseletre jogosult személy, vagy minősített többséggel rendelkező tag, vagy az uralkodó tag korlátlanul felelős a cég tartozásaiért",
            language_of_company_documents: "A cégiratok fordítása az Európai Unió melyik hivatalos nyelvén szerepel a cégnyilvántartásban",
            banned_member: "Eltiltás hatálya alatt álló tagra, képviselőre vonatkozó adatok",
            forced_cancellation: "A kényszertörlési eljárás",
            firm_type: "Vállalat típusa",
            firm_management_type: "A vállalat írányítása formája",
            members_details: "Közkereseti társaság tagjai (KKT)",
            acting_partner_details: "Betéti társaság beltagja",
            silent_partner_details: "Betéti társaság kültagok",
            members_details: "Egyesulés tagjai",
            members_details: "Kft tagjainak adatai",
            lien_details: "Kft üzletrészen alapított zálogjog adatai",
            shareholders_details: "Rt részvényesek adatai",
            share_transfer: "Rt részvényátruházás korlátozásának jelzése",
            management_type: "Rt Ügyvezetés típusa",
            share_details: "Rt részvények száma és nev as nameértéke",
            owners_details: "Egyéni cég tulajdonságainak az adatai",
            foreign_company_details: "Kmkkv Külföldi vállalkozások adatai",
            foreign_company_representatives: "Külföldi vállalkozás vezető tisztségviselőinek adatai",
            water_company_members_details: "Ellenőrző bizottság/felügyelőbizottság tagjainak adatai",
            foreign_company_details: "Külföldi vállalkozás magyarországi fióktelepe adatai",
            foreign_company_representatives: "Külföldi vállalkozás képviseletére jogosult személy vagy szerv adatai",
            office_members_details: "Végrehajtói iroda tagjának vagy tagjainak adatai",
            shareholders_details: "Európai részvénytársaság részvényesek adatai",
            share_details: "Európai részvénytársaság részvények száma és nev as nameértéke",
            office_members_details: "Közjegyzői iroda tagjainak adatai"}


        if ((!lang.hasOwnProperty(nameConstant)) ) {
            return nameConstant;
        }


        return lang[nameConstant];
    }
}

    