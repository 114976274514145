import React from 'react';
import {ProjectDescriptor, FieldDescriptor, Layout}  from './../../utils.js';
import * as ajax from './../../ajax.js';
//import Panel from './../../Components/Panel';
import BasicForm from './../../Components/BasicForm';


class CompanyManager extends React.Component {

    constructor(props) {
        super(props);
        var endpoint = ajax.getUrlBase() + 'modules/CompanyManager/';
        var formTitle = 'Cégek';
        var projectDescriptor = new ProjectDescriptor('CompanyManager', endpoint, formTitle);
        projectDescriptor.setFormDimensions(820, 330);

        var id = new FieldDescriptor(true, FieldDescriptor.TYPE_NUMBER, 'id', '#', '');
       // var adoszam = new FieldDescriptor(false, FieldDescriptor.TYPE_TAX_NUMBER_FIELD, 'adoszam', 'Adószám', '');
        var adoszam = new FieldDescriptor(false, FieldDescriptor.TYPE_TEXT, 'adoszam', 'Adószám', '');
        adoszam.setJump(4);

        var cegnev = new FieldDescriptor(false, FieldDescriptor.TYPE_TEXT, 'firstName', 'Cégnév', '');
        cegnev.setRef('firstName');
        var felhasznalonev = new FieldDescriptor(false, FieldDescriptor.TYPE_TEXT, 'loginName', 'Felhasználónév', '');
        var jelszo = new FieldDescriptor(false, FieldDescriptor.TYPE_TEXT, 'loginPass', 'Jelszó', '');
        var irsz = new FieldDescriptor(false, FieldDescriptor.TYPE_TEXT, 'irsz', 'IRSZ', '');
        irsz.setRef('irsz');
        var varos = new FieldDescriptor(false, FieldDescriptor.TYPE_TEXT, 'varos', 'Város', '');
        varos.setRef('varos');
        var utcaHsz = new FieldDescriptor(false, FieldDescriptor.TYPE_TEXT, 'utcaHsz', 'Utca házszám', '');
        utcaHsz.setRef('utcaHsz');
        var kapcsolattarto = new FieldDescriptor(false, FieldDescriptor.TYPE_TEXT, 'kapcsolattarto', 'Kapcsolattartó', '');
        var telefon = new FieldDescriptor(false, FieldDescriptor.TYPE_TEXT, 'telefon', 'Telefon', '');
        var mail = new FieldDescriptor(false, FieldDescriptor.TYPE_TEXT, 'mail', 'E-mail', '');
        mail.setRef('mail');
        var adoazonosito = new FieldDescriptor(false, FieldDescriptor.TYPE_TEXT, 'adoazonosito', 'Adó az.', '');
        var companyId = new FieldDescriptor(false, FieldDescriptor.TYPE_TEXT, 'companyId', 'Cég az.', '');
        var bankszamlaszam = new FieldDescriptor(false, FieldDescriptor.TYPE_TEXT, 'bankszamlaszam', 'Bankszámlaszám', '');
        var iktatottBejovoApiStatuszok = new FieldDescriptor(false, FieldDescriptor.TYPE_SELECT, 'iktatottBejovoApiStatuszok', 'Átadott adatok', [{id: 'ellenorizve', label: 'Ellenőrizve'}, {id: 'iktatott_ellenorizve', label: 'Iktatva és Ellenőrizve'}]);
         var instantInvoiceLinesFromNav = new FieldDescriptor(false, FieldDescriptor.TYPE_SELECT, 'instantInvoiceLinesFromNav', 'NAV Adatok tételei', [{id:0,label:'Ne tároljuk'},{id:1,label:'Tároljuk'}]);
         

        projectDescriptor.addFieldDescriptor(id);
        projectDescriptor.addFieldDescriptor(adoszam);
        projectDescriptor.addFieldDescriptor(cegnev);
        projectDescriptor.addFieldDescriptor(felhasznalonev);
        projectDescriptor.addFieldDescriptor(jelszo);
        projectDescriptor.addFieldDescriptor(irsz);
        projectDescriptor.addFieldDescriptor(varos);
        projectDescriptor.addFieldDescriptor(utcaHsz);
        projectDescriptor.addFieldDescriptor(kapcsolattarto);
        projectDescriptor.addFieldDescriptor(telefon);
        projectDescriptor.addFieldDescriptor(mail);
        projectDescriptor.addFieldDescriptor(adoazonosito);
        projectDescriptor.addFieldDescriptor(companyId);
        projectDescriptor.addFieldDescriptor(bankszamlaszam);
        projectDescriptor.addFieldDescriptor(iktatottBejovoApiStatuszok);
         projectDescriptor.addFieldDescriptor(instantInvoiceLinesFromNav);

        var layout = new Layout();
        layout.addColumns([0, 120, 270, 400, 550, 670])
        layout.addRows([0, 34, 68, 102, 136, 170])
        this.projectDescriptor = projectDescriptor;
        this.layout = layout;
    }

    render() {
        return <BasicForm 
            blackTheme={this.props.blackTheme} 
            alertDialog={this.props.alertDialog.bind(this)} 
            layout={this.layout} 
            projectDescriptor={this.projectDescriptor} 
            companyInfoButtonVisible={true}
            companyBackgroundInfoPanelOpen ={this.props.companyBackgroundInfoPanelOpen.bind(this)}
            isPartner ={false}
            isCompany={true}
            frameState={this.props.frameState}
            />;
    }
}

export default CompanyManager;