import React, { Component } from 'react';
import * as ajax from './../ajax.js';
import open from './../img/black/operner.svg';

class FormComboMegjegyzes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ref: React.createRef(),
            dropDownVisible: false,
            mouseOnDropDownList: false,
            hoveredId: -1,
            selectedID: -1,
            selectedText: '',
            maxItemCount: 100,
            items: []
        };
        if (typeof this.props.maxItemCount !== 'undefined' && this.props.maxItemCount !== '') {
            this.state.maxItemCount = this.props.maxItemCount;
        }
    }

    componentDidMount() {


    }

    componentWillReceiveProps(newProps) {

        if (typeof newProps.formComboRef["MEGJEGYZ"] !== 'undefined' && newProps.formComboRef["MEGJEGYZ"] === '') {
            this.state.selectedText = '';
            return false;
        }
        if (newProps.formComboRef["MEGJEGYZ"] == null) {
            this.state.selectedText = '';
            return false;
        }

        this.state.selectedText=newProps.formComboRef["MEGJEGYZ"];

    }

    onOpenerClick = function (e) {
        if (!this.state.dropDownVisible) {
            this.loadItems("");
        } else {
            this.setState({dropDownVisible: false});
        }
    }

    onTextFieldClick = function (e) {
        this.loadItems("");
    }

    handleChange = function (e) {

        this.props.formComboRef["MEGJEGYZ"] = e.target.value;
        this.setState({selectedText: e.target.value});
        this.loadItems(e.target.value);
    }

    lastHoveredExists = function () {
        if (typeof this.state.items != "undefined" && typeof this.state.items.length != "undefined" && this.state.items.length > 0) {
            for (var i = 0; i < this.state.items.length - 1; i++) {
                if (this.state.hoveredId == this.state.items[i].id) {
                    return true;
                }
            }
        }
        return false;
    }

    hoveredForward() {

        var itemsCount = this.state.items.length
        if (itemsCount === 0) {
            this.setState({hoveredId: -1});
        } else if (itemsCount === 1) {
            var nextHoveredId = this.state.items[0].id;
            this.setState({hoveredId: nextHoveredId});

            if (typeof this.props.afterSelect !== 'undefined') {
                this.props.formComboRef["MEGJEGYZ"] = nextHoveredId;
                this.props.afterSelect();
            }


        } else {
            for (var i = 0; i < this.state.items.length - 1; i++) {
                if (this.state.hoveredId == this.state.items[i].id) {
                    var nextHoveredId = this.state.items[i + 1].id;
                    this.setState({hoveredId: nextHoveredId});

                    if (typeof this.props.afterSelect !== 'undefined') {
                        this.props.formComboRef["MEGJEGYZ"] = nextHoveredId;
                        this.props.afterSelect();
                    }


                }
                if (this.state.hoveredId === -1) {
                    var nextHoveredId = this.state.items[0].id;
                    this.setState({hoveredId: nextHoveredId});

                    if (typeof this.props.afterSelect !== 'undefined') {
                        this.props.formComboRef["MEGJEGYZ"] = nextHoveredId;
                        this.props.afterSelect();
                    }

                }
            }
            if (!this.lastHoveredExists()) {
                var nextHoveredId = this.state.items[0].id;
                this.setState({hoveredId: nextHoveredId});
            }
        }
    }

    hoveredBack() {

        var itemsCount = this.state.items.length
        if (itemsCount === 0) {
            this.setState({hoveredId: -1});
        } else if (itemsCount === 1) {
            var nextHoveredId = this.state.items[0].id;
            this.setState({hoveredId: nextHoveredId});

            if (typeof this.props.afterSelect !== 'undefined') {
                this.props.formComboRef["MEGJEGYZ"] = nextHoveredId;
                this.props.afterSelect();
            }



        } else {
            for (var i = this.state.items.length - 1; i > 0; i--) {
                if (this.state.hoveredId == this.state.items[i].id) {
                    var nextHoveredId = this.state.items[i - 1].id;
                    this.setState({hoveredId: nextHoveredId});

                    if (typeof this.props.afterSelect !== 'undefined') {
                        this.props.formComboRef["MEGJEGYZ"] = nextHoveredId;
                        this.props.afterSelect();
                    }



                }
                if (this.state.hoveredId === -1) {
                    var nextHoveredId = this.state.items[0].id;
                    this.setState({hoveredId: nextHoveredId});

                    if (typeof this.props.afterSelect !== 'undefined') {
                        this.props.formComboRef["MEGJEGYZ"] = nextHoveredId;
                        this.props.afterSelect();
                    }


                }
            }
        }
    }

    setValueByHover = function () {
        for (var i = 0; i < this.state.items.length; i++) {
            if (this.state.items[i].id === this.state.hoveredId) {

                this.setFormValue(this.state.items[i].id);
                this.setState({selectedID: this.state.items[i].id, selectedText: this.state.items[i].value, dropDownVisible: false});

                if (typeof this.props.afterSelect !== 'undefined') {
                    this.props.formComboRef["MEGJEGYZ"] = this.state.hoveredId;
                    this.props.afterSelect();
                }

            }
        }
    }

    handleKeyDown = function (e) {
        if (e.keyCode == 40) { // arrow down

            if (this.state.dropDownVisible == false) {

                this.loadItems("");

            } else {

                this.hoveredForward();
                e.preventDefault();
                e.stopPropagation();
            }


        }
        if (e.keyCode == 38) { // arrow up
            e.preventDefault();
            e.stopPropagation();
            this.hoveredBack();
        }
        if (e.keyCode == 13) { // enter
            this.setValueByHover();
            e.preventDefault();
            e.stopPropagation();

        }
        if (e.keyCode == 27) { // escape
            this.setState({dropDownVisible: false});
            e.preventDefault();
            e.stopPropagation();

        }
    }

    loadItems = function (filterText) {
        try {
            const url = ajax.getUrlBase() + this.props.dataSourceUrl;
            return fetch(url, {
                method: "POST",
                //credentials: "include",
                //credentials: "*", // Tesztüzemben
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({filterText: filterText})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {

                        if (typeof response.data != 'undefined' && response.data != null) {

                            if (response.data.length === 0) {
                                this.setState({dropDownVisible: false});
                            } else {
                                this.setState({items: response.data, dropDownVisible: true});
                            }
                        }
                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }

        this.setState({dropDownVisible: true, hoveredId: -1});

    }

    dropDownItemClicked = function (e) {
        var selectedItemID = e.target.id;
        this.setTextFieldValue(selectedItemID);
        this.setState({dropDownVisible: false});


        if (typeof this.props.afterSelect !== 'undefined') {
            this.props.formComboRef["MEGJEGYZ"] = selectedItemID;
            this.props.afterSelect();
        }


    }

    setFormValue = function (selectedId) {
        this.props.formComboRef["MEGJEGYZ"] = selectedId;
        if (typeof this.props.afterSelect !== 'undefined') {
            this.props.afterSelect();
        }
    }
    focusLost = function () {
        if (!this.state.mouseOnDropDownList) {
            this.setState({dropDownVisible: false});
        }
    }
    mouseOverDropDownList = function () {
        this.setState({mouseOnDropDownList: true});
    }
    mouseOutDropDownList = function () {
        this.setState({mouseOnDropDownList: false});
    }

    textField = function () {

        var typedText = this.state.selectedText;
        if (this.state.selectedID === null) {
            //  typedText='';
        }
        return <input 
            ref={this.state.ref} 
            className={"formInput"}  
            onBlur={this.focusLost.bind(this)} 
            onKeyDown={this.handleKeyDown.bind(this)} 
            onChange={(e) => this.handleChange(e)}  
            value={typedText} 
            onClick={this.onTextFieldClick.bind(this)} 
            style={{
                            width: '100%',
                            position:'relative',
                            top:'-2px',
                            minWidth: '300px',
                            maxWidth: '300px',
                            border: 'none',
                            fontSize: '10px',
                            fontWeight: 'bold',
                            outline: "currentcolor none medium"}} type="text" />;
    }

    select = function () {
        this.state.ref.current.select();
    }

    onMouseOver = function (e) {
        this.setState({hoveredId: e.target.id});
        if (typeof this.props.afterSelect !== 'undefined') {
            this.props.formComboRef["MEGJEGYZ"] = e.target.id;
            this.props.afterSelect();
        }

    }

    queryResults = function () {



        var comboItems = [];
        for (var i = 0; i < this.state.items.length; i++) {
            var itemDescriptor = this.state.items[i];
            var itemStyle = {backgroundColor: "#FFFFFF",  cursor: 'pointer',fontSize:'12px',fontWeight:'bold'};

            if (this.state.hoveredId == itemDescriptor.id) {
                itemStyle = {backgroundColor: "#3ab4eb44", cursor: 'pointer',fontSize:'12px',fontWeight:'bold'};
            }

            comboItems.push(<div 
                style={itemStyle} 
                key={'dropdown_item_' + i} 
                className="dropdown-list-item" 
                id={itemDescriptor.id}
                onMouseOver={this.onMouseOver.bind(this)}
                >{itemDescriptor.value}</div>);

        }
        return <div 
            style={{ border: 'solid 1px #3ab4eb',backgroundColor:'white'}} 
            onClick={this.dropDownItemClicked.bind(this)} 
            >{comboItems}</div>;
    }

    setTextFieldValue = function (id) {
        var items = this.state.items;
        for (var i = 0; i < items.length; i++) {
            if (items[i].id == id) {
                this.setState({selectedID: items[i].id, selectedText: items[i].value});
                this.setFormValue(items[i].id);
            }
        }
    }

    render() {
        // The id,value pair came from the formLoader in object format.
        if (this.props.formComboRef != null) {
            if (this.props.formComboRef["MEGJEGYZ"] != null) {
                // After we change the param type from object to integer, the program don't repeating this block.
                if (typeof this.props.formComboRef["MEGJEGYZ"] === 'object') {
                    this.setState({selectedID: this.props.formComboRef["MEGJEGYZ"], selectedText: this.props.formComboRef["MEGJEGYZ"]});
                    // Repleacing the value object to integer
                    this.props.formComboRef["MEGJEGYZ"] = this.props.formComboRef["MEGJEGYZ"];
                } else if (typeof this.props.formComboRef["MEGJEGYZ"] === 'string') {
                    if (this.props.formComboRef["MEGJEGYZ"] === '') {
                        this.state.selectedID = null;
                        //this.state.selectedText='';
                    }
                }
            }
        }


        var dropDown = "";
        if (this.state.dropDownVisible) {
            dropDown = (
                    <div 
                        style={{overflowY: 'auto', maxHeight: '200px', zIndex: 1100}}
                        onMouseOver={this.mouseOverDropDownList.bind(this)} 
                        onMouseOut={this.mouseOutDropDownList.bind(this)} 
                    
                        className="dropdown-list">
                        {this.queryResults()}
                    </div>);
        }

        return (
                <div style={{marginTop:'1px',maxHeight: '25px', display:'flex',alignContent:'center', fontHeight:'10px',fontWeight:'bold' , minWidth: '330px', maxWidth: '330px', border: 'solid 1px #00000022',borderRadius:'3px'}}>
                
                    <div style={{position: 'relative', zIndex: '1100', backgroundColor: 'white',marginLeft:'2px'}}>
                        {this.textField()}
                        {dropDown}
                    </div>
                    <div  
                        onClick={this.onOpenerClick.bind(this)} 
                        style={{
                        cursor: 'pointer',
                                position: 'absolute',
                                maxHeight: '22px',
                                top: 1,
                                zIndex: 1200,
                                right: '13px'

                            }}>
                
                        <img 
                            onClick={this.onOpenerClick.bind(this)} 
                            style={{
                        cursor: 'pointer',
                                    maxWidth: '10px',
                                    position: 'relative',
                                    top: '-1px',
                                    right:'14px'

                                }} src={open}
                            />
                    </div>
                </div>

                )
    }
}
export default FormComboMegjegyzes;
