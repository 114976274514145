import React from 'react';


class Panel extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        var themeBackground = (this.props.blackTheme)?'#313943':'#FFFFFF';
        return <div style={{backgroundColor:themeBackground}} className={"panel"}>
           {this.props.children}
        </div>;
    }
}

export default Panel;