import React from 'react';
import './../../css/login.css';
import * as ajax from './../../ajax';
import Logo from './../../css/logo.png';
import ProgressWindow from './../../Components/ProgressWindow';

class PasswordChange extends React.Component {

   constructor(props) {
      super(props);
      this.state = {
         loginSuccess: true,
         tmpPassword: '',
         newPassword: '',
         newPasswordAgain: '',
         needProgress: false,
         fail: ''
      }
   }


   passwordChange = function () {
      try {
         this.setState({ needProgress: true });
         const url = ajax.getUrlBase() + "secure/passwordChange.php";
         return fetch(url, {
            method: "POST",
            headers: new Headers({ "Content-Type": "application/json" }),
            body: JSON.stringify({
               tmpPassword: this.state.tmpPassword,
               newPassword: this.state.newPassword,
               newPasswordAgain: this.state.newPasswordAgain
            })
         }).then(response => {
            if (response.ok) {
               return response.json().then(response => {
                  if (response.success) {
                     window.location.reload();
                  } else {
                     this.setState({ loginSuccess: false, fail: response.fail, needProgress: false });
                  }
               }).catch( jsonError => {
                  if (null == jsonError) {
                     console.error("Fetch JSON error:");

                  } else {
                     console.error("Fetch JSON error:" + String(jsonError));
                  }

                  this.setState({ needProgress: false });
               });
            } else {
               console.error("HTTP status error:", response.statusText);
               this.setState({ needProgress: false });
            }
         }).catch( error => {
            console.error("Fetch error:", error);
            if (null == error) {
               console.error("Fetch error:");
            } else {
               console.error("Fetch error:" + String(error));
            }

            this.setState({ needProgress: false });
         });
      } catch (ex) {
         console.error("Fetch exception:", String(ex));
         this.setState({ needProgress: false });
      }
   }

   onLoginEnter = (event) => {
      if ((event.code !== undefined && (event.code === 'Enter' || event.code === 'NumpadEnter')) ||
         (event.key !== undefined && (event.key === 'Enter' || event.key === 'NumpadEnter')) ||
         (event.keyCode !== undefined && (event.keyCode === 13 || event.keyCode === 13))) {

         this.passwordChange();
      };
   }

   setTmpPassword = function (e) {
      this.setState({ tmpPassword: e.target.value })
   }

   setNewPassword = function (e) {
      this.setState({ newPassword: e.target.value })
   }
   setNewPasswordAgain = function (e) {
      this.setState({ newPasswordAgain: e.target.value })
   }

   render() {
      var loginSuccess = (this.state.loginSuccess) ? "loginSuccess" : "loginFail";

      let loginProgressPopup = this.state.needProgress ? <ProgressWindow /> : '';

      return <div className={"loginWindow"}>
         <div className={"loginBox"} onKeyDown={this.onLoginEnter} >
            <div className={"loginRow"}>
               <div style={{ marginBottom: '0px' }} className={"loginInput"}>
                  <img className={"logoStyle"} src={Logo} alt='QualitySoft Logo' />
               </div>
            </div>
            <div className={"loginHeader"}  >
               <p style={{ color: '#FF0000aa' }} className={"loginHeaderText"}><b>JELSZÓCSERE</b></p>
            </div>
            <div className={"loginHeader"}  >
               <p style={{ maxWidth: '300px' }} className={"loginHeaderText"}>Az ideiglenes jelszót továbbítottuk, kérem ellenőrizze az E-mail fiókját.</p>

            </div>
            <div style={{ marginTop: '20px' }} className={"loginRow"}>
               <div className={"loginInput"}>
                  <input placeholder="Ideiglenes jelszó*" onChange={this.setTmpPassword.bind(this)} className={"loginTextField"} value={this.state.tmpPassword} type="text" autoFocus />
               </div>
            </div>
            <div className={"loginRow"}>
               <div className={"loginInput"}>
                  <input placeholder="Új jelszó*" onChange={this.setNewPassword.bind(this)} className={"loginTextField"} value={this.state.newPassword} type="password" />
               </div>
            </div>
            <div className={"loginRow"}>
               <div className={"loginInput"}>
                  <input placeholder="Új jelszó megerősítése*" onChange={this.setNewPasswordAgain.bind(this)} className={"loginTextField"} value={this.state.newPasswordAgain} type="password" />
               </div>
            </div>

            <div style={{ display: 'flex', marginTop: '30px' }}>
               <div style={{ display: 'flex', width: '100%', textAlign: 'center', justifyContent: 'center' }}>
                  <button onClick={this.passwordChange.bind(this)} className={"loginButton"} >Jelszócsere</button>
               </div>
            </div>
            <div style={{ display: 'flex', marginTop: '0px' }}>
               <div className={loginSuccess}>
                  {this.state.fail}
               </div>
            </div>
         </div>

         {loginProgressPopup}
      </div>;
   }
}

export default PasswordChange;