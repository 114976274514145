import React, { Component } from 'react';
import * as ajax from './../../ajax';

import '@inovua/reactdatagrid-community/index.css';
import '@inovua/reactdatagrid-community/base.css';

import '@inovua/reactdatagrid-community/theme/default-dark.css';
import '@inovua/reactdatagrid-community/theme/default-light.css';

import ReactDataGrid from '@inovua/reactdatagrid-community';
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import BoolFilter from '@inovua/reactdatagrid-community/BoolFilter'
import DateEditor from '@inovua/reactdatagrid-community/DateEditor';
import NumericEditor from '@inovua/reactdatagrid-community/NumericEditor';
import SelectEditor from '@inovua/reactdatagrid-community/SelectEditor';
import PagingToolbar from './../../Components/PagingToolbar';
import greenCircle from "./../../img/green_circle.svg";

import QueryInvoiceDigestView from './QueryInvoiceDigestViewForm';
import JsonTable from './JsonTable';
import PopUpWindow from './../../Components/PopUpWindow';
import BookingForm from './../../modules/Booking/BookingForm';
import AlertDialog from "./../../Components/AlertDialog";

import AtadasKonyvelesreWhite from './../../img/white/post_add_white_24dp.svg';
import AtadasKonyvelesreBlack from './../../img/black/post_add_black_24dp.svg';

import outgoingWhite from   './../../img/white/outbox_white_24dp.svg';
import incomingWhite from   './../../img/white/move_to_inbox_white_24dp.svg';

import outgoingBlack from   './../../img/black/outbox_black_24dp.svg';
import incomingBlack from   './../../img/black/move_to_inbox_black_24dp.svg';

import moment from 'moment';
import 'moment/locale/hu';
moment().locale('hu');



class QueryInvoiceDigestLinesTable extends Component {

    constructor(props) {
        super(props);

        this.NONE = "NONE";

        this.FORM_DELETE = "DELETE";
        this.FORM_INSERT = "INSERT";
        this.FORM_UPDATE = "UPDATE";
        this.FORM_VIEW = "VIEW";


        this.DIALOG_INFO = "INFO";
        this.DIALOG_ALERT = "ALERT";
        this.DIALOG_CONFIRMATION = "CONFIRMATION";
        var columnNames = [
            'id',

            'invoiceNumber', 'invoiceIssueDate', 'invoiceDeliveryDate', 'paymentDate',
            'konyvelesTelel_id',
            'queryInvoiceDigest_id',
            'advanceData',
            'lineDescription',
            'quantity',
            'unitOfMeasure',
            'unitPriceHUF',
            'lineNetAmountHUF',
            'vatPercentage',
            'lineVatAmountHUF',

            'invoiceDirection'

        ];


        this.state = {
            currentContentId: 'bookingIncoming',
            columnOrder: columnNames,
            theme: this.props.theme,
            openedForm: this.NONE, // DELETE,INSERT,UPDATE,VIEW
            defaultUpdateBlocked: false,
            selectedRow: null,
            sortInfo: null,
            groupBy: false,
            filterValue: null,
            additionalParams: [],
            requestProps: null,
            skip: 0,
            paging: {
                totalPageCount: 0,
                currentPage: 0,
                pageRowCount: 50,
                showingRowFrom: 0,
                showingRowTo: 5,
                totalRowCount: 0,
                skip: 0
            },
            dialogType: this.NONE,
            dialogText: ''
        };
        this.renderPaginationToolbar = null;
        this.ref = React.createRef();
        this.queryInvoiceDigest_id = -1;



        this.invoiceDirection = 'INBOUND';


        this.advanceData = null;
        this.selectedInvoiceId = null;
        this.errorMsg = null;

    }
    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            this.setState({data: this.props.value});
        }
        if (prevProps.theme !== this.props.theme) {
            this.setState({theme: this.props.theme});
        }
    }

    shouldComponentUpdate(nextProps, nextState) {

        return true; // equals() is your implementation
    }

    setView = function (e) {

        var formName = e.target.id;

        var rowSelectionNeed = false;
        var hasSelectedRow = true;

        if (formName == this.FORM_DELETE || formName == this.FORM_UPDATE || formName == this.FORM_VIEW) {
            rowSelectionNeed = true;
        }
        if (this.state.selectedRow == null || this.state.selectedRow == '') {
            hasSelectedRow = false;
        }
        if (rowSelectionNeed == true && hasSelectedRow == false) {
            this.setState({openedForm: this.DIALOG_ALERT, dialogText: ''});
        } else {
            var dialogText = "";
            if (formName == this.FORM_DELETE) {
                dialogText = '';
            }
            this.setState({openedForm: formName, dialogText: dialogText});
        }



    }

    closeForm = function () {
        this.setState({openedForm: this.NONE});
    }

    customPaging = function (pagingProps) {
        return <PagingToolbar blackTheme={this.props.theme} tableReload={this.reload.bind(this)} paging={this.state.paging} />
    }
    reload = function (skip) {
        this.state.paging.skip = skip;
        this.forceUpdate();
    }

    concatAdditionalParams = function () {
        // var stateClone = JSON.parse(JSON.stringify(this.state));
        if (typeof this.props.additionalParams == 'undefined') {
            return this.state;
        }

        if (this.props.additionalParams == null) {
            return this.state;
        }

        var additionalParams = this.props.additionalParams;
        for (var i = 0; i < additionalParams.length; i++) {
            var additionalParam = additionalParams[i];
            for (const property in additionalParam) {
                this.state[property] = additionalParam[property];
            }
        }
        return this.state;
    }

    addForeignKeyFilter = function (filterValues) {

        var additionalParams = this.props.additionalParams;

        if (typeof additionalParams == 'undefined') {
            return filterValues;
        }

        if (additionalParams == null) {
            return filterValues;
        }

        if (additionalParams.length == null) {
            return filterValues;
        }

        for (var ap = 0; ap < additionalParams.length; ap++) {

            var additionalParam = additionalParams[ap];

            for (const property in additionalParam) {

                var filter = null;
                for (var f = 0; f < filterValues.length; f++) {
                    var currentFilter = filterValues[f];
                    if (currentFilter.name == property) {
                        filter = currentFilter;
                    }
                }

                if (filter != null) {
                    filter.operator = 'eq';
                    filter.type = 'number';
                    filter.value = additionalParam[property];
                } else {
                    filter = {
                        name: property,
                        operator: 'eq',
                        type: 'number',
                        value: additionalParam[property]
                    };
                    filterValues.push(filter);
                }
            }

        }
        return filterValues;
    }

    changeFilterValue = function (filters, name, newValue) {
        // Ellenőrzések
        if (!filters) {
            console.error('Az első paraméter null vagy undefined.');
            return filters;
        }
        if (!Array.isArray(filters)) {
            console.error('Az első paraméter nem egy tömb.');
            return filters;
        }
        if (typeof name !== 'string') {
            console.error('A második paraméter nem string.');
            return filters;
        }

        // Objektum keresése és módosítása
        for (var i = 0; i < filters.length; i++) {

            if (filters[i].name === name) {
                filters[i].value = newValue;
            }

        }
        return filters;
    }

    dataLoader = function ( { skip, limit, sortInfo, groupBy, filterValue }){
        this.state.paging.skip = (typeof this.state.paging.skip == 'undefined') ? 0 : this.state.paging.skip;
        var state = this.state;
        filterValue = this.addForeignKeyFilter(filterValue);

        filterValue = this.changeFilterValue(filterValue, 'invoiceDirection', this.props.invoiceDirection);
        filterValue = this.changeFilterValue(filterValue, 'queryInvoiceDigest_id', this.props.queryInvoiceDigestId);
        this.state.requestProps = {skip: state.paging.skip, limit: limit, sortInfo: sortInfo, filterValue: filterValue};
        try {
            const url = ajax.getUrlBase() + "modules/QueryInvoiceDigestLines/queryInvoiceDigestLinesTableSelect.php";
            state.update = false;
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({skip: state.paging.skip, limit: limit, sortInfo: sortInfo, filterValue: filterValue})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {

                        var totalCount = response.count;
                        var data = response.data;
                        state.paging.totalRowCount = totalCount;
                        var totalPageCount = ((totalCount) / state.paging.pageRowCount);
                        state.paging.totalPageCount = Math.ceil(totalPageCount) - 1;
                        state.paging.showingRowFrom = skip;
                        state.paging.showingRowTo = skip + state.pageRowCount;

                        return Promise.resolve({data, count: parseInt(totalCount), skip: state.paging.skip});
                    }).catch(function (jsonError) {

                        if (null == jsonError) {

                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    state.defaultUpdateBlocked = false;
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                state.defaultUpdateBlocked = false;
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            state.defaultUpdateBlocked = false;
            console.error("Fetch exception:", String(ex));
    }
    }

    delete = function () {

        var state = this.state;
        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/QueryInvoiceDigestLines/queryInvoiceDigestLinesFormDelete.php";
            state.update = false;
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({id: this.state.selectedRow})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        self.setState({openedForm: 'HIDDEN', selectedRow: null});
                    }).catch(function (jsonError) {

                        if (null == jsonError) {

                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {

                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {

                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {

            console.error("Fetch exception:", String(ex));
        }
    }

    onSelectionChange = function (selection) {

        this.queryInvoiceDigest_id = selection.data.queryInvoiceDigest_id;
        this.konyvelesTelel_id = selection.data.konyvelesTelel_id;



        this.invoiceDirection = selection.data.invoiceDirection;

        this.advanceData = selection.data.advanceData;


        this.setState({selectedRow: selection.selected});
    }

    onRowClick = function (rowProps, event) {

        if (typeof rowProps == 'undefined') {
            return false;
        }

        if (rowProps == null) {
            return false;
        }

        if (typeof rowProps.data == 'undefined') {
            return false;
        }

        if (rowProps.data == null) {
            return false;
        }

        if (typeof rowProps.data.id == 'undefined') {
            return false;
        }

        if (rowProps.data.id == null) {
            return false;
        }
        this.setState({selectedRow: rowProps.data.id});
        this.forceUpdate();
    }

    hasSelectedRow = function () {
        if (typeof this.state.selectedRow == 'undefined') {
            return false;
        }
        if (this.state.selectedRow == null) {
            return false;
        }


        if (this.state.selectedRow == -1) {
            return false;
        }
        return true;
    }

    isViewForm = function () {

        if (typeof this.props.formType == 'undefined') {
            return false;
        }
        if (this.props.formType == null) {
            return false;
        }
        if (this.props.formType == '') {
            return false;
        }
        if (this.props.formType == this.FORM_VIEW) {
            return true;
        }
        return false;
    }

    onColumnVisibleChange = function ( { column, visible }){
        this.forceUpdate();
    }

    jsonParser = function (str) {
        if (str === null) {
            return null;
        }

        try {
            const jsonObj = JSON.parse(str);
            return jsonObj;
        } catch (e) {
            return null;
        }
    }

    findLabelById = function (array, id) {

        for (var i = 0; i < array.length; i++) {
            var obj = array[i];
            if (obj.id == id) {
                return obj.label;
            }
        }
        return "";
    }

    closeBookingForm = function () {
        console.log('close booking from ');
    }

    alertDialog = function (msg) {
        this.state.error.active = true;
        this.state.error.msg = msg;
        this.setState({});
    }

    mailSenderDialog = function (msg) {
        this.state.mailSenderDialog.active = true;
        this.state.mailSenderDialog.msg = msg;
        this.setState({});
    }
    showNavWithButtons = function (visible) {
        this.setState({queryInvoiceDigestVisible: visible, queryInvoiceDigestVisibleWithButtons: true});
    }

    companyBackgroundInfoPanelOpen = function (documentId, taxId, partnerName, isCompany, isPartner) {

        this.setState({
            companyBackgroundInfoPanelDocumentId: documentId,
            companyBackgroundInfoPanelVisible: true,
            companyBackgroundInfoPanelTaxNumber: taxId,
            companyBackgroundInfoPanelTaxPartnerName: partnerName,
            companyBackgroundInfoPanelisCompany: isCompany,
            companyBackgroundInfoPanelisPartner: isPartner
        });
    }

    openUpdateBookingForm = function (bookingFormTableFilters) {
        this.setState({bookingFormVisible: true, bookingFormTableFilters: bookingFormTableFilters, lastInsertId: -1});
    }
    closeBookingForm = function () {
        this.setState({bookingFormVisible: false});
    }

    alertDialogClose = function () {
        this.setState({openedForm: null, dialogText: ''});
    }

    hasConnectedInvoiceCheck = function () {

        var state = this.state;
        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/QueryInvoiceDigestLines/hasConnectedInvoice.php";
            state.update = false;
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({id: this.state.selectedRow})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {

                        if (
                                typeof (response.extra.konyvelesTetel_id) == 'undefined' ||
                                response.extra.konyvelesTetel_id == null ||
                                response.extra.konyvelesTetel_id == -1
                                ) {
                            this.setState({openedForm: this.DIALOG_ALERT, dialogText: 'A tételhez nem tartozik számla'});
                        } else {
                            this.props.invoiceChooser(response.extra.konyvelesTetel_id);
                        }
                    }).catch(function (jsonError) {

                        if (null == jsonError) {

                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {

                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {

                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {

            console.error("Fetch exception:", String(ex));
        }



    }

    jumpToInvoice = function () {

        // if (typeof this.konyvelesTelel_id == 'undefined' || this.konyvelesTelel_id == null || this.konyvelesTelel_id == -1) {
            this.setState({openedForm: this.DIALOG_ALERT, dialogText: 'A tételhez nem tartozik számla.'});
        //} else {
            this.hasConnectedInvoiceCheck();
        //}

    }

    render() {

        var alertDialog = "";

        if (this.state.openedForm == this.DIALOG_ALERT) {
            alertDialog = <AlertDialog 
                blackTheme={this.state.blackTheme}
                alertDialogClose={this.alertDialogClose.bind(this)}>{this.state.dialogText}
            </AlertDialog>
        }


        const currencies = [{id: 1, label: 'HUF'}, {id: 2, label: 'EUR'}, {id: 3, label: 'USD'}];
        const fizmodok = [{id: 1, label: 'Készpénz'}, {id: 2, label: 'Átutalás'}, {id: 3, label: 'Bankkártya'}, {id: 4, label: 'Egyéb'}];
        const me = [
            {id: 0, label: "Ismeretlen"},
            {id: 1, label: "Darab"},
            {id: 2, label: "Kilogramm"},
            {id: 3, label: "Metrikus tonna"},
            {id: 4, label: "Kilowatt óra"},
            {id: 5, label: "Nap"},
            {id: 6, label: "Óra"},
            {id: 7, label: "Perc"},
            {id: 8, label: "Hónap"},
            {id: 9, label: "Liter"},
            {id: 10, label: "Kilométer"},
            {id: 11, label: "Köbméter"},
            {id: 12, label: "Méter"},
            {id: 13, label: "Folyóméter"},
            {id: 14, label: "Karton"},
            {id: 15, label: "Csomag"},
            {id: 16, label: "Saját mennyiségi egység megnevezés"}
        ];




        var advanceData = this.jsonParser(this.advanceData);


        var columns = [
            {name: 'id', sortable: true, type: 'number', header: '#', filterDelay: 1000, defaultVisible: true}
            , {name: 'lineDescription', sortable: true, header: 'Termék/Szolg. megnevezése', filterDelay: 1000, defaultVisible: true}
            , {name: 'quantity', sortable: true, type: 'number', header: 'Mennyiség', filterDelay: 1000, defaultVisible: true}
            , {name: 'unitOfMeasure', sortable: true, header: 'ME.', filterDelay: 1000, defaultVisible: true, defaultWidth: 100,

                render: ({value}) => {
                    return this.findLabelById(me, value);
                },
                filterEditor: SelectFilter,
                filterEditorProps: {
                    multiple: true,
                    wrapMultiple: false,
                    dataSource: me.map(c => {
                        return {id: c.id, label: c.label}
                    }),
                }

            }

            , {name: 'invoiceNumber', sortable: true, header: 'Számlaszám', filterDelay: 1000, defaultVisible: true}
            , {name: 'invoiceIssueDate', sortable: true, header: 'Teljesítés kelte', filterDelay: 1000,
                dateFormat: 'YYYY-MM-DD',
                filterEditor: DateFilter,
                filterEditorProps: (props, { index }) => {
                    return {
                        dateFormat: 'MM-DD-YYYY',
                        cancelButton: false,
                        highlightWeekends: false,
                        placeholder: index == 1 ? 'Előtte' : 'Utána'
                }
                },
                render: ({ value, cellProps }) => {
                    if (value === null) {
                        return '';
                    }
                    return moment(value).format('YYYY.MM.DD')
                }
                , defaultVisible: true},
            , {name: 'invoiceDeliveryDate', sortable: true, header: 'Szállítás dátum', filterDelay: 1000, defaultVisible: true,
                dateFormat: 'YYYY-MM-DD',
                filterEditor: DateFilter,
                filterEditorProps: (props, { index }) => {
                    return {
                        dateFormat: 'MM-DD-YYYY',
                        cancelButton: false,
                        highlightWeekends: false,
                        placeholder: index == 1 ? 'Előtte' : 'Utána'
                }
                },
                render: ({ value, cellProps }) => {
                    if (value === null) {
                        return '';
                    }
                    return moment(value).format('YYYY.MM.DD');
                }}
            , {name: 'paymentDate', sortable: true, header: 'Kifizetés dátum', filterDelay: 1000, defaultVisible: true,
                dateFormat: 'YYYY-MM-DD',
                filterEditor: DateFilter,
                filterEditorProps: (props, { index }) => {
                    return {
                        dateFormat: 'MM-DD-YYYY',
                        cancelButton: false,
                        highlightWeekends: false,
                        placeholder: index == 1 ? 'Előtte' : 'Utána'
                }
                },
                render: ({ value, cellProps }) => {
                    if (value === null) {
                        return '';
                    }
                    return moment(value).format('YYYY.MM.DD');
                }}
            , {name: 'unitPriceHUF', sortable: true, type: 'number', header: 'Egységár net. HUF', filterDelay: 1000, defaultVisible: true}
            , {name: 'lineNetAmountHUF', sortable: true, type: 'number', header: 'Nettó össz HUF', filterDelay: 1000, defaultVisible: true}
            , {name: 'lineVatAmountHUF', sortable: true, type: 'number', header: 'Áfa össz. HUF', filterDelay: 1000, defaultVisible: true}
            , {name: 'vatPercentage', sortable: true, header: 'ÁFA', filterDelay: 1000, defaultVisible: true, defaultWidth: 100}
            , {name: 'konyvelesTelel_id', type: 'number', sortable: true, header: 'Könyvelés tétel id', filterDelay: 1000, defaultVisible: false}
            , {name: 'queryInvoiceDigest_id', type: 'number', sortable: true, header: 'Fejlec az.', filterDelay: 1000, defaultVisible: false}
            , {name: 'invoiceDirection', sortable: true, header: 'Számal iránya', filterDelay: 1000, defaultVisible: false}

        ];

        const filters = [
            {name: 'id', operator: 'eq', type: 'number', value: ''}
            , {name: 'invoiceNumber', operator: 'contains', type: 'string', value: ''}
            , {name: 'invoiceIssueDate', operator: 'eq', type: 'date', value: ''}
            , {name: 'invoiceDeliveryDate', operator: 'eq', type: 'date', value: ''}
            , {name: 'paymentDate', operator: 'eq', type: 'date', value: ''}
            , {name: 'konyvelesTelel_id', operator: 'eq', type: 'number', value: ''}
            , {name: 'queryInvoiceDigest_id', operator: 'eq', type: 'number', value: ''}
            , {name: 'lineNumber', operator: 'eq', type: 'number', value: ''}
            , {name: 'advanceData', operator: 'contains', type: 'string', value: ''}
            , {name: 'lineDescription', operator: 'contains', type: 'string', value: ''}
            , {name: 'quantity', operator: 'eq', type: 'number', value: ''}
            , {name: 'unitPriceHUF', operator: 'eq', type: 'number', value: ''}
            , {name: 'unitOfMeasure', operator: 'inlist', type: 'select'}
            , {name: 'lineNetAmountHUF', operator: 'eq', type: 'number', value: ''}
            , {name: 'vatPercentage', operator: 'eq', type: 'number', value: ''}
            , {name: 'lineVatAmountHUF', operator: 'eq', type: 'number', value: ''}
            , {name: 'invoiceDirection', operator: 'contains', type: 'string', value: ''}

        ]

        var openedForm = "";
        var hideGrid = false;


        if (this.renderPaginationToolbar == null) {
            this.renderPaginationToolbar = this.customPaging.bind(this)
        }

        var darkButtonStyle = {
            backgroundColor: '#475360',
            color: '#FFFFFFaa',
            border: 'solid 0px #FFFFFF',
            padding: '5px',
            margin: '5px',
            boxShadow: '1px 1px 2px #000000',
            cursor: 'pointer'
        };
        var lightButtonStyle = {

            backgroundColor: '#FFFFFF',
            color: '#000000aa',
            border: 'solid 0px #000000',
            padding: '5px',
            margin: '5px',
            boxShadow: '1px 1px 2px #000000aa',
            cursor: 'pointer'

        };


        var isViewForm = this.isViewForm();


        var theme = (this.props.theme == "true") ? 'default-dark' : 'default-light';

        var gridVisibility = {};
        if (hideGrid == true) {
            gridVisibility = {display: 'none'};
        }

        var szamlaAdatok = "";
        if (false) {
            szamlaAdatok = <div style={{minWidth: '40%', textAlign: 'left'}}>    
                <QueryInvoiceDigestView  theme=
                                         {this.props.theme}
                                         key={'queryInvoiceDigest_KEY_VIEW'}
                                         formType={this.FORM_VIEW}
                                         additionalParams={[]}
                                         id={this.queryInvoiceDigest_id}
                                         closeForm={this.closeForm.bind(this)}
                                         />
            </div>;
        }


        var bookingForm = "";

        if (this.invoiceDirection == 'INBOUND') {

            this.state.invoiceDirection = "INBOUND";
            if (this.state.bookingFormVisible) {
                bookingForm = <PopUpWindow blackTheme={false} collapsed={this.state.menuCollapsed}>
                    <div style={{display: 'flex'}}>
                        <BookingForm 
                            blackTheme={false}
                            bookingFormTableFilters={this.state.bookingFormTableFilters}
                            selectedRowId = {this.konyvelesTelel_id}
                            closeBookingForm={this.closeBookingForm.bind(this)} 
                            parentState={this.state}
                            alertDialog={this.alertDialog.bind(this)} 
                            mailSenderDialog={this.mailSenderDialog.bind(this)} 
                            showNav={this.showNavWithButtons.bind(this)}
                            selectedInvoice={this.state.selectedInvoice}
                            invoiceDirection={"INBOUND"}
                            companyBackgroundInfoPanelOpen={this.companyBackgroundInfoPanelOpen.bind(this)}
                            frameState={this.state}
                            />
                    </div>
                </PopUpWindow>
            }
        }

        if (this.invoiceDirection == 'OUTBOUND') {
            this.state.invoiceDirection = "OUTBOUND";
            if (this.state.bookingFormVisible) {
                bookingForm = <PopUpWindow blackTheme={false} collapsed={this.state.menuCollapsed}>
                    <div style={{display: 'flex'}}>
                        <BookingForm 
                            blackTheme={false}
                            bookingFormTableFilters={this.state.bookingFormTableFilters}
                            selectedRowId = {this.konyvelesTelel_id}
                            closeBookingForm={this.closeBookingForm.bind(this)} 
                            parentState={this.state}
                            alertDialog={this.alertDialog.bind(this)} 
                            mailSenderDialog={this.mailSenderDialog.bind(this)} 
                            showNav={this.showNavWithButtons.bind(this)}
                            selectedInvoice={this.state.selectedInvoice}
                            invoiceDirection={"OUTBOUND"}
                            companyBackgroundInfoPanelOpen={this.companyBackgroundInfoPanelOpen.bind(this)}
                            frameState={this.state}
                            />
                    </div>
                </PopUpWindow>
            }
        }

        var newHeight = window.innerHeight - 180;
        const gridStyle = {minHeight: newHeight, fontFamily: 'Arial'};

        var buttons = "";

        if (typeof this.props.queryInvoiceDigestId === 'undefined') {
            buttons = <div style={{display: 'flex'}}>
                <div style={{width: '100%', display: 'flex', textAlign: 'right', justifyContent: 'right', marginTop: '20px', marginRight: 5}}>
                    <button style={{backgroundColor: '#9fd027'}} className={"bookingFormButton"}  onClick={this.jumpToInvoice.bind(this)} >Ugrás a számlára</button>
                </div>
                <div style={{width: '100%', display: 'flex', textAlign: 'left', justifyContent: 'left', marginTop: '20px', marginLeft: 5}}>
                    <button className={"bookingFormButton"}  onClick={() => {
                                        this.props.close()
                                            }} >Bezárás</button>
                </div>
            
            </div>
        } else {
            buttons = <div style={{display: 'flex'}}>
                <div style={{width: '100%', display: 'flex', textAlign: 'center', justifyContent: 'center', marginTop: '20px', marginLeft: 5}}>
                    <button className={"bookingFormButton"}  onClick={() => {
                                        this.props.close()
                                            }} >Bezárás</button>
                </div>
            
            </div>

        }

        return (<div  style={{minHeight: '100%'}}>             
        
            <div style={{
                            textAlign: 'center',
                            fontWeight: 'bold'
                             }}>KÖNYVELÉS TÉTELEK</div>
        
            <ReactDataGrid 
                idProperty="id" 
                i18n={ajax.getI18n()}
                columns={columns}
                dataSource={this.dataLoader.bind(this)}
                defaultFilterValue={filters}
                enableSelection={true}
                onRowClick={this.onRowClick.bind(this)}
                onSelectionChange={this.onSelectionChange.bind(this)}
                theme={theme}
                rowHeight={25}
                defaultLimit={100}
                pagination={true}
                style={gridStyle}
        
                renderPaginationToolbar={this.renderPaginationToolbar}
                skip={this.state.skip}
                limit={this.state.paging.pageRowCount}
                onColumnVisibleChange={this.onColumnVisibleChange.bind(this)}
                onFilterValueChange={() => {
                                this.state.paging.skip = 0;
                                this.state.paging.currentPage = 0;
                                this.setState({});
                            }}
        
                />
        
            {buttons}
            {bookingForm}
            {alertDialog}
        </div>);
    }
}

export default QueryInvoiceDigestLinesTable;
