import React, { Component } from 'react';
import './../css/alertDialog.css';
import AlertImage from './../img/dialog/success.svg';

class MailSenderDialog extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return <div className="dialogFrame">
            <div className="dialogWindow">
                <img className="alertImage" src={AlertImage}/>
                <div className="dialogHeader">
                    <h1>Értesítés</h1>
                </div>
                <div className="dialogContent">
                    {this.props.children}
                </div>
                <input autoFocus onClick={this.props.mailSenderDialogClose.bind(this)} className="mailDialogCloseButton" type="button" value="Bezárás" />
            </div>
        
        </div>;
    }
}

export default MailSenderDialog;