import React, { Component } from 'react';
import './../css/comboEditor.css';

class ComboEditorNAV extends React.Component {

    constructor(props) {
        super(props);
        var id = this.props.editorProps.cellProps.data.egyediAz;
        var key = this.props.editorProps.cellProps.id;
        var currentValue = this.props.editorProps.cellProps.data[key];

        this.state = {
            value: currentValue
        }
    }

    getOptions = function () {

        var options = [];

        var id = this.props.editorProps.cellProps.data.egyediAz;
        var key = this.props.editorProps.cellProps.id;
        var currentValue = this.props.editorProps.cellProps.data[key];

        var values = this.props.values; // Ez a módosítás előtti állapot.
        //  1: Letöltve
        //  2: Párosítva
        //  3: Kép feltöltve
        //  4: Nem könyvelhetö



// Alapból amikor letöltödik az adatszolgálattaás akkor a státusz mezö tartalma: "Letöltve" legyen (régebbei Kapcsolva ""nem" helyett),
        var values = [{id: 1, label: 'Letöltve'}, {id: 2, label: 'Párosítva'}];


// A státusz mezön enetert nyomva vagy duplán rákattintva választható legyen ha a státusz letöltve, akkor választható legyen: "Nem könyvelhetö" és "Kép feltöltve" státuszok közül. 
// Letöltve státusz esetén
        if (typeof currentValue != 'undefined' && currentValue != null && currentValue != '' && currentValue == 1) {
            values = [
                {id: 1, label: 'Letöltve'},
                {id: 3, label: 'Kép feltöltve'},
                {id: 4, label: 'Nem könyvelhető'}
            ];
        }

// Ha a státusz Párosítva akkor választható legyen "Letöltve" és a "Nem könyvelhetö". 
        // Párosított státusz esetén
        if (typeof currentValue != 'undefined' && currentValue != null && currentValue != '' && currentValue == 2) {
            values = [
                {id: 2, label: 'Párosítva'},
                {id: 1, label: 'Letöltve'},
                {id: 4, label: 'Nem könyvelhető'}
            ];
        }

// Ha a státusz: Kép feltöltve akkor választzható legyen "Nem könyvelhetö" és "Letöltve" státusz.
// Kép feltöltve státusz esetén
        if (typeof currentValue != 'undefined' && currentValue != null && currentValue != '' && currentValue == 3) {
            values = [
                {id: 3, label: 'Kép feltöltve'},
                {id: 1, label: 'Letöltve'},
                {id: 4, label: 'Nem könyvelhető'}
            ];
        }

        for (var i = 0; i < values.length; i++) {
            var value = values[i];
            var selected = (currentValue == value.id);
            var component = <option 
                key={'option_' + key + "_" + id + "_" + value.id} 
                onMouseDown={this.onClick.bind(this)}
                value={value.id} 
                defaultValue={selected} >{value.label}</option>;
            options.push(component);
        }
        return options;
    }

    onChange = function (e) {
        
        var id = this.props.editorProps.cellProps.data.egyediAz;
        var key = this.props.editorProps.cellProps.id;
        var currentValue = e.target.value;

        this.state.value = currentValue
        this.props.state.defaultUpdateBlocked = true;
        this.props.callback(id, key, currentValue);

        return false;
        this.props.editorProps.onTabNavigation(
                true /*complete navigation?*/,
                e.shiftKey ? -1 : 1 /*backwards of forwards*/
                );
        e.preventDefault();
        e.stopPropagation();
        return;

    }
    onClick = function (e) {
        
        var id = this.props.editorProps.cellProps.data.egyediAz;
        var key = this.props.editorProps.cellProps.id;
        var currentValue = e.target.value;

        this.state.value = currentValue
        this.props.state.defaultUpdateBlocked = true;

        this.props.editorProps.onTabNavigation(
                true /*complete navigation?*/,
                e.shiftKey ? 0 : 0 /*backwards of forwards*/
                );
        this.props.callback(id, key, currentValue);
        e.preventDefault();
        e.stopPropagation();
        return;
    }
    onBlur = function (e) {

        
        //  this.props.callback(-1,null,null);


        this.props.editorProps.onTabNavigation(
                true /*complete navigation?*/,
                e.shiftKey ? 0 : 0 /*backwards of forwards*/
                );
        e.preventDefault();
        e.stopPropagation();
        return;
    }

    render() {

        var id = this.props.editorProps.cellProps.data.egyediAz;
        var key = this.props.editorProps.cellProps.id;
        var value = this.props.editorProps.cellProps.data[key];

        var comboEditor = (this.props.blackTheme) ? "comboEditorDark" : "comboEditorLight";

        return <select 
            autoFocus
            onChange={this.onChange.bind(this)}
            onBlur={this.onBlur.bind(this)}
            id={'unique_id_' + key + '_' + id}
            key={'unique_' + key + '_' + id}
            className={comboEditor}
            value={value} 
            style={{minWidth: '100%'}} >{this.getOptions()}
        </select>

    }
}

export default ComboEditorNAV;