import React, { Component } from 'react';
import List from "./../../img/paging/list.svg";
import FirstPage from "./../../img/paging/first_page.svg";
import ArrowLeft from "./../../img/paging/arrow_left.svg";

import ArrowRight from "./../../img/paging/arrow_right.svg";
import LastPage from "./../../img/paging/last_page.svg";
import './../../css/bookingFormPaging.css';
import * as ajax from './../../ajax.js';

class BookingFormPaging extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            cursorPosition: 0,
            bookingFormTableFilters: this.props.bookingFormTableFilters,
            selectedRowId: this.props.selectedRowId,
            loadedIDs: [],
            totalRowCount: 0
        }
        this.getPages();
    }

    asNumber = function (val) {
        return val * 1.0;
    }

    getPages = function () {

        var state = this.state;
        try {
            const url = ajax.getUrlBase() + "modules/Booking/formPaginator.php";

            return fetch(url, {
                method: "POST",
                //credentials: "include",
                //credentials: "*", // Tesztüzemben
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({skip: state.bookingFormTableFilters.skip, limit: state.bookingFormTableFilters.limit, sortInfo: state.bookingFormTableFilters.sortInfo, filterValue: state.bookingFormTableFilters.filterValue,invoiceDirection:this.props.invoiceDirection})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        var records = response.data;
                        var idArray = [];
                        for (var i = 0; i < records.length; i++) {
                            var record = records[i];
                            idArray.push(record.id);
                            if (record.id == this.state.selectedRowId) {
                                this.state.cursorPosition = i;
                                //this.state.selectedRowId = -1;
                            }
                        }
                        this.setState({loadedIDs: idArray, totalRowCount: response.count});

                        var currentId = idArray[this.state.cursorPosition];
                        this.props.loadRecord(currentId);


                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }
    }

    forward = function () {
        if ((this.state.loadedIDs.length - 1) > this.state.cursorPosition) {
            this.state.cursorPosition = this.state.cursorPosition + 1;
            var currentId = this.state.loadedIDs[this.state.cursorPosition];
            this.props.loadRecord(currentId,this.props.bookingFormReference);
        }

    }

    backward = function () {
        if (this.state.cursorPosition > 0) {
            this.state.cursorPosition = this.state.cursorPosition-1;
            var currentId = this.state.loadedIDs[this.state.cursorPosition];
            this.props.loadRecord(currentId,this.props.bookingFormReference);
        }
    }

    firstRecord = function () {
        this.state.cursorPosition = 0;
        var currentId = this.state.loadedIDs[this.state.cursorPosition];
        this.props.loadRecord(currentId,this.props.bookingFormReference);
    }

    lastRecord = function () {
        this.state.cursorPosition = this.state.loadedIDs.length - 1;
        var currentId = this.state.loadedIDs[this.state.cursorPosition];
        this.props.loadRecord(currentId,this.props.bookingFormReference);
    }

    render() {
        
        var firsPageVisible = this.state.cursorPosition > 0;
        var prevRecordVisible = this.state.cursorPosition > 0;
        var nextRecordVisible = this.state.cursorPosition < this.state.loadedIDs.length - 1;
        var lastPageVisible = this.state.cursorPosition < this.state.loadedIDs.length - 1;

        var activeStyle = {opacity: '1'};
        var inactiveStyle = {opacity: '0.5'};

        var firstPageStyle = (firsPageVisible) ? activeStyle : inactiveStyle;
        var prevRecordStyle = (prevRecordVisible) ? activeStyle : inactiveStyle;
        var nextRecordStyle = (nextRecordVisible) ? activeStyle : inactiveStyle;
        var lastPageStyle = (lastPageVisible) ? activeStyle : inactiveStyle;




        return <div className={"bookingFormPagingContainer"}>
            <div className={"bookingFormImageContainer"}><img className={"bookingFormImage"}  src={List}/></div>
            <div onClick={this.firstRecord.bind(this)} className={"bookingFormImageContainer"}>
                <img style={firstPageStyle} className={"bookingFormImage"}  src={FirstPage}/>
            </div>
            <div onClick={this.backward.bind(this)} className={"bookingFormImageContainer"}>
                <img style={prevRecordStyle} className={"bookingFormImage"}  src={ArrowLeft}/>
            </div>
            <div onClick={this.forward.bind(this)} className={"bookingFormImageContainer"}>
                <img style={nextRecordStyle} className={"bookingFormImage"}  src={ArrowRight}/>
            </div>
            <div onClick={this.lastRecord.bind(this)} className={"bookingFormImageContainer"}>
                <img style={lastPageStyle} className={"bookingFormImage"}  src={LastPage}/>
            </div>
        </div>;
    }
}

export default BookingFormPaging;