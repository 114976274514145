import React, { Component } from 'react';

class JsonTable extends Component {

    getLang = function () {

        return {
            "case": "Minősített eset",
            "reason": "Oka",
            "productCode": "Termékkód",
            "productCodeCategory": "Termékkód kategória",
            "dataName": "Adat megnevezése",
            "dataDescription": "Adat jellege",
            "dataValue": "Adat értéke",
            "lineNumberReference": "Hivatkozott sorszám",
            "lineOperation": "Művelet típusa",
            "advanceIndicator": "Előleg számla",
            "advanceOriginalInvoice": "Előlegszámla sorszáma",
            "advancePaymentData": "Kapcsolódó információk",
            "advancePaymentDate": "Az előleg fizetésének dátuma",
            "advanceExchangeRate": "Az előlegfizetés során alkalmazott árfolyam",
            "orderNumbers": "Megrendelésszám(ok)",
            "deliveryNotes": "Szállítólevél szám(ok)",
            "shippingDates": "Szállítási dátum(ok)",
            "contractNumbers": "Szerződésszám(ok)",
            "supplierCompanyCodes": "Az eladó vállalati kódja(i)",
            "customerCompanyCodes": "A vevő vállalati kódja(i)",
            "dealerCodes": "Beszállító kód(ok)",
            "costCenters": "Költséghely(ek)",
            "projectNumbers": "Projektszám(ok)",
            "generalLedgerAccountNumbers": "Főkönyvi számlaszám(ok)",
            "glnNumbersSupplier": "Kiállítói globális helyazonosító szám(ok)",
            "glnNumbersCustomer": "Vevői globális helyazonosító szám(ok)",
            "materialNumbers": "Anyagszám(ok)",
            "itemNumbers": "Cikkszám(ok)",
            "ekaerIds": "EKÁER azonosító(k)"
        }



    }

    getTitle = function (nameConstant) {
        var lang = this.getLang();

        if ((!lang.hasOwnProperty(nameConstant)) || lang[nameConstant] === '') {
            return nameConstant;
        }
        return lang[nameConstant];
    }

    renderTable = (data) => {
        if (data == null) {
            return null;
        }
        if (Array.isArray(data)) {
            return (
                    <table style={{border: '1px solid black', borderCollapse: 'collapse', width: '100%'}}>
                        <tbody>
                            {data.map((item, index) => (
                                                <tr key={index}>
                                                    <td style={{border: '1px solid #00000022', padding: '8px'}}>
                                                        {this.renderTable(item)}
                                                    </td>
                                                </tr>
                                            ))}
                        </tbody>
                    </table>
                    );
        } else if (typeof data === 'object' && data !== null) {
            return (
                    <table style={{border: '1px solid black', borderCollapse: 'collapse', width: '100%'}}>
                        <tbody>
                            {Object.keys(data).map((key) => (
                                                <tr key={key}>
                                                    <td style={{border: '1px solid #00000022', padding: '8px', backgroundColor: '#f0f0f0'}}>
                                                        {this.getTitle(key)}
                                                    </td>
                                                    <td style={{border: '1px solid #00000022', padding: '8px'}}>
                                                        {this.renderTable(data[key])}
                                                    </td>
                                                </tr>
                                            ))}
                        </tbody>
                    </table>
                    );
        }
        return <span>{data.toString()}</span>;
    }
    ;
            render() {
        return (
                <div style={{width: '100%'}}>
                    {this.renderTable(this.props.data)}
                </div>
                );
    }
}
export default JsonTable;