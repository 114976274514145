import React, { Component } from 'react';
import './../css/alertDialog.css';
import AlertImage from './../img/dialog/alert.svg';

class DeleteConfirmationDialog extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        var windowClass=(this.props.size)?"dialogWindowBig":"dialogWindow";
        var contentClass=(this.props.size)?"dialogContentBig":"dialogContent";

        return <div className="dialogFrame">
    <div className={windowClass}>
        <img className="alertImage" src={AlertImage}/>
        <div className="dialogHeader">
            <h1>Megerősítés kérése</h1>
        </div>
        <div className={contentClass}>
            {this.props.children}
        </div>
        <div style={{display:'flex'}}>
            <div style={{minWidth:'48%',textAlign:'right'}}>
                <input style={{backgroundColor:'#9fd027',paddingLeft:15,paddingRight:15}} autoFocus onClick={() => this.props.delete()} className="bookingFormButton" type="button" value="Törlés" />
            </div>
            <div style={{minWidth:'4%',textAlign:'center'}}>
            </div>
            <div style={{minWidth:'48%',textAlign:'left'}}>
                <input autoFocus onClick={() => this.props.cancel()} className="bookingFormButton" type="button" value="Mégsem" />
            </div>
        </div>
    </div>

</div>;
    }
}

export default DeleteConfirmationDialog;