import React, { Component } from 'react';

class DisplayField extends React.Component {

    constructor(props) {
        super(props);
        var currentValue = (this.props.value == null) ? '' : this.props.value;
        this.state = {value: currentValue}
    }

    componentDidUpdate(prevProps) {
        if (prevProps.value !== this.props.value) {
            var currentValue = (this.props.value == null) ? '' : this.props.value;
            this.setState({value: currentValue});
        }
    }



    render() {
        if((typeof this.props.title == 'undefined' || this.props.title==null)){
            return '';
        }
        // A színtaktikai elemző hibát jelez de nincs gond a kóddal.
        return <>
        <td>
            {(typeof this.props.title == 'undefined' || this.props.title==null)?'':this.props.title}:&nbsp;
        </td>
        <td>
            {this.state.value}
        </td>
        </>
    }
}

export default DisplayField;