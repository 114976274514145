import React, { Component } from 'react';
import * as ajax from './../../ajax.js';
import PopUpWindow from './../../Components/PopUpWindow';
import DisplayField from './../../Components/DisplayField';
import CheckBoxInsertField from './../../Components/CheckBoxInsertField';
import './../../css/invoiceQueryTable.css';


class QueryInvoiceDigestView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            egyediAz: ''
            , id: ''
            , invoiceNumber: ''
            , invoiceNumberbutton: ''
            , batchIndex: ''
            , invoiceOperation: ''
            , invoiceCategory: ''
            , invoiceIssueDate: ''
            , supplierTaxNumber: ''
            , supplierGroupMemberTaxNumber: ''
            , supplierName: ''
            , customerTaxNumber: ''
            , customerGroupMemberTaxNumber: ''
            , customerName: ''
            , paymentMethod: ''
            , paymentDate: ''
            , invoiceAppearance: ''
            , source: ''
            , invoiceDeliveryDate: ''
            , currency: ''
            , invoiceNetAmount: ''
            , invoiceNetAmountHUF: ''
            , invoiceVatAmount: ''
            , invoiceVatAmountHUF: ''
            , transactionId: ''
            , originalInvoiceNumber: ''
            , modificationIndex: ''
            , insDate: ''
            , completenessIndicator: ''
            , used: ''
            , createdBy: ''
            , downloadedBy: ''
            , invoiceDirection: ''
            , invoiceGrossAmountHUF: ''
            , notBookable: ''

        }


        this.references = [];
        this.references['egyediAz'] = React.createRef();
        this.references['id'] = React.createRef();
        this.references['invoiceNumber'] = React.createRef();
        this.references['invoiceNumberbutton'] = React.createRef();
        this.references['batchIndex'] = React.createRef();
        this.references['invoiceOperation'] = React.createRef();
        this.references['invoiceCategory'] = React.createRef();
        this.references['invoiceIssueDate'] = React.createRef();
        this.references['supplierTaxNumber'] = React.createRef();
        this.references['supplierGroupMemberTaxNumber'] = React.createRef();
        this.references['supplierName'] = React.createRef();
        this.references['customerTaxNumber'] = React.createRef();
        this.references['customerGroupMemberTaxNumber'] = React.createRef();
        this.references['customerName'] = React.createRef();
        this.references['paymentMethod'] = React.createRef();
        this.references['paymentDate'] = React.createRef();
        this.references['invoiceAppearance'] = React.createRef();
        this.references['source'] = React.createRef();
        this.references['invoiceDeliveryDate'] = React.createRef();
        this.references['currency'] = React.createRef();
        this.references['invoiceNetAmount'] = React.createRef();
        this.references['invoiceNetAmountHUF'] = React.createRef();
        this.references['invoiceVatAmount'] = React.createRef();
        this.references['invoiceVatAmountHUF'] = React.createRef();
        this.references['transactionId'] = React.createRef();
        this.references['originalInvoiceNumber'] = React.createRef();
        this.references['modificationIndex'] = React.createRef();
        this.references['insDate'] = React.createRef();
        this.references['completenessIndicator'] = React.createRef();
        this.references['used'] = React.createRef();
        this.references['createdBy'] = React.createRef();
        this.references['downloadedBy'] = React.createRef();
        this.references['invoiceDirection'] = React.createRef();
        this.references['invoiceGrossAmountHUF'] = React.createRef();
        this.references['notBookable'] = React.createRef();


        this.error = false;
        this.msg = "";

        this.load();

    }

    componentDidUpdate(prevProps) {
        if (prevProps.id !== this.props.id) {
            this.load();
        }
    }

    reset = function () {
        this.setState({egyediAz: ''
            , id: ''
            , invoiceNumber: ''
            , invoiceNumberbutton: ''
            , batchIndex: ''
            , invoiceOperation: ''
            , invoiceCategory: ''
            , invoiceIssueDate: ''
            , supplierTaxNumber: ''
            , supplierGroupMemberTaxNumber: ''
            , supplierName: ''
            , customerTaxNumber: ''
            , customerGroupMemberTaxNumber: ''
            , customerName: ''
            , paymentMethod: ''
            , paymentDate: ''
            , invoiceAppearance: ''
            , source: ''
            , invoiceDeliveryDate: ''
            , currency: ''
            , invoiceNetAmount: ''
            , invoiceNetAmountHUF: ''
            , invoiceVatAmount: ''
            , invoiceVatAmountHUF: ''
            , transactionId: ''
            , originalInvoiceNumber: ''
            , modificationIndex: ''
            , insDate: ''
            , completenessIndicator: ''
            , used: ''
            , createdBy: ''
            , downloadedBy: ''
            , invoiceDirection: ''
            , invoiceGrossAmountHUF: ''
            , notBookable: ''
        });
    }

    setBack = function (id, value) {
        this.setState({[id]: value});
    }

    getForm = function () {
        return <table class="zebra-table" >
            <thead>
            <tr>
                <th colspan="4" style={{textAlign:'center'}}>SZÁMLA ADATOK</th>
            </tr>
            </thead>
            <tbody>
                <tr>
            <DisplayField 
                id={'egyediAz'} 
                title="#"
                value={this.state.egyediAz}  
                setBack={this.setBack.bind(this)}
                reference={this.references['egyediAz']}
                editable={false}
                theme={this.props.theme}
                />
        
            <DisplayField 
                id={'id'} 
                title="Iktatószám"
                value={this.state.id}  
                setBack={this.setBack.bind(this)}
                reference={this.references['id']}
                editable={false}
                theme={this.props.theme}
                />
        </tr>    
        <tr>    
        <DisplayField 
            id={'invoiceNumber'} 
            title="Számaszám"
            value={this.state.invoiceNumber}  
            setBack={this.setBack.bind(this)}
            reference={this.references['invoiceNumber']}
            editable={false}
            theme={this.props.theme}
            />
        <DisplayField 
            id={'invoiceNumberbutton'} 
        
            value={this.state.invoiceNumberbutton}  
            setBack={this.setBack.bind(this)}
            reference={this.references['invoiceNumberbutton']}
            editable={false}
            theme={this.props.theme}
            />
        </tr>    
        <tr>        
        <DisplayField 
            id={'batchIndex'} 
            title="A módosító okirat sorszáma a kötegen belül"
            value={this.state.batchIndex}  
            setBack={this.setBack.bind(this)}
            reference={this.references['batchIndex']}
            editable={false}
            theme={this.props.theme}
            />
        <DisplayField 
            id={'invoiceOperation'} 
            title="Számalaművelet típusa"
            value={this.state.invoiceOperation}  
            setBack={this.setBack.bind(this)}
            reference={this.references['invoiceOperation']}
            editable={false}
            theme={this.props.theme}
            />
        
        </tr>    
        <tr>     
        <DisplayField 
            id={'invoiceCategory'} 
            title="Számla típusa"
            value={this.state.invoiceCategory}  
            setBack={this.setBack.bind(this)}
            reference={this.references['invoiceCategory']}
            editable={false}
            theme={this.props.theme}
            />
        <DisplayField 
            id={'invoiceIssueDate'} 
            title="Kiállítás dátuma"
            value={this.state.invoiceIssueDate}  
            setBack={this.setBack.bind(this)}
            reference={this.references['invoiceIssueDate']}
            editable={false}
            theme={this.props.theme}
            />
        </tr>    
        <tr>    
        <DisplayField 
            id={'supplierTaxNumber'} 
            title="A kibocsátó adószáma"
            value={this.state.supplierTaxNumber}  
            setBack={this.setBack.bind(this)}
            reference={this.references['supplierTaxNumber']}
            editable={false}
            theme={this.props.theme}
            />
        <DisplayField 
            id={'supplierGroupMemberTaxNumber'} 
            title="A kibocsátó csoporttag száma"
            value={this.state.supplierGroupMemberTaxNumber}  
            setBack={this.setBack.bind(this)}
            reference={this.references['supplierGroupMemberTaxNumber']}
            editable={false}
            theme={this.props.theme}
            />
        </tr>    
        <tr>         
        <DisplayField 
            id={'supplierName'} 
            title="Az eladó (szállító) neve"
            value={this.state.supplierName}  
            setBack={this.setBack.bind(this)}
            reference={this.references['supplierName']}
            editable={false}
            theme={this.props.theme}
            />
        <DisplayField 
            id={'customerTaxNumber'} 
            title="A vevő adószáma"
            value={this.state.customerTaxNumber}  
            setBack={this.setBack.bind(this)}
            reference={this.references['customerTaxNumber']}
            editable={false}
            theme={this.props.theme}
            />
        </tr>    
        <tr>    
        <DisplayField 
            id={'customerGroupMemberTaxNumber'} 
            title="A vevő csoporttag száma"
            value={this.state.customerGroupMemberTaxNumber}  
            setBack={this.setBack.bind(this)}
            reference={this.references['customerGroupMemberTaxNumber']}
            editable={false}
            theme={this.props.theme}
            />
        <DisplayField 
            id={'customerName'} 
            title="A vevő neve"
            value={this.state.customerName}  
            setBack={this.setBack.bind(this)}
            reference={this.references['customerName']}
            editable={false}
            theme={this.props.theme}
            />
        </tr>    
        <tr>    
        <DisplayField 
            id={'paymentMethod'} 
            title="Fizetés módja"
            value={this.state.paymentMethod}  
            setBack={this.setBack.bind(this)}
            reference={this.references['paymentMethod']}
            editable={false}
            theme={this.props.theme}
            />
        <DisplayField 
            id={'paymentDate'} 
            title="Fizetési határidő"
            value={this.state.paymentDate}  
            setBack={this.setBack.bind(this)}
            reference={this.references['paymentDate']}
            editable={false}
            theme={this.props.theme}
            />
        </tr>    
        <tr>    
        <DisplayField 
            id={'invoiceAppearance'} 
            title="A számla megjelenési formája"
            value={this.state.invoiceAppearance}  
            setBack={this.setBack.bind(this)}
            reference={this.references['invoiceAppearance']}
            editable={false}
            theme={this.props.theme}
            />
        <DisplayField 
            id={'source'} 
            title="Az adatszolgáltatás forrása"
            value={this.state.source}  
            setBack={this.setBack.bind(this)}
            reference={this.references['source']}
            editable={false}
            theme={this.props.theme}
            />
        </tr>    
        <tr>    
        <DisplayField 
            id={'invoiceDeliveryDate'} 
            title="Teljesítés dátuma"
            value={this.state.invoiceDeliveryDate}  
            setBack={this.setBack.bind(this)}
            reference={this.references['invoiceDeliveryDate']}
            editable={false}
            theme={this.props.theme}
            />
        <DisplayField 
            id={'currency'} 
            title="Pénznem"
            value={this.state.currency}  
            setBack={this.setBack.bind(this)}
            reference={this.references['currency']}
            editable={false}
            theme={this.props.theme}
            />
        </tr>    
        <tr>    
        <DisplayField 
            id={'invoiceNetAmount'} 
            title="Nettó"
            value={this.state.invoiceNetAmount}  
            setBack={this.setBack.bind(this)}
            reference={this.references['invoiceNetAmount']}
            editable={false}
            theme={this.props.theme}
            />
        <DisplayField 
            id={'invoiceNetAmountHUF'} 
            title="Nettó HUF"
            value={this.state.invoiceNetAmountHUF}  
            setBack={this.setBack.bind(this)}
            reference={this.references['invoiceNetAmountHUF']}
            editable={false}
            theme={this.props.theme}
            />
        </tr>    
        <tr>    
        <DisplayField 
            id={'invoiceVatAmount'} 
            title="ÁFA összeg"
            value={this.state.invoiceVatAmount}  
            setBack={this.setBack.bind(this)}
            reference={this.references['invoiceVatAmount']}
            editable={false}
            theme={this.props.theme}
            />
        <DisplayField 
            id={'invoiceVatAmountHUF'} 
            title="ÁFA összeg HUF"
            value={this.state.invoiceVatAmountHUF}  
            setBack={this.setBack.bind(this)}
            reference={this.references['invoiceVatAmountHUF']}
            editable={false}
            theme={this.props.theme}
            />
        </tr>    
        <tr>    
        <DisplayField 
            id={'transactionId'} 
            value={this.state.transactionId}  
            setBack={this.setBack.bind(this)}
            reference={this.references['transactionId']}
            editable={false}
            theme={this.props.theme}
            />
        <DisplayField 
            id={'originalInvoiceNumber'} 
            title="Az eredeti számla sorszáma"
            value={this.state.originalInvoiceNumber}  
            setBack={this.setBack.bind(this)}
            reference={this.references['originalInvoiceNumber']}
            editable={false}
            theme={this.props.theme}
            />
        </tr>    
        <tr>    
        <DisplayField 
            id={'modificationIndex'} 
            value={this.state.modificationIndex}  
            setBack={this.setBack.bind(this)}
            reference={this.references['modificationIndex']}
            editable={false}
            theme={this.props.theme}
            />
        <DisplayField 
            id={'insDate'} 
            title="Létrehozás időpontja UTC"
            value={this.state.insDate}  
            setBack={this.setBack.bind(this)}
            reference={this.references['insDate']}
            editable={false}  
            theme={this.props.theme} 
            />
        </tr>    
        <tr>        
        <DisplayField 
            id={'completenessIndicator'} 
            title="Az adatszolgáltatás maga a számla"
            value={this.state.completenessIndicator}  
            setBack={this.setBack.bind(this)}
            reference={this.references['completenessIndicator']}
            editable={false}
            theme={this.props.theme}
            />
        <DisplayField 
            id={'used'} 
            title="Használt"
            value={this.state.used}  
            setBack={this.setBack.bind(this)}
            reference={this.references['used']}
            editable={false}
            theme={this.props.theme}
            />
        
        </tr>    
        <tr>    
        <DisplayField 
            id={'createdBy'} 
            title="Készítette"
            value={this.state.createdBy}  
            setBack={this.setBack.bind(this)}
            reference={this.references['createdBy']}
            editable={false}
            theme={this.props.theme}
            />
        <DisplayField 
            id={'downloadedBy'} 
            title="Letöltötte"
            value={this.state.downloadedBy}  
            setBack={this.setBack.bind(this)}
            reference={this.references['downloadedBy']}
            editable={false}
            theme={this.props.theme}
            />
        
        </tr>    
        <tr>    
        <DisplayField 
            id={'invoiceDirection'} 
            title="Számla iránya"
            value={this.state.invoiceDirection}  
            setBack={this.setBack.bind(this)}
            reference={this.references['invoiceDirection']}
            editable={false}
            theme={this.props.theme}
            />
        <DisplayField 
            id={'invoiceGrossAmountHUF'} 
            title="Összesen HUF"
            value={this.state.invoiceGrossAmountHUF}  
            setBack={this.setBack.bind(this)}
            reference={this.references['invoiceGrossAmountHUF']}
            editable={false}
            theme={this.props.theme}
            />
        </tr>    
        <tr>    
        <DisplayField 
            id={'notBookable'} 
            title="Nem könyvelhető"
            value={this.state.notBookable}  
            setBack={this.setBack.bind(this)}
            reference={this.references['notBookable']}
            editable={false}
            theme={this.props.theme}
            />
        <td></td>
        <td></td>
        
        </tr>    
        
        </tbody>  
        </table>
    }

    closeForm = function () {
        this.reset();
        this.props.closeForm();

    }

    transactionSuccess = function () {
        this.error = false;
        this.msg = 'Sikeres tranzakció';
        this.setState({});
    }

    transactionFail = function (errorStringConstant) {
        this.error = true;
        this.msg = errorStringConstant;
        this.setState({});
    }

    concatAdditionalParams = function () {
        // var stateClone = JSON.parse(JSON.stringify(this.state));
        if (typeof this.props.additionalParams == 'undefined') {
            return this.state;
        }

        if (this.props.additionalParams == null) {
            return this.state;
        }

        var additionalParams = this.props.additionalParams;
        for (var i = 0; i < additionalParams.length; i++) {
            var additionalParam = additionalParams[i];
            for (const property in additionalParam) {
                this.state[property] = additionalParam[property];
            }
        }
        return this.state;
    }

    load = function () {

        var self = this;
        try {
            const url = ajax.getUrlBase() + "modules/QueryInvoiceDigest/queryInvoiceDigestFormLoader.php";

            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({id: this.props.id})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            this.setState(response.data[0]);
                        } else {
                            self.transactionFail(response.fail);
                        }

                    }).catch(function (jsonError) {

                        if (null == jsonError) {
                            self.transactionFail('Fetch JSON error');
                            console.error("Fetch JSON error:");
                        } else {
                            self.transactionFail("Fetch JSON error:" + String(jsonError));
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {

                    self.transactionFail("HTTP status error:", response.statusText);
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {

                self.transactionFail("Fetch error:", error);
                console.error("Fetch error:", error);
                if (null == error) {
                    self.transactionFail("Fetch error:");
                    console.error("Fetch error:");
                } else {
                    self.transactionFail("Fetch error:" + String(error));
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {

            self.transactionFail("Fetch exception:", String(ex));
            console.error("Fetch exception:", String(ex));
        }
    }

    render() {
        var form = this.getForm();
        var className = (this.props.theme == "true") ? "Dark" : "Light";
        return (<div  style={{width:'100%'}} className={'popupWrapper' + className} id="QueryInvoiceDigestUpdate">
            <div  className={"popupHeader" + className} >
                <div>
                    <div></div>
                    <div style={{textAlign: 'right'}}>
        
                    </div>
                </div>
            </div>
            <div  className={"popupBody" + className} >{form}</div>
            <div  className={"popupFooter" + className} >
                <div>
                    <div>
                        <div style={{whiteSpace: 'break-spaces'}}>
                            {this.msg} 
                        </div>
        
                    </div>
                </div>
        
                <div>
                    <div style={{minHeight: '10px'}}>
        
                    </div>
                </div>
        
            </div>
        </div>);
    }
}

export default QueryInvoiceDigestView;

