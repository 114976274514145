import React from 'react';
import MenuItem from "./../Components/MenuItem";



class Menu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            menuItems: this.getMenu(this.props.userType)
        };
    }

    getMenu = function (userType) {

        switch (userType) {
            case "ADMIN":
                return this.getAdminMenu();

            case "OFFICE":
                return this.getOfficeMenu();

            case "CHIEF_ACCOUNTANT":
                return this.getChiefAccountantMenu();

            case "ACCOUNTANT":
                return this.getAccountantMenu();

            case "COMPANY":
                return this.getCompanyMenu();

            case "OPERATOR":
                return this.getOperatorMenu();

            default:
                return [];
        }

    }

    getAdminMenu = function () {
        return [
            {id: 'Users', title: 'Felhasználók', ico: 'profile', childrens: [
                    {id: 'AdminManager', title: 'Adminisztrátorok', ico: 'admins', childrens: []},
                    {id: 'office', title: 'Könyvelőiroda', ico: 'office', childrens: []},
                    {id: 'dbAdmin', title: 'Archívumok', ico: 'dbAdmin', childrens: []},
                    {id: 'limits', title: 'Limitek', ico: 'limits', childrens: []}
                ]}

        ];
    }
    getOfficeMenu = function () {
        return [
            {id: 'Users', title: 'Felhasználók', ico: 'profile', childrens: [
                    {id: 'ChiefAccountantManager', title: 'Főkönyvelő', ico: 'accountant', childrens: []},
                    {id: 'AccountantManager', title: 'Könyvelők', ico: 'accountant', childrens: []},
                    {id: 'CompanyManager', title: 'Cégek', ico: 'companies', childrens: []},
                    
                ]},
            {id: 'widgets', title: 'Partnerek', ico: 'widgets', childrens: []},
            {id: 'Booking', title: 'Iktatás', ico: 'dashboard', childrens: [
                    {id: 'bookingIncoming', title: 'Bejövő számla', ico: 'incoming', childrens: []},
                    {id: 'bookingOutgoing', title: 'Kimenő számla', ico: 'outgoing', childrens: []},

                ]},
            {id: 'DBTransfer', title: 'Átmozgatás', ico: 'dbTransfer', childrens: [
                    {id: 'bookingIncomingTransfer', title: 'Bejövő számla', ico: 'incoming', childrens: []},
                    {id: 'bookingOutgoingTransfer', title: 'Kimenő számla', ico: 'outgoing', childrens: []},
                ]},
            {id: 'Upload', title: 'Feltöltés', ico: 'upload', childrens: [
                    {id: 'outgoing', title: 'Kimenő számla XML', ico: 'outgoing', childrens: []},
                    {id: 'bank', title: 'Banki import', ico: 'bank', childrens: []},
                    {id: 'docOther', title: 'Egyéb irat', ico: 'docOther', childrens: []}

                ]},
            {id: 'pages', title: 'Dokumentumok', ico: 'pages', childrens: [
                    {id: 'lockClose', title: 'Ügyvezetői', ico: 'lockClose', childrens: []},
                    {id: 'lockOpen', title: 'Ügyintézői', ico: 'lockOpen', childrens: []}
                ]}
        ];
    }
    getChiefAccountantMenu = function () {
        return [
            {id: 'Users', title: 'Felhasználók', ico: 'profile', childrens: [
                    {id: 'AccountantManager', title: 'Könyvelők', ico: 'accountant', childrens: []},
                    {id: 'CompanyManager', title: 'Cégek', ico: 'companies', childrens: []},
                ]},
            {id: 'widgets', title: 'Partnerek', ico: 'widgets', childrens: []},
            {id: 'Booking', title: 'Iktatás', ico: 'dashboard', childrens: [
                    {id: 'bookingIncoming', title: 'Bejövő számla', ico: 'incoming', childrens: []},
                    {id: 'bookingOutgoing', title: 'Kimenő számla', ico: 'outgoing', childrens: []},
                    
                    
                ]},
            {id: 'DBTransfer', title: 'Átmozgatás', ico: 'dbTransfer', childrens: [
                    {id: 'bookingIncomingTransfer', title: 'Bejövő számla', ico: 'incoming', childrens: []},
                    {id: 'bookingOutgoingTransfer', title: 'Kimenő számla', ico: 'outgoing', childrens: []},
                ]},
            {id: 'Upload', title: 'Feltöltés', ico: 'upload', childrens: [
                    {id: 'outgoing', title: 'Kimenő számla XML', ico: 'outgoing', childrens: []},
                    {id: 'bank', title: 'Banki import', ico: 'bank', childrens: []},
                    {id: 'docOther', title: 'Egyéb irat', ico: 'docOther', childrens: []}
                ]},
            {id: 'pages', title: 'Dokumentumok', ico: 'pages', childrens: [
                    {id: 'lockClose', title: 'Ügyvezetői', ico: 'lockClose', childrens: []},
                    {id: 'lockOpen', title: 'Ügyintézői', ico: 'lockOpen', childrens: []}
                ]}
        ];
    }
    getAccountantMenu = function () {
        return [
            {id: 'Users', title: 'Felhasználók', ico: 'profile', childrens: [
                    {id: 'CompanyManager', title: 'Cégek', ico: 'companies', childrens: []},
                ]},
            {id: 'widgets', title: 'Partnerek', ico: 'widgets', childrens: []},
            {id: 'Booking', title: 'Iktatás', ico: 'dashboard', childrens: [
                    {id: 'bookingIncoming', title: 'Bejövő számla', ico: 'incoming', childrens: []},
                    {id: 'bookingOutgoing', title: 'Kimenő számla', ico: 'outgoing', childrens: []},

                ]},
            {id: 'DBTransfer', title: 'Átmozgatás', ico: 'dbTransfer', childrens: [
                    {id: 'bookingIncomingTransfer', title: 'Bejövő számla', ico: 'incoming', childrens: []},
                    {id: 'bookingOutgoingTransfer', title: 'Kimenő számla', ico: 'outgoing', childrens: []},
                ]},
            {id: 'Upload', title: 'Feltöltés', ico: 'upload', childrens: [
                    {id: 'outgoing', title: 'Kimenő számla XML', ico: 'outgoing', childrens: []},
                    {id: 'bank', title: 'Banki import', ico: 'bank', childrens: []},
                    {id: 'docOther', title: 'Egyéb irat', ico: 'docOther', childrens: []}
                ]},
            {id: 'pages', title: 'Dokumentumok', ico: 'pages', childrens: [
                    {id: 'lockClose', title: 'Ügyvezetői', ico: 'lockClose', childrens: []},
                    {id: 'lockOpen', title: 'Ügyintézői', ico: 'lockOpen', childrens: []}
                ]}
        ];
    }
    getCompanyMenu = function () {
        return [
            {id: 'Users', title: 'Felhasználók', ico: 'profile', childrens: [
                    {id: 'OperatorManager', title: 'Kezelők', ico: 'operator', childrens: []},
                ]},
            {id: 'widgets', title: 'Partnerek', ico: 'widgets', childrens: []},
            {id: 'Booking', title: 'Iktatás', ico: 'dashboard', childrens: [
                    {id: 'bookingIncoming', title: 'Bejövő számla', ico: 'incoming', childrens: []},
                    {id: 'bookingOutgoing', title: 'Kimenő számla', ico: 'outgoing', childrens: []},

                ]},
            {id: 'Upload', title: 'Feltöltés', ico: 'upload', childrens: [
                    {id: 'outgoing', title: 'Kimenő számla XML', ico: 'outgoing', childrens: []},
                    {id: 'bank', title: 'Banki import', ico: 'bank', childrens: []},
                    {id: 'docOther', title: 'Egyéb irat', ico: 'docOther', childrens: []}
                ]},
            {id: 'pages', title: 'Dokumentumok', ico: 'pages', childrens: [
                    {id: 'lockClose', title: 'Ügyvezetői', ico: 'lockClose', childrens: []},
                    {id: 'lockOpen', title: 'Ügyintézői', ico: 'lockOpen', childrens: []}
                ]}
        ];
    }

    getOperatorMenu = function () {

        var iktathat = this.props.iktathat;
        var feltolthet = this.props.feltolthet;

        var menu = [];
        if (iktathat) {
            menu.push({id: 'widgets', title: 'Partnerek', ico: 'widgets', childrens: []});
        }
        if (iktathat) {
            menu.push({id: 'Booking', title: 'Iktatás', ico: 'dashboard', childrens: [
                    {id: 'bookingIncoming', title: 'Bejövő számla', ico: 'incoming', childrens: []},
                    {id: 'bookingOutgoing', title: 'Kimenő számla', ico: 'outgoing', childrens: []}
                ]});
        }

        if (feltolthet) {
            menu.push({id: 'Upload', title: 'Feltöltés', ico: 'upload', childrens: [
                    {id: 'outgoing', title: 'Kimenő számla XML', ico: 'outgoing', childrens: []},
                    {id: 'bank', title: 'Banki import', ico: 'bank', childrens: []},
                    {id: 'docOther', title: 'Egyéb irat', ico: 'docOther', childrens: []}
                ]});
            menu.push({id: 'pages', title: 'Dokumentumok', ico: 'pages', childrens: []});
        }

        return menu;
    }

    setActiveMenu = function (menuId) {
        this.props.setContent(menuId);
        this.setState({});
    }

    getMenuItems = function () {

        var menuItems = this.state.menuItems;
        var items = [];

        for (var i = 0; i < menuItems.length; i++) {
            var item = menuItems[i];
            var menuChildrens = this.getMenuChidrens(item);
            items.push(
                    <MenuItem 
                        isChild={false}
                        activeMenuId={this.props.currentContentId} 
                        setActiveMenu={this.setActiveMenu.bind(this)} 
                        key={"menu_item_" + i} 
                        item={item} 
                        collapsed={this.props.collapsed}
                        blackTheme={this.props.blackTheme}
                        >{menuChildrens}</MenuItem>

                    );
        }

        return items;

    }

    getMenuChidrens = function (menuItem) {

        var menuItems = menuItem.childrens;
        var items = [];

        for (var i = 0; i < menuItems.length; i++) {
            var item = menuItems[i];
            items.push(
                    <MenuItem 
                        isChild={true}
                        activeMenuId={this.props.currentContentId} 
                        setActiveMenu={this.setActiveMenu.bind(this)} 
                        key={"menu_item_" + i} 
                        item={item} 
                        collapsed={this.props.collapsed}
                        blackTheme={this.props.blackTheme}
                        />

                    );
        }

        return items;

    }

    render() {
        var menuItems = this.getMenuItems();

        return <div>{menuItems}</div>;
    }
}

export default Menu;