import React from 'react';
import * as ajax from './../../ajax.js';

class InfoPanelCompanies extends React.Component {

   constructor(props) {
      super(props);
      this.state = {
         data: []
      };
   }

   componentDidMount() {
      this.loadData();
   }

   componentDidUpdate(prevProps) {
      if (prevProps.currentCompany !== this.props.currentCompany || prevProps.updateNeed !== this.props.updateNeed) {
         this.loadData();
      }
   }

   infoBox = function (value, color, openMenu) {
      if (typeof (openMenu) === 'function') {
         return (
            <div style={{
               backgroundColor: color,
               color: '#FFFFFFdd',
               paddingTop: 5,
               paddingBottom: 5,
               cursor: 'pointer',
               minWidth: '100%',
               maxWidth: '100%',
               boxShadow: '2px 2px 5px #000000aa',
               userSelect: 'none',
               margin: 'auto'
            }} onClick={openMenu}>
               &nbsp;&nbsp;&nbsp;{value}
            </div>
         );
      } else {
         return (
            <div style={{
               backgroundColor: color,
               color: '#FFFFFFdd',
               paddingTop: 5,
               paddingBottom: 5,
               minWidth: '100%',
               maxWidth: '100%',
               boxShadow: '2px 2px 5px #000000aa',
               userSelect: 'none',
               margin: 'auto'
            }}>
               &nbsp;&nbsp;&nbsp;{value}
            </div>
         );
      };
   }

   openBejovoIktatott = function () {
      this.props.setContent('bookingIncoming');
   }
   openKimenoIktatott = function () {
      this.props.setContent('bookingOutgoing');
   }
   openBankiIktatott = function () {
      this.props.setContent('bank');
   }
   openKimenoNavXML = function () {
      this.props.setContent('outgoing');
   }
   openElkuldott = function () {
      this.props.setContent('pages');
   }

   getTableRow = function (row) {

      return <tr key={row.id}>
         <td key={row.id + 'nev'} style={{ textAlign: 'left' }}>{this.infoBox(row.name, "#868686")}</td>

         <td key={row.id + 'bejovoIktatott'}>{this.infoBox(row.bejovoIktatott, "#9ec550", () => { this.setCompanyFilter(row.id, 'openBejovoIktatott') })}</td>
         <td key={row.id + 'kimenoIktatott'}>{this.infoBox(row.kimenoIktatott, "#00acee", () => { this.setCompanyFilter(row.id, 'openKimenoIktatott') })}</td>
         <td key={row.id + 'feltoltott'}>{this.infoBox(row.feltoltott, "#ff4e00", () => { this.setCompanyFilter(row.id, 'openKimenoNavXML') })}</td>
         <td key={row.id + 'elkuldott'}>{this.infoBox(row.elkuldott, "#23b14d", () => { this.setCompanyFilter(row.id, 'openElkuldott') })}</td>
      </tr>;
   }
   getTableRows = function (rows) {
      var tableData = [];
      for (var i = 0; i < rows.length; i++) {
         var tableRow = this.getTableRow(rows[i]);
         tableData.push(tableRow);
      }
      return tableData;
   }

   loadData = function () {
      try {
         const url = ajax.getUrlBase() + "modules/InfoPanelCompanies/InfoPanelCompaniesData.php";
         return fetch(url, {
            method: "POST",
            //credentials: "include",
            //credentials: "*", // Tesztüzemben
            headers: new Headers({ "Content-Type": "application/json" }),
            body: JSON.stringify({})
         }).then(response => {
            if (response.ok) {
               return response.json().then(response => {
                  this.setState({ data: response.data });
               }).catch(function (jsonError) {
                  if (null == jsonError) {
                     console.error("Fetch JSON error:");
                  } else {
                     console.error("Fetch JSON error:" + String(jsonError));
                  }
               });
            } else {
               console.error("HTTP status error:", response.statusText);
            }
         }).catch(function (error) {
            console.error("Fetch error:", error);
            if (null == error) {
               console.error("Fetch error:");
            } else {
               console.error("Fetch error:" + String(error));
            }
         });
      } catch (ex) {
         console.error("Fetch exception:", String(ex));
      }
   }

   setCompanyFilter = function (companyId, documentType) {
      var selectedCompanyId = companyId;

      try {
         const url = ajax.getUrlBase() + "/modules/CompanyManager/setCurrentCompany.php";

         return fetch(url, {
            method: "POST",
            //credentials: "include",
            //credentials: "*", // Tesztüzemben
            headers: new Headers({ "Content-Type": "application/json" }),
            body: JSON.stringify({ id: selectedCompanyId })
         }).then(response => {
            if (response.ok) {
               return response.json().then(response => {
                  this.props.setCurrentCompany(selectedCompanyId);
                  switch (documentType) {
                     case 'openBejovoIktatott':
                        this.openBejovoIktatott();
                        break;
                     case 'openKimenoIktatott':
                        this.openKimenoIktatott();
                        break;
                     case 'openKimenoNavXML':
                        this.openKimenoNavXML();
                        break;
                     case 'openElkuldott':
                        this.openElkuldott();
                        break;
                     default:
                        break;
                  }
               }).catch(function (jsonError) {
                  if (null == jsonError) {
                     console.error("Fetch JSON error:");
                  } else {
                     console.error("Fetch JSON error:" + String(jsonError));
                  }
               });
            } else {
               console.error("HTTP status error:", response.statusText);
            }
         }).catch(function (error) {
            console.error("Fetch error:", error);
            if (null == error) {
               console.error("Fetch error:");
            } else {
               console.error("Fetch error:" + String(error));
            }
         });
      } catch (ex) {
         console.error("Fetch exception:", String(ex));
      }
   }

   render() {
      var newHeight = window.innerHeight - 230;
      var tableData = this.getTableRows(this.state.data);

      return <div style={{ marginLeft: '10px' }}  >
         <div style={{
            maxWidth: '940px',
            margin: 'auto',
            justifyContent: 'center',
            maxHeight: '40px',
            minHeight: '40px',
            textAlign: 'center',
            backgroundColor: '#374350',
            color: '#adb3b8'
         }} >
            <h3 style={{ paddingTop: '5px', fontFamily: "'Titillium Web', sans-serif" }}>Infopanel</h3>
         </div>
         <div style={{ margin: 'auto', width: '100%' }}>
            <div style={{ display: 'flex', overflow: 'auto', maxHeight: newHeight, fontWeight: 'bold', marginTop: '3%', justifyContent: 'center' }}>
               <table style={{ maxWidth: '940px', textAlign: 'center' }}>
                  <thead>
                     <tr>
                        <th style={{ width: '40%' }}>Cég</th>
                        <th >Bejövő iktatott</th>
                        <th >Kimenő iktatott</th>
                        <th >Feltöltött</th>
                        <th >Elküldött</th>
                     </tr>
                  </thead>
                  <tbody>
                     {tableData}
                  </tbody>
               </table>
            </div>
         </div>
      </div>

   }
}

export default InfoPanelCompanies;