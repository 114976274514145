import React, { Component } from 'react';
import './../css/comboEditor.css';

class ComboEditor extends React.Component {

    constructor(props) {
        super(props);
        var id = this.props.editorProps.cellProps.data.id;
        var key = this.props.editorProps.cellProps.id;
        var currentValue = this.props.editorProps.cellProps.data[key];
        this.state = {
            value: currentValue
        }
    }

    getOptions = function () {
        var options = [];

        var id = this.props.editorProps.cellProps.data.id;
        var key = this.props.editorProps.cellProps.id;
        var currentValue = this.props.editorProps.cellProps.data[key];

        var values = this.props.values;

        for (var i = 0; i < values.length; i++) {
            var value = values[i];
            var selected = (currentValue == value.id);
            var component = <option 
                key={'option_'+key+"_"+id+"_" + value.id} 
                onMouseDown={this.onClick.bind(this)}
                value={value.id} 
                defaultValue={selected} >{value.label}</option>;
            options.push(component);
        }
        return options;
    }

    onChange = function (e) {

        var id = this.props.editorProps.cellProps.data.id;
        var key = this.props.editorProps.cellProps.id;
        var currentValue = e.target.value;

        this.state.value = currentValue
        this.props.state.defaultUpdateBlocked = true;
        this.props.callback(id, key, currentValue);
        

        this.props.editorProps.onTabNavigation(
                true /*complete navigation?*/,
                e.shiftKey ? -1 : 1 /*backwards of forwards*/
                );
        e.preventDefault();
        e.stopPropagation();
        return;

    }
    onClick = function (e) {
        var id = this.props.editorProps.cellProps.data.id;
        var key = this.props.editorProps.cellProps.id;
        var currentValue = e.target.value;

        this.state.value = currentValue
        this.props.state.defaultUpdateBlocked = true;
        this.props.callback(id, key, currentValue);

          this.props.editorProps.onTabNavigation(
                  true /*complete navigation?*/,
                  e.shiftKey ? -1 : 1 /*backwards of forwards*/
                  );
          e.preventDefault();
          e.stopPropagation();
          return;
    }
    onBlur=function(e){
        this.props.editorProps.onTabNavigation(
                true /*complete navigation?*/,
                e.shiftKey ? -1 : 1 /*backwards of forwards*/
                );
        e.preventDefault();
        e.stopPropagation();
        return;
    }

    render() {

        var id = this.props.editorProps.cellProps.data.id;
        var key = this.props.editorProps.cellProps.id;
        var value = this.props.editorProps.cellProps.data[key];

        var comboEditor = (this.props.blackTheme) ? "comboEditorDark" : "comboEditorLight";

        return <select 
            autoFocus
            onChange={this.onChange.bind(this)}
            onBlur={this.onBlur.bind(this)}
            id={'unique_id_' + key + '_' + id}
            key={'unique_' + key + '_' + id}
            className={comboEditor}
            value={value} 
            style={{minWidth: '100%'}} >{this.getOptions()}
        </select>

    }
}

export default ComboEditor;