import React from 'react';
import {ProjectDescriptor, FieldDescriptor, Layout}  from './../../utils.js';
import * as ajax from './../../ajax.js';
//import Panel from './../../Components/Panel';
import BasicForm from './../../Components/BasicForm';


class Limits extends React.Component {

    constructor(props) {
        super(props);
        var endpoint = ajax.getUrlBase() + 'modules/Limits/';
        var formTitle = 'Kvóták';
        var projectDescriptor = new ProjectDescriptor('Limits', endpoint, formTitle);
        projectDescriptor.deleteButtonVisible = false;
        projectDescriptor.newButtonVisible = false;
        projectDescriptor.filterButtonVisible = false;
        projectDescriptor.modifyButtonVisible = false;
        projectDescriptor.viewButtonVisible = false;
        projectDescriptor.viewXlsExportVisible = false;
        
        projectDescriptor.setFormDimensions(550, 290);


        var id = new FieldDescriptor(true, FieldDescriptor.TYPE_NUMBER, 'id', '#', '');
        id.editableTableField = false;
        var adoszam = new FieldDescriptor(false, FieldDescriptor.TYPE_TEXT, 'adoszam', 'Adószám', '');
        adoszam.setJump(2);
        adoszam.editableTableField = false;
        var name = new FieldDescriptor(false, FieldDescriptor.TYPE_TEXT, 'name', 'Cég neve', '');
        name.editableTableField = false;
        var db = new FieldDescriptor(false, FieldDescriptor.TYPE_TEXT, 'db', 'Iktatva', '');
        db.editableTableField = false;
        var archivedYearsCount = new FieldDescriptor(false, FieldDescriptor.TYPE_NUMBER, 'archivedYearsCount', 'Év limit', '');
        var companyMaxDocumentCount = new FieldDescriptor(false, FieldDescriptor.TYPE_NUMBER, 'companyMaxDocumentCount', 'Doku. limit', '');

        projectDescriptor.addFieldDescriptor(id);
        projectDescriptor.addFieldDescriptor(adoszam);
        projectDescriptor.addFieldDescriptor(name);
        projectDescriptor.addFieldDescriptor(db);
        projectDescriptor.addFieldDescriptor(companyMaxDocumentCount);
        projectDescriptor.addFieldDescriptor(archivedYearsCount);

        var layout = new Layout();
        layout.addColumns([0, 120, 270, 400])
        layout.addRows([0, 34, 68, 102, 136])
        this.projectDescriptor = projectDescriptor;
        this.layout = layout;
    }

    render() {
        return <BasicForm 
        isFilterVisible={false}
        blackTheme={this.props.blackTheme} 
        alertDialog={this.props.alertDialog.bind(this)}  
        layout={this.layout} 
        projectDescriptor={this.projectDescriptor} />
    }
}

export default Limits;