// Iktatva 
import React, { useState, useCallback }
from 'react'
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import '@inovua/reactdatagrid-community/base.css';

import '@inovua/reactdatagrid-community/theme/default-dark.css';
import '@inovua/reactdatagrid-community/theme/default-light.css';

import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter';
import SelectFilter from '@inovua/reactdatagrid-community/SelectFilter';
import DateFilter from '@inovua/reactdatagrid-community/DateFilter';
import BoolFilter from '@inovua/reactdatagrid-community/BoolFilter'
//import DateEditor from '@inovua/reactdatagrid-community/DateEditor';
import DateEditor from './../../Components/DateEditor';
import NumericEditor from '@inovua/reactdatagrid-community/NumericEditor';
//import SelectEditor from '@inovua/reactdatagrid-community/SelectEditor';
import ComboEditor from './../../Components/ComboEditor';
import BooleanEditor from './../../Components/BooleanEditor';
import * as ajax from './../../ajax';
import PagingToolbar from './../../Components/PagingToolbar';
// https://reactdatepicker.com/
import DatePicker from "react-datepicker";
import {registerLocale, setDefaultLocale} from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import hu from 'date-fns/locale/hu';
import calendar_ico from "./../../img/white/edit_calendar_white_24dp.svg";
import greenCircle from "./../../img/green_circle.svg";

import MultiFileChooser from './../../Components/MultiFileChooser'

import multiUploadWhite from './../../img/white/upload_file_white_24dp.svg'
import DeleteIconWhite from './../../img/white/delete_white_24dp.svg';
import AddWhiteIconWhite from './../../img/white/add_white_24dp.svg';
import FilterIconWhite from './../../img/white/filter_alt_white_24dp.svg';
import AtadasKonyvelesreWhite from './../../img/white/post_add_white_24dp.svg';
import NavConnectionWhite from './../../img/white/cloud_download_white_24dp.svg';
import PreviewWhite from './../../img/white/preview_white_24dp.svg';
import DetailsBlack from './../../img/black/description_black_24dp.svg';
import DetailsWhite from './../../img/white/description_white_24dp.svg';


import multiUploadBlack from './../../img/black/upload_file_black_24dp.svg'
import DeleteIconBlack from './../../img/black/delete_black_24dp.svg';
import AddWhiteIconBlack from './../../img/black/add_black_24dp.svg';
import FilterIconBlack from './../../img/black/filter_alt_black_24dp.svg';
import AtadasKonyvelesreBlack from './../../img/black/post_add_black_24dp.svg';
import NavConnectionBlack from './../../img/black/cloud_download_black_24dp.svg';
import PreviewBlack from './../../img/black/preview_black_24dp.svg';

import saveBlack from './../../img/black/save_black_24dp.svg';
import saveWhite from './../../img/white/save_white_24dp.svg';

import bankiImportBlack from './../../img/black/account_balance_black_24dp.svg';
import bankiImportWhite from './../../img/white/account_balance_white_24dp.svg';

import checkBoxActiveBlack from './../../img/black/check_box_black_24dp.svg';
import checkBoxActiveWhite from './../../img/white/check_box_white_24dp.svg';

import checkBoxInactiveBlack from './../../img/black/check_box_outline_blank_black_24dp.svg';
import checkBoxInactiveWhite from './../../img/white/check_box_outline_blank_white_24dp.svg';

import DeleteConfirmationDialog from './../../Components/DeleteConfirmationDialog';
import BankiImportDialog from './../../Components/BankiImportDialog';
import BankszamlaszamFrissitesDialog from './../../Components/YesNoDialog';

import PopUpWindow from './../../Components/PopUpWindow';
import QueryInvoiceDigestLinesTable from './../../modules/QueryInvoiceDigestLines/QueryInvoiceDigestLinesTable';

import "./../../css/datePicker.css";
import "./../../css/gridFilter.css";


import moment from 'moment';
import 'moment/locale/hu';
moment().locale('hu');

registerLocale('hu', hu);



class BookingTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            columnOrder: ["checkbox", "kiegyenlitettSzamla", "id", "id_by_type", "FILENEVE", "selectedMonth", "SZALLITO", "Status", "IKT_DATE", "KONYDATE", "DOKUID", "eredetiSzamla", "workNumber", "GROSVAL", "CURRENC", "FIZMOD", "DATUM", "TELJESIT", "HATIDO", "AFATELJ", "SZALADO", "SZALIRSZ", "SZALCITY", "SZALUTCA", "AFAMENT", "AFAKULC1", "TETELNE1", "TETELAFA1", "TETELBR1", "AFAKULC2", "TETELNE2", "TETELAFA2", "TETELBR2", "AFAKULC3", "TETELNE3", "TETELAFA3", "TETELBR3", "MEGJEGYZ", "FORDAFA", "sharedDoc", "kiegyenlitesDatuma", "kiegyenlitettOsszeg", "kiegyenlitetlenOsszeg", "szallitoBankszamlaszam"],
            multiFileChooserVisible: false,
            filesArray: [],
            showDeleteConfirmationDialog: false,
            gridRef: null,
            defaultUpdateBlocked: false,
            isFilterVisible: true,
            dateEditor: {
                active: false,
                id: null,
                key: null,
                value: null,
                editorProps: null
            },
            requestProps: null,
            skip: 0,
            loading: false,
            page: 1,
            selectedRow: -1,
            prevTableData: null,
            paging: {
                totalPageCount: 0,
                currentPage: 0,
                pageRowCount: 100,
                showingRowFrom: 0,
                showingRowTo: 5,
                totalRowCount: 0,
                skip: 0
            }
        }

        this.setColumnOrder();
        this.multiSelection = [];
        this.gridData = [];

        this.data = [];
        this.userType = null;
        this.buttonDeleteRef = React.createRef();
        this.buttonModifyRef = React.createRef();
        this.bankiImportDialogVisible = false;
        this.bankiImportMessage = "";

        // Bankszámlaszám frissítése a partnernél
        this.showSzallitoBankszamlaszamDialog = false;
        this.prevSzallitoBankszamlaszam = "";
        this.nextSzallitoBankszamlaszam = "";
        this.bankszamlaszamRecordId = -1; // Nem tudjuk lekérdezni a partnerId-t ezért a rekordId alapján határozzuk meg szerveroldalon
        this.isBankiImportEnabled = true;
        this.queryInvoiceDigestLinesTableVisible = false;
        this.queryInvoiceDigestLinesKonyvelesTetel_id = -1;
        this.instantInvoiceLinesFromNav = false; // A "Tétel szűrés" gomb megjelenítését szabályozza.
        this.instantInvoiceLinesFromNavRef = React.createRef();

        this.tarsitottFilter = 0;

        this.bankiImportSelectAllRef = React.createRef();
        this.bankiImportSelectNoneRef = React.createRef();

    }

    isUserTypeCompany = function () {

        if (typeof this.props.parentState.userType == 'undefined') {
            return false;
        }
        if (this.props.parentState.userType == null) {
            return false;
        }
        if (this.props.parentState.userType != 'COMPANY') {
            return false;
        }
        return true;
    }

    reload = function (skip) {
        if (this.state.dateEditor.editorProps) {
            this.state.dateEditor.editorProps.onComplete();
        }
        this.state.paging.skip = skip;
        this.setState({dateEditor: {
                active: this.state.dateEditor.active,
                id: null,
                key: null,
                value: null,
                editorProps: null,
                defaultUpdateBlocked: false
            }});
    }

    onSelectionChange = function (selection) {

        this.state.defaultUpdateBlocked = true;
        this.state.selectedRow = selection.data.id;
        this.props.setSelectedRowId(selection.data.id);

        return true;
    }

    getPositionById = function (id) {
        if (typeof this.state.prevTableData == 'undefined') {
            return -1;
        }

        if (this.state.prevTableData == null) {
            return -1;
        }

        if (typeof this.state.prevTableData.data == 'undefined') {
            return -1;
        }
        if (this.state.prevTableData.data.length == 0) {
            return -1;
        }

        var prevTableData = this.state.prevTableData.data;

        for (var i = 0; i < prevTableData.length; i++) {
            if (prevTableData[i].id == id) {
                return i * 1;
            }
        }
    }

    getIdByPosition = function (pos) {

        if (typeof this.state.prevTableData == 'undefined') {
            return -1;
        }

        if (this.state.prevTableData == null) {
            return -1;
        }

        if (typeof this.state.prevTableData.data == 'undefined') {
            return -1;
        }
        if (this.state.prevTableData.data.length == 0) {
            return -1;
        }

        if (pos > this.state.prevTableData.data.length) {
            return -1;
        }
        if (pos < 0) {
            return -1;
        }

        var prevTableData = this.state.prevTableData.data;

        for (var i = 0; i < prevTableData.length; i++) {
            if (pos == i) {
                return prevTableData[i].id;
            }
        }
    }

    suggestSelection = function (id) {
        var intId = id * 1;
        var deletedPosition = this.getPositionById(intId);
        var prevId = this.getIdByPosition(deletedPosition - 1);
        var nextId = this.getIdByPosition(deletedPosition + 1);

        if (typeof nextId != 'undefined' && nextId != -1) {
            return nextId;
        }
        if (typeof prevId != 'undefined' && prevId != -1) {
            return prevId;
        }
        return -1;
    }

    deleteRecord = function () {
        if (this.state.selectedRow == -1) {
            return false;
        }
        try {
            const url = ajax.getUrlBase() + "modules/Booking/delete.php";

            return fetch(url, {
                method: "POST",
                //credentials: "include",
                //credentials: "*", // Tesztüzemben
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({id: this.state.selectedRow})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success == false) {
                            this.props.alertDialog(response.fail);
                        }
                        this.state.selectedRow = this.suggestSelection(this.state.selectedRow);
                        this.reload(this.state.paging.skip);
                        this.setState({showDeleteConfirmationDialog: false});
                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }
    }

    insertRecord = function () {
        try {
            const url = ajax.getUrlBase() + "modules/Booking/insert.php";
            return fetch(url, {
                method: "POST",
                //credentials: "cors",
                //credentials: "*", // Tesztüzemben
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({invoiceDirection: this.props.invoiceDirection})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success == false) {
                            this.props.alertDialog(response.fail);
                        } else {
                            this.props.parentState['lastInsertId'] = response.data.lastInsertId;
                            this.state.selectedRow = response.data.lastInsertId;
                            this.props.setSelectedRowId(response.data.lastInsertId);
                            this.props.openInsertBookingForm(this.state.requestProps);
                        }
                        //this.reload(this.state.paging.skip);
                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }
    }

    updateRecord = function (id, key, value) {

        if (key == "szallitoBankszamlaszam" && this.prevSzallitoBankszamlaszam == "" && value != null && value.trim() != '') {

            for (var i = 0; i < this.gridData.length; i++) {
                var gridRow = this.gridData[i];
                var gridRow_id = gridRow.id;
                if (gridRow_id == id) {

                    this.showSzallitoBankszamlaszamDialog = true;
                    this.prevSzallitoBankszamlaszam = "";
                    this.nextSzallitoBankszamlaszam = value;
                    this.bankszamlaszamRecordId = id;
                }
            }
        }

        try {
            const url = ajax.getUrlBase() + "modules/Booking/update.php";
            var reload = this.reload.bind(this);
            var skip = this.state.paging.skip;
            return fetch(url, {
                method: "POST",
                //credentials: "include",
                //credentials: "*", // Tesztüzemben
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({invoiceDirection: this.props.invoiceDirection, id: id, key: key, value: value})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success == false) {
                            this.props.alertDialog(response.fail);
                        }
                        this.props.parentState['lastInsertId'] = -1;
                        reload(skip);
                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");

                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));

                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);

                }
            }).catch(function (error) {
                console.error("Fetch error:", error);

                if (null == error) {
                    console.error("Fetch error:");

                } else {
                    console.error("Fetch error:" + String(error));


                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));

        }
    }

    customPaging = function (pagingProps) {
        return <PagingToolbar blackTheme={this.props.blackTheme} tableReload={this.reload.bind(this)} paging={this.state.paging} />
    }

    showCalendar = function (editorProps) {

        this.setState({dateEditor: {
                active: true,
                id: editorProps.cell.props.data.id,
                key: editorProps.cell.props.id,
                editorProps: editorProps,
                value: null
            }});
    }

    editingEnd = function () {
        this.state.defaultUpdateBlocked = false;
        this.state.dateEditor.editorProps.onComplete();

    }

    myDateEditor = function (editorProps) {
        return <DateEditor 
            editorState={this.state.dateEditor} 
            editorProps ={editorProps} 
            showCalendar={this.showCalendar.bind(this)} 
            updateRecord={this.updateRecord.bind(this)}
            state={this.state}
            />
    }

    dateRendererClick = function (data) {
        this.state.dateEditor.value = data;
        this.state.defaultUpdateBlocked = true;
    }

    calendarCancel = function (e) {
        this.state.defaultUpdateBlocked = true;
        if (this.state.dateEditor.editorProps) {
            this.state.dateEditor.editorProps.onComplete();
        }
        this.state.dateEditor.active = false;
        this.setState({});
    }

    setFilterVisible = function () {
        this.setState({isFilterVisible: !this.state.isFilterVisible});
    }

    findLabelById = function (array, id) {

        for (var i = 0; i < array.length; i++) {
            var obj = array[i];
            if (obj.id == id) {
                return obj.label;
            }
        }
        return "";
    }

    setGridRef = function (gridRef) {
        this.state.gridRef = gridRef;
    }

    onCellSelectionChanged = function (cell) {
        if (typeof cell == 'undefined') {
            return false;
        }
        if (cell == null) {
            return false;
        }
        var keys = Object.keys(cell);
        for (var i = 0; i < keys.length; i++) {
            var key = keys[i];
            if (typeof key != 'undefined' && key != null) {
                var idColName = key.split(',');
                if (idColName.length == 2) {
                    var id = idColName[0];

                    this.state.selectedRow = id;
                    this.props.setSelectedRowId(id);
                }
            }
        }
    }

    multiUploadFileChooserOpen = function () {
        this.setState({multiFileChooserVisible: true});
    }
    multiUploadFileChooserClose = function () {
        this.setState({multiFileChooserVisible: false});
    }

    multiUploadFileChooserSet = function (files) {
        this.state.filesArray = files;
        this.multiUploadFileChooserClose();
    }

    isSelectedRecordExists = function (data, id) {
        if (typeof data == 'undefined') {
            return false;
        }

        if (typeof data.length == 'undefined') {
            return false;
        }
        if (data.length == 0) {
            return false;
        }

        if (typeof id == 'undefined' || id == null || id == -1) {
            return false;
        }

        for (var i = 0; i < data.length; i++) {
            var record = data[i];
            if (record.id == id) {
                return true;
            }
        }
        return false;
    }

    onColumnResize = function (resized) {

        var projectName = 'BookingTable';
        localStorage.setItem(projectName + '#' + resized.column.name, resized.width);
    }

    getColumnWidth = function (columnName, defaultSize) {
        var projectName = 'BookingTable';
        var storedSize = localStorage.getItem(projectName + "#" + columnName);
        storedSize = storedSize;
        return (storedSize != null) ? storedSize * 1.0 : defaultSize;
    }

    onColumnOrderChange = function (ordered) {
        localStorage.setItem('BookingTable#customColumnOrder', ordered);
    }

    setColumnOrder = function () {

        var storedOrder = localStorage.getItem('BookingTable#customColumnOrder');

        if (typeof storedOrder == 'undefined') {
            storedOrder = '';
        }

        if (storedOrder == null) {
            storedOrder = '';
        }
        if (storedOrder == '') {
            storedOrder = '';
        }

        var storedOrderArray = storedOrder.split(",");

        if (storedOrderArray.length == this.state.columnOrder.length) {
            this.state.columnOrder = storedOrderArray;
        }
    }

    myColumnsReorder = function (columns) {
        var orderedColumns = [];
        var order = this.state.columnOrder;

        for (var o = 0; o < order.length; o++) {
            for (var c = 0; c < columns.length; c++) {
                var column = columns[c];
                if (order[o] == column.name) {
                    orderedColumns.push(column);
                }
            }
        }

        return orderedColumns;
    }

    showDeleteConfirmation = function () {
        if (this.state.selectedRow != -1) {
            this.setState({showDeleteConfirmationDialog: true});
        } else {
            this.props.alertDialog("Kérem jelölje ki azt a sort a táblázatban, amit törölni szeretne.");
        }
    }
    cancelDeleteConfirmation = function () {
        this.setState({showDeleteConfirmationDialog: false});
    }

    dateToDotted = function (dateStr) {
        if (typeof dateStr == "undefined") {
            return '';
        }
        if (dateStr == null) {
            return '';
        }
        return  dateStr.replaceAll("-", ".");
    }

    exportToXls = function () {

        var state = this.state;
        var skip = this.state.requestProps.skip;
        var limit = this.state.requestProps.limit;
        var sortInfo = this.state.requestProps.sortInfo;
        var filterValue = this.state.requestProps.filterValue;

        try {
            const url = ajax.getUrlBase() + "modules/Booking/xls.php";
            state.update = false;
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({skip: skip, limit: limit, sortInfo: sortInfo, filterValue: filterValue, invoiceDirection: this.props.invoiceDirection})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        var data = response.data;

                        window.open(data.file, '_blank');
                    }).catch(function (jsonError) {
                        this.state.defaultUpdateBlocked = false;
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    this.state.defaultUpdateBlocked = false;
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            this.state.defaultUpdateBlocked = false;
            console.error("Fetch exception:", String(ex));
        }
    }

    onActiveCellChange = function (selection) {
        const grid = this.state.gridRef.current;
        var rowIndex = selection[0];
        if (typeof grid.data[rowIndex] != 'undefined') {
            if (this.state.selectedRow !== grid.data[rowIndex].id) {
                this.queryInvoiceDigestLinesKonyvelesTetel_id = -1;
            }
            var selectedRowId = grid.data[rowIndex].id;
            this.state.selectedRow = selectedRowId;
            this.setButtonsDisabled();
            this.props.setSelectedRowId(selectedRowId);
            this.prevSzallitoBankszamlaszam = grid.data[rowIndex].szallitoBankszamlaszam;

        }
    }

    isCellEditable = function (editorProps) {
        var invoiceState = editorProps.data.Status;
        var isCompanyBlocked = invoiceState == 4; // Könyvelve
        var isUserCompany = this.isUserTypeCompany();

        if (isUserCompany == false) {
            return true;
        }
        if (isCompanyBlocked == true) {
            return false;
        }
        return true;

    }

    // A multiselect funkciók 
    isSelected = function (id) {
        const index = this.multiSelection.indexOf(id);
        if (index > -1) {
            return true;
        }
        return false;
    }

    isCheckboxSelected = function (id) {
        return this.multiSelection.indexOf(id) > -1;
    }

    handleCheckBox = function (e) {
        var lastSelectedId = e.target.id * 1;
        const index = this.multiSelection.indexOf(lastSelectedId);
        if (index > -1) {
            this.multiSelection.splice(index, 1);
        } else {
            this.multiSelection.push(lastSelectedId);
        }
        this.forceUpdate();
    }

    checkAll = function (e) {

        var action = e.target.id;
        if (action == "remove") {
            for (var i = 0; i < this.gridData.length; i++) {
                var gridRow = this.gridData[i];
                var id = gridRow.id;
                const index = this.multiSelection.indexOf(id);
                if (index > -1) {
                    this.multiSelection.splice(index, 1);
                }

            }
        }
        if (action == "add") {
            for (var i = 0; i < this.gridData.length; i++) {
                var gridRow = this.gridData[i];
                var id = gridRow.id;
                if (this.multiSelection.indexOf(id) == -1) {
                    this.multiSelection.push(id);
                }
            }
        }
        this.forceUpdate();
    }

    setButtonsDisabled = function () {
        if (this.state.selectedRow == null) {
            this.buttonDeleteRef.current.disabled = false;
            this.buttonModifyRef.current.disabled = false;
            return false;
        }
        if (this.state.selectedRow == '') {
            this.buttonDeleteRef.current.disabled = false;
            this.buttonModifyRef.current.disabled = false;
            return false;
        }

        if (typeof this.data == 'undefined') {
            this.buttonDeleteRef.current.disabled = false;
            this.buttonModifyRef.current.disabled = false;
            return false;
        }
        if (this.data == null) {
            this.buttonDeleteRef.current.disabled = false;
            this.buttonModifyRef.current.disabled = false;
            return false;
        }

        if (this.data.length == 0) {
            this.buttonDeleteRef.current.disabled = false;
            this.buttonModifyRef.current.disabled = false;
            return false;
        }

        for (var i = 0; i < this.data.length; i++) {
            if (this.data[i].id == this.state.selectedRow) {
                if (this.data[i].Status > 2) { // Átadva

                    if (this.userType == 'COMPANY' || this.userType == 'OPERATOR') {
                        this.buttonDeleteRef.current.disabled = true;
                        this.buttonModifyRef.current.disabled = true;
                        return false;

                    }
                }
            }
        }

        this.buttonDeleteRef.current.disabled = false;
        this.buttonModifyRef.current.disabled = false;
        return false;
    }

    showBankiImportDialog = function () {
        this.bankiImportDialogVisible = true;
        this.forceUpdate();
    }

    hideBankiImportDialog = function () {
        this.bankiImportDialogVisible = false;
        this.forceUpdate();
    }

    createBankiImport = function (terhelesDatuma, filetype) {
        try {
            const url = ajax.getUrlBase() + "UGIRO/Import.php";
            return fetch(url, {
                method: "POST",
                //credentials: "include",
                //credentials: "*", // Tesztüzemben
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({terhelesDatuma: terhelesDatuma, szamlak: this.multiSelection, filetype: filetype})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success == false) {
                            this.props.alertDialog(response.fail);
                        } else {
                            this.bankiImportMessage = "A banki import elkészült.";
                            var filename = response.data['filename'];
                            //window.open(ajax.getUrlBase() + "UGIRO/imports/"+filename);
                            this.importFileUrl = ajax.getUrlBase() + "UGIRO/imports/" + filename;
                            this.importFileName = filename;
                            this.forceUpdate();


                        }
                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");

                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));

                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);

                }
            }).catch(function (error) {
                console.error("Fetch error:", error);

                if (null == error) {
                    console.error("Fetch error:");

                } else {
                    console.error("Fetch error:" + String(error));


                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));

        }

    }

    bankiImportLetoltese = function () {
        if (this.importFileUrl == null) {
            return null;
        }

        if (this.importFileUrl == '') {
            return null;
        }

        if (this.importFileName == null) {
            return null;
        }

        if (this.importFilename == '') {
            return null;
        }
        this.mentesURLrol(this.importFileUrl, this.importFileName);

    }

    mentesURLrol = async function (fileUrl, fajlnev) {

        try {
            // Letöltés indítása
            var link = document.createElement("a");
            link.href = fileUrl;
            link.target = '_blank';
            link.download = fajlnev; // A letöltött fájl neve
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            this.multiSelection = [];
            this.bankiImportDialogVisible = false;
            this.forceUpdate();
            this.importFileUrl = '';
            this.importFileName = '';
            this.bankiImportMessage = '';
        } catch (error) {
            console.error("Hiba történt a fájl letöltése közben:", error);
        }
    }

    updateSzallitoBankszamlaszam = function (id, szallitoBankszamlaszam) {

        try {
            const url = ajax.getUrlBase() + "modules/Partner/updateSzallitoBankszamlaszam.php";
            return fetch(url, {
                method: "POST",
                //credentials: "include",
                //credentials: "*", // Tesztüzemben
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({id: id, szallitoBankszamlaszam: szallitoBankszamlaszam})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success == false) {
                            this.props.alertDialog(response.fail);
                        } else {
                            this.showSzallitoBankszamlaszamDialog = false;
                            this.prevSzallitoBankszamlaszam = "";
                            this.nextSzallitoBankszamlaszam = "";
                            this.bankszamlaszamRecordId = -1; // Nem tudjuk lekérdezni a partnerId-t ezért a rekordId alapján határozzuk meg szerveroldalon
                            this.forceUpdate();

                        }
                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");

                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));

                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);

                }
            }).catch(function (error) {
                console.error("Fetch error:", error);

                if (null == error) {
                    console.error("Fetch error:");

                } else {
                    console.error("Fetch error:" + String(error));


                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));

        }


    }

    setBack = function (queryInvocieDigest_id) {

    }

    showNavTetelek = function () {
        this.queryInvoiceDigestLinesTableVisible = true;
        this.setState({});
    }

    hideNavTetelek = function () {
        this.queryInvoiceDigestLinesTableVisible = false;
        this.setState({});
    }

    invoiceChooser = function (queryInvoiceDigestLinesKonyvelesTetel_id) {
        this.queryInvoiceDigestLinesTableVisible = false;
        this.queryInvoiceDigestLinesKonyvelesTetel_id = queryInvoiceDigestLinesKonyvelesTetel_id;
        this.setState({});
    }

    onRenderRow = function (rowProps) {
        if (rowProps.data.id == this.queryInvoiceDigestLinesKonyvelesTetel_id) {
            rowProps.style.color = '#3370a6';
            rowProps.style.fontWeight = 'bold';
        }
    }

    render() {

        const documentStates = [{id: 1, label: 'Iktatva'}, {id: 2, label: 'Ellenőrizve'}, {id: 3, label: 'Átadva'}, {id: 4, label: 'Könyvelve'}, {id: 5, label: 'NAV 3.0 nem található'}, {id: 6, label: 'Függő'}, {id: 7, label: 'Nem könyvelhető'}];
        const currencies = [{id: 1, label: 'HUF'}, {id: 2, label: 'EUR'}, {id: 3, label: 'USD'}];
        const fizmodok = [{id: 1, label: 'Készpénz'}, {id: 2, label: 'Átutalás'}, {id: 3, label: 'Bankkártya'}, {id: 4, label: 'Egyéb'}];
        const afakulcsok = [{id: 1, label: '27%'}, {id: 2, label: '18%'}, {id: 3, label: '5%'}];
        const booleanComboFordafa = [{id: 0, label: 'Nem'}, {id: 1, label: 'Igen'}];
        const booleanComboMegosztva = [{id: 0, label: 'Nem'}, {id: 1, label: 'Igen'}];
        const selectedMonths = [
            {id: 0, label: 'Nincs kiválasztva'},
            {id: 1, label: 'Január'},
            {id: 2, label: 'Február'},
            {id: 3, label: 'Március'},
            {id: 4, label: 'Április'},
            {id: 5, label: 'Május'},
            {id: 6, label: 'Június'},
            {id: 7, label: 'Július'},
            {id: 8, label: 'Augusztus'},
            {id: 9, label: 'Szeptember'},
            {id: 10, label: 'Október'},
            {id: 11, label: 'November'},
            {id: 12, label: 'December'}
        ];
        var newHeight = window.innerHeight - 180;

        const gridStyle = {minHeight: newHeight, fontFamily: 'Arial'};
        var findLabelById = this.findLabelById.bind(this);
        var state = this.state;
        var hasSelectedRecord = (typeof this.multiSelection != 'undefined' && this.multiSelection != null && Array.isArray(this.multiSelection) && this.multiSelection.length > 0) ? true : false;
        var bankiImportDialog = (this.bankiImportDialogVisible) ? <BankiImportDialog 
            hideBankiImportDialog={this.hideBankiImportDialog.bind(this)} 
            createBankiImport ={this.createBankiImport.bind(this)}
            bankiImportLetoltese={this.bankiImportLetoltese.bind(this)}
            message = {this.bankiImportMessage}
            /> : '';
        var bankszamlaszamFrissitesDialog = (this.showSzallitoBankszamlaszamDialog == false) ? '' : <BankszamlaszamFrissitesDialog 
            yesTitle={"Felülírom"}
            noTitle={"Kézzel javítom"}
            onYes={() => {
                            this.updateSzallitoBankszamlaszam(this.bankszamlaszamRecordId, this.nextSzallitoBankszamlaszam);
                        }}  
        
            onNo={() => {
                            this.showSzallitoBankszamlaszamDialog = false;
                            this.prevSzallitoBankszamlaszam = "";
                            this.nextSzallitoBankszamlaszam = "";
                            this.bankszamlaszamRecordId = -1; // Nem tudjuk lekérdezni a partnerId-t ezért a rekordId alapján határozzuk meg szerveroldalon
                            this.forceUpdate();
                        }}  
            > A partner bankszámlaszámával felülírja-e az aktuális bankszámlaszámot, vagy kézzel javítja?</BankszamlaszamFrissitesDialog>;
        var booleanEditorStyleDark = {
            cursor: 'pointer',
            position: 'absolute',
            background: '#464d56',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            paddingLeft: 8,
            paddingTop: 9
        };

        var booleanEditorStyleLight = {
            cursor: 'pointer',
            position: 'absolute',
            background: '#FFFFFF',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            paddingLeft: 8,
            paddingTop: 9
        };

        var booleanEditorStyle = (this.props.blackTheme) ? booleanEditorStyleDark : booleanEditorStyleLight;


        var darkButtonStyle = {
            backgroundColor: '#475360',
            color: '#FFFFFFaa',
            border: 'solid 0px #FFFFFF',
            padding: '5px',
            margin: '5px',
            boxShadow: '1px 1px 2px #000000',
            cursor: 'pointer'
        };
        var lightButtonStyle = {

            backgroundColor: '#FFFFFF',
            color: '#000000aa',
            border: 'solid 0px #000000',
            padding: '5px',
            margin: '5px',
            boxShadow: '1px 1px 2px #000000aa',
            cursor: 'pointer'

        };




        var nevTitle = (this.props.invoiceDirection == 'OUTBOUND') ? 'Vevő neve' : 'Szállító neve';
        var adoszamTitle = (this.props.invoiceDirection == 'OUTBOUND') ? 'Vevő adószáma' : 'Szállító adószáma';
        var irszTitle = (this.props.invoiceDirection == 'OUTBOUND') ? 'Vevő ir.szám' : 'Szállító ir.szám';
        var cityTitle = (this.props.invoiceDirection == 'OUTBOUND') ? 'Vevő helység' : 'Szállító helység';
        var utcaHszTitle = (this.props.invoiceDirection == 'OUTBOUND') ? 'Vevő címe' : 'Szállító címe';

        var visibleInInbound = this.props.invoiceDirection == 'INBOUND';


        const columns = [
            {name: 'checkbox', sortable: false,
                header: () => {
                    return <div style={{display: "flex"}}>
                
                </div>
                },
                render: ({ data }) => {
                    return <input 
                        id={data.id}
                        key={"checkbox_key_" + data.id} 
                        type="checkbox" 
                        onChange={this.handleCheckBox.bind(this)}
                        checked={this.isCheckboxSelected(data.id)}
                        style={{cursor: 'pointer'}}
                        />
                },
                align: 'center',
                filterDelay: 1000,
                defaultWidth: 30,
                type: 'number',
                visible: visibleInInbound
            },
            {name: 'kiegyenlitettSzamla', visible: visibleInInbound, header: 'Jel', filterDelay: 1000, defaultWidth: this.getColumnWidth('kiegyenlitettSzamla', 75),
                filterEditor: BoolFilter,
                editable: true,
                render: ({value}) => {

                    return (value == 0) ? <div></div> : <div style={{width: '100%', textAlign: 'center'}}><img src={greenCircle}/></div>;
                }
            },

            {name: 'id', header: '#', editable: false, type: 'number', filterDelay: 1000, defaultWidth: this.getColumnWidth('id', 120)},
            {name: 'id_by_type', editable: false, header: 'Iktatószám', type: 'number', filterDelay: 1000, defaultWidth: this.getColumnWidth('id', 120)},

            {name: 'FILENEVE', header: 'Fájl',
                editable: (editValue, cellProps) => {
                    var editable = this.isCellEditable(cellProps);
                    return Promise.resolve(editable)
                },

                filterDelay: 1000, defaultWidth: this.getColumnWidth('FILENEVE', 150)},

            {name: 'SZALLITO', header: nevTitle, filterDelay: 1000, defaultWidth: this.getColumnWidth('SZALLITO', 150)
                , editable: (editValue, cellProps) => {
                    var editable = this.isCellEditable(cellProps);
                    return Promise.resolve(editable)
                }

            },
            {name: 'Status', header: 'Státusz', filterDelay: 1000, defaultWidth: this.getColumnWidth('Status', 150),
                editable: (editValue, cellProps) => {
                    var editable = this.isCellEditable(cellProps);
                    return Promise.resolve(editable)
                },

                renderEditor: (editorProps) => {
                    var id = editorProps.cellProps.data.id;
                    var key = editorProps.cellProps.id;
                    return <div style={{width: '100%'}} key={"combo" + key + "_" + id}>
                <ComboEditor 
                    blackTheme={this.props.blackTheme}
                    callback={ this.updateRecord.bind(this) } 
                    values={documentStates} 
                    editorProps={editorProps}
                    state={state} 
            
                    />
            </div>
                },
                render: ({value}) => {
                    return this.findLabelById(documentStates, value);
                },
                filterEditor: SelectFilter,
                filterEditorProps: {
                    multiple: true,
                    wrapMultiple: false,
                    dataSource: documentStates.map(c => {
                        return {id: c.id, label: c.label}
                    }),
                }
            },
            {name: 'IKT_DATE', header: 'Iktatás dátuma', filterDelay: 1000, defaultWidth: this.getColumnWidth('IKT_DATE', 150),
                editable: (editValue, cellProps) => {
                    var editable = this.isCellEditable(cellProps);
                    return Promise.resolve(editable)
                },
                dateFormat: 'YYYY-MM-DD',
                filterEditor: DateFilter,
                filterEditorProps: (props, { index }) => {
                    return {
                        dateFormat: 'YYYY-MM-DD',
                        cancelButton: false,
                        highlightWeekends: false,
                        placeholder: index == 1 ? 'Előtte' : 'Utána'
                }
                },
                render: ({data}) => {
                    return <div  onMouseDown={() => {
                                                this.dateRendererClick(data.IKT_DATE)
                                                  }}>{this.dateToDotted(data.IKT_DATE)}</div>
                },
                editor: this.myDateEditor.bind(this)
            },
            {name: 'KONYDATE', header: 'Könyvelés dátuma', filterDelay: 1000, defaultWidth: this.getColumnWidth('KONYDATE', 150),
                visible: (typeof this.userType == 'undefined' || this.userType == null || this.userType === 'COMPANY' || this.userType === 'OPERATOR') ? false : true,
                editable: (editValue, cellProps) => {
                    var editable = this.isCellEditable(cellProps);
                    return Promise.resolve(editable)
                },
                dateFormat: 'YYYY-MM-DD',
                filterEditor: DateFilter,
                filterEditorProps: (props, { index }) => {
                    return {
                        dateFormat: 'MM-DD-YYYY',
                        cancelButton: false,
                        highlightWeekends: false,
                        placeholder: index == 1 ? 'Előtte' : 'Utána'
                }
                },
                render: ({ value }) => {
                    return <div>{this.dateToDotted(value)}</div>
                },
                editor: this.myDateEditor.bind(this)
            },
            {name: 'DOKUID', header: 'Számlaszám', filterDelay: 1000, defaultWidth: this.getColumnWidth('DOKUID', 150),
                editable: (editValue, cellProps) => {
                    var editable = this.isCellEditable(cellProps);
                    return Promise.resolve(editable)
                }
            },

            {name: 'eredetiSzamla', header: 'Eredeti számlaszám', filterDelay: 1000, defaultWidth: this.getColumnWidth('eredetiSzamla', 150),
                editable: (editValue, cellProps) => {
                    var editable = this.isCellEditable(cellProps);
                    return Promise.resolve(editable)
                }

            },
            {name: 'workNumber', header: 'Munkaszám', filterDelay: 1000, defaultWidth: this.getColumnWidth('workNumber', 150), editable: false},
            {name: 'GROSVAL', header: 'Bruttó összes érték', type: 'text', filterEditor: NumberFilter, filterDelay: 1000, defaultWidth: this.getColumnWidth('GROSVAL', 150),
                editable: (editValue, cellProps) => {
                    var editable = this.isCellEditable(cellProps);
                    return Promise.resolve(editable)
                }

            },
            {name: 'CURRENC', header: 'Pénznem', filterDelay: 1000, defaultWidth: this.getColumnWidth('CURRENC', 150),
                editable: (editValue, cellProps) => {
                    var editable = this.isCellEditable(cellProps);
                    return Promise.resolve(editable)
                },
                renderEditor: (editorProps) => {
                    var id = editorProps.cellProps.data.id;
                    var key = editorProps.cellProps.id;
                    return <div style={{width: '100%'}} key={"combo" + key + "_" + id}>
                        <ComboEditor 
                            blackTheme={this.props.blackTheme}
                            callback={ this.updateRecord.bind(this) } 
                            values={currencies} 
                            editorProps={editorProps}
                            state={state}
                            /></div>
                },
                render: ({value}) => {
                    return this.findLabelById(currencies, value);
                },
                filterEditor: SelectFilter,
                filterEditorProps: {
                    multiple: true,
                    wrapMultiple: false,
                    dataSource: currencies.map(c => {
                        return {id: c.id, label: c.label}
                    }),
                }
            },
            {name: 'FIZMOD', header: 'Fizetési mód', filterDelay: 1000, defaultWidth: this.getColumnWidth('FIZMOD', 150),
                editable: (editValue, cellProps) => {
                    var editable = this.isCellEditable(cellProps);
                    return Promise.resolve(editable)
                },
                renderEditor: (editorProps) => {
                    var id = editorProps.cellProps.data.id;
                    var key = editorProps.cellProps.id;
                    return <div style={{width: '100%'}} key={"combo_" + key + "_" + id}>
                    <ComboEditor 
                        blackTheme={this.props.blackTheme}
                        callback={ this.updateRecord.bind(this) } 
                        values={fizmodok} 
                        editorProps={editorProps}
                        state={state}
                        /></div>
                },
                render: ({value}) => {
                    return this.findLabelById(fizmodok, value);
                },
                filterEditor: SelectFilter,
                filterEditorProps: {
                    multiple: true,
                    wrapMultiple: false,
                    dataSource: fizmodok.map(c => {
                        return {id: c.id, label: c.label}
                    }),
                }
            },
            {name: 'DATUM', header: 'Bizonylat dátuma', filterDelay: 1000, defaultWidth: this.getColumnWidth('DATUM', 150),
                editable: (editValue, cellProps) => {
                    var editable = this.isCellEditable(cellProps);
                    return Promise.resolve(editable)
                },
                dateFormat: 'YYYY-MM-DD',
                filterEditor: DateFilter,
                filterEditorProps: (props, { index }) => {
                    return {
                        dateFormat: 'MM-DD-YYYY',
                        cancelButton: false,
                        highlightWeekends: false,
                        placeholder: index == 1 ? 'Előtte' : 'Utána'
                }
                },
                render: ({ value }) => {
                    return <div>{this.dateToDotted(value)}</div>
                },
                editor: this.myDateEditor.bind(this)},

            {name: 'TELJESIT', header: 'Számviteli teljesítés', filterDelay: 1000, defaultWidth: this.getColumnWidth('TELJESIT', 150),
                editable: (editValue, cellProps) => {
                    var editable = this.isCellEditable(cellProps);
                    return Promise.resolve(editable)
                },
                dateFormat: 'YYYY-MM-DD',
                filterEditor: DateFilter,
                filterEditorProps: (props, { index }) => {
                    return {
                        dateFormat: 'MM-DD-YYYY',
                        cancelButton: false,
                        highlightWeekends: false,
                        placeholder: index == 1 ? 'Előtte' : 'Utána'
                }
                },
                render: ({ value }) => {
                    return <div>{this.dateToDotted(value)}</div>
                },
                editor: this.myDateEditor.bind(this)},
            {name: 'HATIDO', header: 'Fizetési Határidő', filterDelay: 1000, defaultWidth: this.getColumnWidth('HATIDO', 150),
                editable: (editValue, cellProps) => {
                    var editable = this.isCellEditable(cellProps);
                    return Promise.resolve(editable)
                },

                render: ({ value }) => {
                    return <div>{this.dateToDotted(value)}</div>
                },
                dateFormat: 'YYYY-MM-DD',
                filterEditor: DateFilter,
                filterEditorProps: (props, { index }) => {
                    return {
                        dateFormat: 'MM-DD-YYYY',
                        cancelButton: false,
                        highlightWeekends: false,
                        placeholder: index == 1 ? 'Előtte' : 'Utána'
                }
                },
                editor: this.myDateEditor.bind(this)},
            {name: 'AFATELJ', header: 'ÁFA teljesítés dátuma', filterDelay: 1000, defaultWidth: this.getColumnWidth('AFATELJ', 150),
                editable: (editValue, cellProps) => {
                    var editable = this.isCellEditable(cellProps);
                    return Promise.resolve(editable)
                },
                dateFormat: 'YYYY-MM-DD',
                filterEditor: DateFilter,
                filterEditorProps: (props, { index }) => {
                    return {
                        dateFormat: 'MM-DD-YYYY',
                        cancelButton: false,
                        highlightWeekends: false,
                        placeholder: index == 1 ? 'Előtte' : 'Utána'
                }
                },
                render: ({ value }) => {
                    return <div>{this.dateToDotted(value)}</div>
                },
                editor: this.myDateEditor.bind(this)},

            {name: 'SZALADO', header: adoszamTitle, filterDelay: 1000, defaultWidth: this.getColumnWidth('SZALADO', 150),
                editable: (editValue, cellProps) => {
                    var editable = this.isCellEditable(cellProps);
                    return Promise.resolve(editable)
                }

            },
            {name: 'SZALIRSZ', header: irszTitle, filterDelay: 1000, defaultWidth: this.getColumnWidth('SZALIRSZ', 150),
                editable: (editValue, cellProps) => {
                    var editable = this.isCellEditable(cellProps);
                    return Promise.resolve(editable)
                }
            },
            {name: 'SZALCITY', header: cityTitle, filterDelay: 1000, defaultWidth: this.getColumnWidth('SZALCITY', 150),
                editable: (editValue, cellProps) => {
                    var editable = this.isCellEditable(cellProps);
                    return Promise.resolve(editable)
                }
            },
            {name: 'SZALUTCA', header: utcaHszTitle, filterDelay: 1000, defaultWidth: this.getColumnWidth('SZALUTCA', 150),
                editable: (editValue, cellProps) => {
                    var editable = this.isCellEditable(cellProps);
                    return Promise.resolve(editable)
                }
            },

            {name: 'AFAMENT', header: 'Áfamentes', type: 'text', filterEditor: NumberFilter, filterDelay: 1000, defaultWidth: this.getColumnWidth('AFAMENT', 150),
                editable: (editValue, cellProps) => {
                    var editable = this.isCellEditable(cellProps);
                    return Promise.resolve(editable)
                },

            },
            {name: 'AFAKULC1', header: 'Áfa% 1', filterDelay: 1000, defaultWidth: this.getColumnWidth('AFAKULC1', 150),
                editable: (editValue, cellProps) => {
                    var editable = this.isCellEditable(cellProps);
                    return Promise.resolve(editable)
                },
                renderEditor: (editorProps) => {
                    var id = editorProps.cellProps.data.id;
                    var key = editorProps.cellProps.id;
                    return <div style={{width: '100%'}} key={"combo" + key + "_" + id}>
                        <ComboEditor 
                            blackTheme={this.props.blackTheme}
                            callback={ this.updateRecord.bind(this) } 
                            values={afakulcsok} 
                            editorProps={editorProps}
                            state={state}
                            /></div>
                },
                render: ({value}) => {
                    return this.findLabelById(afakulcsok, value);
                },
                filterEditor: SelectFilter,
                filterEditorProps: {
                    multiple: true,
                    wrapMultiple: false,
                    dataSource: afakulcsok.map(c => {
                        return {id: c.id, label: c.label}
                    }),
                }
            },
            {name: 'TETELNE1', header: 'Nettó érték 1', type: 'text', filterEditor: NumberFilter, filterDelay: 1000, defaultWidth: this.getColumnWidth('TETELNE1', 150),
                editable: (editValue, cellProps) => {
                    var editable = this.isCellEditable(cellProps);
                    return Promise.resolve(editable)
                }

            },
            {name: 'TETELAFA1', header: 'Áfa érték 1', type: 'text', filterEditor: NumberFilter, filterDelay: 1000, defaultWidth: this.getColumnWidth('TETELAFA1', 150),
                editable: (editValue, cellProps) => {
                    var editable = this.isCellEditable(cellProps);
                    return Promise.resolve(editable)
                }

            },
            {name: 'TETELBR1', header: 'Bruttó érték 1', type: 'text', filterEditor: NumberFilter, filterDelay: 1000, defaultWidth: this.getColumnWidth('TETELBR1', 150),
                editable: (editValue, cellProps) => {
                    var editable = this.isCellEditable(cellProps);
                    return Promise.resolve(editable)
                }

            },
            {name: 'AFAKULC2', header: 'Áfa% 2', filterDelay: 1000, defaultWidth: this.getColumnWidth('AFAKULC2', 150),
                editable: (editValue, cellProps) => {
                    var editable = this.isCellEditable(cellProps);
                    return Promise.resolve(editable)
                },
                renderEditor: (editorProps) => {
                    var id = editorProps.cellProps.data.id;
                    var key = editorProps.cellProps.id;
                    return <div style={{width: '100%'}} key={"combo" + key + "_" + id}>
                    <ComboEditor 
                        blackTheme={this.props.blackTheme}
                        callback={ this.updateRecord.bind(this) } 
                        values={afakulcsok} 
                        editorProps={editorProps}
                        state={state}
                        /></div>
                },
                render: ({value}) => {
                    return this.findLabelById(afakulcsok, value);
                },
                filterEditor: SelectFilter,
                filterEditorProps: {
                    multiple: true,
                    wrapMultiple: false,
                    dataSource: afakulcsok.map(c => {
                        return {id: c.id, label: c.label}
                    }),
                }},
            {name: 'TETELNE2', header: 'Nettó érték 2', type: 'text', filterEditor: NumberFilter, filterDelay: 1000, defaultWidth: this.getColumnWidth('TETELNE2', 150),
                editable: (editValue, cellProps) => {
                    var editable = this.isCellEditable(cellProps);
                    return Promise.resolve(editable)
                }

            },
            {name: 'TETELAFA2', header: 'Áfa érték 2', type: 'text', filterEditor: NumberFilter, filterDelay: 1000, defaultWidth: this.getColumnWidth('TETELAFA2', 150),
                editable: (editValue, cellProps) => {
                    var editable = this.isCellEditable(cellProps);
                    return Promise.resolve(editable)
                }

            },
            {name: 'TETELBR2', header: 'Bruttó érték 2', type: 'text', filterEditor: NumberFilter, filterDelay: 1000, defaultWidth: this.getColumnWidth('TETELBR2', 150),

                editable: (editValue, cellProps) => {
                    var editable = this.isCellEditable(cellProps);
                    return Promise.resolve(editable)
                }

            },
            {name: 'AFAKULC3', header: 'Áfa% 3', filterDelay: 1000, defaultWidth: this.getColumnWidth('AFAKULC3', 150),
                editable: (editValue, cellProps) => {
                    var editable = this.isCellEditable(cellProps);
                    return Promise.resolve(editable)
                },
                renderEditor: (editorProps) => {
                    var id = editorProps.cellProps.data.id;
                    var key = editorProps.cellProps.id;
                    return <div style={{width: '100%'}} key={"combo" + key + "_" + id}>
                    <ComboEditor 
                        blackTheme={this.props.blackTheme}
                        callback={ this.updateRecord.bind(this) } 
                        values={afakulcsok} 
                        editorProps={editorProps}
                        state={state}
                        /></div>
                },
                render: ({value}) => {
                    return this.findLabelById(afakulcsok, value);
                },
                filterEditor: SelectFilter,
                filterEditorProps: {
                    multiple: true,
                    wrapMultiple: false,
                    dataSource: afakulcsok.map(c => {
                        return {id: c.id, label: c.label}
                    }),
                }},
            {name: 'TETELNE3', header: 'Nettó érték 3', type: 'text', filterEditor: NumberFilter, filterDelay: 1000, defaultWidth: this.getColumnWidth('TETELNE3', 150),
                editable: (editValue, cellProps) => {
                    var editable = this.isCellEditable(cellProps);
                    return Promise.resolve(editable)
                }

            },
            {name: 'TETELAFA3', header: 'Áfa érték 3', type: 'text', filterEditor: NumberFilter, filterDelay: 1000, defaultWidth: this.getColumnWidth('TETELAFA3', 150),
                editable: (editValue, cellProps) => {
                    var editable = this.isCellEditable(cellProps);
                    return Promise.resolve(editable)
                }
            },
            {name: 'TETELBR3', header: 'Bruttó érték 3', type: 'text', filterEditor: NumberFilter, filterDelay: 1000, defaultWidth: this.getColumnWidth('TETELBR3', 150),
                editable: (editValue, cellProps) => {
                    var editable = this.isCellEditable(cellProps);
                    return Promise.resolve(editable)
                }
            },
            {name: 'MEGJEGYZ', header: 'Megjegyzés', filterDelay: 1000, defaultWidth: this.getColumnWidth('MEGJEGYZ', 150),
                editable: (editValue, cellProps) => {
                    var editable = this.isCellEditable(cellProps);
                    return Promise.resolve(editable)
                }


            },
            {name: 'FORDAFA', header: 'Fordított áfa?', filterDelay: 1000, defaultWidth: this.getColumnWidth('FORDAFA', 150),
                editable: (editValue, cellProps) => {
                    var editable = this.isCellEditable(cellProps);
                    return Promise.resolve(editable)
                },
                renderEditor: (editorProps) => {
                    var id = editorProps.cellProps.data.id;
                    var key = editorProps.cellProps.id;
                    return <div style={{width: '100%'}} key={"combo" + key + "_" + id}>
                    <ComboEditor 
                        blackTheme={this.props.blackTheme}
                        callback={ this.updateRecord.bind(this) } 
                        values={booleanComboFordafa} 
                        editorProps={editorProps}
                        state={state}
                        /></div>
                },
                render: ({value}) => {
                    return this.findLabelById(booleanComboFordafa, value);
                },
                filterEditor: SelectFilter,
                filterEditorProps: {
                    multiple: true,
                    wrapMultiple: false,
                    dataSource: booleanComboFordafa.map(c => {
                        return {id: c.id, label: c.label}
                    }),
                }
            },
            {name: 'sharedDoc', header: 'Megosztva', filterDelay: 1000, defaultWidth: this.getColumnWidth('sharedDoc', 150),
                editable: (editValue, cellProps) => {
                    var editable = this.isCellEditable(cellProps);
                    return Promise.resolve(editable)
                },
                renderEditor: (editorProps) => {
                    var id = editorProps.cellProps.data.id;
                    var key = editorProps.cellProps.id;
                    return <div style={{width: '100%'}} key={"combo" + key + "_" + id}>
                    <ComboEditor 
                        blackTheme={this.props.blackTheme}
                        callback={ this.updateRecord.bind(this) } 
                        values={booleanComboMegosztva} 
                        editorProps={editorProps}
                        state={state}
                        /></div>
                },
                render: ({value}) => {
                    return this.findLabelById(booleanComboMegosztva, value);
                },
                filterEditor: SelectFilter,
                filterEditorProps: {
                    multiple: true,
                    wrapMultiple: false,
                    dataSource: booleanComboMegosztva.map(c => {
                        return {id: c.id, label: c.label}
                    }),
                }
            },

            {name: 'kiegyenlitesDatuma', visible: visibleInInbound, header: 'Kiegyenlítés dátuma', filterDelay: 1000, defaultWidth: this.getColumnWidth('kiegyenlitesDatuma', 150),
                editable: true,
                dateFormat: 'YYYY-MM-DD',
                filterEditor: DateFilter,
                filterEditorProps: (props, { index }) => {
                    return {
                        dateFormat: 'MM-DD-YYYY',
                        cancelButton: false,
                        highlightWeekends: false,
                        placeholder: index == 1 ? 'Előtte' : 'Utána'
                }
                },
                render: ({ value }) => {
                    return <div>{this.dateToDotted(value)}</div>
                },
                editor: this.myDateEditor.bind(this)
            },
            {name: 'kiegyenlitettOsszeg', visible: visibleInInbound, header: 'Kiegyenlítve', type: 'text', filterEditor: NumberFilter, filterDelay: 1000, defaultWidth: this.getColumnWidth('kiegyenlitettOsszeg', 150),
                editable: true
            },
            {name: 'kiegyenlitetlenOsszeg', visible: visibleInInbound, header: 'Kiegyenlítetlen', type: 'text', filterEditor: NumberFilter, filterDelay: 1000, defaultWidth: this.getColumnWidth('kiegyenlitetlenOsszeg', 150),
                editable: true
            },
            {name: 'szallitoBankszamlaszam', header: 'Száll. bankszámlaszám', filterDelay: 1000, defaultWidth: this.getColumnWidth('szallitoBankszamlaszam', 150),
                editable: (editValue, cellProps) => {
                    var editable = this.isCellEditable(cellProps);
                    return Promise.resolve(editable)
                }
            },
            {name: 'selectedMonth', header: 'Hónap', filterDelay: 1000, defaultWidth: this.getColumnWidth('selectedMonth', 150),
                editable: (editValue, cellProps) => {
                    var editable = this.isCellEditable(cellProps);
                    if (typeof cellProps.data.Status != 'undefined' && cellProps.data.Status != null) {
                        editable = (cellProps.data.Status < 3) ? true : false;
                    }
                    return Promise.resolve(editable)
                },
                renderEditor: (editorProps) => {
                    var id = editorProps.cellProps.data.id;
                    var key = editorProps.cellProps.id;
                    return <div style={{width: '100%'}} key={"combo_" + key + "_" + id}>
                    <ComboEditor 
                        blackTheme={this.props.blackTheme}
                        callback={ this.updateRecord.bind(this) } 
                        values={selectedMonths} 
                        editorProps={editorProps}
                        state={state}
                        /></div>
                },
                render: ({value}) => {
                    return this.findLabelById(selectedMonths, value);
                },
                filterEditor: SelectFilter,
                filterEditorProps: {
                    multiple: true,
                    wrapMultiple: false,
                    dataSource: selectedMonths.map(c => {
                        return {id: c.id, label: c.label}
                    }),
                }
            }
        ];

        const data = [];
        var updateRecord = this.updateRecord.bind(this);
        var state = this.state;
        const callback = function (result, data) {
            if (state.defaultUpdateBlocked == false) {
                updateRecord(result.data.id, result.columnId, result.value);
                result.data[result.columnId] = result.value;
            }

            return data;
        }


        const filterValue = [
            {name: 'id', operator: 'eq', type: 'number'},
            {name: 'id_by_type', operator: 'eq', type: 'number'},
            {name: 'kiegyenlitettSzamla', operator: 'eq', type: 'boolean', value: null},
            {name: 'FILENEVE', operator: 'contains', type: 'string', value: ''},
            {name: 'selectedMonth', operator: 'inlist', type: 'select'},
            {name: 'Status', operator: 'inlist', type: 'select'},
            {name: 'IKT_DATE', operator: 'eq', type: 'date', value: ''},
            {name: 'KONYDATE', operator: 'eq', type: 'date', value: ''},
            {name: 'DOKUID', operator: 'contains', type: 'string', value: ''},
            {name: 'workNumber', operator: 'contains', type: 'string', value: ''},
            {name: 'GROSVAL', operator: 'eq', type: 'number'},
            {name: 'CURRENC', operator: 'inlist', type: 'select'},
            {name: 'FIZMOD', operator: 'inlist', type: 'select'},
            {name: 'DATUM', operator: 'eq', type: 'date', value: ''},
            {name: 'TELJESIT', operator: 'eq', type: 'date', value: ''},
            {name: 'HATIDO', operator: 'eq', type: 'date', value: ''},
            {name: 'AFATELJ', operator: 'eq', type: 'date', value: ''},
            {name: 'SZALLITO', operator: 'contains', type: 'string', value: ''},
            {name: 'SZALADO', operator: 'contains', type: 'string', value: ''},
            {name: 'SZALIRSZ', operator: 'contains', type: 'string', value: ''},
            {name: 'SZALCITY', operator: 'contains', type: 'string', value: ''},
            {name: 'SZALUTCA', operator: 'contains', type: 'string', value: ''},
            {name: 'AFAMENT', operator: 'eq', type: 'number'},
            {name: 'AFAKULC1', operator: 'inlist', type: 'select'},
            {name: 'TETELNE1', operator: 'eq', type: 'number'},
            {name: 'TETELAFA1', operator: 'eq', type: 'number'},
            {name: 'TETELBR1', operator: 'eq', type: 'number'},
            {name: 'AFAKULC2', operator: 'inlist', type: 'select'},
            {name: 'TETELNE2', operator: 'eq', type: 'number'},
            {name: 'TETELAFA2', operator: 'eq', type: 'number'},
            {name: 'TETELBR2', operator: 'eq', type: 'number'},
            {name: 'AFAKULC3', operator: 'inlist', type: 'select'},
            {name: 'TETELAFA3', operator: 'eq', type: 'number'},
            {name: 'TETELNE3', operator: 'eq', type: 'number'},
            {name: 'TETELBR3', operator: 'eq', type: 'number'},
            {name: 'MEGJEGYZ', operator: 'contains', type: 'string', value: ''},
            {name: 'FORDAFA', operator: 'eq', type: 'select', value: null},
            {name: 'eredetiSzamla', operator: 'contains', type: 'string', value: ''},
            {name: 'sharedDoc', operator: 'eq', type: 'select', value: null},
            {name: 'kiegyenlitesDatuma', operator: 'eq', type: 'date', value: ''},
            {name: 'kiegyenlitettOsszeg', operator: 'eq', type: 'number'},
            {name: 'kiegyenlitetlenOsszeg', operator: 'eq', type: 'number'},
            {name: 'szallitoBankszamlaszam', operator: 'contains', type: 'string', value: ''},
        ];




        const dataLoader = ({ skip, limit, sortInfo, groupBy, filterValue }) => {

            var state = this.state;

            this.state.requestProps = {skip: skip, limit: limit, sortInfo: sortInfo, filterValue: filterValue};

            try {
                const url = ajax.getUrlBase() + "modules/Booking/select.php";
                state.update = false;
                return fetch(url, {
                    method: "POST",
                    //credentials: "include",
                    //credentials: "*", // Tesztüzemben
                    headers: new Headers({"Content-Type": "application/json"}),
                    body: JSON.stringify({skip: skip, limit: limit, sortInfo: sortInfo, filterValue: filterValue, invoiceDirection: this.props.invoiceDirection, queryInvoiceDigestLinesKonyvelesTetel_id: this.queryInvoiceDigestLinesKonyvelesTetel_id, tarsitottFilter: this.tarsitottFilter})
                }).then(response => {
                    if (response.ok) {
                        return response.json().then(response => {
                            this.userType = response.extra.userType;
                            this.isBankiImportEnabled = response.extra.isBankiImportEnabled;
                            if (this.isBankiImportEnabled == 1) {
                                this.bankiImportSelectAllRef.current.style.display = '';
                                this.bankiImportSelectNoneRef.current.style.display = '';
                            } else {
                                this.bankiImportSelectAllRef.current.style.display = 'none';
                                this.bankiImportSelectNoneRef.current.style.display = 'none';

                            }
                            this.instantInvoiceLinesFromNav = response.extra.instantInvoiceLinesFromNav;

                            if (this.instantInvoiceLinesFromNav == 1) {
                                this.instantInvoiceLinesFromNavRef.current.style.display = '';
                            } else {
                                this.instantInvoiceLinesFromNavRef.current.style.display = 'none';
                            }



                            this.state.defaultUpdateBlocked = false;
                            var totalCount = response.count;
                            var data = response.data;
                            this.data = data;
                            this.gridData = data;
                            state.paging.totalRowCount = totalCount;
                            var totalPageCount = ((totalCount) / state.paging.pageRowCount);
                            state.paging.totalPageCount = Math.ceil(totalPageCount) - 1;
                            state.paging.showingRowFrom = skip;
                            state.paging.showingRowTo = skip + state.pageRowCount;

                            var isSelectedRecordExists = this.isSelectedRecordExists(data, this.state.selectedRow);
                            if (this.queryInvoiceDigestLinesKonyvelesTetel_id != null && this.queryInvoiceDigestLinesKonyvelesTetel_id > -1) {
                                isSelectedRecordExists = false;
                            }

                            if (isSelectedRecordExists == false) {
                                if (data.length > 0) {
                                    this.state.selectedRow = data[0].id;
                                    this.props.setSelectedRowId(this.state.selectedRow);
                                }
                            }

                            // this.queryInvoiceDigestLinesKonyvelesTetel_id=-1;

                            this.state.prevTableData = {data, count: parseInt(totalCount), skip: skip};
                            return Promise.resolve({data, count: parseInt(totalCount), skip: skip});
                        }).catch(function (jsonError) {
                            this.state.defaultUpdateBlocked = false;
                            if (null == jsonError) {

                                console.error("Fetch JSON error:");
                            } else {
                                console.error("Fetch JSON error:" + String(jsonError));
                            }
                        });
                    } else {
                        this.state.defaultUpdateBlocked = false;
                        console.error("HTTP status error:", response.statusText);
                    }
                }).catch(function (error) {
                    this.state.defaultUpdateBlocked = false;
                    console.error("Fetch error:", error);
                    if (null == error) {
                        console.error("Fetch error:");
                    } else {
                        console.error("Fetch error:" + String(error));
                    }
                });
            } catch (ex) {
                this.state.defaultUpdateBlocked = false;
                console.error("Fetch exception:", String(ex));
        }
        }


        // Ellenőrizni kell, hogy ez kell-e?
        const setSkip = function () {
            this.setState({skip: this.state.skip + 10});
        }


        const renderPaginationToolbar = this.customPaging.bind(this);

        var isVisibleDateEditor = (this.state.dateEditor.active) ? 'grid' : 'none';
        var filters = [];

        if (this.state.isFilterVisible) {
            filters = filterValue;
        }

        const DEFAULT_ACTIVE_CELL = [0, 1];

        let inEdit;


        const onEditStart = () => {
            inEdit = true
        }

        const onEditStop = () => {
            requestAnimationFrame(() => {
                inEdit = false;
                this.state.defaultUpdateBlocked = true;
                this.state.gridRef.current.focus();

            })
        }

        const onKeyDown = (event) => {
            if (inEdit) {
                return
            }
            const grid = this.state.gridRef.current;
            let [rowIndex, colIndex] = grid.computedActiveCell;
            if (typeof grid.data[rowIndex] != 'undefined') {
                var selectedRowId = grid.data[rowIndex].id;
                this.state.selectedRow = selectedRowId;
                this.props.setSelectedRowId(selectedRowId);
            }


            if (event.key === 'Enter') {
                const column = grid.getColumnBy(colIndex)
                grid.startEdit({columnId: column.name, rowIndex})
                event.preventDefault()
                return;
            }
            if (event.key !== 'Tab') {
                return
            }

            console.log("Navigáció", event.key);

            event.preventDefault()
            event.stopPropagation()

            const direction = event.shiftKey ? -1 : 1

            const columns = grid.visibleColumns
            const rowCount = grid.count

            colIndex += direction
            if (colIndex === -1) {
                colIndex = columns.length - 1
                rowIndex -= 1
            }
            if (colIndex === columns.length) {
                rowIndex += 1
                colIndex = 0
            }
            if (rowIndex < 0 || rowIndex === rowCount) {
                return
            }

            grid.setActiveCell([rowIndex, colIndex])
        }

        const onEditComplete = ({ value, columnId, rowIndex }) => {
            data[rowIndex] = Object.assign({}, data[rowIndex], {[columnId]: value})
        }


        var multiFileChooser = "";
        if (this.state.multiFileChooserVisible) {
            multiFileChooser = <MultiFileChooser     
            multiUploadFileChooserClose={this.multiUploadFileChooserClose.bind(this)}
            blackTheme={this.props.blackTheme}
            invoiceDirection ={this.props.invoiceDirection}
            alertDialog={this.props.alertDialog.bind(this)}
        
            />
        }

        var buttonStyle = (this.props.blackTheme) ? darkButtonStyle : lightButtonStyle;



        var multiUpload = (this.props.blackTheme) ? multiUploadWhite : multiUploadBlack;
        var DeleteIcon = (this.props.blackTheme) ? DeleteIconWhite : DeleteIconBlack;
        var AddWhiteIcon = (this.props.blackTheme) ? AddWhiteIconWhite : AddWhiteIconBlack;
        var FilterIcon = (this.props.blackTheme) ? FilterIconWhite : FilterIconBlack;
        var AtadasKonyvelesre = (this.props.blackTheme) ? AtadasKonyvelesreWhite : AtadasKonyvelesreBlack;
        var Preview = (this.props.blackTheme) ? PreviewWhite : PreviewBlack;
        var NavConnection = (this.props.blackTheme) ? NavConnectionWhite : NavConnectionBlack;
        var checkBoxActive = (this.props.blackTheme) ? checkBoxActiveWhite : checkBoxActiveBlack;
        var checkBoxInactive = (this.props.blackTheme) ? checkBoxInactiveWhite : checkBoxInactiveBlack;
        var bankiImport = (this.props.blackTheme) ? bankiImportWhite : bankiImportBlack;

        var details = (this.props.blackTheme) ? DetailsWhite : DetailsBlack;





        var panelBackground = (this.props.blackTheme) ? "#313943" : "#FFFFFF";

        var gridTheme = "";
        if (this.props.blackTheme) {
            gridTheme = "default-dark";
        } else {
            gridTheme = "default-light";
        }

        var reactDatepickerClass = (this.props.blackTheme) ? "react-datepickerDark" : "react-datepickerLight";
        var calendarClose = (this.props.blackTheme) ? "calendarCloseDark" : "calendarCloseLight";

        var deleteConfirmationDialog = "";
        if (this.state.showDeleteConfirmationDialog == true) {
            var id_by_type = null;
            try {
                const grid = this.state.gridRef.current;
                let [rowIndex, colIndex] = grid.computedActiveCell;
                if (typeof grid.data[rowIndex] != 'undefined') {
                    id_by_type = grid.data[rowIndex].id_by_type;
                }
            } catch (e) {

            }

            deleteConfirmationDialog = <DeleteConfirmationDialog
                delete ={this.deleteRecord.bind(this)}    
                cancel ={this.cancelDeleteConfirmation.bind(this)}
                >
                Valóban törölni szeretné az <b>{id_by_type}</b> iktatószámon nyilvántartott tételt?
            </DeleteConfirmationDialog>
        }

        var osszesKijeloleseButton = "";
        var kijelolesekMegszunteteseButton = "";
        var bankiImportButton = "";
        var tarsitottFilterCombo = "";

        osszesKijeloleseButton = <button
            ref={this.bankiImportSelectAllRef}
            style={buttonStyle} id={"add"} 
            onClick={this.checkAll.bind(this)} >
            <img className={"buttonImage"} src={checkBoxActive}/>
            Az összes kijelölése
        </button>
        kijelolesekMegszunteteseButton = <button 
            ref={this.bankiImportSelectNoneRef}
            style={buttonStyle} 
            id={"remove"} 
            onClick={this.checkAll.bind(this)} 
            >
            <img className={"buttonImage"} src={checkBoxInactive}/>
            Kijelölések megszüntetése
        </button>
        if (this.multiSelection.length > 0) {
            bankiImportButton = <button 
                style={buttonStyle} 
                id={"bankiImport"} 
                onClick={this.showBankiImportDialog.bind(this)} >
                <img className={"buttonImage"} src={bankiImport}/>
                Banki import (kijelöltek)
            </button>
        }
        var filterStyleColor ='black';
        var backgroundColor ='white';
        if (this.tarsitottFilter > 0) {
            backgroundColor='#a52a2a';
            filterStyleColor = 'white';
        }
        var tarsitottFilterCombo = <select 
            value={this.tarsitottFilter}
            style={{marginLeft: 5, minHeight: 30,color:filterStyleColor,backgroundColor:backgroundColor}}
            onChange={(e) => {
                            this.tarsitottFilter = e.target.value;
                            this.state.paging.skip = 0;
                            this.state.paging.currentPage = 0;
                            this.setState({});
                        }}
            >
            <option value={0}>Minden iktatás</option>
            <option value={1}>Társítatlan ikt.</option>
            <option value={2}>Társítatott ikt.</option>
        </select>

        var queryInvoiceDigestLinesTable = "";

        if (this.queryInvoiceDigestLinesTableVisible) {

            queryInvoiceDigestLinesTable = <PopUpWindow blackTheme={this.state.blackTheme}  collapsed={this.state.menuCollapsed}>
                <QueryInvoiceDigestLinesTable 
                    setBack={this.setBack.bind(this)} 
                    additionalParams={(typeof this.props.additionalParams == 'undefined') ? [] : this.props.additionalParams}
                    formType={(typeof this.props.formType == 'undefined') ? null : this.props.formType}
                    theme={this.state.theme}
                    invoiceDirection={this.props.invoiceDirection}
                    close={this.hideNavTetelek.bind(this)}
                    invoiceChooser={this.invoiceChooser.bind(this)}
                    />
            </PopUpWindow>;
        }

        return (
                <div  style={{margin: 'auto', backgroundColor: panelBackground}}>
                    <div onClick={this.calendarCancel.bind(this)} style={{display: isVisibleDateEditor}} className={"loadWrapper"} ></div>
                    <div  style={{display: 'flex', justifyContent: 'center', maxHeight: '0px'}}>
                        <div  style={{display: isVisibleDateEditor, position: 'sticky', top: 30, left: 30, backgroundColor: 'black', zIndex: '1000'}}>
                            <DatePicker 
                                onChange={(date) => {
                                        this.state.defaultUpdateBlocked = true;
                                        this.state.dateEditor.active = false;
                                        var date = new Date(date);
                                        var dateString = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
                                        this.state.dateEditor.value = dateString;
                                        this.updateRecord(this.state.dateEditor.id, this.state.dateEditor.key, dateString);
                                    }} 
                                locale="hu"  
                                inline />
                            <button onClick={this.calendarCancel.bind(this)} className={"calendarClose"} >Mégsem</button>
                        </div>
                
                
                    </div>
                    <div>
                        {tarsitottFilterCombo}
                        <button ref={this.buttonDeleteRef}  style={buttonStyle} onClick={this.showDeleteConfirmation.bind(this)} >
                            <img className={"buttonImage"} src={DeleteIcon}/>
                            Törlés
                        </button>
                        <button style={buttonStyle} onClick={this.insertRecord.bind(this)} >
                            <img className={"buttonImage"} src={AddWhiteIcon}/>
                            Új iktatás
                        </button>
                
                        <button ref={this.buttonModifyRef} style={buttonStyle} onClick={() => {
                                this.props.openUpdateBookingForm(this.state.requestProps)
                                    }} >
                            <img className={"buttonImage"} src={AtadasKonyvelesre}/>
                            Módosítás
                        </button>
                        <button style={buttonStyle} onClick={() => {
                                this.props.openViewBookingForm(this.state.requestProps)
                                    }} >
                            <img className={"buttonImage"} src={Preview}/>
                            Megnéz
                        </button>
                        <button style={buttonStyle} onClick={() => {
                                this.props.showNav(true);
                                    }} >
                            <img className={"buttonImage"} src={NavConnection}/>
                            NAV online számla adatszolgáltatás
                        </button>
                        <button 
                            ref ={this.instantInvoiceLinesFromNavRef}
                            style={buttonStyle} onClick={() => {
                                    this.showNavTetelek(true);
                                }} >
                            <img className={"buttonImage"} src={details}/>
                            Tétel szűrés
                        </button>
                        <button  onClick={this.multiUploadFileChooserOpen.bind(this)} style={buttonStyle}><img 
                                onClick={this.multiUploadFileChooserOpen.bind(this)}
                                className={"buttonImage"}
                                title={"Fájlok hozzáadása"} 
                                src={multiUpload}/>Fájl választás</button>
                
                        <button style={buttonStyle} onClick={() => {
                                this.exportToXls();
                                    }} >
                            <img className={"buttonImage"} src={(this.props.blackTheme) ? saveWhite : saveBlack}/>
                            Exportálás
                        </button> 
                
                
                        {osszesKijeloleseButton}
                        {kijelolesekMegszunteteseButton}
                        {bankiImportButton}
                
                    </div>
                    <ReactDataGrid 
                        onActiveCellChange={this.onActiveCellChange.bind(this)}
                        handle ={this.setGridRef.bind(this)}
                        idProperty="id" 
                        columns={this.myColumnsReorder(columns)} 
                        style={gridStyle}
                        theme={gridTheme}
                
                        defaultActiveCell={DEFAULT_ACTIVE_CELL}
                        onKeyDown={onKeyDown}
                        onEditComplete={callback}
                        onEditStart={onEditStart}
                        onEditStop={onEditStop}
                        onCellSelectionChange={this.onCellSelectionChanged.bind(this)}
                        editable={true}
                
                
                        i18n={ajax.getI18n()}
                        dataSource={dataLoader}
                        defaultLimit={100}
                        defaultFilterValue={filters}
                
                
                        loading={false}
                        pagination={true}
                        renderPaginationToolbar={renderPaginationToolbar}
                        skip={this.state.paging.skip}
                        limit={this.state.paging.pageRowCount}
                        defaultCellSelection={[]}
                
                        onColumnResize={this.onColumnResize.bind(this)}  
                        onColumnOrderChange={this.onColumnOrderChange.bind(this)}
                        onRenderRow={this.onRenderRow.bind(this)}
                
                        enableFiltering={this.state.isFilterVisible}
                        multiSelect={false}
                        onFilterValueChange={() => {
                                this.state.paging.skip = 0;
                                this.state.paging.currentPage = 0;
                                this.setState({});
                            }}
                        />
                    {queryInvoiceDigestLinesTable}    
                    {multiFileChooser}                
                    {deleteConfirmationDialog}
                    {bankiImportDialog}
                    {bankszamlaszamFrissitesDialog}
                </div>

                );
    }
}

export default BookingTable;
