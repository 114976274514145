import React, { Component } from 'react';
import * as ajax from './../ajax.js';
import openBlack from './../img/black/operner.svg';
import openWhite from './../img/white/operner.svg';


import cleanBlack from './../img/black/highlight_off_black_24dp.svg';

class FormComboPartner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ref: React.createRef(),
            dropDownVisible: false,
            mouseOnDropDownList: false,
            hoveredId: -1,
            selectedID: -1,
            selectedText: '',
            maxItemCount: 100,
            items: []
        };
        if (typeof this.props.maxItemCount !== 'undefined' && this.props.maxItemCount !== '') {
            this.state.maxItemCount = this.props.maxItemCount;
        }
    }

    componentWillReceiveProps(newProps) {

        if (typeof newProps.formComboRef.value !== 'undefined' && newProps.formComboRef.value === '') {
            this.state.selectedText = '';
        }
        if (newProps.formComboRef.value == null) {
            this.state.selectedText = '';
        }

    }

    onOpenerClick = function (e) {
        if (!this.state.dropDownVisible) {
            this.loadItems("");
        } else {
            this.setState({dropDownVisible: false});
        }
    }

    onTextFieldClick = function (e) {
        this.loadItems("");
    }

    handleChange = function (e) {


        this.setState({selectedText: e.target.value});
        this.loadItems(e.target.value);
    }

    lastHoveredExists = function () {
        if (typeof this.state.items != "undefined" && typeof this.state.items.length != "undefined" && this.state.items.length > 0) {
            for (var i = 0; i < this.state.items.length - 1; i++) {
                if (this.state.hoveredId == this.state.items[i].id) {
                    return true;
                }
            }
        }
        return false;
    }

    hoveredForward() {

        var itemsCount = this.state.items.length
        if (itemsCount === 0) {
            this.setState({hoveredId: -1});
        } else if (itemsCount === 1) {
            var nextHoveredId = this.state.items[0].id;
            this.setState({hoveredId: nextHoveredId});

            if (typeof this.props.afterSelect !== 'undefined') {
                this.props.formComboRef.value = nextHoveredId;
                this.props.afterSelect();
            }


        } else {
            for (var i = 0; i < this.state.items.length - 1; i++) {
                if (this.state.hoveredId == this.state.items[i].id) {
                    var nextHoveredId = this.state.items[i + 1].id;
                    this.setState({hoveredId: nextHoveredId});

                    if (typeof this.props.afterSelect !== 'undefined') {
                        this.props.formComboRef.value = nextHoveredId;
                        this.props.afterSelect();
                    }


                }
                if (this.state.hoveredId === -1) {
                    var nextHoveredId = this.state.items[0].id;
                    this.setState({hoveredId: nextHoveredId});

                    if (typeof this.props.afterSelect !== 'undefined') {
                        this.props.formComboRef.value = nextHoveredId;
                        this.props.afterSelect();
                    }

                }
            }
            if (!this.lastHoveredExists()) {
                var nextHoveredId = this.state.items[0].id;
                this.setState({hoveredId: nextHoveredId});
            }
        }
    }

    hoveredBack() {

        var itemsCount = this.state.items.length
        if (itemsCount === 0) {
            this.setState({hoveredId: -1});
        } else if (itemsCount === 1) {
            var nextHoveredId = this.state.items[0].id;
            this.setState({hoveredId: nextHoveredId});

            if (typeof this.props.afterSelect !== 'undefined') {
                this.props.formComboRef.value = nextHoveredId;
                this.props.afterSelect();
            }



        } else {
            for (var i = this.state.items.length - 1; i > 0; i--) {
                if (this.state.hoveredId == this.state.items[i].id) {
                    var nextHoveredId = this.state.items[i - 1].id;
                    this.setState({hoveredId: nextHoveredId});

                    if (typeof this.props.afterSelect !== 'undefined') {
                        this.props.formComboRef.value = nextHoveredId;
                        this.props.afterSelect();
                    }



                }
                if (this.state.hoveredId === -1) {
                    var nextHoveredId = this.state.items[0].id;
                    this.setState({hoveredId: nextHoveredId});

                    if (typeof this.props.afterSelect !== 'undefined') {
                        this.props.formComboRef.value = nextHoveredId;
                        this.props.afterSelect();
                    }


                }
            }
        }
    }

    setValueByHover = function () {
        for (var i = 0; i < this.state.items.length; i++) {
            if (this.state.items[i].id === this.state.hoveredId) {

                this.setFormValue(this.state.items[i].id);
                this.setState({selectedID: this.state.items[i].id, selectedText: this.state.items[i].value, dropDownVisible: false});

                if (typeof this.props.afterSelect !== 'undefined') {
                    this.props.formComboRef.value = this.state.hoveredId;
                    this.props.afterSelect();
                }

            }
        }
    }

    handleKeyDown = function (e) {
        if (e.keyCode == 40) { // arrow down

            if (this.state.dropDownVisible == false) {

                this.loadItems("");

            } else {

                this.hoveredForward();
                e.preventDefault();
                e.stopPropagation();
            }


        }
        if (e.keyCode == 38) { // arrow up
            e.preventDefault();
            e.stopPropagation();
            this.hoveredBack();
        }
        if (e.keyCode == 13) { // enter
            this.setValueByHover();
            e.preventDefault();
            e.stopPropagation();

        }
        if (e.keyCode == 27) { // escape
            this.setState({dropDownVisible: false});
            e.preventDefault();
            e.stopPropagation();

        }
    }

    loadItems = function (filterText) {
        try {
            const url = ajax.getUrlBase() + this.props.dataSourceUrl;
            return fetch(url, {
                method: "POST",
                //credentials: "include",
                //credentials: "*", // Tesztüzemben
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({filterText: filterText})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {

                        if (typeof response.data != 'undefined' && response.data != null) {

                            if (response.data.length === 0) {
                                this.setState({dropDownVisible: false});
                            } else {
                                this.setState({items: response.data, dropDownVisible: true});
                            }
                        }
                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }

        this.setState({dropDownVisible: true, hoveredId: -1});

    }

    dropDownItemClicked = function (e) {
        var selectedItemID = e.target.id;
        this.setTextFieldValue(selectedItemID);
        this.setState({dropDownVisible: false});


        if (typeof this.props.afterSelect !== 'undefined') {
            this.props.formComboRef.value = selectedItemID;
            this.props.afterSelect();
        }


    }

    setFormValue = function (selectedId) {
        this.props.formComboRef.value = selectedId;
        if (typeof this.props.afterSelect !== 'undefined') {
            this.props.afterSelect();
        }
    }

    resetFormValue = function () {

        try {



            this.props.stateRef.data[0].partner.id = null;
            this.props.stateRef.data[0].partner.value = null;

            this.props.stateRef.partner.id = null;
            this.props.stateRef.partner.value = null;
            this.props.stateRef.data.partnerId = null;

            this.props.stateRef.data.SZALADO = '';
            this.props.stateRef.data.SZALCITY = '';
            this.props.stateRef.data.SZALIRSZ = '';
            this.props.stateRef.data.SZALLITO = '';
            this.props.stateRef.data.SZALUTCA = '';

        } catch (e) {

        }

        if (typeof this.props.afterSelect !== 'undefined') {
            this.props.afterSelect();
        }
    }

    focusLost = function () {
        if (!this.state.mouseOnDropDownList) {
            this.setState({dropDownVisible: false});
        }
    }
    mouseOverDropDownList = function () {
        this.setState({mouseOnDropDownList: true});
    }
    mouseOutDropDownList = function () {
        this.setState({mouseOnDropDownList: false});
    }

    textField = function () {

        var typedText = this.state.selectedText;
        if (this.state.selectedID === null) {
            //  typedText='';
        }

        var defaultBackground = (this.props.blackTheme) ? "#313943" : "white";
        var defaultForeground = (this.props.blackTheme) ? "white" : "black";

        var hoverBackground = (this.props.blackTheme) ? "#313943aa" : "#31394322";
        var hoverForeground = (this.props.blackTheme) ? "#ffffff" : "black";



        return <input 
            ref={this.state.ref} 
            className={"formInput"}  
            onBlur={this.focusLost.bind(this)} 
            onKeyDown={this.handleKeyDown.bind(this)} 
            onChange={(e) => this.handleChange(e)}  
            value={typedText} 
            onClick={this.onTextFieldClick.bind(this)} 
            style={{
                            width: '100%',
                            minHeight: '18px',
                            minWidth: '100%',
                            backgroundColor: defaultBackground,
                            color: defaultForeground,
                            border: 'solid 1px #00000022',
                            backgroundColor: 'white',
                            color: 'black',
                            border: '1px solid rgba(0, 0, 0, 0)',
                            outline: 'currentcolor none medium',
                            position: 'relative',
                            top: -2

                        }} type="text" />;
    }

    select = function () {
        this.state.ref.current.select();
    }

    onMouseOver = function (e) {
        this.setState({hoveredId: e.target.id});
        if (typeof this.props.afterSelect !== 'undefined') {
            this.props.formComboRef.value = e.target.id;
            this.props.afterSelect();
        }

    }

    queryResults = function () {




        var comboItems = [];

        var defaultBackground = (this.props.blackTheme) ? "#313943" : "white";
        var defaultForeground = (this.props.blackTheme) ? "white" : "black";

        var hoverBackground = (this.props.blackTheme) ? "#313943aa" : "#31394322";
        var hoverForeground = (this.props.blackTheme) ? "#ffffff" : "black";




        for (var i = 0; i < this.state.items.length; i++) {
            var itemDescriptor = this.state.items[i];
            var itemStyle = {color: defaultForeground, backgroundColor: defaultBackground, zIndex: 1110, cursor: 'pointer'};

            if (this.state.hoveredId == itemDescriptor.id) {
                itemStyle = {color: hoverForeground, backgroundColor: hoverBackground, zIndex: 1110, cursor: 'pointer'};
            }

            comboItems.push(<div 
                style={itemStyle} 
                key={'dropdown_item_' + i} 
                className="dropdown-list-item" 
                id={itemDescriptor.id}
                onMouseOver={this.onMouseOver.bind(this)}
                >{itemDescriptor.value}</div>);

        }
        return <div 
            style={{zIndex: 1100, border: 'solid 1px #3ab4eb'}} 
            onClick={this.dropDownItemClicked.bind(this)} 
            >{comboItems}</div>;
    }

    setTextFieldValue = function (id) {
        var items = this.state.items;
        for (var i = 0; i < items.length; i++) {
            if (items[i].id == id) {
                this.setState({selectedID: items[i].id, selectedText: items[i].value});
                this.setFormValue(items[i].id);
            }
        }
    }

    render() {
        // The id,value pair came from the formLoader in object format.
        if (this.props.formComboRef != null) {
            if (this.props.formComboRef.value != null) {
                // After we change the param type from object to integer, the program don't repeating this block.
                if (typeof this.props.formComboRef.value === 'object') {
                    this.setState({selectedID: this.props.formComboRef.value.id, selectedText: this.props.formComboRef.value.value});
                    // Repleacing the value object to integer
                    this.props.formComboRef.value = this.props.formComboRef.value.id;
                } else if (typeof this.props.formComboRef.value === 'string') {
                    if (this.props.formComboRef.value === '') {
                        this.state.selectedID = null;
                        //this.state.selectedText='';
                    }
                }
            }
        }


        var dropDown = "";
        if (this.state.dropDownVisible) {
            dropDown = (
                    <div 
                        style={{overflowY: 'auto', maxHeight: '200px', zIndex: 1100, minWidth: 'min-content', backgroundColor: 'white'}}
                        onMouseOver={this.mouseOverDropDownList.bind(this)} 
                        onMouseOut={this.mouseOutDropDownList.bind(this)} 
                    
                        className="dropdown-list">
                        {this.queryResults()}
                    </div>);
        }
        var defaultBackground = (this.props.blackTheme) ? "#313943" : "white";
        var open = (this.props.blackTheme) ? openWhite : openBlack;

        return (<div style={this.props.style}>
            <div style={{position: 'relative', zIndex: '100', backgroundColor: defaultBackground, maxWidth: 330, top: '0px', maxHeight: '20px'}}>
                {this.textField()}
                {dropDown}
            </div>
            <div onClick={this.onOpenerClick.bind(this)} style={{cursor: 'pointer', position: 'absolute', maxHeight: '20px', minWidth: '15px', top: -3, zIndex: 1200, right: '24px', textAlign: 'center'}}>
                <img onClick={this.onOpenerClick.bind(this)} style={{cursor: 'pointer', maxWidth: '10px', marginTop: '0px'}} src={open}/>
            </div>
            <div onClick={this.resetFormValue.bind(this)} style={{cursor: 'pointer', position: 'absolute', maxHeight: '20px', minWidth: '15px', top: 2, zIndex: 1200, right: '2px', textAlign: 'center'}}>
                <img title={'Törlés'} onClick={this.resetFormValue.bind(this)} style={{cursor: 'pointer', maxWidth: '20px', marginTop: '0px'}} src={cleanBlack}/>
            </div>
        </div>
                )
    }
}
export default FormComboPartner;
