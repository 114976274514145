import React, { Component } from 'react';
import './../css/alertDialog.css';
import AlertImage from './../img/dialog/alert.svg';

class ConfirmationDialog extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        var windowClass=(this.props.size)?"dialogWindowBig":"dialogWindow";
        var contentClass=(this.props.size)?"dialogContentBig":"dialogContent";

        return <div className="dialogFrame">
    <div className={windowClass}>
        <img className="alertImage" src={AlertImage}/>
        <div className="dialogHeader">
            <h1>Megerősítés kérése</h1>
        </div>
        <div className={contentClass}>
            {this.props.children}
        </div>
        <div style={{display:'flex'}}>
            
            
            <div style={{minWidth:'100%',textAlign:'center'}}>
                <input autoFocus onClick={() => this.props.cancel()} className="bookingFormButton" type="button" value="Rendben" />
            </div>
            
        </div>
    </div>

</div>;
    }
}

export default ConfirmationDialog;