import React, { Component } from 'react';
import * as ajax from './../ajax.js';
import openBlack from './../img/black/operner.svg';
import openWhite from './../img/white/operner.svg';

import deleteBlack from './../img/black/delete_black_24dp.svg';
import deleteWhite from './../img/white/delete_white_24dp.svg';

import editBlack from './../img/black/edit_black_24dp.svg';
import editWhite from './../img/white/edit_white_24dp.svg';

import addBlack from './../img/black/add_black_24dp.svg';

import iconCancel from './../img/dialog/alert.svg';
import iconOk from './../img/dialog/success.svg';

import cleanBlack from './../img/black/highlight_off_black_24dp.svg';


class FormComboWorkNumber extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDeleteConfirmation: false,
            selectedIdToModification: -1,
            workNumberTitle: '',
            workNumberDetails: '',

            showUpdateDialog: false,
            showInsertDialog: false,

            showEdit: false,
            ref: React.createRef(),
            dropDownVisible: false,
            mouseOnDropDownList: false,
            hoveredId: -1,
            selectedID: -1,
            selectedText: '',
            maxItemCount: 100,
            items: []
        };
        if (typeof this.props.maxItemCount !== 'undefined' && this.props.maxItemCount !== '') {
            this.state.maxItemCount = this.props.maxItemCount;
        }
    }

    componentWillReceiveProps(newProps) {

        if (typeof newProps.formComboRef.value !== 'undefined' && newProps.formComboRef.value === '') {
            this.state.selectedText = '';
        }
        if (newProps.formComboRef.value == null) {
            this.state.selectedText = '';
        }

    }

    onOpenerClick = function (e) {
        if (!this.state.dropDownVisible) {
            this.loadItems("");
        } else {
            this.setState({dropDownVisible: false});
        }
    }

    onTextFieldClick = function (e) {
        this.loadItems("");
    }

    handleChange = function (e) {


        this.setState({selectedText: e.target.value});
        this.loadItems(e.target.value);
    }

    lastHoveredExists = function () {
        if (typeof this.state.items != "undefined" && typeof this.state.items.length != "undefined" && this.state.items.length > 0) {
            for (var i = 0; i < this.state.items.length - 1; i++) {
                if (this.state.hoveredId == this.state.items[i].id) {
                    return true;
                }
            }
        }
        return false;
    }

    hoveredForward() {


        var itemsCount = this.state.items.length
        if (itemsCount === 0) {
            this.setState({hoveredId: -1});
        } else if (itemsCount === 1) {
            var nextHoveredId = this.state.items[0].id;
            var nextDetail = this.getDetailsById(nextHoveredId);
            this.setState({hoveredId: nextHoveredId, workNumberDetails: nextDetail});

            if (typeof this.props.afterSelect !== 'undefined') {
                this.props.formComboRef.value = nextHoveredId;
                this.props.afterSelect();
            }


        } else {
            for (var i = 0; i < this.state.items.length - 1; i++) {
                if (this.state.hoveredId == this.state.items[i].id) {
                    var nextHoveredId = this.state.items[i + 1].id;
                    var nextDetail = this.getDetailsById(nextHoveredId);
                    this.setState({hoveredId: nextHoveredId, workNumberDetails: nextDetail});


                    if (typeof this.props.afterSelect !== 'undefined') {
                        this.props.formComboRef.value = nextHoveredId;
                        this.props.afterSelect();
                    }


                }
                if (this.state.hoveredId === -1) {
                    var nextHoveredId = this.state.items[0].id;
                    var nextDetail = this.getDetailsById(nextHoveredId);
                    this.setState({hoveredId: nextHoveredId, workNumberDetails: nextDetail});


                    if (typeof this.props.afterSelect !== 'undefined') {
                        this.props.formComboRef.value = nextHoveredId;
                        this.props.afterSelect();
                    }

                }
            }
            if (!this.lastHoveredExists()) {
                var nextHoveredId = this.state.items[0].id;
                var nextDetail = this.getDetailsById(nextHoveredId);
                this.setState({hoveredId: nextHoveredId, workNumberDetails: nextDetail});

            }
        }


        this.props.setWorkNumberDeatails(nextDetail);

    }

    hoveredBack() {


        var itemsCount = this.state.items.length
        if (itemsCount === 0) {
            this.setState({hoveredId: -1});
        } else if (itemsCount === 1) {
            var nextHoveredId = this.state.items[0].id;
            var nextDetail = this.getDetailsById(nextHoveredId);
            this.setState({hoveredId: nextHoveredId, workNumberDetails: nextDetail});

            if (typeof this.props.afterSelect !== 'undefined') {
                this.props.formComboRef.value = nextHoveredId;
                this.props.afterSelect();
            }



        } else {
            for (var i = this.state.items.length - 1; i > 0; i--) {
                if (this.state.hoveredId == this.state.items[i].id) {
                    var nextHoveredId = this.state.items[i - 1].id;
                    var nextDetail = this.getDetailsById(nextHoveredId);
                    this.setState({hoveredId: nextHoveredId, workNumberDetails: nextDetail});

                    if (typeof this.props.afterSelect !== 'undefined') {
                        this.props.formComboRef.value = nextHoveredId;
                        this.props.afterSelect();
                    }
                }
                if (this.state.hoveredId === -1) {
                    var nextHoveredId = this.state.items[0].id;
                    var nextDetail = this.getDetailsById(nextHoveredId);
                    this.setState({hoveredId: nextHoveredId, workNumberDetails: nextDetail});

                    if (typeof this.props.afterSelect !== 'undefined') {
                        this.props.formComboRef.value = nextHoveredId;
                        this.props.afterSelect();
                    }


                }
            }
        }


        this.props.setWorkNumberDeatails(nextDetail);

    }

    setValueByHover = function () {
        for (var i = 0; i < this.state.items.length; i++) {
            if (this.state.items[i].id === this.state.hoveredId) {

                this.setFormValue(this.state.items[i].id);
                this.setState({selectedID: this.state.items[i].id, selectedText: this.state.items[i].value, dropDownVisible: false});

                if (typeof this.props.afterSelect !== 'undefined') {
                    this.props.formComboRef.value = this.state.hoveredId;
                    this.props.afterSelect();
                }
            }
        }
        this.props.setWorkNumberDeatails(this.state.workNumberDetails);
    }

    handleKeyDown = function (e) {
        if (e.keyCode == 40) { // arrow down

            if (this.state.dropDownVisible == false) {
                this.loadItems("");
            } else {
                this.hoveredForward();
                e.preventDefault();
                e.stopPropagation();
            }
        }
        if (e.keyCode == 38) { // arrow up
            e.preventDefault();
            e.stopPropagation();
            this.hoveredBack();
        }
        if (e.keyCode == 13) { // enter
            this.setValueByHover();
            e.preventDefault();
            e.stopPropagation();

        }
        if (e.keyCode == 27) { // escape
            this.setState({dropDownVisible: false});
            e.preventDefault();
            e.stopPropagation();

        }
    }

    loadItems = function (filterText) {
        try {
            const url = ajax.getUrlBase() + this.props.dataSourceUrl;
            return fetch(url, {
                method: "POST",
                //credentials: "include",
                //credentials: "*", // Tesztüzemben
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({filterText: filterText})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {

                        if (typeof response.data != 'undefined' && response.data != null) {
                            console.log(response.data);

                            if (response.data.length === 0) {
                                this.setState({dropDownVisible: false});
                            } else {
                                this.setState({items: response.data, dropDownVisible: true});
                            }
                        }
                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }

        this.setState({dropDownVisible: true, hoveredId: -1});

    }

    dropDownItemClicked = function (e) {
        var selectedItemID = e.target.id;
        this.setTextFieldValue(selectedItemID);
        this.setState({dropDownVisible: false});


        if (typeof this.props.afterSelect !== 'undefined') {
            this.props.formComboRef.value = selectedItemID;
            this.props.afterSelect();
        }


    }

    setFormValue = function (selectedId) {
        this.props.formComboRef.value = selectedId;
        if (typeof this.props.afterSelect !== 'undefined') {
            this.props.afterSelect();
        }
    }
    resetFormValue = function () {

        this.props.formComboRef.id = null;
        this.props.formComboRef.value = null;
        this.state.showDeleteConfirmation = false;
        this.state.selectedIdToModification = -1;
        this.state.workNumberTitle = '';
        this.state.workNumberDetails = '';

        this.state.showUpdateDialog = false;
        this.state.showInsertDialog = false;

        this.state.dropDownVisible = true;
        this.state.mouseOnDropDownList = false;
        this.state.hoveredId = -1;
        this.state.selectedID = -1;
        this.state.selectedText = '';
        
        this.props.stateRef.workNumber.id=null;
        this.props.stateRef.workNumber.value=null;
        this.props.stateRef.data.workNumberDetails=null;
        this.props.stateRef.data.workNumberId=null;
        this.props.stateRef.data[0].workNumber.details=null;
        this.props.stateRef.data[0].workNumber.id=null;
        this.props.stateRef.data[0].workNumber.value=null;

        this.setState({});

        if (typeof this.props.afterSelect !== 'undefined') {
             this.props.afterSelect();
        }
    }
    focusLost = function () {
        if (!this.state.mouseOnDropDownList) {
            this.setState({dropDownVisible: false});
        }
    }
    mouseOverDropDownList = function () {
        this.setState({mouseOnDropDownList: true});
    }
    mouseOutDropDownList = function () {
        this.setState({mouseOnDropDownList: false});
    }

    textField = function () {

        var typedText = this.state.selectedText;
        if (this.state.selectedID === null) {
            //  typedText='';
        }

        var defaultBackground = (this.props.blackTheme) ? "#313943" : "white";
        var defaultForeground = (this.props.blackTheme) ? "white" : "black";

        var hoverBackground = (this.props.blackTheme) ? "#313943aa" : "#31394322";
        var hoverForeground = (this.props.blackTheme) ? "#ffffff" : "black";


        return <input 
            ref={this.state.ref} 
            className={"formInput"}  
            onBlur={this.focusLost.bind(this)} 
            onKeyDown={this.handleKeyDown.bind(this)} 
            onChange={(e) => this.handleChange(e)}  
            value={typedText} 
            onClick={this.onTextFieldClick.bind(this)} 
            style={{
                            width: '100%',
                            maxHeight: '17px',
                            minWidth: '100%',
                            backgroundColor: defaultBackground,
                            color: defaultForeground,
                            border: 'solid 1px #00000000',
                            outline: 'none'
                        }} type="text" />;
    }

    select = function () {
        this.state.ref.current.select();
    }

    onMouseOver = function (e) {
        var nextDetail = this.getDetailsById(e.target.id);
        this.setState({hoveredId: e.target.id, workNumberDetails: nextDetail});
        if (typeof this.props.afterSelect !== 'undefined') {
            this.props.formComboRef.value = e.target.id;
            var nextDetail = this.getDetailsById(e.target.id);
            this.props.setWorkNumberDeatails(nextDetail);
            this.props.afterSelect();
        }

    }

    queryResults = function () {

        var comboItems = [];

        var defaultBackground = (this.props.blackTheme) ? "#313943" : "white";
        var defaultForeground = (this.props.blackTheme) ? "white" : "black";

        var hoverBackground = (this.props.blackTheme) ? "#313943aa" : "#31394322";
        var hoverForeground = (this.props.blackTheme) ? "#ffffff" : "black";

        for (var i = 0; i < this.state.items.length; i++) {
            var itemDescriptor = this.state.items[i];
            var itemStyle = {color: defaultForeground, backgroundColor: defaultBackground, zIndex: 1110, cursor: 'pointer', display: 'flex'};

            if (this.state.hoveredId == itemDescriptor.id) {
                itemStyle = {color: hoverForeground, backgroundColor: hoverBackground, zIndex: 1110, cursor: 'pointer', display: 'flex'};
            }

            comboItems.push(<div 
                style={itemStyle} 
                key={'dropdown_item_' + i} 
                className="dropdown-list-item" 
                id={itemDescriptor.id}
                onMouseOver={this.onMouseOver.bind(this)}
                >
                <div  id={"delete_" + itemDescriptor.id} onClick={this.showDeleteConfirmation.bind(this)}>
                    <img id={"deleteImg_" + itemDescriptor.id} title="Munkaszám törlése" style={{maxHeight: '16px', cursor: 'pointer', marginRight: '5px', marginTop: '5px'}} src={deleteBlack} /></div>
                <div id={"rename_" + itemDescriptor.id} onClick={this.showUpdateDialog.bind(this)}>
                    <img id={"renameImg_" + itemDescriptor.id} title="Munkaszám átnevezése" style={{maxHeight: '16px', cursor: 'pointer', marginRight: '5px', marginTop: '5px'}} src={editBlack} />
                </div>
                {itemDescriptor.value}
            </div>);

        }
        return <div 
            style={{zIndex: 1100, border: 'solid 1px #3ab4eb'}} 
            onClick={this.dropDownItemClicked.bind(this)} 
            >{comboItems}</div>;
    }

    setTextFieldValue = function (id) {
        var items = this.state.items;
        for (var i = 0; i < items.length; i++) {
            if (items[i].id == id) {
                this.setState({selectedID: items[i].id, selectedText: items[i].value});
                this.setFormValue(items[i].id);
            }
        }
    }

    getTitleById = function (id) {
        var items = this.state.items;
        for (var i = 0; i < items.length; i++) {
            if (items[i].id == id) {
                return items[i].value;
            }
        }
        return "";
    }
    getDetailsById = function (id) {
        var items = this.state.items;
        for (var i = 0; i < items.length; i++) {
            if (items[i].id == id) {
                return items[i].details;
            }
        }
        return "";
    }

    showDeleteConfirmation = function (e) {
        var id = e.target.id.split('_')[1];
        var title = this.getTitleById(id);
        var details = this.getDetailsById(id);
        this.setState({showDeleteConfirmation: true, selectedIdToModification: id, workNumberTitle: title, workNumberDetails: details});
    }

    showUpdateDialog = function (e) {
        var id = e.target.id.split('_')[1];
        var title = this.getTitleById(id);
        var details = this.getDetailsById(id);
        this.setState({showUpdateDialog: true, selectedIdToModification: id, workNumberTitle: title, workNumberDetails: details});
    }
    showInsertDialog = function (e) {
        this.setState({showInsertDialog: true, selectedIdToModification: '', workNumberTitle: '', workNumberDetails: ''});
    }

    hideDeleteConfirmation = function () {
        this.setState({showDeleteConfirmation: false, selectedIdToModification: -1, workNumberTitle: '', workNumberDetails: ''});

    }

    hideUpdateDialog = function () {
        this.setState({showUpdateDialog: false, selectedIdToModification: -1, workNumberTitle: '', workNumberDetails: ''});
    }
    hideInsertDialog = function () {
        this.setState({showInsertDialog: false, selectedIdToModification: -1, workNumberTitle: '', workNumberDetails: ''});
    }

    deleteRecord = function () {

        try {
            const url = ajax.getUrlBase() + "modules/Booking/deleteWorkNumber.php";

            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({id: this.state.selectedIdToModification})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        this.hideDeleteConfirmation();

                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }
    }

    updateRecord = function () {
        try {
            const url = ajax.getUrlBase() + "modules/Booking/updateWorkNumber.php";

            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({id: this.state.selectedIdToModification, workNumber: this.state.workNumberTitle, workNumberDetails: this.state.workNumberDetails})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        this.state.selectedText = this.state.workNumberTitle;
                        this.hideUpdateDialog();

                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }
    }

    insertRecord = function () {
        try {
            const url = ajax.getUrlBase() + "modules/Booking/insertWorkNumber.php";
            return fetch(url, {
                method: "POST",
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({workNumber: this.state.workNumberTitle, workNumberDetails: this.state.workNumberDetails})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success == true) {
                            this.state.selectedText = this.state.workNumberTitle;
                            this.hideInsertDialog();
                        }
                        if (response.success == false) {
                            this.props.alertDialog(response.fail);
                        }
                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }
    }

    getDeleteConfirmation = function () {

        return <div style={{
                        position: 'relative',
                        top: -175,
                        left: -180,
                        backgroundColor: 'white',
                        border: "solid 1px #00000022",
                        boxShadow: "0px 0px 5px #000000",
                        borderRadius: "5px",
                        minWidth: '400px',
                        minHeight: '130px',
                        textAlign: 'center',
                        paddingTop: 10,
                        paddingBottom: 10,
                        zIndex: 1300
                         }}>
            <div style={{backgroundColor: '#3ab4eb', color: "white"}}><b>Megerősítés kérése!</b></div>
            <div style={{marginTop: '10px'}}>Biztosan törölni  szeretné az alábbi munkaszámot?</div>
            <div style={{marginTop: '0px'}}>{this.state.workNumberTitle}</div>
            <div style={{display: 'flex', textAlign: 'center', justifyContent: 'center', paddingTop: '10px'}}>
                <div className={"bookingFormButton"} onClick={this.deleteRecord.bind(this)} style={{backgroundColor: '#9fd027', minWidth: 70, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>Igen</div>
                <div style={{minWidth: '20px'}}></div>
                <div className={"bookingFormButton"}  onClick={this.hideDeleteConfirmation.bind(this)} style={{minWidth: 70, backgroundColor: 'minWidth:70,', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>Nem</div>
            </div>
        </div>

    }

    getUpdateDialog = function () {

        return <div style={{
                        position: 'relative',
                        top: -170,
                        left: -180,
                        backgroundColor: 'white',
                        border: "solid 1px #00000022",
                        boxShadow: "0px 0px 5px #000000",
                        borderRadius: "5px",
                        minWidth: '400px',
                        minHeight: '130px',
                        textAlign: 'center',
                        paddingTop: 10,
                        paddingBottom: 10,
                        zIndex: 1300
                         }}>
            <div style={{backgroundColor: '#3ab4eb', color: "white", marginBottom: '10px'}}><b>Munkaszám módosítása!</b></div>
            <div style={{marginTop: '0px', display: "flex", marginLeft: '5px'}}>
                <div style={{minWidth: '100px', textAlign: 'left'}}>Munkaszám:</div>  <input onChange={this.changeWorkNumber.bind(this)} style={{minWidth: 285}} className="formInputLight" type="text" value={this.state.workNumberTitle} />
            </div>
            <div style={{marginTop: '0px', display: "flex", marginLeft: '5px'}}>
                <div style={{minWidth: '100px', textAlign: 'left'}}>Részletek:</div>    <input onChange={this.changeWorkNumberDetails.bind(this)} style={{minWidth: 285}} className="formInputLight" type="text" value={this.state.workNumberDetails} />
            </div>
            <div style={{display: 'flex', textAlign: 'center', justifyContent: 'center', paddingTop: '10px'}}>
                <div className={"bookingFormButton"} onClick={this.updateRecord.bind(this)} style={{minWidth: 70, backgroundColor: '#9fd027', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>Mentés</div>
                <div style={{minWidth: '20px'}}></div>
                <div className={"bookingFormButton"} onClick={this.hideUpdateDialog.bind(this)} style={{minWidth: 70, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>Kilépés</div>
            </div>
        </div>

    }

    getInsertDialog = function () {

        return <div style={{
                        position: 'relative',
                        top: -170,
                        left: -180,
                        backgroundColor: 'white',
                        border: "solid 1px #00000022",
                        boxShadow: "0px 0px 5px #000000",
                        borderRadius: "5px",
                        minWidth: '400px',
                        minHeight: '130px',
                        textAlign: 'center',
                        paddingTop: 10,
                        paddingBottom: 10,
                        zIndex: 1300
                         }}>
            <div style={{backgroundColor: '#3ab4eb', color: "white", marginBottom: '10px'}}><b>Munkaszám létrehozása!</b></div>
            <div style={{marginTop: '0px', display: "flex", marginLeft: '5px'}}>
                <div style={{minWidth: '100px', textAlign: 'left'}}>Munkaszám:</div>  <input onChange={this.changeWorkNumber.bind(this)} style={{minWidth: 285}} className="formInputLight" type="text" value={this.state.workNumberTitle} />
            </div>
            <div style={{marginTop: '0px', display: "flex", marginLeft: '5px'}}>
                <div style={{minWidth: '100px', textAlign: 'left'}}>Részletek:</div>    <input onChange={this.changeWorkNumberDetails.bind(this)} style={{minWidth: 285}} className="formInputLight" type="text" value={this.state.workNumberDetails} />
            </div>
            <div style={{display: 'flex', textAlign: 'center', justifyContent: 'center', paddingTop: '10px'}}>
                <div className={"bookingFormButton"} onClick={this.insertRecord.bind(this)} style={{minWidth: 70, backgroundColor: '#9fd027', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>Mentés</div>
                <div style={{minWidth: '20px'}}></div>
                <div className={"bookingFormButton"} onClick={this.hideInsertDialog.bind(this)} style={{minWidth: 70, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>Kilépés</div>
            </div>
        </div>

    }

    getInfoPanel = function (details) {
        return <div style={{
                        position: 'absolute',
                        left: '-205px',
                        top: '26px',
                        background: 'white',
                        minWidth: '225px',
                        maxWidth: '225px',
                        minHeight: '140px',
                        maxHeight: '140px',
                        border: 'solid 1px #3ab4eb',

                         }}>
            <div className={"titleSmall"} style={{textAlign: 'center', backgroundColor: '#3ab4eb', color: '#ffffff'}}><b>Részletek</b></div>            
            <div className={"titleSmall"} style={{textAlign: 'left', whiteSpace: 'normal', overflowY: 'hidden', maxHeight: '120px'}}>{this.state.workNumberDetails}</div>            
        
        </div>
    }

    changeWorkNumber = function (e) {
        this.setState({workNumberTitle: e.target.value});
    }
    changeWorkNumberDetails = function (e) {
        this.setState({workNumberDetails: e.target.value});
    }

    render() {

        // The id,value pair came from the formLoader in object format.
        if (this.props.formComboRef != null) {
            if (this.props.formComboRef.value != null) {
                // After we change the param type from object to integer, the program don't repeating this block.
                if (typeof this.props.formComboRef.value === 'object') {
                    this.setState({selectedID: this.props.formComboRef.value.id, selectedText: this.props.formComboRef.value.value});
                    // Repleacing the value object to integer
                    this.props.formComboRef.value = this.props.formComboRef.value.id;
                } else if (typeof this.props.formComboRef.value === 'string') {
                    if (this.props.formComboRef.value === '') {
                        this.state.selectedID = null;
                        //this.state.selectedText='';
                    }
                }
            }
        }
        var deleteConfirmation = "";
        if (this.state.showDeleteConfirmation) {
            deleteConfirmation = this.getDeleteConfirmation();
        }
        var updateDialog = "";
        if (this.state.showUpdateDialog) {
            updateDialog = this.getUpdateDialog();
        }
        var insertDialog = "";
        if (this.state.showInsertDialog) {
            insertDialog = this.getInsertDialog();
        }

        var dropDown = "";

        if (this.state.dropDownVisible) {
            dropDown = (
                    <div 
                        style={{overflowY: 'auto', maxHeight: '200px', zIndex: 1100, minWidth: 'min-content', backgroundColor: 'white'}}
                        onMouseOver={this.mouseOverDropDownList.bind(this)} 
                        onMouseOut={this.mouseOutDropDownList.bind(this)} 
                    
                        className="dropdown-list">
                    
                        {this.queryResults()}
                    </div>);

        }
        var defaultBackground = (this.props.blackTheme) ? "#313943" : "white";
        var open = (this.props.blackTheme) ? openWhite : openBlack;

        return (<div style={{position: 'absolute', maxWidth: '220px', minWidth: '220px', maxHeight: '20px', display: 'flex'}}>
            <img 
                onClick={this.showInsertDialog.bind(this)} 
                style={{cursor: 'pointer', maxWidth: '25px', marginTop: 5}} 
                src={addBlack}
                />
            <div style={{zIndex: '100', backgroundColor: defaultBackground, maxHeight: '17px', minWidth: 135, maxWidth: 135}}>
                {this.textField()}
                {dropDown}
        
            </div>
            <div onClick={this.onOpenerClick.bind(this)} style={{
                        cursor: 'pointer',
                        display: 'flex',
                            maxHeight: '20px',
                            minHeight: '20px',
                            minWidth: '30px',
                            maxWidth: '30px',
                            top: 2,
                            zIndex: 1200,
                            right: '8px',
                            justifyContent: 'center'
                        }}>
                <img 
                    onClick={this.onOpenerClick.bind(this)} 
                    style={{cursor: 'pointer', position: 'absolute', maxWidth: '10px', top: '10px', right: '30px'}} 
                    src={open}
                    />
                <img 
                    onClick={this.resetFormValue.bind(this)} 
                    style={{cursor: 'pointer', position: 'absolute', maxWidth: '20px', top: '3px', right: '1px'}} 
                    src={cleanBlack}
                    />
            </div>
            {deleteConfirmation}
            {updateDialog}
            {insertDialog}
        </div>
                )
    }
}
export default FormComboWorkNumber;
