import React, { Component } from 'react';
import DocuIcoWhite from './../../img/white/receipt_long_white_24dp.svg';
import DocuIcoBlack from './../../img/black/receipt_long_black_24dp.svg';
import InfoIco from './../../img/black/info_black_24dp.svg';
import CustomDate from './../../Components/CustomDate';
import BookingFormPaging from './BookingFormPaging';
import * as ajax from './../../ajax';
import FormComboPartner from './../../Components/FormComboPartner';
import openBlack from './../../img/black/operner.svg';
import openWhite from './../../img/white/operner.svg';
import './../../css/popup.css';
import FormComboWorkNumber from './../../Components/FormComboWorkNumber';
import FormComboMegjegyzes from './../../Components/FormComboMegjegyzes';

import attachEmailBlack from './../../img/black/attach_email_black_24dp.svg';
import attachEmailWhite from './../../img/white/attach_email_white_24dp.svg';



class InvoiceViewer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: {
                pdf: null

            }
        }

        this.loadRecord(this.props.selectedRowId);
    }

    componentDidUpdate(prevProps) {

        if (this.props.selectedInvoice == null) {
            return false;
        }

        if (prevProps.selectedInvoice == null && this.props.selectedInvoice == null) {
            return false;
        }

        if (prevProps.selectedInvoice == this.props.selectedInvoice) {
            return false;
        }
        this.loadRecord(this.props.selectedInvoice);
        
    }

  
    
    loadRecord = function (recordId) {

        try {
            const url = ajax.getUrlBase() + "modules/Booking/selectRecord.php";
            return fetch(url, {
                method: "POST",
                //credentials: "include",
                //credentials: "*", // Tesztüzemben
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({id: recordId})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success == false) {
                            this.props.alertDialog(response.fail);
                        } else {
                            if (typeof response.data != 'undefined' && response.data != null) {
                                this.state.data = response.data;
                                this.setState({data:response.data});
                            }
                        }
                    }).catch(function (jsonError) {

                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }
    }

    closeBookingForm = function () {
        this.props.closeBookingForm();
    }



    getBase64 = function (file, fileName, isZip) {
        var reader = new FileReader();
        var self = this;
        reader.readAsDataURL(file);
        reader.onload = function () {

            self.state.lastFilename = '';
            self.state.lastBlobUrl = '';

            self.state.data.pdf = reader.result;
            self.state.data.FILENEVE = fileName;
            if (!isZip) {
                self.setState({});
            } else {
                self.serverSideFileExtractor(self.state.data.pdf, self.state.data.FILENEVE);
            }
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    isZip = function (fileName) {
        if (typeof fileName == "undefined") {
            return false;
        }
        if (fileName == null) {
            return false;
        }
        if (fileName.trim() == "") {
            return false;
        }

        var fileNameAndExtension = fileName.split(".");
        if (fileNameAndExtension.length < 2) {
            return false;
        }

        var extension = fileNameAndExtension[fileNameAndExtension.length - 1];
        extension = extension.trim();
        extension = extension.toLowerCase();

        var isZip = (extension == "zip") ? true : false;

        return isZip;

    }

    serverSideFileExtractor = function (zip, fileName) {
        try {
            const url = ajax.getUrlBase() + "modules/Booking/serverSideFileExtractor.php";
            return fetch(url, {
                method: "POST",
                //credentials: "include",
                //credentials: "*", // Tesztüzemben
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({zip: zip, fileName: fileName})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (typeof response.data != 'undefined' && response.data != null) {
                            this.state.data.pdf = response.data.pdf;
                            this.state.data.FILENEVE = response.data.fileName;
                            this.setState({});
                        }
                    }
                    ).catch(function (jsonError) {
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");
                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));
                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }

    }

    b64toBlob = function (dataURI) {
        if (dataURI == null) {
            return null;
        }

        var byteString = atob(dataURI.split(',')[1]);
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);

        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], {type: 'application/pdf'});
    }


    render() {

        var alert1 = (this.state.diff1Error) ? this.correctionDialog1() : '';
        var alert2 = (this.state.diff2Error) ? this.correctionDialog2() : '';
        var alert3 = (this.state.diff3Error) ? this.correctionDialog3() : '';


        if (this.state.data.FILENEVE != this.state.lastFilename) {
            const blob = this.b64toBlob(this.state.data.pdf);
            var blobUrl = null;

            if (blob != null) {
                blobUrl = URL.createObjectURL(blob);
            }

            this.state.lastFilename = this.state.data.FILENEVE;
            this.state.lastBlobUrl = blobUrl;
        }


        return <div style={{width: '100%', margin: '5px', height: '85vh'}}>
        
        
            <div style={{display: 'flex'}}>
                {alert1}{alert2}{alert3}
                <BookingFormPaging 
                    loadRecord ={this.loadRecord.bind(this)}
                    selectedRowId={this.props.selectedRowId} 
                    bookingFormTableFilters={this.props.bookingFormTableFilters} 
                    invoiceDirection={this.props.invoiceDirection}
                    bookingFormReference={this}
                    />
        
        
        
            </div>
            <iframe style={{width: '100%', height: '82vh'}} src={ this.state.lastBlobUrl}/>
            <div style={{width: '100%', height: '2vh',textAlign:'center'}}>
                <button 
                    onClick={this.closeBookingForm.bind(this)} 
                    className={"bookingFormButton"} 
                    style={{width: 52, height: 19}}>Kilépés</button>
            </div>
        
        
        
        </div>
    }
}

export default InvoiceViewer;