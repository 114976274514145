import React, { Component } from 'react';
import ToggleOn from './../img/black/toggle_on_black_24dp.svg';
import ToggleOff from './../img/black/toggle_off_black_24dp.svg';


class BooleanEditor extends React.Component {

    constructor(props) {
        super(props);
        this.state={
            checked:(this.props.editorProps.cellProps.value==1)?true:false,
            id:this.props.editorProps.cellProps.data.id,
            key:this.props.editorProps.cellProps.id
        }
    }
    
    componentDidUpdate(prevProps) {
        if (prevProps.editorProps.cellProps.value !== this.props.editorProps.cellProps.value) {
            this.setState({checked:(this.props.editorProps.cellProps.value==1)?true:false,});
        }
    }
    
    onClick=function(){
        this.state.checked=!this.state.checked;
        if(typeof this.props.callback != 'undefined'){
            var checked = (this.state.checked)?1:0;
            this.props.callback(this.state.id,this.state.key,checked);
            
        }
        //this.setState({});
    }
    
    onKeyUp=function(e){
        alert("itttt");
    }
    

    render() {
        var toggle = (this.state.checked)?ToggleOn : ToggleOff
        return <div onKeyUp={this.onKeyUp.bind(this)}><img style={{minHeight:'30px',minWidth:'40px'}} onClick={this.onClick.bind(this)} src={toggle} alt="on_off"/></div>
    }
}

export default BooleanEditor;