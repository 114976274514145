import React from 'react';
import {ProjectDescriptor, FieldDescriptor, Layout}  from './../../utils.js';
import * as ajax from './../../ajax.js';
//import Panel from './../../Components/Panel';
import BasicForm from './../../Components/BasicForm';


class AccountantManager extends React.Component {

    constructor(props) {
        super(props);
        var endpoint = ajax.getUrlBase() + 'modules/AccountantManager/';
        var formTitle = 'Könyvelő';
        var projectDescriptor = new ProjectDescriptor('AccountantManager',endpoint, formTitle);
        projectDescriptor.setFormDimensions(550, 290);

        var id = new FieldDescriptor(true, FieldDescriptor.TYPE_NUMBER, 'id', '#', '');

        var firstName = new FieldDescriptor(false, FieldDescriptor.TYPE_TEXT, 'firstName', 'Vezetéknév', '');
        firstName.setJump(2);
        var lastName = new FieldDescriptor(false, FieldDescriptor.TYPE_TEXT, 'lastName', 'Keresztnév', '');
        var mail = new FieldDescriptor(false, FieldDescriptor.TYPE_TEXT, 'mail', 'E-mail', '');
        var loginName = new FieldDescriptor(false, FieldDescriptor.TYPE_TEXT, 'loginName', 'Felhasználónév', '');
        var loginPass = new FieldDescriptor(false, FieldDescriptor.TYPE_TEXT, 'loginPass', 'Felhasználó jelszó', '');
        //var myCompany = new FieldDescriptor(false, FieldDescriptor.TYPE_BOOLEAN, 'myCompany', 'Illetékes', '');
        
        var myCompany = new FieldDescriptor(false, FieldDescriptor.TYPE_SELECT, 'myCompany', 'Illetékes', [{id:0,label:'Nem'},{id:1,label:'Igen'}]);
        
       // var myCompany = new FieldDescriptor(false, FieldDescriptor.TYPE_BOOLEAN, 'myCompany', 'Illetékes', '');
        
        
        var createdAt = new FieldDescriptor(false, FieldDescriptor.TYPE_TEXT, 'createdAt', 'Létrehozva', '');
        

        projectDescriptor.addFieldDescriptor(id);
        projectDescriptor.addFieldDescriptor(firstName);
        projectDescriptor.addFieldDescriptor(lastName);
        projectDescriptor.addFieldDescriptor(mail);
        projectDescriptor.addFieldDescriptor(loginName);
        projectDescriptor.addFieldDescriptor(loginPass);
        projectDescriptor.addFieldDescriptor(myCompany);
        projectDescriptor.addFieldDescriptor(createdAt);
        



        var layout = new Layout();
        layout.addColumns([0, 120, 270, 400])
        layout.addRows([0, 34, 68, 102,136])
        this.projectDescriptor = projectDescriptor;
        this.layout = layout;
    }

    render() {
        return <BasicForm blackTheme={this.props.blackTheme} alertDialog={this.props.alertDialog.bind(this)}  layout={this.layout} projectDescriptor={this.projectDescriptor} />
    }
}

export default AccountantManager;