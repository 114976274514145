import React, { Component } from 'react';
import calendar_icoDark from "./../img/white/edit_calendar_white_24dp.svg";
import calendar_icoLight from "./../img/black/edit_calendar_black_24dp.svg";

class DateEditor extends React.Component {

    constructor(props) {
        super(props);
        var currentValue = (this.props.editorProps.value == null) ? '' : this.props.editorProps.value;
        this.state = {value: currentValue}
    }

    componentDidUpdate(prevProps) {
        if (prevProps.editorProps.value !== this.props.editorProps.value) {
            var currentValue = (this.props.editorProps.value == null) ? '' : this.props.editorProps.value;
            this.setState({value: currentValue});
        }
    }

    onDateChanged = function (e) {
        this.setState({value: e.target.value});
    }

    onKeyDown = function (e) {
        if (e.keyCode == 13 || e.keyCode == 9) { // 13. Enter , 9 Tab
            this.props.editorProps.onComplete();
            this.props.updateRecord(this.props.editorProps.cellProps.data.id, this.props.editorProps.cellProps.id, this.state.value);
            this.props.editorProps.onTabNavigation(
                    true /*complete navigation?*/,
                    e.shiftKey ? -1 : 1 /*backwards of forwards*/
                    );
        }

    }

    showCalendar = function (e) {
        this.props.state.defaultUpdateBlocked = true;
        this.props.showCalendar(this.props.editorProps);
    }

    dateToDotted = function (dateStr) {
        if (typeof dateStr == "undefined") {
            return '';
        }
        if (dateStr == null) {
            return '';
        }
        return  dateStr.replaceAll("-", ".");
    }

    render() {

        var calendarButton = (this.props.blackTheme) ? "calendarButtonDark" : "calendarButtonLight";
        var calendarInput = (this.props.blackTheme) ? "calendarInputDark" : "calendarInputLight";
        var calendar_ico = (this.props.blackTheme) ? calendar_icoDark : calendar_icoLight;
        var dottedValue = this.dateToDotted(this.state.value);

        return <div style={{display: 'flex'}}>
            <input 
                autoFocus
                className={calendarInput}
                style={{width: '90%'}}
                onBlur={this.props.editorProps.onComplete.bind(this)}
                onKeyDown={this.onKeyDown.bind(this)}
                onChange={this.onDateChanged.bind(this)}
                value={dottedValue} 
                type="text"/>
            <div className={calendarButton} style={{width: '30px', marginTop: '3px'}} onMouseDown={this.showCalendar.bind(this)} >
                <img onMouseDown={this.showCalendar.bind(this)}  src={calendar_ico}/>
            </div>
        </div>
    }
}

export default DateEditor;