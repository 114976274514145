import React, { Component } from 'react';
import DarkWhite from './../img/white/dark_mode_white_24dp.svg';
import DarkBlack from './../img/black/dark_mode_black_24dp.svg';
import LightWhite from './../img/white/light_mode_white_24dp.svg';
import LightBlack from './../img/black/light_mode_black_24dp.svg';

class DarkOrWhiteMode extends React.Component {

    constructor(props) {
        super(props);
        
    }
    
    changeState = function(){
        this.props.setTheme(!this.props.blackTheme);
    }
    

    render() {
        
        var icon = DarkBlack;
        
        if(this.props.blackTheme){
            icon = DarkBlack;
        }else{
            icon = LightBlack;
        }
        
        return <div style={{cursor:'pointer'}} onClick={this.changeState.bind(this)} >
        <img title="Nappali és éjszakai mód váltása." onClick={this.changeState.bind(this)} src={icon} style={{cursor:'pointer',maxWidth:'20px',maxHeight:'20px',marginRight:'20px',marginTop:'8px'}} />
        </div>
    }
}

export default DarkOrWhiteMode;