import React from 'react';
import BasicForm from './../../Components/BasicForm'
//import Panel from './../../Components/Panel'
import * as ajax from './../../ajax.js';

import {ProjectDescriptor, FieldDescriptor,Layout}  from './../../utils.js';
   class MasterData extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        
          var endpoint = ajax.getUrlBase() + 'modules/Partner/';
            var formTitle = 'Partner form';
            var projectDescriptor = new ProjectDescriptor('Partner',endpoint,formTitle);
            projectDescriptor.setFormDimensions(550,294);
            var id = new FieldDescriptor(true, FieldDescriptor.TYPE_NUMBER, 'id', '#', '');
            //var taxNumber = new FieldDescriptor(false, FieldDescriptor.TYPE_TAX_NUMBER_FIELD, 'taxNumber', 'Adószám', '');
            var taxNumber = new FieldDescriptor(false, FieldDescriptor.TYPE_TEXT, 'taxNumber', 'Adószám', '');
            taxNumber.setJump(2);
            var partnerName = new FieldDescriptor(false, FieldDescriptor.TYPE_TEXT, 'partnerName', 'Név', '');
            partnerName.setRef('partnerName');
            var country = new FieldDescriptor(false, FieldDescriptor.TYPE_TEXT, 'country', 'Ország', '');
            var zipCode = new FieldDescriptor(false, FieldDescriptor.TYPE_TEXT, 'zipCode', 'IRSZ', '');
            zipCode.setRef('zipCode');
            var city = new FieldDescriptor(false, FieldDescriptor.TYPE_TEXT, 'city', 'Város', '');
            city.setRef('city');
            var address = new FieldDescriptor(false, FieldDescriptor.TYPE_TEXT, 'address', 'Cím', '');
            address.setRef('address');
            var bankszamlaszam = new FieldDescriptor(false, FieldDescriptor.TYPE_TEXT, 'bankszamlaszam', 'Bankszámlaszám', '');
            bankszamlaszam.setRef('address');
            
            
           
            projectDescriptor.addFieldDescriptor(id);
            projectDescriptor.addFieldDescriptor(taxNumber);
            projectDescriptor.addFieldDescriptor(partnerName);
            projectDescriptor.addFieldDescriptor(country);
            projectDescriptor.addFieldDescriptor(zipCode);
            projectDescriptor.addFieldDescriptor(city);
            projectDescriptor.addFieldDescriptor(address);
            projectDescriptor.addFieldDescriptor(bankszamlaszam);
            

            var layout = new Layout();
            layout.addColumns([0, 120, 270, 400])
            layout.addRows([0, 34, 68, 102,136,170])

            
        
        
        return <BasicForm 
        blackTheme={this.props.blackTheme} 
        alertDialog={this.props.alertDialog.bind(this)} 
        layout={layout} 
        projectDescriptor={projectDescriptor} 
        companyInfoButtonVisible={true}
        companyBackgroundInfoPanelOpen ={this.props.companyBackgroundInfoPanelOpen.bind(this)}
        isPartner ={true}
        isCompany={false}
        frameState={this.props.frameState}
        />;
    }
}

export default MasterData;