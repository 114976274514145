import React, { Component } from 'react';
import {ProjectDescriptor, FieldDescriptor, Layout}  from './../../utils.js';
import * as ajax from './../../ajax.js';
import Panel from './../../Components/Panel';
import BasicForm from './../../Components/BasicForm';
import PopUpWindow  from './../../Components/PopUpWindow';

class DocStore extends React.Component {

    constructor(props) {
        super(props);

        this.state = {popUpVisible: false, currentDoc: null}

        var endpoint = ajax.getUrlBase() + 'modules/DocStore/';
        var formTitle = 'Dokumentum';
        var projectDescriptor = new ProjectDescriptor('DocStore', endpoint, formTitle);
        projectDescriptor.setFormDimensions(550, 300);
        

        var id = new FieldDescriptor(true, FieldDescriptor.TYPE_NUMBER, 'id', 'Iktatószám', '');
        var doc = new FieldDescriptor(false, FieldDescriptor.TYPE_FILE_UPLOADER, 'doc', 'Fájl', {filename:'',file:''});
        doc.setJump(2);
        doc.setVisibleTableField(false);
        
        var fileName = new FieldDescriptor(false, FieldDescriptor.TYPE_LABEL, 'fileName', 'Fájl neve', '');
        fileName.setVisibleFormField(false);
        var createdAt = new FieldDescriptor(false, FieldDescriptor.TYPE_LABEL, 'createdAt', 'Készítve', '');
        createdAt.setJump(2);
        
        var docViewer = new FieldDescriptor(false, FieldDescriptor.TYPE_ACTION_BUTTON, 'actionId', 'Megnyitás', '');
        docViewer.setVisibleFormField(false);
        docViewer.setAction(this.showDocument.bind(this));
        
        var sharedDoc = new FieldDescriptor(false, FieldDescriptor.TYPE_BOOLEAN, 'sharedDoc', 'Közös', '');
            sharedDoc.setJump(2) ;
            
        var docComment =new FieldDescriptor(false, FieldDescriptor.TYPE_COMMENT_FIELD, 'docComment', 'Megjegyzés', '');   
        docComment.setCommentFieldWidth(397);
        docComment.setJump(2);
            
        projectDescriptor.addFieldDescriptor(id);
        projectDescriptor.addFieldDescriptor(doc);
        projectDescriptor.addFieldDescriptor(fileName);
        //projectDescriptor.addFieldDescriptor(sharedDoc);
        projectDescriptor.addFieldDescriptor(createdAt);
        projectDescriptor.addFieldDescriptor(docComment);
        projectDescriptor.addFieldDescriptor(docViewer);


        var layout = new Layout();
        layout.addColumns([0, 120, 270, 385])
        layout.addRows([0, 34, 68, 102, 136, 170])
        this.projectDescriptor = projectDescriptor;
        this.layout = layout;
    }
    
    
    
    showDocument = function (id) {
          try {
            const url = ajax.getUrlBase() + "modules/DocStore/getDocument.php";
            return fetch(url, {
                method: "POST",
                // credentials: "include",
                // credentials: "same-origin",
                // credentials: "*", // Tesztüzemben
                headers: new Headers({"Content-Type": "application/json"}),
                body: JSON.stringify({id:id})
            }).then(response => {
                if (response.ok) {
                    return response.json().then(response => {
                        if (response.success) {
                            this.setState({popUpVisible: true, currentDoc: response.data});
                        } else {
                            this.setState({loginSuccess: false});
                        }

                    }).catch(function (jsonError) {
                        if (null == jsonError) {
                            console.error("Fetch JSON error:");

                        } else {
                            console.error("Fetch JSON error:" + String(jsonError));

                        }
                    });
                } else {
                    console.error("HTTP status error:", response.statusText);
                }
            }).catch(function (error) {
                console.error("Fetch error:", error);
                if (null == error) {
                    console.error("Fetch error:");
                } else {
                    console.error("Fetch error:" + String(error));
                }
            });
        } catch (ex) {
            console.error("Fetch exception:", String(ex));
        }
    }

    hideDocument = function () {
        this.setState({popUpVisible: false});
    }

    b64toBlob = function (dataURI) {
        if (dataURI == null) {
            return null;
        }
        var parts =dataURI.split(',');
        var extension = parts[0];
        var mimeType = extension.substring("data:application/".length, extension.indexOf(";base64"));
        
        if(!mimeType.includes("pdf")){
            return dataURI;
        }
        var byteString = atob(parts[1]);
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);

        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        
        var blob = new Blob([ab], {type: 'application/pdf'});
        return URL.createObjectURL(blob);
    }


    render() {
        var popUp = null;
        
        var panelBackground = (this.props.blackTheme)?"#313943":"#FFFFFF";
        
        if (this.state.popUpVisible == true) {
            
              
            var blobUrl=null;
            try {
                 blobUrl = this.b64toBlob(this.state.currentDoc);
            } catch (e) {

            }

            
            popUp = <PopUpWindow blackTheme={this.props.blackTheme}>
                <div style={{width: '100%',  height: '90%'}}>
                    <iframe style={{width: '100%', height: '100%'}} src={blobUrl}/>
                </div>
                <div style={{width: '100%',  marginTop: '40px',layout:'flex',textAlign:'center'}}>
                <button 
                style={{textAlign:'center'}} 
                onClick={this.hideDocument.bind(this)}
                className={"bookingFormButton"}>Bezárás</button>
                </div>
            </PopUpWindow>;
        }
        return <Panel>
            {popUp}
            <BasicForm blackTheme={this.props.blackTheme} alertDialog={this.props.alertDialog.bind(this)} layout={this.layout} projectDescriptor={this.projectDescriptor} />
        </Panel>
    }
}

export default DocStore;