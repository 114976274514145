import React from 'react';
import './../../css/login.css';
import * as ajax from './../../ajax';
import Logo from './../../css/logo.png';
import PasswordChange from './PasswordChange';
import ProgressWindow from './../../Components/ProgressWindow';

class Login extends React.Component {

   constructor(props) {
      super(props);
      this.state = {
         passwordSended: false,
         passwordChangeVisible: false,
         passwordMailFailure: false,
         loginSuccess: true,
         needProgress: true,
         userName: '',
         password: '',
         fail: ''
      }

      this.onLoginCheck();
   }

   onLoginCheck = function () {
      try {
         const url = ajax.getUrlBase() + "secure/loginCheck.php";
         return fetch(url, {
            method: "POST",
            // credentials: "include",
            // credentials: "same-origin",
            // credentials: "*", // Tesztüzemben
            headers: new Headers({ "Content-Type": "application/json" }),
            body: JSON.stringify({})
         }).then(response => {
            if (response.ok) {
               return response.json().then(response => {
                  if (response.success) {
                     this.props.setLoginState(response);
                  } else {
                     this.setState({ loginSuccess: false, fail: 'Sikertelen bejelentkezés' });
                  }
                  
                  this.setState({ needProgress: false });
               }).catch( jsonError => {
                  if (null == jsonError) {
                     console.error("Fetch JSON error:");

                  } else {
                     console.error("Fetch JSON error:" + String(jsonError));
                  }

                  this.setState({ needProgress: false });
               });
            } else {
               console.error("HTTP status error:", response.statusText);
               this.setState({ needProgress: false });
            }
         }).catch( error => {
            console.error("Fetch error:", error);

            if (null == error) {
               console.error("Fetch error:");
            } else {
               console.error("Fetch error:" + String(error));
            }

            this.setState({ needProgress: false });
         });
      } catch (ex) {
         console.error("Fetch exception:", String(ex));
         this.setState({ needProgress: false });
      }
   }

   onLoginEnter = (event) => {
      if ((event.code !== undefined && (event.code === 'Enter' || event.code === 'NumpadEnter')) ||
         (event.key !== undefined && (event.key === 'Enter' || event.key === 'NumpadEnter')) ||
         (event.keyCode !== undefined && (event.keyCode === 13 || event.keyCode === 13))) {

         this.onLogin();
      };
   }

   onLogin = function () {
      try {
         this.setState({ needProgress: true });
         const url = ajax.getUrlBase() + "secure/login.php";
         return fetch(url, {
            method: "POST",
            // credentials: "include",
            // credentials: "same-origin",
            // credentials: "*", // Tesztüzemben
            headers: new Headers({ "Content-Type": "application/json" }),
            body: JSON.stringify({ userName: this.state.userName, password: this.state.password })
         }).then(response => {
            if (response.ok) {
               return response.json().then(response => {
                  this.setState({ passwordSended: true, needProgress: false });
                  
                  if (response.success) {
                     this.props.setLoginState(response);
                  } else {
                     this.setState({ loginSuccess: false, fail: response.fail });
                  }
               }).catch( jsonError => {
                  if (null == jsonError) {
                     console.error("Fetch JSON error:");

                  } else {
                     console.error("Fetch JSON error:" + String(jsonError));
                  }

                  this.setState({ needProgress: false });
               });
            } else {
               console.error("HTTP status error:", response.statusText);
               this.setState({ needProgress: false });
            }
         }).catch( error => {
            console.error("Fetch error:", error);
            if (null == error) {
               console.error("Fetch error:");
            } else {
               console.error("Fetch error:" + String(error));
            }
            
            this.setState({ needProgress: false });
         });
      } catch (ex) {
         console.error("Fetch exception:", String(ex));
         this.setState({ needProgress: false });
      }
   }

   setUserName = function (e) {
      this.setState({ userName: e.target.value })
   }

   setPassword = function (e) {
      this.setState({ password: e.target.value })
   }

   passwordChange = function (e) {
      this.setState({ passwordChangeVisible: true });
   }

   sendigPasswordChangeMail = function () {
      try {
         this.setState({ needProgress: true });
         const url = ajax.getUrlBase() + "secure/passwordChangeEmail.php";
         return fetch(url, {
            method: "POST",
            headers: new Headers({ "Content-Type": "application/json" }),
            body: JSON.stringify({ userName: this.state.userName })
         }).then(response => {
            if (response.ok) {
               return response.json().then(response => {
                  if (response.success === false) {
                     this.setState({ fail: response.fail, passwordMailFailure: true, needProgress: false });
                  } else {
                     this.setState({ passwordChangeVisible: true, passwordMailFailure: false, needProgress: false });
                  }
               }).catch( jsonError => {
                  if (null == jsonError) {
                     console.error("Fetch JSON error:");

                  } else {
                     console.error("Fetch JSON error:" + String(jsonError));
                  }

                  this.setState({ needProgress: false });
               });
            } else {
               console.error("HTTP status error:", response.statusText);
               this.setState({ needProgress: false });
            }
         }).catch( error => {
            console.error("Fetch error:", error);
            if (null == error) {
               console.error("Fetch error:");
            } else {
               console.error("Fetch error:" + String(error));
            }
            
            this.setState({ needProgress: false });
         });
      } catch (ex) {
         console.error("Fetch exception:", String(ex));
         this.setState({ needProgress: false });
      }
   }

   render() {

      var passwordChange = "";

      if (this.state.passwordChangeVisible) {
         passwordChange = <PasswordChange />
      }

      var loginSuccess = ((this.state.passwordSended === true || this.state.passwordMailFailure === true) && this.state.loginSuccess === false) ? 'loginFail' : "loginSuccess";

      let loginProgressPopup = this.state.needProgress ? <ProgressWindow /> : '';

      return <div className={"loginWindow"}>
         <div className={"loginBox"} onKeyDown={this.onLoginEnter}>
            <div className={"loginRow"}>
               <div style={{ marginBottom: '30px' }} className={"loginInput"}>
                  <img className={"logoStyle"} src={Logo} alt="QualitySoft Logo" />
               </div>
            </div>
            <div className={"loginHeader"}  >
               <p className={"loginHeaderText"}>JELENTKEZZEN BE A FIÓKJÁBA</p>
            </div>
            <div className={"loginRow"}>
               <div className={"loginInput"}>
                  <input placeholder="Felhasználónév*" onChange={this.setUserName.bind(this)} className={"loginTextField"} value={this.state.userName} type="text" autoFocus />
               </div>
            </div>
            <div className={"loginRow"}>
               <div className={"loginInput"}>
                  <input placeholder="Jelszó*" onChange={this.setPassword.bind(this)} className={"loginTextField"} value={this.state.password} type="password" />
               </div>
            </div>

            <div style={{ display: 'flex', marginTop: '50px' }}>
               <div style={{ display: 'flex', width: '100%', textAlign: 'center', justifyContent: 'center' }}>
                  <button onClick={this.onLogin.bind(this)} className={"loginButton"} >Bejelentkezés</button>
               </div>
            </div>
            <div style={{ display: 'flex', marginTop: '20px' }}>
               <div style={{ display: 'flex', width: '100%', textAlign: 'center', justifyContent: 'center' }}>
                  <div
                     style={{ textDecoration: 'underline', cursor: 'pointer' }}
                     className={"forgottPassword"}
                     onClick={this.sendigPasswordChangeMail.bind(this)} >Elfelejtette a jelszavát?</div>
               </div>
            </div>
            <div style={{ display: 'flex', marginTop: '0px' }}>
               <div className={loginSuccess}>
                  {this.state.fail}
               </div>
            </div>
         </div>

         {passwordChange}

         {loginProgressPopup}

      </div>;
   }
}

export default Login;